import React, {  useState } from "react";
import {
    Box,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTextField from "../../common/CustomTextField";
import Button from "../../common/Button";
import { Cancel, CheckCircleOutline } from "@mui/icons-material";
import dayjs from "dayjs";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import { RESPONSE_CODES } from "../../../constants/applicationConstants";
import { AddPaymentInfo, saveRecordPaymentInfo } from "../../../redux/slice/payment/paymentSlice";

const AddPaymentDetail = () => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>({});

    const navigate = useNavigate();
    const [submitAction, setSubmitAction] = useState<string>();
    const { state } = useLocation();
    const dispatch = useAppDispatch();


    const onSubmit = async (data: AddPaymentInfo) => {
        if(submitAction==="addPayMentDetail"){
            const paymentData={
                    invoiceId:state.invoiceId,
                    paymentDate: dayjs(data?.paymentDate).format("MM/DD/YYYY"),
                    paidAmount:data?.paidAmount,
                    paymentComments: data?.paymentComments
         
            }

          

            if(paymentData){
                const response = await dispatch(saveRecordPaymentInfo(paymentData))
                if (response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
                                    let alertMessage = "Payment recorded successfully!";
                                    const alertObj: SingleAlertInfo = {
                                        message: alertMessage,
                                        alertType: "success",
                                    };
                                    dispatch(setSingleAlertObj(alertObj));
                                    navigate(-1);
                                } else if (response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
                                    let alertMessage = response.payload?.message;
                                    const alertObj: SingleAlertInfo = {
                                        message: alertMessage,
                                        alertType: "error",
                                    };
                                    dispatch(setSingleAlertObj(alertObj));
                                }
            }
            
        }
      
    };

    return (
        <Box sx={{
            margin: {
                xs: "20px 10px",
                sm: "20px",
            },
        }}>

            <Header title="Record Payment" subtitle="Record Payment Management" />
            <Box>
                <Card>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box p={1}>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12}>
                                    <Typography>Please add payment details.</Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2} mb={2} mt={2}>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        INV # :
                                    </Typography>
                                    <Typography component="span" style={{ paddingLeft: "10px" }}>
                                        {state.invNumber}
                                    </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography component="label" fontWeight="bold">
                                        Practice :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.practice}
                                    </Typography>
                                </Grid>

                                <Grid item md={3}>
                                    <Typography component="label" fontWeight="bold">
                                        Invoice Amount :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.invoiceAmount}
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        INV Date :
                                        {/* {orderInfo?.orderNumber} */}
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.invDate}
                                    </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography component="label" fontWeight="bold">
                                        MFR :
                                        {/* {orderInfo?.poNumber} */}
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.mfr}
                                    </Typography>
                                </Grid>

                                <Grid item md={3}>
                                    <Typography component="label" fontWeight="bold">
                                        Credit Amount :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.creditAmount}
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }} />
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12} md={3} >
                                    <CustomDatePicker
                                        controllerName="paymentDate"
                                        control={control}
                                        label="Payment Date"
                                        rules={
                                            submitAction === "addPayMentDetail"
                                                ? { required: "Payment date is required" }
                                                : {}
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} >
                                </Grid>
                                <Grid item xs={12} md={3} >
                                </Grid>
                                <Grid item xs={12} md={3} >
                                </Grid>
                                <Grid item xs={12} md={3} >
                                    <CustomCurrencyField
                                        controllerName="paidAmount"
                                        control={control}
                                        label="Amount"
                                        rules={
                                            submitAction === "addPayMentDetail"
                                                ? { required: "Amount is required", min: { value: 0.01, message: "Minimum value allowed is 0.01" } }
                                                : {}
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} >
                                </Grid>
                                <Grid item xs={12} md={3} >
                                </Grid>
                                <Grid item xs={12} md={3} >
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        control={control}
                                        controllerName="paymentComments"
                                        label="Comments"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                            </Grid>
                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button
                                    size="large"
                                    startIcon={<CheckCircleOutline />}
                                    sx={{ mr: 1 }}
                                    type="submit"
                                    onClick={() => {
                                        setSubmitAction("addPayMentDetail")
                                    }}

                                >
                                    Record Payment
                                </Button>
                                <Button
                                    color="error"
                                    startIcon={<Cancel />}
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Card>
            </Box>
        </Box>
    );
};

export default AddPaymentDetail;