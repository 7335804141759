import { UserCreateObj } from "../components/operation/user/CreateUser";
import { ResetPassword, SearchUsers, UserCreate, UserNotificationPreference, UserPreference } from "../redux/slice/userSlice";
import { FETCH_SECURITY_TOKENS_URL, FETCH_USER_PREFERENCE_URL, GET_ALL_USERROLES_URL, GET_USERPROFILE_BY_ID, INACTIVATE_ACTIVATE_USER_URL, RESET_PASSWORD_URL, SAVE_NOTIFICATION_PREFERENCE_URL, SAVE_USER_PREFERENCE_URL, SAVE_USER_PROFILE_URL, SEARCH_NOTIFICATION_PREFERENCE_URL, SEARCH_USER_LIST_URL } from "./URL";
import { http } from "./http";

export const getAllUserRoles = async () => {
    const response = await http.get(GET_ALL_USERROLES_URL);
    return response;
};

export const getUserProfileById = async (userId: number) => {
    const response = await http.get(`${GET_USERPROFILE_BY_ID}/${userId}`);
    return response;
}

export const saveUserProfile = async (saveUserProfile: UserCreateObj) => {
    try{
        const response = await http.post(SAVE_USER_PROFILE_URL, saveUserProfile, undefined, {skipErrorPopup: true});
        return response;
    }
    catch(error){
        throw error;
    }
};

  export const getUserList = async (data: SearchUsers) => {
    const response = await http.post(SEARCH_USER_LIST_URL, data);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
  };

  export const changePassword = async(passwordObj: ResetPassword) => {
    const response = await http.post(RESET_PASSWORD_URL,passwordObj);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};

export const inactivateActivateUserProfile = async(userId: number, action: string) => {
    const response = await http.get(`${INACTIVATE_ACTIVATE_USER_URL}/${userId}/${action}`);
    const v3Response = response.data;
    const resData = v3Response.data;
    return resData;
};


export const searchNotificationPreferenceData = async (preference: UserNotificationPreference) => {
    const response = await http.post(SEARCH_NOTIFICATION_PREFERENCE_URL, preference);
    return response;
};
export const saveNotificationPreferenceData = async (preference: UserNotificationPreference) => {
    const response = await http.post(SAVE_NOTIFICATION_PREFERENCE_URL, preference);
    return response;
};

export const savePreferenceData = async (preference: UserPreference) => {
    const response = await http.post(SAVE_USER_PREFERENCE_URL, preference);
    return response;
};

export const getUserPreferenceData = async (userId: number) => {
    const response = await http.get(`${FETCH_USER_PREFERENCE_URL}?userId=${userId}`);
    return response;
};

export const getSecurityTokensByUserIdAndTokenName = async (userId: number, roleId: number, tokenName: string) => {
    const response = await http.get(`${FETCH_SECURITY_TOKENS_URL}?userId=${userId}&roleId=${roleId}&tokenName=${tokenName}`);
    return response;
};