import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Grid, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import Button from "../../common/Button";
import { SaveOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomDatePicker from '../../common/CustomDatePicker';
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { updateInvoice, UpdateInvoice, useInvoiceSlice } from '../../../redux/slice/invoice/InvoiceSlice';
import { fetchAgenciesList, setSingleAlertObj, SingleAlertInfo, useCommonReducer } from '../../../redux/slice/commonSlice';
import CustomCurrencyField from '../../common/CustomCurrencyField';
import { parseAmount, priceFormatter } from '../../../utils/Utility';
import dayjs from 'dayjs';
import CustomDropdownField from '../../common/CustomDropdownFiled';
import OptionsPopup, { OptionPopupObjectProps } from '../../common/OptionsPopup';


type Option = {
  value: string | number;
  label: string;
};

const EditInvoice: React.FC = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const { agencyInfoList } = useCommonReducer();
  const [submitAction, setSubmitAction] = useState<string>();
  const { invoice } = useInvoiceSlice();
  const dispatch = useAppDispatch();
  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: "", show: false });

  const { reset, } = useForm<UpdateInvoice>();
  const onClose = () => {
    navigate(-1);
    reset();
    if (location.state) {
      location.state.invoice = null;
    }
  }

  useEffect(() => {
    dispatch(fetchAgenciesList());
  }, [dispatch]);




  const AgencyListoptions = useMemo(() => {
    let options: Option[] = [];
    agencyInfoList
      ?.filter(option =>
        option.commissionType === 'Agency'
      )
      .map((option) => {
        options.push({
          label: option.agencyName,
          value: option.agencyId,
        });
      });
    return options;
  }, [agencyInfoList, state?.agencyId]);


  const overAgencyListoptions = useMemo(() => {
    let options: Option[] = [];
    agencyInfoList
      ?.filter(option => option.commissionType === 'Override')
      .map((option) => {
        options.push({
          label: option.agencyName,
          value: option.agencyId,
        });
      });
    return options;
  }, [agencyInfoList]);

  const invoicesStatusOptions = [
    { label: "PARTIAL", value: "PARTIAL" },
    { label: "PAID", value: "PAID" },
    { label: "UNPAID", value: "UNPAID" }
  ];


  const onSubmit = async (data: UpdateInvoice) => {

    if (submitAction === "markInvoiced") {
      setShowOptionsPopup({
        message: "Do you really want to record the Update Invoice?",
        show: true,
        variant: "confirm",
        buttons: [{
          name: "Confirm", color: 'primary',
          onClick: () => handleConfirmUpdate(data),
        }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
      });
    }
  };


  const handleConfirmUpdate = async (data: UpdateInvoice) => {
    try {

      const formattedDate = dayjs(data.mfgInvoiceDate).format("MM/DD/YYYY");
      const formattedDueDate = dayjs(data.invoiceDueDate).format("MM/DD/YYYY");


      const reqObj = {
        ...data,
        mfgInvoiceDate: formattedDate,
        invoiceDueDate: formattedDueDate,
      };


      const response = await dispatch(
        updateInvoice({
          interceptorOption: { skipErrorPopup: true },
          data: reqObj,
        })
      );

      if (response.payload?.message === "SUCCESS" || response.payload?.message === "Invoice updated successfully.") {
        navigate(-1);
        const alertObj: SingleAlertInfo = {
          message: "Invoice Updated Successfully!",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
      } else {

        const alertObj: SingleAlertInfo = {
          message: response.payload?.message || "Update failed!",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
    } catch (error) {

    } finally {
      cancelOptionPopup();
    }
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const { control, handleSubmit, getValues } = useForm<UpdateInvoice>({
    defaultValues: {
      invoiceId: state?.invoiceId || '',
      mfgInvoiceNumber: state?.mfgInvoiceNumber || '',
      invoiceAmount: parseAmount(state?.invAmt) || 0.00,
      mfgInvoiceDate: state?.invDate,
      v3CommissionAmount: parseAmount(state?.v3commisionamount) || 0.00,
      agencyCommissionAmount: parseAmount(state?.agencycommisionAmount) || 0.00,
      ovrAgencyCommissionAmount: parseAmount(state?.ovrrideagncyamount) || 0.00,
      invoiceDueDate: state?.invDueDate || '',
      agencyId: state?.agencyId,
      ovrAgencyId: state?.ovrAgencyId,
      invoiceStatus: state?.invoiceStatus,
      productName: state?.productName,
      agencyCommissionAmounts: state?.agencyCommissionAmounts || 0.00,
      v3CommissionAmounts: state?.v3CommissionAmounts || 0.00,
      ovrAgencyCommissionAmounts: state?.ovrAgencyCommissionAmounts || 0.00,

    },
  });


  const onError = (_errors: any, e: any) => {
    let hasError = false;

    // Get all form values
    const data: UpdateInvoice = getValues();

    if (
      data.invoiceAmount < data.v3CommissionAmount ||
      data.invoiceAmount < data.agencyCommissionAmount ||
      data.invoiceAmount < data.ovrAgencyCommissionAmount
    ) {
      const alertObj: SingleAlertInfo = {
        message: "Invoice amount cannot be less than any commission amount.",
        alertType: "error",
      };
      hasError = true;
      dispatch(setSingleAlertObj(alertObj));
    }

    // Scroll to top if there are errors
    if (Object.keys(_errors).length > 0 || hasError) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };


  return (
    <Box
      sx={{
        margin: {
          xs: "20px 10px",
          sm: "20px",
        },
      }}
    >

      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Header title="Edit Invoice" subtitle="Edit Invoice Details" />
      <Divider sx={{ marginY: 2 }} />

      <Card>
        <form onSubmit={handleSubmit(onSubmit, onError)}>

          <Grid container spacing={2} mb={5}>
            <Grid item md={3} xs={12}>
              <Typography>
                <b>Practice Name:</b> <span style={{ paddingLeft: "10px" }}>{state?.practice}</span>
              </Typography>

            </Grid>
            <Grid item md={3} xs={12} ml={-4}>
              <Typography>
                <b>MFR Name:</b> <span style={{ paddingLeft: "10px" }}>{state?.mfr}</span>
              </Typography>
            </Grid>

            {state?.creditAmount !== "$0.00" && (
              <Grid item md={3} xs={12} ml={-4}>
                <Typography>
                  <b>Credit Amount:</b> <span style={{ paddingLeft: "10px" }}>{state?.creditAmount}</span>
                </Typography>
              </Grid>
            )}

            {state?.v3InvoicePaidAmount !== "$0.00" && (
              <Grid item md={3} xs={12} ml={-4}>
                <Typography>
                  <b>Invoice Paid  Amount:</b> <span style={{ paddingLeft: "10px" }}>{state?.v3InvoicePaidAmount}</span>
                </Typography>

              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomTextField
                control={control}
                controllerName="mfgInvoiceNumber"
                label="MFG Invoice Number"
                rules={{
                  required: "MFG Invoice Number is required.",
                  maxLength: { value: 50, message: "Maximum length of MFG Invoice Number is 50" },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomCurrencyField
                control={control}
                controllerName="invoiceAmount"
                label="Invoice Amount"
                rules={{
                  required: "Invoice Amount is required.",
                  min: { value: 1, message: "Minimum value allowed is 1" },
                  validate: {
                    totalCommissionNotExceedInvoice: (value: number) => {
                      const v3Commission = getValues("v3CommissionAmounts");
                      const agencyCommission = getValues("agencyCommissionAmounts");
                      const ovrAgencyCommission = getValues("ovrAgencyCommissionAmounts");
                      const totalCommission = v3Commission + agencyCommission + ovrAgencyCommission;
                      return totalCommission <= value || "Total commission amount cannot exceed Invoice Amount.";

                    },

                    checkpaidAmountandInvoiceAmount: (value: number) => {
                      const paidAmount = parseAmount(state?.v3InvoicePaidAmount);
                      return paidAmount <= value || "Invoice Amount cannot exceed Paid Amount";

                    }

                  }

                }}
              />
            </Grid>
          </Grid>

          <Grid item md={12} xs={12} mb={2}>
            <Box sx={{ marginY: 2 }} />
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomDatePicker
                control={control}
                controllerName="mfgInvoiceDate"
                label="Invoice Date"
                rules={{
                  required: "Invoice Date is required.",
                  validate: {
                    validDate: (value: Date) =>
                      dayjs(value).isValid() || "Invalid invoice date format",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomDatePicker
                control={control}
                controllerName="invoiceDueDate"
                label="Invoice Due Date"
                rules={{
                  required: "Invoice Due Date is required.",
                  validate: {
                    validDate: (value: Date) =>
                      dayjs(value).isValid() || "Invalid Due invoice date format",
                    notbigInvoiceduedate: (value: string) => {
                      const mfgInvoiceDate = dayjs(getValues("mfgInvoiceDate"));
                      const dueDate = dayjs(value);
                      return (
                        dueDate.isSame(mfgInvoiceDate) || dueDate.isAfter(mfgInvoiceDate) ||
                        "Invoice due date cannot be earlier than invoice date"
                      );
                    },

                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomCurrencyField
                control={control}
                controllerName="v3CommissionAmounts"
                label="V3 Commission Amount"
                rules={{
                  required: "V3 Commission Amount is required.",
                  min: { value: 1, message: "Minimum value allowed is 1" },
                  validate: {
                    notExceedInvoice: () => {
                      const invoiceAmount = (getValues("invoiceAmount"));
                      const v3CommissionAmount = (getValues("v3CommissionAmounts"));

                      return v3CommissionAmount <= invoiceAmount || "V3 Commission Amount cannot exceed Invoice Amount.";
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomDropdownField
                name="agencyId"
                control={control}
                options={AgencyListoptions}

                label="Agency Name"
                rules={{
                  required: "Agency is required.",

                }}

              />

            </Grid>
          </Grid>


          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomCurrencyField
                control={control}
                controllerName="agencyCommissionAmounts"
                label="Agency Commission Amount"
                rules={{
                  required: "Agency Commission Amount is required.",
                  validate: {
                    notExceedInvoice: () => {
                      const invoiceAmount = (getValues("invoiceAmount"));
                      const agencyCommissionAmount = (getValues("agencyCommissionAmounts"));
                      return agencyCommissionAmount <= invoiceAmount || "Agency Commission Amount cannot exceed Invoice Amount.";
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomDropdownField
                name="ovrAgencyId"
                control={control}
                options={overAgencyListoptions}
                label="Ovr Agency Name"

              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomCurrencyField
                control={control}
                controllerName="ovrAgencyCommissionAmounts"
                label="Override Agency Commission Amount"
                rules={{
                  validate: {
                    notExceedInvoice: () => {
                      const invoiceAmount = (getValues("invoiceAmount"));
                      const ovrAgencyCommissionAmount = (getValues("ovrAgencyCommissionAmounts"));
                      return ovrAgencyCommissionAmount <= invoiceAmount || "Override Agency Commission Amount cannot exceed Invoice Amount.";
                    },
                    ovrAgencyRequired: (value: number) => {

                      const ovrAgencyId = getValues("ovrAgencyId");
                      return !value || ovrAgencyId ? true : "Please select an agency if Override Agency Commission Amount is entered.";
                    }
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item md={4} xs={12}>
              <CustomDropdownField
                control={control}
                name="invoiceStatus"
                label="Invoice Status"
                options={invoicesStatusOptions}
                rules={{
                  required: "Invoice Status is required.",
                }}
              />
            </Grid>
          </Grid>

          <Grid container mt={2} justifyContent="flex-end" alignItems="center" spacing={1}>
            <Grid item xs={12} md="auto">
              <Button type="submit" color="success" startIcon={<SaveOutlined />} fullWidth
                onClick={() => {
                  setSubmitAction("markInvoiced");
                }}
              >
                Update Invoice
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button color="error" onClick={() => onClose()} fullWidth>
                Close
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Box>
  );
};

export default EditInvoice;
