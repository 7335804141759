import { Box, Divider, Grid, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
    MainErrorInfo,
    SingleAlertInfo,
    setMainErrorObj,
    setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import { editManufacturerData, fetchManufacturerDataById, saveManufacturers, useManufacturerListSlice } from "../../../redux/slice/manufacturer/ManufacturerSlice";
import CustomEmailChipField from "../../common/CustomEmailChipField";
import { INVOICE_DUE_DATE, INVOICE_DUE_DAYS } from "../../../constants/applicationConstants";
import CustomNumberField from "../../common/CustomNumberField";
import CustomDatePicker from "../../common/CustomDatePicker";
import moment from "moment";
;

export type ManufacturerCreateObj = {
    manufacturerId: string;
    manufacturerName: string;
    contactPhone: string;
    v3PayDay?:number|null;
    v3PayMonth?:number|null;
    agencyPayDay?:number|null;
    agencyPayMonth?:number|null;
    inactiveDate?:string|undefined;
    notificationEmail: {
        ivr_info: any;
        order_info: any;
        return_info: any;
        tissue_info: any;
        product_agreement_info: any;
    }
    customConfig: {
        invoice_due_date: string | null;
        invoice_net_days: string | null;
        cc_authorized_practice_user: string | null;
    };

}

const CreateMaufacturer = () => {
    const location = useLocation();
    const isEditing = Boolean(location.state?.mfrData);

    const {
        control,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm<ManufacturerCreateObj>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { ManufacturerData } = useManufacturerListSlice();
    const { allCodeTablesValuesMap } = useCodetablesSlice();
    useEffect(() => {
        if (location.state?.mfrData?.manufacturerId) {
            dispatch(fetchManufacturerDataById(parseInt(location.state?.mfrData?.manufacturerId)));
        }
    }, [dispatch,location.state?.mfrData?.manufacturerId])

    const invoiceDueDateOptions = useMemo(() => {
        let statuses: CodeTable[] = [];
        if (allCodeTablesValuesMap) {
            statuses = getCodeTableValuesByTableName(allCodeTablesValuesMap, INVOICE_DUE_DATE);
        }
        if (!statuses || statuses.length === 0) {
            dispatch(fetchAllCodeTablesValues());
        }
        let options: { label: string, value: any }[] = [];
        statuses?.map((option) => {
            options.push({ label: option.displayValue, value: option.displayValue });
        });
        return options;
    }, [allCodeTablesValuesMap]);

    const invoiceDueDaysOptions = useMemo(() => {
        let statuses: CodeTable[] = [];
        if (allCodeTablesValuesMap) {
            statuses = getCodeTableValuesByTableName(allCodeTablesValuesMap, INVOICE_DUE_DAYS);
        }
        if (!statuses || statuses.length === 0) {
            dispatch(fetchAllCodeTablesValues());
        }
        let options: { label: string, value: any }[] = [];
        statuses?.map((option) => {
            options.push({ label: option.displayValue, value: option.displayValue });
        });
        return options;
    }, [allCodeTablesValuesMap]);



    const onSubmit = async (data: any) => {
        if (isEditing) {
            await handleEditManufacturer({ ...data, mfrId: location.state?.mfrData?.manufacturerId });
        } else {
            await handleSaveManufacturer({ ...data });
        }
    };

    const onClose = () => {
        navigate(-1);
    }

    const handleSaveManufacturer = async (data: any) => {
        let errorObj: MainErrorInfo = {
            title: "",
        };
        const updatedData = {
            ...data,
            inactiveDate:data?.inactiveDate?moment(data?.inactiveDate.toString()).format("MM/DD/YYYY"):'',
            manufacturerName: data?.manufacturerName,
            customConfig: JSON.stringify(data?.customConfig),
            contactPhone: data?.contactPhone,
            notificationEmail: JSON.stringify(data?.notificationEmail),
        }

        try {
            const response = await dispatch(saveManufacturers(updatedData));
            if (response.payload && response.payload.message === 'SUCCESS') {

                let alertMessage = isEditing ?
                    "Manufacturer Updated Successfully!" :
                    "Manufacturer Created Successfully!";

                const alertObj: SingleAlertInfo = {
                    message: alertMessage,
                    alertType: "success",
                };

                await dispatch(setSingleAlertObj(alertObj));
                await navigate(-1);

            } else {
                let errorSentence = '';
                if (response.payload.errors.length <= 0) {
                    errorSentence = response.payload.message || "An unknown error occurred.";
                }
                errorObj.title = "Manufacturer Creation Failed!";
                errorObj.errorMessageStream = errorSentence;
                dispatch(setMainErrorObj(errorObj));
            }
        } catch (error) {
            errorObj.title = "Error creating Manufacturer";
            errorObj.errorMessageStream = "" + error;
            dispatch(setMainErrorObj(errorObj));
        }
    };
    const handleEditManufacturer = async (data: any) => {
        let errorObj: MainErrorInfo = {
            title: "",
        };
        const updatedData = {
            ...data,
            inactiveDate:data?.inactiveDate?moment(data?.inactiveDate.toString()).format("MM/DD/YYYY"):'',
            manufacturerId: data?.manufacturerId,
            manufacturerName: data?.manufacturerName,
            customConfig: JSON.stringify(data?.customConfig),
            contactPhone: data?.contactPhone,
            notificationEmail: JSON.stringify(data?.notificationEmail),
        }

        try {
            const response = await dispatch(editManufacturerData(updatedData));
            if (response.payload && response.payload.message === 'SUCCESS') {

                let alertMessage =
                    "Manufacturer Updated Successfully!"

                const alertObj: SingleAlertInfo = {
                    message: alertMessage,
                    alertType: "success",
                };

                await dispatch(setSingleAlertObj(alertObj));
                await navigate(-1);

            } else {
                let errorSentence = '';
                if (response.payload.errors.length <= 0) {
                    errorSentence = response.payload.message || "An unknown error occurred.";
                } 

                errorObj.title = "Manufacturer Updation Failed!";
                errorObj.errorMessageStream = errorSentence;
                dispatch(setMainErrorObj(errorObj));
            }
        } catch (error) {
            errorObj.title = "Error Updating Manufacturer";
            errorObj.errorMessageStream = "" + error;
            dispatch(setMainErrorObj(errorObj));
        }
    };
    const defaultValues = useCallback(() => {
        if (ManufacturerData && isEditing ) {
            reset(ManufacturerData);
        } else {
            //   reset({
            //     userRoleId: undefined,
            //     entityId: 0,
            //     entityIds: [],
            //     email: '',
            //     password: '',
            //     confirmPassword: '',
            //     phone: '',
            //   });
        }
    }, [ManufacturerData, reset]);


    useEffect(() => {
        defaultValues();
    }, [defaultValues]);



    return (
        <Box sx={{
            margin: {
                xs: "20px 10px",
                sm: "20px",
            },
        }}>
            <Header
                title="Manufacturer Management"
                subtitle={isEditing ? "Manufacturer Update" : "Manufacturer Create"}
            />
            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item md={4} xs={12}>
                            <CustomTextField
                                control={control}
                                controllerName="manufacturerName"
                                label="Manufacturer Name"
                                rules={{
                                    required: "Manufacturer Name is required.",
                                }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomPatternTextField
                                control={control}
                                name="contactPhone"
                                label="Phone"
                                rules={{
                                    required: "Phone is required.",
                                    pattern: {
                                      value: /^(\+\d{1,2}\s)?\(\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                      message: "Invalid phone number."
                                    }
                                  }}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                        </Grid>
                        <Grid item md={2.3} xs={12}>
                            <CustomNumberField
                                control={control}
                                controllerName="v3PayDay"
                                label="V3 Pay Day"
                                allowDecimal={false}
                                allowNegative={false}
                                rules={{ min: { value: 1, message: "V3 pay day should be between 1 - 31" }, max: { value: 31, message: "V3 pay day should be between 1 - 31" } }}
                            />
                        </Grid>
                        <Grid item md={2.3} xs={12}>
                            <CustomNumberField
                                control={control}
                                controllerName="v3PayMonth"
                                label="V3 Pay Month"
                                allowDecimal={false}
                                allowNegative={false}
                                rules={{ min: { value: 1, message: "V3 pay month should be between 1 - 12" }, max: { value: 12, message: "V3 pay month should be between 1 - 12" } }}
                            />
                        </Grid>
                        <Grid item md={2.3} xs={12}>
                            <CustomNumberField
                                control={control}
                                controllerName="agencyPayDay"
                                label="Agency Pay Day"
                                allowDecimal={false}
                                allowNegative={false}
                                rules={{ min: { value: 1, message: "Agency pay day should be between 1 - 31" }, max: { value: 31, message: "Agency pay day should be between 1 - 31" } }}
                            />
                        </Grid>
                        <Grid item md={2.3} xs={12}>
                            <CustomNumberField
                                control={control}
                                controllerName="agencyPayMonth"
                                label="Agency Pay Month"
                                allowDecimal={false}
                                allowNegative={false}
                                rules={{ min: { value: 1, message: "Agency pay month should be between 1 - 12" }, max: { value: 12, message: "Agency pay month should be between 1 - 12" } }}
                            />
                        </Grid>
                        <Grid item md={2.3} xs={12}>
                            <CustomDatePicker
                                control={control}
                                controllerName="inactiveDate"
                                label="Inactive Date"
                                isDisablePast={true}
                                
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider
                                orientation="horizontal"
                                variant="middle"
                                flexItem
                                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>
                                Notification Emails:
                            </Typography>
                           
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomEmailChipField
                                control={control}
                                returnAsArray={true}
                                label="IVR Info"
                                controllerName="notificationEmail.ivr_info"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomEmailChipField
                                returnAsArray={true}
                                control={control}
                                label="Order Info"
                                controllerName="notificationEmail.order_info"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomEmailChipField
                                control={control}
                                returnAsArray={true}
                                label="Return Info"
                                controllerName="notificationEmail.return_info"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomEmailChipField
                                control={control}
                                returnAsArray={true}
                                label="Tissue Info"
                                controllerName="notificationEmail.tissue_info"
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomEmailChipField
                                control={control}
                                returnAsArray={true}
                                label="Product Agreement Info"
                                controllerName="notificationEmail.product_agreement_info"
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Divider
                                orientation="horizontal"
                                variant="middle"
                                flexItem
                                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Typography>
                                Custom Config:
                            </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomDropdownField name="customConfig.invoice_due_date" label="Invoice Due By"
                                options={invoiceDueDateOptions} control={control} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomDropdownField name="customConfig.invoice_net_days" label="Invoice Due Days"
                                options={invoiceDueDaysOptions} control={control} />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <CustomDropdownField name="customConfig.cc_authorized_practice_user" label="Send Emails to Authorized Practice Signer?"
                                options={[{label:'Yes',value:'Y'},{label:'No',value:'N'}]} control={control} />
                        </Grid>


                        <Grid
                            mt={2}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item xs={12} md="auto">
                                <Button
                                    type="submit"
                                    color="info"
                                    startIcon={<SaveOutlined />}
                                    fullWidth
                                >
                                    {isEditing ? "Update Manufacturer" : "Create Manufacturer"}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md="auto">
                                <Button
                                    color="error"
                                    startIcon={<CancelOutlined />}
                                    onClick={() => onClose()}
                                    fullWidth
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Card>
        </Box>
    );
};

export default CreateMaufacturer;