import React, { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "../../common/Button";
import { AddCircleOutlineOutlined, Cancel, VpnKey } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { PracticeInfo } from "../../../redux/slice/practiceSlice";
import { selectIsValueFoundInToken, useAuthSlice } from "../../../redux/slice/authSlice";
import { hideElement, sortNames } from "../../../utils/Utility";
import { IVR_STATUS, USER_ROLES } from "../../../constants/applicationConstants";
import { fetchPracticeProductsData } from "../../../redux/slice/practice/practiceProductSlice";
import CustomRadioButton from "../../common/CustomRadioButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface IVRPracticeSelectionProps {
  open: boolean;
  onClose: () => void;
  practiceByUserRole: PracticeInfo[] | null | undefined;
}

type Option = {
  value: string;
  label: string;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const IVRPracticeSelectionPopup: React.FC<IVRPracticeSelectionProps> = ({
  open,
  onClose,
  practiceByUserRole
}) => {
  const { control, handleSubmit, reset, setValue } = useForm({});
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { userInfo } = useAuthSlice();
  const ivrEmailNotificationOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const canAccessIvrEmailSetting = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessIVRActions", IVR_STATUS.EMAIL_SETTINGS)(state));

  const practiceOptions = useMemo(() => {
    if (userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN) {
      let options: Option[] = [];
      if (practiceByUserRole) {
        setValue("practiceId", practiceByUserRole[0]?.practiceId);
        practiceByUserRole?.map((practice) => {
          options.push({
            value: practice.practiceId,
            label: practice.practiceName,
          });
        });
      }
      return options;
    } else {
      let options: Option[] = [];
      if (practiceByUserRole) {
        const sortedPracticeInfo: PracticeInfo[] = sortNames(
          practiceByUserRole,
          "practiceName"
        );
        sortedPracticeInfo?.map((practice) => {
          options.push({
            value: practice.practiceId,
            label: practice.practiceName,
          });
        });
      }
      return options;
    }
  }, [practiceByUserRole]);

  const onSubmit = async (data: any) => {
    if (data.practice) {
      const response = await dispatch(fetchPracticeProductsData(data.practice));
      // const isExecuted = response.payload.data.some(
      //   (product: any) => product.agreementStatus === "EXECUTED"
      // );
      // if (!isExecuted) {
      if (!response || !response.payload || !response.payload.data || response.payload.data.length === 0) {
        setMessage(
          "Could not find any valid onboarding agreements for the selected practice. Please select different practice"
        );
      } else {
        setMessage("");
        navigate("/ivr", { state: { practiceId: data.practice,ivrEmailNotification:data.ivrEmailNotification } });
      }
    }
  };

  const handleClose = () => {
    onClose();
    reset({});
    setMessage("");
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{ "& .MuiDialog-paper": { width: "550px", maxWidth: "none" } }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Select Practice</DialogTitle>
          <DialogContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}></Grid>
                <Typography sx={{ color: "red" }}>{message}</Typography>
                <Grid item md={12} xs={12}></Grid>
                <Grid item md={12} xs={12}>
                  <CustomDropdownField
                    name={"practice"}
                    control={control}
                    options={practiceOptions}
                    label="Practice"
                    rules={{
                      required: "Please Select a practice to continue.",
                    }}
                    onChangeSelect={() => {setMessage("")}}
                  />
                </Grid>
                <Grid container mt={2} ml={3} style={{ display: `${hideElement(!canAccessIvrEmailSetting)}` }}>
                  <Grid xs={8}>
                <Typography mt={1}>
                          Do you want to send email notifications?
                      </Typography>
                      </Grid>
                      <Grid xs={4}>
                <CustomRadioButton
                        control={control}
                        options={ivrEmailNotificationOptions}
                        controllerName="ivrEmailNotification"
                        defaultValue = "true"
                        // rules={{
                        //   required:
                        //     "Email notification option is required.",
                        // }}
                   />
                   </Grid>

                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              justifyContent="flex-end"
              sx={{flexWrap: "wrap" }}
              spacing={1}
            >
              <Grid item xs={12} sm="auto">
                <Button
                  type="submit"
                  startIcon={<AddCircleOutlineOutlined />}
                  fullWidth
                >
                  Create IVR
                </Button>
              </Grid>
              <Grid item xs={12} sm="auto">
                <Button
                  color="error"
                  startIcon={<Cancel />}
                  onClick={() => handleClose()}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default IVRPracticeSelectionPopup;