import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, Divider, Grid } from "@mui/material";
import Button from "../../common/Button";
import CustomDatePicker from "../../common/CustomDatePicker";
import { Controller, useForm } from "react-hook-form";
import { ProductItemPriceInfo, initialValuesOfProductItemPrice } from "../../../redux/slice/product/createProductSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import dayjs, { Dayjs } from "dayjs";
import { useCommonReducer } from "../../../redux/slice/commonSlice";
import { Cancel, EditOutlined, ErrorOutlineRounded } from "@mui/icons-material";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";

interface ProductItemPriceProps {
  open: boolean;
  onClose: () => void;
  addProductItemPrice: (productItemPrice: ProductItemPriceInfo) => void;
  productItemPriceObject: ProductItemPriceInfo;
  editPricing: boolean;
  editProduct: boolean;
  rowIndex: number | null;
  inactiveOnly: boolean;
  productItemPriceList: ProductItemPriceInfo[];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let erroredFields: string[] = [];

const ProductItemPricePricePopup: React.FC<ProductItemPriceProps> = ({
  open,
  onClose,
  addProductItemPrice,
  productItemPriceObject,
  editPricing,
  editProduct,
  rowIndex,
  inactiveOnly,
  productItemPriceList,
}) => {
  const showInlineErrorMinLength: number = 60;
  const customTopErrorString: string = "-custom top error";
  const [submitAction, setSubmitAction] = useState<string>();
  const { control, handleSubmit, reset, setError, watch, getValues, formState } = useForm<ProductItemPriceInfo>({ defaultValues: initialValuesOfProductItemPrice });
  const { errors } = formState;
  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: "", show: false });

  const { macInfoList } = useCommonReducer();

  const errorMessages = useMemo(() => {
    let errorMsgs: JSX.Element[] = [];
    if (errors) {
      let objKeys: string[] = Object.keys(errors);
      let errMsg: string | undefined = undefined;
      if (objKeys && objKeys.length > 0) {
        objKeys.map((key) => {
          // @ts-ignore
          errMsg = errors[key].message;
          if (errMsg && (errMsg.length >= showInlineErrorMinLength || errMsg.includes(customTopErrorString))) {
            errMsg = errMsg.replace(customTopErrorString, "");
            errorMsgs.push(<Box sx={{ display: 'flex', justifyContent: 'flex-start', color: 'error.main' }} >
              <Box sx={{ pr: 0.5, }} ><ErrorOutlineRounded /></Box>
              <Box>{errMsg}</Box>
            </Box>);
          }
        });
      }
    }
    return errorMsgs;
  }, [formState]);

  const mac1Options = useMemo(() => {
    let options: { label: string, value: any }[] = [];
    macInfoList?.map((option) => {
      options.push({ label: option.macName, value: option.macId })
    })
    return options;
  }, [macInfoList]);

  const options = useMemo(() => {
    return [
      { label: "MAC", value: "MAC" },
      { label: "National", value: "NATIONAL" },
    ]
  }, []);

  useEffect(() => {
    if (open) {
      let Obj: ProductItemPriceInfo = {
        ...productItemPriceObject,
        inactiveDate: productItemPriceObject.inactiveDate ? dayjs(productItemPriceObject.inactiveDate) : null,
        effectiveDate: productItemPriceObject.effectiveDate ? dayjs(productItemPriceObject.effectiveDate) : null,
      }
      reset(Obj)
    }
    if (!open) {
      setDefaultValues();
          }
  }, [open]);

  const validateOthers = useCallback(() => {

    if (inactiveOnly) {
      return validateInactivate();
    } 

    if (!productItemPriceList || productItemPriceList.length === 0) {
      return true;
    }

    let priceList: ProductItemPriceInfo[] = productItemPriceList;
    if (editPricing) {
      priceList = productItemPriceList.filter((item: ProductItemPriceInfo, index: number) => {
        if (index !== rowIndex) {
          return true;
        }
        return false;
      });
    }

    let fileType: string = getValues('priceType');
    let macId: number = getValues('macId');
    let effectiveDateLocal: Dayjs = dayjs(getValues('effectiveDate'));

    if (editProduct) {
      let currentDate: Dayjs = dayjs(dayjs().format('MM/DD/YYYY'));
      // let sameTypePriceMap: Map<string | number, ProductItemPriceInfo[]> = new Map<string, ProductItemPriceInfo[]>();
      // let sameTypePriceList: ProductItemPriceInfo[] | undefined = [];
      // let mapKey: string | number = "";

      for (let i : number = 0; i < priceList.length; i++) {
        // if (priceList[i].priceType === "NATIONAL") {
        //   mapKey = "NATIONAL";
        // } else {
        //   mapKey = priceList[i].macId;
        // }

        // if (sameTypePriceMap.has(mapKey)) {
        //   sameTypePriceList = sameTypePriceMap.get(mapKey);
        //   sameTypePriceList?.push(priceList[i]);
        // } else {
        //   sameTypePriceList = [];
        //   sameTypePriceList.push(priceList[i]);
        //   sameTypePriceMap.set(mapKey, sameTypePriceList);
        // }

        // if (!dayjs(priceList[i].inactiveDate).isValid()
        //   && (fileType !== priceList[i].priceType || fileType === "NATIONAL" || (fileType === "MAC" && macId === priceList[i].macId))) {
        //   let macName = fileType === "MAC" ? macInfoList?.find(option => option.macId === priceList[i].macId)?.macName : "";
        //   if (currentDate.isBefore(dayjs(priceList[i].effectiveDate))) {
        //     return priceList[i].priceType === "NATIONAL"
        //       ? "A future active National pricing is exists. Please delete it or set a suitable inactive date before adding a new pricing." + customTopErrorString
        //       : fileType === "NATIONAL" ? "Future active Local pricing/pricings is/are exists. Please delete it/them or set (a) suitable inactive date(s) before adding a new pricing." + customTopErrorString
        //         : "A future active " + macName + " pricing is exists. Please delete it or set a suitable inactive date before adding a new pricing." + customTopErrorString;
        //   }
        //    else {
        //     return priceList[i].priceType === "NATIONAL"
        //       ? "An active National pricing is exists. Please set a suitable inactive date before adding a new pricing." + customTopErrorString
        //       : fileType === "NATIONAL" ? "An active Local pricing/pricings is/are exists. Please set (a) suitable inactive date(s) before adding a new pricing." + customTopErrorString
        //         : "An active " + macName + " pricing is exists. Please set a suitable inactive date before adding a new pricing." + customTopErrorString;
        //   }
        // }

      }

      // for (let i : number = 0; i < priceList.length; i++) {
      //   if (dayjs(priceList[i].inactiveDate).isValid() && effectiveDateLocal.isValid()
      //     && (effectiveDateLocal.isSame(dayjs(priceList[i].inactiveDate)) || effectiveDateLocal.isBefore(dayjs(priceList[i].inactiveDate)))
      //     && (fileType === "NATIONAL" || priceList[i].priceType === "NATIONAL" || macId === priceList[i].macId)) {
      //     return "Entered effective date is conflicted with a active period of a existing pricing." + customTopErrorString;
      //   }
      // }

    } else {
      for (let i : number = 0; i < priceList.length; i++) {
        if ("MAC" === priceList[i].priceType && fileType === "NATIONAL") {
          erroredFields.push("priceType");
          return "Mac pricing is/are already exists." + customTopErrorString;
        } else if (fileType === priceList[i].priceType && fileType === "NATIONAL") {
          erroredFields.push("priceType");
          return "A National pricing is already exists." + customTopErrorString;
        } else if (macId === priceList[i].macId) {
          erroredFields.push("macId");
          return "This MAC pricing is already exists." + customTopErrorString;
        }
      }
    }
    return true;
  }, [productItemPriceList, watch('priceType'), watch('macId'), watch('effectiveDate')]);

  const validateInactivate = useCallback(() => {
    let fileType: string = getValues('priceType');
    let macId: number = getValues('macId');
    let effectiveDateLocal: Dayjs = dayjs(getValues('effectiveDate'));
    let inactiveDateLocal: Dayjs = dayjs(getValues('inactiveDate'));

    let priceList: ProductItemPriceInfo[] = productItemPriceList;
    if (editPricing) {
      priceList = productItemPriceList.filter((item: ProductItemPriceInfo, index: number) => {
        if (effectiveDateLocal.isBefore(dayjs(item.effectiveDate))) {
          return true;
        }
        return false;
      });
    }

    for (let i : number = 0; i < priceList.length; i++) {
      if (inactiveDateLocal.isValid()
        && (inactiveDateLocal.isSame(dayjs(priceList[i].effectiveDate)) || inactiveDateLocal.isAfter(dayjs(priceList[i].effectiveDate)))
        && (fileType === "NATIONAL" || priceList[i].priceType === "NATIONAL" || macId === priceList[i].macId)) {
        return "Entered inactive date is conflicted with a active period of a existing pricing." + customTopErrorString;
      }
    }

  }, [productItemPriceList, watch('priceType'), watch('macId'), watch('inactiveDate')]);

  const validateEffectiveDate = useCallback((value: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
      return "Invalid date format."
    }

    return true;
  }, [productItemPriceList, watch('effectiveDate'), watch('macId')]);

  const validateInactiveDate = useCallback((value: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
      return "Invalid date format."
    }

    if (dayjs(value, "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isAfter(dayjs(value, "MM/DD/YYYY", true))) {
      return "Inactive date should be a past date or euqal to effective date."
    }

    return true;
  }, [productItemPriceList, watch('inactiveDate'), watch('macId')]);

  const validateOptionalInactiveDate = useCallback((value: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if (value !== null) {
      if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
          return "Invalid date format.";
      }
    }

    if (dayjs(value, "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isValid()
      && dayjs(getValues("effectiveDate"), "MM/DD/YYYY", true).isAfter(dayjs(value, "MM/DD/YYYY", true))) {
      return "The inactive date should be the same as or after the effective date."
    }

    return true;
  }, [productItemPriceList, watch('inactiveDate'), watch('macId')]);

  const setDefaultValues = useCallback(() => {
    reset();
  }, []);
  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
};
  const onSubmit = useCallback((data: ProductItemPriceInfo) => {
    if(submitAction==="AddingNewProductPricing"){
      let obj: ProductItemPriceInfo = {
        ...data,
        effectiveDate: data.effectiveDate ? dayjs(data.effectiveDate).format("MM/DD/YYYY") : "",
        inactiveDate: data.inactiveDate ? dayjs(data.inactiveDate).format("MM/DD/YYYY") : ""
      }
     
     if(!obj?.inactiveDate){
      setShowOptionsPopup({
        message: `All current agreements will be inactivated as of ${dayjs(data.effectiveDate).subtract(1, "day").format("MM/DD/YYYY")}, and new agreements will be created effective ${obj.effectiveDate}. Do you want to proceed?`,
        show: true,
        variant: "confirm",
        buttons: [
          { name: "Confirm", color: "primary", onClick: () => addProductItemPrice(obj) },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ],
      });
    }else{
      addProductItemPrice(obj) 
    }
    }
   
  }, [open,submitAction]);

  const onError = (_errors: any, e: any) => {
    erroredFields.forEach((fieldName: string, index: number) => {
      // @ts-ignore
      setError(fieldName, {});
    });
    erroredFields = [];
  };

  const validateAmount = (value: string, message: string) => {
    let val: number = value !== "" ? parseFloat(value) : 0;
    if (val === 0 || val === 0.0) {
      return message;
    }
  }

  return (
    <>
     {showOptionPopup.show && (
                <OptionsPopup
                    open={showOptionPopup.show}
                    onClose={() => setShowOptionsPopup({ message: "", show: false })}
                    variant={showOptionPopup.variant}
                    message={<>{showOptionPopup.message}</>}
                    buttons={showOptionPopup.buttons}
                    title={showOptionPopup.title}
                    obj={showOptionPopup.obj}
                />
            )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        onClick={(event) => {
          event.stopPropagation();
        }} 
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <DialogTitle>{editPricing? "Update Product Pricing": "Add Product Pricing"}</DialogTitle>
          <Divider orientation="horizontal" variant="fullWidth" flexItem />
          <DialogContent>
            {errorMessages.length > 0 &&
              <Box pb={1} ml={0.5}>
                {errorMessages && errorMessages}
              </Box>
            }
            <Box p={1}>
              {!inactiveOnly &&
              <>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <CustomDropdownField
                      label="File Type"
                      name="priceType"
                      options={options}
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      rules={{ 
                        required: "File Type is required.",
                        }}
                    />
                  </Grid>
                  {(watch("priceType") ? getValues("priceType") === "MAC" : false) ?
                    <Grid item md={6} xs={12}>
                      <CustomDropdownField
                        label="MAC"
                        name="macId"
                        options={mac1Options}
                        control={control}
                        showErrorMinLength={showInlineErrorMinLength}
                        rules={{
                          required: "MAC is required.",
                          validate: {
                            customValidation: (value: string) => {
                              if (!value) {
                                return "MAC is required.";
                              }
                            }
                          }
                        }}
                      />
                    </Grid> : <></>
                  }
                
                  <Grid item md={6} xs={12}>
                    <CustomCurrencyField
                      label="Allowable Price"
                      controllerName="allowableUnitPrice"
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      rules={{
                        required: "Allowable Price is required.",
                        validate: {
                          validateAmount: (value: string) => {
                            return validateAmount(value, "Enter a valid Price.");
                          }
                        }
                      }}
                    />
                  </Grid>
                
                  <Grid item md={6} xs={12}>
                    <CustomCurrencyField
                      label="List Price"
                      controllerName="listPrice"
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      rules={{
                        required: "List Price is required.",
                        validate: {
                          validateAmount: (value: string) => {
                            return validateAmount(value, "Enter a valid List Price.");
                          }
                        }
                      }}
                    />
                  </Grid>
                
                  <Grid item md={6} xs={12}>
                    <CustomCurrencyField
                      label="Custom Price"
                      controllerName="customPrice"
                      control={control}
                    />
                  </Grid>
                
                  <Grid item md={6} xs={12}>
                    <CustomDatePicker
                      label="Effective Date"
                      openTo="day"
                      controllerName="effectiveDate"
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      format="MM/DD/YYYY"
                      rules={{
                        required: "Effective Date is required.",
                        validate: {
                        isValidDate: (
                          value:
                          | string
                          | number
                          | Date
                          | dayjs.Dayjs
                          | null
                          | undefined
                        ) =>
                        validateEffectiveDate(value),
                        },
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <CustomDatePicker
                      label="Inactive Date"
                      openTo="day"
                      controllerName="inactiveDate"
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      format="MM/DD/YYYY"
                      rules={{
                        validate: {
                        isValidDate: (
                          value:
                          | string
                          | number
                          | Date
                          | dayjs.Dayjs
                          | null
                          | undefined
                        ) =>
                        validateOptionalInactiveDate(value),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </>
              }

              {watch("productPriceId") && inactiveOnly ?
              <Grid container spacing={2}>
                <Grid container item spacing={2}>
                  <Grid item md={8} xs={12}>
                    <CustomDatePicker
                      label="Inactive Date"
                      openTo="day"
                      controllerName="inactiveDate"
                      control={control}
                      showErrorMinLength={showInlineErrorMinLength}
                      format="MM/DD/YYYY"
                      rules={{
                        validate: {
                          isValidDate: (
                            value:
                              | string
                              | number
                              | Date
                              | dayjs.Dayjs
                              | null
                              | undefined
                          ) =>
                            validateInactiveDate(value),
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              : <></>
              }

              <Controller
                name="fakeValidationPurposeAttribute"
                control={control}
                rules={{
                  validate: { validateOthers }
                }}
                render={({ field, fieldState }) => {
                  return (<></>)
                }}
              />
            </Box>
          </DialogContent>
          <Divider orientation="horizontal" variant="fullWidth" flexItem  />
          <DialogActions>
          <Grid
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              spacing={2}
            >
              
              <Grid item xs={12} md="auto">
              <Button type="submit"
               onClick={() => {
                if (editPricing) {
                    setSubmitAction("UpdateProductPrice")
                } else {
                    setSubmitAction("AddingNewProductPricing")
                }}}
               startIcon={<EditOutlined />} fullWidth > {editPricing ? "Update Price" : "Add Price"}</Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  color="error"
                  onClick={onClose}
                  startIcon={<Cancel />}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProductItemPricePricePopup;