import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,Link,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {  useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
    OrderInfo,
    OrderItemDTO,
    addClaim,
    addReimbursedClaim,
    claimInfo,
    getOrderItemsByOrderId,
    orderItemClaim,
    orderItemInvoice,
    reimburseClaimInfo,
    useOrderSlice,
} from "../../../redux/slice/order/OrderSlice";
import OptionsPopup, {
    OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTextField from "../../common/CustomTextField";
import Button from "../../common/Button";
import { Cancel, CheckCircleOutline, CurrencyExchangeOutlined, PaidOutlined } from "@mui/icons-material";
import CustomCheckbox from "../../common/CustomCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsTokenAvailable } from "../../../redux/slice/authSlice";
import dayjs from "dayjs";
import { getProductItemHistory } from "../../../redux/slice/product/createProductSlice";
import { getProductItem } from "../../../service/ProductService";
import { getPracticeProductById, getPracticeProductByPracticeProductId } from "../../../redux/slice/practiceSlice";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { convertBase64, priceFormatter } from "../../../utils/Utility";
import { addCreditMemo, addInvoice, Attachment, getCreditMemoDetailsById, getInvoiceById, InvoiceDetailDTO, SaveInvoiceInfo, useInvoiceSlice } from "../../../redux/slice/invoice/InvoiceSlice";
import { RESPONSE_CODES } from "../../../constants/applicationConstants";
import { SUCCESS_CODE } from "../../../service/ServiceConstants";


interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "right" | "center" | "left";
    format?: (value: number) => string;
}

export interface OrderItemRow {
    select: JSX.Element;
    orderItemId: number;
    serialNumber: string;
    size: string;
    tissueTrackingId: number
    productReturnId: number;
    claim: orderItemClaim;
    invoice: orderItemInvoice;
    extendedPrice: number;
    invoiceId: number;
    units: number;
}

interface billingForm {
    insuranceClaim: string;
    mfgInvoice: string;
    invoiceAmt: number;
    invoicedDate: string;
    invoiceDueDate: string;
    calculatedAmt: number;
    attachment: Attachment[];
}
interface recordCreditMemoForm {
    mfgInvoice: string;
    invId: string;
    creditAmt: number;
    returnAmt: any ;
    comments: number;
    productReturnId: number | null;
}

interface FormattedOrderItem {
    orderItemId: number;
}
const RecordCreditMemo = () => {
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<recordCreditMemoForm>({
        // defaultValues: {
        //     insuranceClaim: "",
        //     mfgInvoice: "",
        //     invoicedDate: dayjs(new Date()).format("MM/DD/YYYY"),
        //     invoiceDueDate: "",
        // }
    });

    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useAppDispatch();
    const [checkedList, setCheckedList] = useState<number[]>([]);
    const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: "", show: false });
    const [submitAction, setSubmitAction] = useState<string>();
    const [productExtendedPrice, setProductExtendedPrice] = useState<number>(0);
    const [orderItemRow, setOrderItemRow] = useState<OrderItemRow[] | []>([]);
    const [totalExtendedPrice, setTotalExtendedPrice] = useState(0);
    const [isAnyChecked, setIsAnyChecked] = useState(false);  // Track if any checkbox is selected

    const [statusCount, setStatusCount] = useState<{ orderItemsCount: number, countToCheck: number }>({
        orderItemsCount: 0,
        countToCheck: 0
    });
    const { state } = useLocation();
    const { recordCreditMemo } = useInvoiceSlice();
    const [creditMemoForProduct, setCreditMemoForProduct] = useState<boolean>(false)
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceDetailDTO>();

    useEffect(() => {
        const invoiceId = state?.invoiceId;
        if (invoiceId) {
            setInvoiceDetails(invoiceId);
        };
    }, []);
    const setInvoiceDetails = async (invoiceId: number) => {
        await dispatch(getCreditMemoDetailsById(invoiceId))
        const response = await dispatch(getInvoiceById(invoiceId));
        if(response?.payload?.code === SUCCESS_CODE && response?.payload?.data) {
          setSelectedInvoice(response.payload.data);
        }
    };

    const parseSize = (size: string): number => {
        const sizeWithoutCm = size.replace('cm', '');
        const [length, width] = sizeWithoutCm.split('x').map(Number);
        return length * width;
    }


    const modifyClaimObject = (claim: orderItemClaim, size: string): orderItemClaim => {
        return {
            ...claim,
            claimAmount: parseSize(size) * 122,
        };
    };

    const selectedOrderItems: { productReturnId: number } = useMemo(() => {
        const newSelectedOrderItems: { productReturnId: number }[] = checkedList?.map((id) => ({
            productReturnId: id
        }));
        return newSelectedOrderItems[0];

    }, [checkedList]);

    const onSubmit = async (data: recordCreditMemoForm) => {
        if (submitAction === "recordedCreditAgainstReturnProduct") {
            setShowOptionsPopup({
                message: (data.creditAmt > data.returnAmt)
                ? "Credit Amount is greater than Return Amount. Do you still want to record the credit memo?"
                : (data.returnAmt === data.creditAmt)
                    ? "Do you really want to record the credit memo?"
                    : "Return Amount and Credit Amount are not equal. Do you still want to record the credit memo?",
                show: true,
                variant: "confirm",
                buttons: [{
                    name: "Confirm", color: 'primary',
                    onClick: () => submitMarkInvoiced({ ...data, mfgInvoice: state.invNumber, invId: state?.invoiceId, productReturnId: selectedOrderItems?.productReturnId ? selectedOrderItems?.productReturnId : null }),
                }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
            });
        } else if (submitAction === "recordedCredit") {
            setShowOptionsPopup({
                message: data.creditAmt ? "Do you really want to record the credit memo?"
                    : "",
                show: true,
                variant: "confirm",
                buttons: [{
                    name: "Confirm", color: 'primary',
                    onClick: () => submitMarkInvoiced({ ...data, mfgInvoice: state.invNumber, invId: state?.invoiceId, productReturnId: null }),
                }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
            });
        }
    };

    const submitMarkInvoiced = async (data: recordCreditMemoForm) => {
        if (data?.invId && data?.mfgInvoice) {
            const creditMemoData = {
                invoiceId: data?.invId,
                mfgInvoiceNumber: data?.mfgInvoice,
                creditAmount: data?.creditAmt,
                productReturnId: data?.productReturnId,
                comments: data?.comments,
            }
            if (creditMemoData) {
                const response = await dispatch(addCreditMemo(creditMemoData))
                if (response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
                    let alertMessage = "Credit Memo recorded successfully";
                    const alertObj: SingleAlertInfo = {
                        message: alertMessage,
                        alertType: "success",
                    };
                    dispatch(setSingleAlertObj(alertObj));
                    navigate(-1);
                } else if (response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
                    let alertMessage = response.payload?.message;
                    const alertObj: SingleAlertInfo = {
                        message: alertMessage,
                        alertType: "error",
                    };
                    dispatch(setSingleAlertObj(alertObj));
                }
                cancelOptionPopup();
            }
        }
        // if (orderInfo) {
        //     const product = await getProductItem(1);
        //     const manufacturerId = product && product.data.data && product.data.data.manufacturerId;
        //     const invoiceDtoObject: SaveInvoiceInfo = {
        //         orderId: orderInfo?.orderId,
        //         manufacturerId: manufacturerId,
        //         mfgInvoiceNumber: data.mfgInvoice,
        //         mfgInvoiceDate: data.invoicedDate,
        //         invoiceDueDate: data.invoiceDueDate,
        //         calculatedAmount: data.calculatedAmt,
        //         invoiceAmount: Number(data.invoiceAmt),
        //         orderItems: selectedOrderItems,
        //         attachInvoice: data.attachment,
        //         orderInvoiceStatus: statusCount.countToCheck === checkedList?.length ? "INVOICED" : "PARTIAL"
        //     };
        //     const response = await dispatch(addInvoice({ invoiceData: invoiceDtoObject, interceptorOption: { skipErrorPopup: true } }));
        //     if (response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
        //         let alertMessage = "Invoice was recorded successfully";
        //         const alertObj: SingleAlertInfo = {
        //             message: alertMessage,
        //             alertType: "success",
        //         };
        //         dispatch(setSingleAlertObj(alertObj));
        //         navigate(-1);
        //     } else if (response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
        //         let alertMessage = response.payload?.message;
        //         const alertObj: SingleAlertInfo = {
        //             message: alertMessage,
        //             alertType: "error",
        //         };
        //         dispatch(setSingleAlertObj(alertObj));
        //     }
        //     cancelOptionPopup();
        // }
    };

    const cancelOptionPopup = () => {
        setShowOptionsPopup({ message: "", show: false });
    };

    const claimDtoObject: orderItemClaim = {
        claimId: 2,
        claimNumber: "C3156456",
        claimAmount: 22.36,
        claimStatus: "REIMBURSED",
        submittedDate: "05/22/2024",
        reimbursedDate: "06/13/2024"
    }

    const handleCheckboxChange = (e: any, productReturnId: number, totalReturnAmount: number) => {
        const { checked } = e.target;
        // Calculate the new total extended price based on whether the checkbox is checked or unchecked
        setTotalExtendedPrice(() => {
            const newVal = checked ?  totalReturnAmount :  0;
            setValue('returnAmt', newVal);
            return newVal;
        });

        // Update the checked list
        setCheckedList(prev => {
            let updatedList: number[];

            if (checked) {
                updatedList = [...prev, productReturnId];
                setIsAnyChecked(true);  // Set to true if any checkbox is checked
            } else {
                updatedList = prev.filter(id => id !== productReturnId);
                setIsAnyChecked(updatedList.length > 0);  // Check if any other item is checked
            }

            return updatedList;
        });
    };

    const disableCheckBox = (row: any) => {
        if (row?.productReturnId !== null) {
            return false;
        }
        return true;
    };

    const handleOrderDetailsNavigate = (data : any)=>{
        const orderData ={ 
          orderId:data?.orderId,
          orderNumber:data?.orderNumber,
          productName:data?.productName,
          practiceName:data?.practiceName,
          po:data?.poNumber,
          dateOfService:data?.dateOfService,
          shippingCarrier:data?.shippingCarrier,
          trackingNumber:data?.trackingNumber,
          orderStatus:data?.orderStatus,
          provider:data?.providerName,
          patientName:data?.patientName,
          manufacturerName:data?.mfgName,
        }
        navigate("/details/1", { state: { orderDetails: orderData } });
    
      }
    return (
        <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
            {showOptionPopup.show && (
                <OptionsPopup
                    open={showOptionPopup.show}
                    onClose={() => setShowOptionsPopup({ message: "", show: false })}
                    variant={showOptionPopup.variant}
                    message={<>{showOptionPopup.message}</>}
                    buttons={showOptionPopup.buttons}
                    title={showOptionPopup.title}
                    obj={showOptionPopup.obj}
                />
            )}
            <Header title="Record Credit Memo" subtitle="Record Credit Memo Management" />
            <Box>
                <Card>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box p={1}>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12}>
                                    <Typography>Please select items to Record Credit Memo.</Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2} mb={2} mt={2}>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        INV # :
                                    </Typography>
                                    <Typography component="span" style={{ paddingLeft: "10px" }}>
                                        {state.invNumber}
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        V3 Order # :
                                        {/* {orderInfo?.productName} */}
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        <Link
                                            component="button"
                                            underline="hover"
                                            variant="body2"
                                            onClick={() => handleOrderDetailsNavigate(selectedInvoice)}
                                            color={colors.info[300]}
                                        >
                                            {recordCreditMemo?.orderNumber}
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        INV Date :
                                        {/* {orderInfo?.orderNumber} */}
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.invDate}
                                    </Typography>
                                </Grid>
                                <Grid item md={3}>
                                    <Typography component="label" fontWeight="bold">
                                        MFR :
                                        {/* {orderInfo?.poNumber} */}
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.mfr}
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        INV Amt :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.invAmt}
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        Practice :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.practice}
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        Remaining Payments :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                    {priceFormatter(recordCreditMemo?.remainingAmount?recordCreditMemo?.remainingAmount:0)}
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <Typography component="label" fontWeight="bold">
                                        Agency :
                                    </Typography>
                                    <Typography component="span" sx={{ paddingLeft: "10px" }}>
                                        {state.agency}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }} />
                            {recordCreditMemo?.creditMemoDTOList?.length > 0 && (<>
                                <Grid item xs={12}>
                                    <Typography >Credit Memo History :</Typography>
                                    <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                                        <ListItem>
                                                <ListItemText sx={{ width: "15%" }}>
                                                    <b>Date Of Credit</b>
                                                </ListItemText>
                                                <ListItemText sx={{ width: "15%" }}>
                                                    <b>Credit Amount</b>
                                                </ListItemText>
                                                <ListItemText sx={{ width: "15%" }}>
                                                    <b>User</b>
                                                </ListItemText>
                                                <ListItemText sx={{ width: "15%" }}>
                                                    <b>Comments</b>
                                                </ListItemText>
                                        </ListItem>
                                        {recordCreditMemo?.creditMemoDTOList?.length > 0 &&
                                                (<>
                                                    {recordCreditMemo?.creditMemoDTOList?.map((row: any) => (
                                                        <ListItem key={row?.productReturnId}>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.dateOfCredit}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                {priceFormatter(row.creditAmount)}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                {row.auditUserName}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.comments}
                                                                </ListItemText>
                                                        </ListItem>
                                                    ))}
                                                </>) }
                                    </List>
                                </Grid>
                                <Divider sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }} />
                            </>)}
                            <Grid container spacing={2} mb={2} mt={1}>
                                <Grid item xs={12}>
                                    <Typography fontSize={15}>
                                        <CustomCheckbox
                                            onChange={(e) => {
                                                const { checked } = e.target;
                                                setCreditMemoForProduct(!creditMemoForProduct)
                                                if(checked){
                                                    setSubmitAction("RerturnProductSelected")
                                                }else{
                                                    setCheckedList([]);
                                                    setIsAnyChecked(false)
                                                    setValue('returnAmt',null)
                                                }
                                            }
                                            }
                                            control={control}
                                            name={'creditMemoForProduct'}
                                            checked={creditMemoForProduct}
                                        />
                                        Is credit memo related to product return?
                                    </Typography>
                                </Grid>
                                {creditMemoForProduct && (<>
                                    <Grid item xs={12}>
                                        <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                                            <ListItem>
                                                    <ListItemIcon sx={{ textAlign: 'left' }}>

                                                    </ListItemIcon>
                                                    <ListItemText sx={{ width: "15%" }}>
                                                        <b>Product Name</b>
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: "15%" }}>
                                                        <b>Serial #</b>
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: "15%" }}>
                                                        <b>Credit Issued ? </b>
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: "15%" }}>
                                                        <b>Return Date</b>
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: "15%" }}>
                                                        <b>Return Reason</b>
                                                    </ListItemText>
                                                    <ListItemText sx={{ width: "15%" }}>
                                                        <b>Amount</b>
                                                    </ListItemText>
                                            </ListItem>
                                            {recordCreditMemo?.productReturnDTOList?.length > 0 ?
                                                (<>
                                                    {recordCreditMemo?.productReturnDTOList?.map((row: any) => (
                                                        <ListItem key={row?.productReturnId}>
                                                                <ListItemIcon >
                                                                    <CustomCheckbox
                                                                        onChange={(e) => handleCheckboxChange(e, row.productReturnId, row.totalReturnAmount)}
                                                                        control={control}
                                                                        name={`select-${row.productReturnId}`}
                                                                        checked={checkedList.includes(row.productReturnId)}
                                                                        isDisabled={(disableCheckBox(row)||isAnyChecked)&&!checkedList.includes(row.productReturnId)}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.productName}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.returnDetail}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.isCreditMemoRecorded?'Yes':'No'}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.returnDate}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {row.returnReason}
                                                                </ListItemText>
                                                                <ListItemText sx={{ width: "15%" }}>
                                                                    {priceFormatter(row.totalReturnAmount)}
                                                                </ListItemText>
                                                        </ListItem>
                                                    ))}
                                                </>) : (
                                                    <Typography>No returned product available.</Typography>
                                                )}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2.4}>
                                        <CustomCurrencyField
                                            label="Return Amt"
                                            controllerName="returnAmt"
                                            control={control}
                                            isDisabled
                                            rules={
                                                submitAction ==="RerturnProductSelected" && recordCreditMemo?.productReturnDTOList?.length > 0
                                                ?{required:'Please select the returned product',min: { value: 1, message: "Please select the returned product" }}:{}
                                            }
                                        />
                                    </Grid>
                                </>)}
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={12} md={3} lg={2.4}>
                                    <CustomCurrencyField
                                        controllerName="creditAmt"
                                        control={control}
                                        label="Credit Amt"
                                        rules={
                                            submitAction === "recordedCredit" || submitAction === "RerturnProductSelected"
                                                ? { required: "Credit Amount is required", min: { value: 0.01, message: "Minimum value allowed is 0.01" } ,max:{value:recordCreditMemo?.remainingAmount , message: " Credit amount should not be higher than Remaining Payments"} }
                                                : {}
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        control={control}
                                        controllerName="comments"
                                        label="Comments"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                            </Grid>
                            <Box display="flex" justifyContent="end" mt="20px">
                                <Button
                                    size="large"
                                    startIcon={<CheckCircleOutline />}
                                    sx={{ mr: 1 }}
                                    type="submit"
                                    onClick={() => {
                                        if (creditMemoForProduct) {
                                            setSubmitAction("recordedCreditAgainstReturnProduct")
                                        } else {
                                            setSubmitAction("recordedCredit")
                                        }
                                    }}
                                >
                                    Record Credit Memo
                                </Button>
                                <Button
                                    color="error"
                                    startIcon={<Cancel />}
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Card>
            </Box>
        </Box>
    );
};

export default RecordCreditMemo;