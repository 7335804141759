import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Description from "@mui/icons-material/Description";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "../../common/Card";
import { tokens } from "../../../theme";
import { Folder } from "@mui/icons-material";

const ExternalLink: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuFile, setMenuFile] = useState<string | null>(null);

  const documents = [
    {
      title: "Post Operation",
      files: [
        {
          documentName: "V1Sample Document.pdf",
          dateUploaded: "11/11/2024",
          lastUploaded: "21/11/2024",
          uploadBy: "harsha@gmail.com",
        },
        {
          documentName: "V2Sample Document.pdf",
          dateUploaded: "12/12/2024",
          lastUploaded: "22/12/2024",
          uploadBy: "madushanka@gmail.com",
        },
      ],
    },
    {
      title: "Product Grid",
      files: [
        {
          documentName: "V3Sample Document.pdf",
          dateUploaded: "13/13/2024",
          lastUploaded: "23/13/2024",
          uploadBy: "hm@gmail.com",
        },
      ],
    },
  ];

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    file: string
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuFile(file);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuFile(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.grey[900],
        pt: 5
      }}
    >
      <Card>
        <Box sx={{ textAlign: "center" }}>
          <img
            src={require("../../../assets/V3_logo-gradient.png")}
            alt="V3 Biomed Logo"
            style={{ height: "60px", marginBottom: "20px" }}
          />
        </Box>

        <Box mb={2}>
          <Typography
            variant="h4"
            sx={{ color: colors.success[300], fontWeight: "bold", mb: 1 }}
          >
            Documents
          </Typography>
        </Box>

        <Box>
          <Accordion
            sx={{
              backgroundColor: "transparent",
              mb: 1,
              "&:before": { display: "none" },
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                alignItems: "center",
                justifyContent: "space-between",
                px: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", flexGrow: 1 }}
              >
                Practice Onboarding
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                {documents.map((doc, idx) => (
                  <Grid item xs={12} key={idx}>
                    <Accordion
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        "&:before": { display: "none" },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          "& .MuiAccordionSummary-content": {
                            margin: 0,
                          },
                          px: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Folder
                            sx={{ fontSize: 40, color: "#4A90E2", mr: 1 }}
                          />
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {doc.title}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {doc.files.map((file, fileIdx) => (
                          <React.Fragment key={fileIdx}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderRadius: "8px",
                                mb: 1,
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box
                                  sx={{
                                    width: "38px",
                                    height: "38px",
                                    backgroundColor: colors.success[400],
                                    borderRadius: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "16px",
                                  }}
                                >
                                  <Description sx={{ fontSize: "28px" }} />
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {file.documentName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: colors.grey[500] }}
                                  >
                                    Uploaded By: {file.uploadBy}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ color: colors.grey[500], mr: 2 }}
                                >
                                  {file.lastUploaded}
                                </Typography>
                                <IconButton
                                  onClick={(e) =>
                                    handleMenuClick(e, file.documentName)
                                  }
                                >
                                  <MoreVertIcon sx={{}} />
                                </IconButton>
                              </Box>
                            </Box>
                            {fileIdx < doc.files.length - 1 && (
                              <Box
                                sx={{
                                  borderBottom: "1px dashed",
                                  borderColor: colors.grey[700],
                                  mb: 1,
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              backgroundColor: "transparent",
              mb: 1,
              "&:before": { display: "none" },
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                alignItems: "center",
                justifyContent: "space-between",
                px: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", flexGrow: 1 }}
              >
                Practice Onboarding
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                {documents.map((doc, idx) => (
                  <Grid item xs={12} key={idx}>
                    <Accordion
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        "&:before": { display: "none" },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          "& .MuiAccordionSummary-content": {
                            margin: 0,
                          },
                          px: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Folder
                            sx={{ fontSize: 40, color: "#4A90E2", mr: 1 }}
                          />
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {doc.title}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {doc.files.map((file, fileIdx) => (
                          <React.Fragment key={fileIdx}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                borderRadius: "8px",
                                mb: 1,
                              }}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Box
                                  sx={{
                                    width: "38px",
                                    height: "38px",
                                    backgroundColor: colors.success[400],
                                    borderRadius: 2,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "16px",
                                  }}
                                >
                                  <Description sx={{ fontSize: "28px" }} />
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {file.documentName}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{ color: colors.grey[500] }}
                                  >
                                    Uploaded By: {file.uploadBy}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ color: colors.grey[500], mr: 2 }}
                                >
                                  {file.lastUploaded}
                                </Typography>
                                <IconButton
                                  onClick={(e) =>
                                    handleMenuClick(e, file.documentName)
                                  }
                                >
                                  <MoreVertIcon sx={{}} />
                                </IconButton>
                              </Box>
                            </Box>
                            {fileIdx < doc.files.length - 1 && (
                              <Box
                                sx={{
                                  borderBottom: "1px dashed",
                                  borderColor: colors.grey[700],
                                  mb: 1,
                                }}
                              />
                            )}
                          </React.Fragment>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleMenuClose}>View</MenuItem>
          <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
          <MenuItem onClick={handleMenuClose}>Delete</MenuItem>
        </Menu>
      </Card>
    </Box>
  );
};

export default ExternalLink;
