import React, { useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import Button from "../../common/Button";
import { Cancel } from "@mui/icons-material";
import CustomTable from "../../common/CustomTable";
import { Column, useCommonReducer } from "../../../redux/slice/commonSlice";
import { getProduct, getProductPricingHistory, useProductReducer } from "../../../redux/slice/product/createProductSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import { tokens } from "../../../theme";

interface ProductItemPriceHistoryProps {
    open: boolean;
    onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const ProductAgreementPopup: React.FC<ProductItemPriceHistoryProps> = ({
    open,
    onClose
}) => {
    const dispatch = useAppDispatch(); 
     const theme = useTheme();
      const colors = tokens(theme.palette.mode);
    const { productObject } = useProductReducer();
    const transformPriceList = (list: any[]) => {
        return list?.map((obj) => ({
            ...obj,
            extendedPrice: <CustomCurrencyField externalValue={obj?.extendedPrice} displayType="text" />,
            discountType:
                obj?.discountType === "CUSTOM"
                    ? obj?.discountType
                    : <CustomCurrencyField externalValue={obj?.discountType} displayType="text" />,
        })) || [];
    };
    
    const newPriceAgreementListArray = useMemo(
        () => transformPriceList(productObject.productPrices.newPriceAgreementList),
        [productObject.productPrices]
    );
    
    const oldPriceInactiveAgreementListArray = useMemo(
        () => transformPriceList(productObject.productPrices.oldPriceInactiveAgreementList),
        [productObject.productPrices]
    );
    
    const notProcessedAgreementListArray = useMemo(
        () => transformPriceList(productObject.productPrices.notProccessedAgreementList),
        [productObject.productPrices]
    );
    const dynamiCountColor = (count:number)=>{
        if(count > 0){
            return (<span style={{color:'greenyellow'}} >{count}</span>)
        }else{
            return (<span style={{color:'red'}}>{count}</span>)
        }
    }
    const columnsPriceItem: Column[] = useMemo(() => {
        return [
            { id: "productName", label: "Product Name", minWidth: 100 },
            { id: "practiceName", label: "Practice Name", minWidth: 100 },
            { id: "providerName", label: "Provider Name", minWidth: 100 },
            { id: "agreementStatus", label: "Agreement Status", minWidth: 100 },
            { id: "effectiveDate", label: "Effective Date", minWidth: 100, align: "right" },
            { id: "inactiveDate", label: "Inactive Date", minWidth: 100, align: "right" },
            { id: "fileType", label: "File Type", minWidth: 100, align: "right" },
            { id: "discountType", label: "Discount Price", minWidth: 100, align: "right" },
            { id: "extendedPrice", label: "Extended Price", minWidth: 100, align: "right" },
        ]
    }, [open]);

    // useEffect(()=>{
    //         dispatch(getProduct({ productId: parseInt(productObject.productPrices.productId) }))

    // },[open])
    
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                maxWidth={"lg"}
            >
                <DialogTitle>Product Agreement(s) Detail</DialogTitle>
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <Grid container>
                    <Grid item xs={4}>
                        <DialogContent>Total Agreement Created : {productObject.productPrices.newPriceAgreementList ? dynamiCountColor(productObject.productPrices.newPriceAgreementList?.length ):0} </DialogContent>
                    </Grid>
                    <Grid item xs={4}>
                        <DialogContent>Total Agreement Inactivated : {productObject.productPrices.oldPriceInactiveAgreementList ? dynamiCountColor(productObject.productPrices.oldPriceInactiveAgreementList?.length ):0}  </DialogContent>
                    </Grid>
                    <Grid item xs={4}>
                        <DialogContent>Total Agreement can not be created : {productObject.productPrices.notProccessedAgreementList ? dynamiCountColor(productObject.productPrices.notProccessedAgreementList?.length) :0}  </DialogContent>
                    </Grid>
                </Grid>
                {/* <Typography>Click on the table name to see the details</Typography> */}
                <Divider orientation="horizontal" variant="fullWidth" flexItem />
                <DialogContent>
                    <Box p={1}>
                        <Grid container columnSpacing={2} spacing={2}>
                            <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary id="panel-header" aria-controls="panel-content">
                                New Price Agreement(s)
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CustomTable
                                        columns={columnsPriceItem}
                                        rows={newPriceAgreementListArray}
                                        disablePagination={true}
                                        borderBox={true}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            </Grid>
                            <Grid item />
                            <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary id="panel-header" aria-controls="panel-content">
                               Old Price Agreement(s)
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CustomTable
                                        columns={columnsPriceItem}
                                        rows={oldPriceInactiveAgreementListArray}
                                        disablePagination={true}
                                        borderBox={true}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            </Grid>
                            <Grid item />
                            <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary id="panel-header" aria-controls="panel-content">
                                    Not Processed Agreement(s)
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CustomTable
                                        columns={columnsPriceItem}
                                        rows={notProcessedAgreementListArray}
                                        disablePagination={true}
                                        borderBox={true}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            </Grid>
                            <Grid item />
                            <Grid xs={12}>
                           <Typography style={{color:colors.info[200],fontSize:'15px',marginLeft:'30%'}}>Pricing added successfully. Check PRICING HISTORY for past details.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button
              color="error"
              onClick={() => {
                onClose();
                window.location.reload();
              }}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ProductAgreementPopup;
