import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";

export type SearchDocument = {
  agencyName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  address: string | undefined;
  commType: string | undefined;
  agencyStatus: string | undefined;
  currentPage: number;
  itemsPerPage: number;
};

export type DocumentUpload = {
  documentType: string | undefined;
  effectiveDate: string | undefined;
  uploadDocument: string | undefined;
  documentName: string | undefined;
  documentURL: string | undefined;
  productTag: string | undefined;
  manufacturerTag: string | undefined;
  categoryTag: string | undefined;
  subCategoryTag: string | undefined;
  publishTag: string | undefined;
};


type DocumentState = {
  status: string;
  error: string | null;

}

const initialState: DocumentState = {
  status: "idle",
  error: null

};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    resetManufacturerData: (state: DocumentState) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export const useDocumentReducer = () => useAppSelector((state) => state.documentSlice);
export default documentSlice.reducer;