import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  OrderInfo,
  OrderItemDTO,
  addClaim,
  addReimbursedClaim,
  claimInfo,
  getOrderItemsByOrderId,
  orderItemClaim,
  orderItemInvoice,
  reimburseClaimInfo,
  useOrderSlice,
} from "../../../redux/slice/order/OrderSlice";
import OptionsPopup, {
  OptionPopupObjectProps,
} from "../../common/OptionsPopup";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CustomDatePicker from "../../common/CustomDatePicker";
import CustomTextField from "../../common/CustomTextField";
import Button from "../../common/Button";
import {
  ArrowDownwardOutlined,
  Cancel,
  CheckCircleOutline,
  CurrencyExchangeOutlined,
  PaidOutlined,
} from "@mui/icons-material";
import CustomCheckbox from "../../common/CustomCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { selectIsTokenAvailable } from "../../../redux/slice/authSlice";
import dayjs from "dayjs";
import { getProductItemHistory } from "../../../redux/slice/product/createProductSlice";
import { getProductItem } from "../../../service/ProductService";
import {
  getPracticeProductById,
  getPracticeProductByPracticeProductId,
} from "../../../redux/slice/practiceSlice";
import {
  SingleAlertInfo,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import CustomCurrencyField from "../../common/CustomCurrencyField";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { convertBase64, priceFormatter } from "../../../utils/Utility";
import {
  addInvoice,
  Attachment,
  SaveInvoiceInfo,
} from "../../../redux/slice/invoice/InvoiceSlice";
import { RESPONSE_CODES } from "../../../constants/applicationConstants";
import moment from "moment";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

export interface OrderItemRow {
  select: JSX.Element;
  orderItemId: number;
  serialNumber: string;
  size: string;
  tissueTrackingId: number;
  productReturnId: number;
  claim: orderItemClaim;
  invoice: orderItemInvoice;
  extendedPrice: number;
  invoiceId: number;
  units: number;
}

interface billingForm {
  insuranceClaim: string;
  mfgInvoice: string;
  invoiceAmt: number;
  invoicedDate: any;
  invoiceDueDate: string;
  calculatedAmt: number;
  attachment: Attachment[];
}

interface FormattedOrderItem {
  orderItemId: number;
}

const Billing = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<billingForm>({
    defaultValues: {
      insuranceClaim: "",
      mfgInvoice: "",
      invoicedDate: "",
      invoiceDueDate: "",
    },
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<OrderInfo>();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showOptionPopup, setShowOptionsPopup] =
    useState<OptionPopupObjectProps>({ message: "", show: false });
  const [orderInfo, setOrderInfo] = useState<OrderInfo>();
  const { orderItemsListInfo } = useOrderSlice();
  const [submitAction, setSubmitAction] = useState<string>();
  const [productExtendedPrice, setProductExtendedPrice] = useState<number>(0);
  const [orderItemRow, setOrderItemRow] = useState<OrderItemRow[] | []>([]);
  const [totalExtendedPrice, setTotalExtendedPrice] = useState(0);
  const [statusCount, setStatusCount] = useState<{
    orderItemsCount: number;
    countToCheck: number;
  }>({
    orderItemsCount: 0,
    countToCheck: 0,
  });
  const location = useLocation();
  const [extendedPriceInfo, setExtendedPriceInfo] = useState("");
  const [invoiceNetdays, setInvoiceNetdays] = useState(0);
  const [invoiceDueDate, setInvoiceDueDate] = useState<string | undefined>(undefined);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const orderDetails: OrderInfo = location?.state?.selectedOrderBilling;
    const dateOfService = orderDetails.dateOfService;
    const orderDate = orderDetails.orderDate;

    if (orderDetails) {
      const fetchProduct = async () => {
        try {
          const product = await getProductItem(orderDetails.productId);
          const customConfig = product?.data?.data?.manufactureCustomConfig;
          const invoiceDueDate =
            customConfig?.invoice_due_date &&
            customConfig?.invoice_due_date === "date_of_service"
              ? dateOfService
              : customConfig?.invoice_due_date &&
                customConfig?.invoice_due_date === "order_date"
              ? orderDate
              : "";
          const invoiceNetdays =
            customConfig?.invoice_net_days && customConfig?.invoice_net_days > 0
              ? customConfig?.invoice_net_days
              : 0;
          setInvoiceNetdays(invoiceNetdays);
          setInvoiceDueDate(invoiceDueDate);
          // if (invoiceDueDate) { CS-612 commented to prevent default initialization
          //   setValue(
          //     "invoiceDueDate",
          //     dayjs(invoiceDueDate)
          //       .add(invoiceNetdays, "day")
          //       .format("MM/DD/YYYY")
          //   );
          // }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      };
      fetchProduct();
    }

    setOrderInfo(orderDetails);
    dispatch(getProductItemHistory({ productId: orderDetails.productId }));
    dispatch(getOrderItemsByOrderId(orderDetails.orderId));
    dispatch(getPracticeProductById(orderDetails.productId.toString())).then(
      (res) => {
        if (res.payload) {
          const productAgreementObject = res.payload;
          setProductExtendedPrice(productAgreementObject.extendedPrice);
        }
      }
    );
  }, []);

  const parseSize = (size: string): number => {
    const sizeWithoutCm = size.replace("cm", "");
    const [length, width] = sizeWithoutCm.split("x").map(Number);
    return length * width;
  };

  const modifyClaimObject = (
    claim: orderItemClaim,
    size: string
  ): orderItemClaim => {
    return {
      ...claim,
      claimAmount: parseSize(size) * 122,
    };
  };

  const selectedOrderItems: { orderItemId: number }[] = useMemo(() => {
    const newSelectedOrderItems: { orderItemId: number }[] = checkedList?.map(
      (id) => ({
        orderItemId: id,
      })
    );
    return newSelectedOrderItems;
  }, [checkedList]);

  const onSubmit = async (data: billingForm) => {
    if (submitAction === "markClaim") {
      if (orderInfo) {
        const claimDtoObject: claimInfo = {
          orderId: orderInfo?.orderId,
          productId: orderInfo?.productId,
          claimNumber: data.insuranceClaim,
          claimStatus: "SUBMITTED",
          submittedDate: dayjs(new Date()).format("MM/DD/YYYY"),
          orderItems: [],
        };
        const response = await dispatch(addClaim(claimDtoObject));

        if (response.payload && response.payload.data.message === "SUCCESS") {
          let alertMessage = "Mark Claim Submitted!";
          const alertObj: SingleAlertInfo = {
            message: alertMessage,
            alertType: "success",
          };
          dispatch(setSingleAlertObj(alertObj));
        }
      }
    } else if (submitAction === "markInvoiced") {
      if (checkedList?.length > 0) {
        setShowOptionsPopup({
          message:
            data.calculatedAmt == data.invoiceAmt
              ? "Do you really want to record the the invoice?"
              : "Calculated Amount and Invoice Amount are not equal. Do you still want to record the invoice?",
          show: true,
          variant: "confirm",
          buttons: [
            {
              name: "Confirm",
              color: "primary",
              onClick: () => submitMarkInvoiced(data),
            },
            { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
          ],
        });
      } else {
        let alertMessage = "Please select atleast one order item";
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
    }
  };

  const submitMarkInvoiced = async (data: billingForm) => {
    if (orderInfo) {
      const product = await getProductItem(orderInfo.productId);
      const manufacturerId =
        product && product.data.data && product.data.data.manufacturerId;

      const formattedInvoiceDueDate = moment(data.invoiceDueDate, "MM/DD/YYYY", true).isValid()
        ? moment(data.invoiceDueDate).format("MM/DD/YYYY")
        : moment().format("MM/DD/YYYY");

      const invoiceDtoObject: SaveInvoiceInfo = {
        orderId: orderInfo?.orderId,
        manufacturerId: manufacturerId,
        mfgInvoiceNumber: data.mfgInvoice,
        mfgInvoiceDate: data.invoicedDate.format("MM/DD/YYYY"),
        invoiceDueDate: formattedInvoiceDueDate,
        calculatedAmount: data.calculatedAmt,
        invoiceAmount: Number(data.invoiceAmt),
        orderItems: selectedOrderItems,
        attachInvoice: data.attachment,
        orderInvoiceStatus:
          statusCount.countToCheck === checkedList?.length
            ? "INVOICED"
            : "PARTIAL",
      };
      const response = await dispatch(
        addInvoice({
          invoiceData: invoiceDtoObject,
          interceptorOption: { skipErrorPopup: true },
        })
      );
      if (response?.payload?.code === RESPONSE_CODES.SUCCESS_CODE) {
        let alertMessage = "Invoice was recorded successfully";
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        navigate(-1);
      } else if (response?.payload?.code === RESPONSE_CODES.FAILED_CODE) {
        let alertMessage = response.payload?.message;
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      cancelOptionPopup();
    }
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const claimDtoObject: orderItemClaim = {
    claimId: 2,
    claimNumber: "C3156456",
    claimAmount: 22.36,
    claimStatus: "REIMBURSED",
    submittedDate: "05/22/2024",
    reimbursedDate: "06/13/2024",
  };

  useEffect(() => {
    const fetchOrderItems = async () => {
      if (orderItemsListInfo) {
        let count = 0;
        const orderItemsPromises = orderItemsListInfo.map(async (orderItem) => {
          const response = await dispatch(
            getPracticeProductByPracticeProductId({practiceProductId: orderItem.practiceProductId, productItemId: orderItem.productItemId.toString()})
          );
          const invoicedStatus = (orderItem?.tissueTrackingId && orderItem?.invoiceId) || (orderItem?.productReturnId && orderItem?.invoiceId) || (!orderItem?.tissueTrackingId && !orderItem?.productReturnId && orderItem?.invoiceId);
          if (
            !invoicedStatus
          ) {
            count += 1;
            setStatusCount((prev) => {
              return { ...prev, countToCheck: count };
            });
          }
          return {
            select: <CustomCheckbox control={control} name="select" />,
            orderItemId: orderItem.orderItemId,
            serialNumber: orderItem.serialNumber,
            size: orderItem.size,
            tissueTrackingId: orderItem.tissueTrackingId,
            productReturnId: orderItem.productReturnId,
            claim: modifyClaimObject(claimDtoObject, orderItem.size),
            invoice: orderItem.invoice,
            extendedPrice: response?.payload?.extendedPrice
              ? response.payload.extendedPrice
              : "",
            productItemId: orderItem.productItemId,
            invoiceId: orderItem.invoiceId,
            units: orderItem.units,
          };
        });
        const orderItems = (await Promise.all(orderItemsPromises)).sort((a, b) => a.units - b.units); 

        const extendedPrices = orderItems
          .map((item) => item.extendedPrice)
          .filter((price) => price !== "");
        const firstArrayPrice = extendedPrices[0] || "";
        setExtendedPriceInfo(firstArrayPrice);

        setOrderItemRow(orderItems);
        setStatusCount((prev) => {
          return { ...prev, orderItemsCount: orderItemsListInfo.length };
        });
      }
    };

    fetchOrderItems();
  }, [orderItemsListInfo]);

  const handleCheckboxChange = (
    e: any,
    orderItemId: number,
    extendedPrice: number,
    units: number
  ) => {
    const { checked } = e.target;

    setTotalExtendedPrice((prev) => {
      const newVal = checked
        ? prev + extendedPrice * units
        : prev - extendedPrice * units;
      setValue("calculatedAmt", newVal);
      return newVal;
    });

    setCheckedList((prev) => {
      if (!prev.includes(orderItemId)) {
        return [...prev, orderItemId];
      }
      return prev.filter((id) => id !== orderItemId);
    });
  };

  const handleMarkedClaimReimbursed = async (claim: orderItemClaim) => {
    const addReimbursedClaimObj: reimburseClaimInfo = {
      claimId: claim.claimId,
      claimAmount: claim.claimAmount ? claim.claimAmount : 0,
      claimStatus: "REIMBURSED",
      reimbursedDate: dayjs(new Date()).format("MM/DD/YYYY"),
    };
    const response = await dispatch(addReimbursedClaim(addReimbursedClaimObj));
  };

  const handleMarkedInvoicePaid = (orderItemId: number) => {
    // setIsInvoicePaid((prevStatuses) => ({
    //   ...prevStatuses,
    //   [orderItemId]: !prevStatuses[orderItemId],
    // }));
  };

  const orderItemStatus = useCallback((row: OrderItemRow) => {
    if (row?.tissueTrackingId) {
      if (row?.invoiceId) {
        return "Applied (Invoiced)";
      }
      return "Applied";
    } else if (row?.productReturnId) {
      if (row?.invoiceId) {
        return "Returned (Invoiced)";
      }
      return "Returned";
    } else if (row?.invoiceId) {
      return "Invoiced";
    }
    return "Available";
  }, []);

  const columns = [
    { id: "size", label: "Ordered Size" },
    { id: "serialNumber", label: "Serial Number" },
    { id: "status", label: "Status" },
    { id: "claim", label: "Claim #" },
    { id: "invoice", label: "Invoice #" },
  ];

  const rows = orderItemRow;

  const caUpdateOrderClaim = useSelector((state: RootState) =>
    selectIsTokenAvailable("caUpdateOrderClaim")(state)
  );
  const caUpdateOrderInvoice = useSelector((state: RootState) =>
    selectIsTokenAvailable("caUpdateOrderInvoice")(state)
  );

  const handleCheckboxEnableDisable = (row: any) => {
    if (row.claim && caUpdateOrderClaim) {
      if (row.claim && row.claim.claimStatus) {
        return true;
      } else {
        return false;
      }
    } else if (row.invoice && caUpdateOrderInvoice) {
      if (row.invoice && row.invoice.mfgInvoiceStatus) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const disableCheckBox = (row: OrderItemRow) => {
    const status = orderItemStatus(row);
    if (status === "Invoiced" || status === "Applied (Invoiced)" || status === "Returned (Invoiced)") {
      return true;
    }
    return false;
  };

  const handleDropzoneChange = async (
    uploadedFiles: ExtendedFile[],
    fieldName: any
  ) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const base64File = String(await convertBase64(uploadedFiles[0]));
      const fileName = uploadedFiles[0].name;
      const fileExtension = fileName
        .slice(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      const base64Substring = base64File.substring(
        fileExtension === "pdf"
          ? "data:application/pdf;base64,".length
          : "data:image/png;base64,".length,
        base64File.length
      );
      const attachmentObj: Attachment[] = [
        {
          content: base64Substring,
          documentName: fileName,
          fileType: fileExtension,
          type: "INVOICE",
        },
      ];
      setValue("attachment", attachmentObj);
    }
  };

  const handleDeleteFile = () => {
    setValue("attachment", []);
  };

  const handleSelectAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      const selectList: number[] = [];
      let invAmt: number = 0;
      orderItemRow?.forEach((row) => {
      const status = orderItemStatus(row);
        if (status !== "Invoiced" && status !== "Applied (Invoiced)" && status !== "Returned (Invoiced)") {
          selectList.push(row.orderItemId);
          invAmt += row.extendedPrice * row.units;
        }
      });
      setTotalExtendedPrice(invAmt);
      setValue("calculatedAmt", invAmt);
      setCheckedList(selectList);
    } else {
      setCheckedList([]);
      setTotalExtendedPrice(0);
      setValue("calculatedAmt", 0);
    }
  };

  const handleDatechange = (date: any) => {
    if (date) {
      setValue(
        "invoiceDueDate",
        dayjs(date)
          .add(invoiceNetdays, "day")
          .format("MM/DD/YYYY")
      );
    }
  };

  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Header title="Invoice" subtitle="Invoice Management" />
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12}>
                  <Typography>
                    Please select items to Record Invoice.
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2} mb={2} mt={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Patient : {orderInfo?.patientName}
                  </Typography>
                  <Typography component="span" style={{ paddingLeft: "10px" }}>
                    {order?.patientName}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Product : {orderInfo?.productName}
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: "10px" }}>
                    {`(${orderInfo?.manufacturerName})`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Order # :{orderInfo?.orderNumber}
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: "10px" }}>
                    {order?.orderNumber}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography component="label" fontWeight="bold">
                    PO # :{orderInfo?.poNumber}
                  </Typography>
                  <Typography component="span" sx={{ paddingLeft: "10px" }}>
                    {order?.poNumber}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography component="label" fontWeight="bold">
                    Extended Price : {priceFormatter(Number(extendedPriceInfo))}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }} />
              {!isMobile && (
                <Grid container md={8} spacing={2} mb={2} mt={1}>
                  <Grid item xs={12}>
                    <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                      {!isMobile && (
                        <ListItem>
                          <ListItemButton
                            onClick={() => {}}
                            sx={{ padding: 0, borderRadius: 2, fontSize: 14 }}
                          >
                            <ListItemIcon sx={{ width: "15%", textAlign: "left" }}>
                              <CustomCheckbox
                                control={control}
                                controllerName="selectAll"
                                onChange={handleSelectAllCheckbox}
                                tooltipMessage="Select All"
                                tooltipPlacement="top"
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ width: "15%" }}>
                              <b>Ordered Size</b>
                            </ListItemText>
                            <ListItemText sx={{ width: "15%" }}>
                              <b>Serial Number</b>
                            </ListItemText>
                            <ListItemText sx={{ width: "15%" }}>
                              <b>Status</b>
                            </ListItemText>
                            {caUpdateOrderClaim ? (
                              <ListItemText sx={{ width: "15%" }}>
                                <b>Claim #</b>
                              </ListItemText>
                            ) : (
                              ""
                            )}
                            {caUpdateOrderInvoice ? (
                              <ListItemText sx={{ width: "15%" }}>
                                <b>Invoice #</b>
                              </ListItemText>
                            ) : (
                              ""
                            )}
                            <ListItemText sx={{ width: "15%", textAlign: "right" }}>
                              <b>COGS</b>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      )}
                  
                      {orderItemRow.map((row) => (
                        <ListItem key={row.orderItemId}>
                          <ListItemButton
                            onClick={() => {}}
                            sx={{ padding: 0, borderRadius: 2 }}
                          >
                            <ListItemIcon sx={{ width: "15%" }}>
                              <CustomCheckbox
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    row.orderItemId,
                                    row.extendedPrice,
                                    row.units
                                  )
                                }
                                control={control}
                                name={`select-${row.orderItemId}`}
                                checked={checkedList.includes(row.orderItemId)}
                                isDisabled={disableCheckBox(row)}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ width: "15%" }}>
                              {row.size}
                            </ListItemText>
                            <ListItemText sx={{ width: {xs: "50%", sm: "15%"}}}>
                              {row.serialNumber}
                            </ListItemText>
                            <ListItemText sx={{ width: "15%" }}>
                              {orderItemStatus(row)}
                            </ListItemText>
                            {caUpdateOrderClaim ? (
                              <ListItemText sx={{ width: "15%" }}>
                                {row.claim.claimNumber}
                                {row.claim.claimStatus === "SUBMITTED" ? (
                                  <>
                                    <IconButton
                                      color="info"
                                      onClick={() =>
                                        handleMarkedClaimReimbursed(row.claim)
                                      }
                                    >
                                      <CurrencyExchangeOutlined />
                                    </IconButton>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{ textAlign: "center" }}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="span"
                                      >
                                        {row.claim.claimStatus}
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : (
                                  ""
                                )}
                              </ListItemText>
                            ) : (
                              ""
                            )}
                            {caUpdateOrderInvoice ? (
                              <ListItemText sx={{ width: "15%" }}>
                                {row.invoice.mfgInvoiceNumber}
                                {row.invoice.mfgInvoiceStatus === "UNPAID" ? (
                                  <>
                                    <IconButton
                                      color="warning"
                                      onClick={() =>
                                        handleMarkedInvoicePaid(row.orderItemId)
                                      }
                                    >
                                      <PaidOutlined />
                                    </IconButton>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{ textAlign: "center" }}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="span"
                                      >
                                        {row.invoice.mfgInvoiceStatus}
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : (
                                  ""
                                )}
                              </ListItemText>
                            ) : (
                              ""
                            )}
                            <ListItemText
                              sx={{ width: {xs: "30%", sm: "15%"}, textAlign: "right" }}
                            >
                              {priceFormatter(row.extendedPrice * row.units)}
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              )}
              {isMobile &&(
                <Box p={1}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid xs={"auto"}>
                      <CustomCheckbox
                        control={control}
                        controllerName="selectAll"
                        onChange={handleSelectAllCheckbox}
                        tooltipMessage="Select All"
                        tooltipPlacement="top"
                      />
                    </Grid>
                    <Grid xs={"auto"}>
                      <Typography fontWeight="bold">
                        Select All 
                      </Typography>
                    </Grid>
                    <Grid xs={"auto"}>
                      <ArrowDownwardOutlined sx={{mt:1, ml:1}}/>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {isMobile && orderItemRow.map((row) => (
                    <Box sx={{p:1,mb:1, borderRadius : 2, borderColor : colors.grey[600]}} border={1}>
                      <Grid container spacing={2} alignItems="center" >
                        <Grid item xs={2}>
                          <CustomCheckbox
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  row.orderItemId,
                                  row.extendedPrice,
                                  row.units
                                )
                              }
                              control={control}
                              name={`select-${row.orderItemId}`}
                              checked={checkedList.includes(row.orderItemId)}
                              isDisabled={disableCheckBox(row)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                          <Typography>
                            {row.size}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>
                            {row.serialNumber}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>
                            {orderItemStatus(row)}
                          </Typography>
                        </Grid>
                        <Grid
                          xs={caUpdateOrderInvoice || caUpdateOrderClaim ? 6: 12} 
                          sx={{
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center'
                          }}
                        >
                          <Typography>
                            {priceFormatter(row.extendedPrice * row.units)}
                          </Typography>
                        </Grid>
                          {caUpdateOrderClaim ? (
                            <Grid item xs={6}>
                              {row.claim.claimNumber}
                              {row.claim.claimStatus === "SUBMITTED" ? (
                                <>
                                  <IconButton
                                    color="info"
                                    onClick={() =>
                                      handleMarkedClaimReimbursed(row.claim)
                                    }
                                  >
                                    <CurrencyExchangeOutlined />
                                  </IconButton>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ textAlign: "center" }}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="span"
                                    >
                                      {row.claim.claimStatus}
                                    </Typography>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                          ) : (
                            ""
                          )}
                          {caUpdateOrderInvoice ? (
                            <Grid item md={5}>
                              {row.invoice.mfgInvoiceNumber}
                              {row.invoice.mfgInvoiceStatus === "UNPAID" ? (
                                <>
                                  <IconButton
                                    color="warning"
                                    onClick={() =>
                                      handleMarkedInvoicePaid(row.orderItemId)
                                    }
                                  >
                                    <PaidOutlined />
                                  </IconButton>
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ textAlign: "center" }}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="span"
                                    >
                                      {row.invoice.mfgInvoiceStatus}
                                    </Typography>
                                  </Grid>
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                          ) : (
                            ""
                          )}
                        
                      </Grid>
                    </Box>
                    ))}
              <Divider sx={{ padding: 0, margin: 0, mt: 1, mb: 3 }} />
              {/* <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={3}>
                  <CustomTextField
                    controllerName="insuranceClaim"
                    control={control}
                    label="Insurance Claim #"
                    rules={submitAction === "markClaim" ? {required:"Insurance Claim Number is required"} : {}}
                  />
                </Grid>
                <Grid item md={6} xs={12}></Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    fullWidth
                    size="large"
                    startIcon={<CheckCircleOutline />}
                    style={{ padding: "15px" }}
                    type="submit"
                    onClick={() => {setSubmitAction("markClaim")}}
                  >
                    Mark Claim Submitted
                  </Button>
                </Grid>
              </Grid> */}
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} md={3} lg={2.4}>
                  <CustomTextField
                    controllerName="mfgInvoice"
                    control={control}
                    label="MFG Invoice #"
                    rules={
                      submitAction === "markInvoiced"
                        ? { required: "Manufacture Invoice Number is required" }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <CustomCurrencyField
                    label="Calculated Amt"
                    controllerName="calculatedAmt"
                    control={control}
                    isDisabled
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <CustomCurrencyField
                    controllerName="invoiceAmt"
                    control={control}
                    label="Invoice Amt"
                    rules={
                      submitAction === "markInvoiced"
                        ? { required: "Invoice amount is required" }
                        : {}
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <CustomDatePicker
                    controllerName="invoicedDate"
                    control={control}
                    label="Invoiced Date"
                    rules={
                      submitAction === "markInvoiced"
                        ? { required: "Invoice date is required" }
                        : {}
                    }
                    onChange={handleDatechange}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2.4}>
                  <CustomDatePicker
                    controllerName="invoiceDueDate"
                    control={control}
                    label="Invoice Due Date"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Dropzone
                    name="invoiceAttachment"
                    onFileChange={() => {}}
                    onFileChangeTest={handleDropzoneChange}
                    onDeleteFile={handleDeleteFile}
                    control={control}
                    maxFileCount={1}
                    label="Attach Invoice"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} display="flex" justifyContent="end">
                <Grid item md={"auto"} xs={12}>
                  <Button
                    size="large"
                    startIcon={<CheckCircleOutline />}
                    sx={{ width: { xs: "100%", md: "auto" } }}
                    type="submit"
                    onClick={() => {
                      setSubmitAction("markInvoiced");
                    }}
                  >
                    Record Invoice
                  </Button>
                </Grid>
                <Grid item md={"auto"} xs={12}>
                  <Button
                    color="error"
                    sx={{ width: { xs: "100%", md: "auto" } }}
                    startIcon={<Cancel />}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Billing;
