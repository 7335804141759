import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import {
  CancelOutlined,
  ClassOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { tokens } from "../../../theme";
import CustomDatePicker from "../../common/CustomDatePicker";
import { useEffect, useMemo, useState } from "react";
import { PracticeInfo, PracticeLocationInfo, getAgreementByProductIdAndPracticeId, getPracticesByUserRole, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { UserRole, fetchAllUserRoles } from "../../../redux/slice/userSlice";
import { useAuthSlice } from "../../../redux/slice/authSlice";
import { fullName, hideElement, sortNames } from "../../../utils/Utility";
import { ORDERTYPE, USER_ROLES } from "../../../constants/applicationConstants";
import { ProductItemInfo, fetchProductItemsByProductId, fetchProductsByUserRoleData, useProductListReducer } from "../../../redux/slice/product/productListSlice";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { addOrder, AddOrderInfo, getOrderItemsByOrderId, GraftQty, OrderInfo, OrderItemDTO, OrderItemInfo, PatientDetails } from "../../../redux/slice/order/OrderSlice";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import { fetchPracticeProductsData, fetchPracticeProductsDataUsingDateOfServiceAndProvider, usePracticeProductsSlice } from "../../../redux/slice/practice/practiceProductSlice";
import { fetchPracticeByIdData } from "../../../redux/slice/practice/practiceEditSlice";
import BorderedSection from "../../common/BorderedSection";
import CustomNametField from "../../common/CustomNametField";
import { PatientInfo } from "../../../redux/slice/patient/patientSlice";
import CustomRadioButton from "../../common/CustomRadioButton";
import CustomNumberField from "../../common/CustomNumberField";
import { orderUpdate } from "../../../service/OrderService";

interface Row {
  graftsNeeded: string;
  qty?: string;
  id: number;
};

type Option = {
  value: string | number,
  label: string
};

type productOption = {
  value: string | number,
  label: string,
  practiceProductId: string | undefined
};

type OrderFormState = {
  practiceDropdown?: string,
  product?: number | string,
  provider: number | string,
  facilityAddress: number | string,
  graftQty: string[],
  requestedDeliveryDate: string,
  requestedShippingMethod: string,
  dateOfService: string,
  po: string,
  manufactureEmailNotifications: string,
  patientInfo?: {
    patientFirstName: string,
    patientMiddleName: string,
    patientLastName: string,
    birthDate: string,
    gender: string
  }
}

export type PracticeProductAgreementDTO = {
  practiceProductId: number;
  practiceName: string;
  productName: string;
  extendedPrice: number;
  discountType: string;
  effectiveDate: string;
  inactiveDate: string | null;
  agreementStatus: string;
  practiceSignedDate: string | null;
  manufacturerSignedDate: string | null;
  productLogo: any;
};

const Order = () => {
  const { control, handleSubmit, reset, watch, getValues, setValue } = useForm<OrderFormState>();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const { practiceByUserRole } = usePracticeSlice();
  const { productsByUserRole } = useProductListReducer();
  const { userInfo } = useAuthSlice();
  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
  const { practiceProductsInfo } = usePracticeProductsSlice();
  const [order, setOrder] = useState<{ orderType: string, orderDetails: any, orderDate: string }>({
    orderType: "", 
    orderDetails: {},
    orderDate: "",
  });
  const [productIVR, setProductIVR] = useState<ProductItemInfo[]>();
  const [listData, setListData] = useState<Row[]>([]);
  const [locationDetails, setLocationDetails] = useState<Option>();
  const [selectedProduct, setSelectedProduct] = useState<productOption | null>();
  const [graftsQtyList, setGraftsQtyList] = useState<GraftQty[]>();
  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({ message: '', show: false });
  const [shippingMethods, setShippingMethods] = useState<CodeTable[]>([]);
  const [isDisableProducts, setIsDisableProducts] = useState(true);
  const [isPracticeUser, setIsPracticeUser] = useState(false);
  const [isRoleAdminUser, setIsRoleAdminUser] = useState(false);
  const [practiceIVR, setPracticeIVR] = useState<PracticeInfo | null>(null);
  const [validPP, setValidPP] = useState<PracticeProductAgreementDTO | null>(null);
  const [manufacturerEmailNotifications, setManufacturerEmailNotifications] = useState<boolean>(true);
  const [selectedLocation, setSelectedLocation] = useState("");

  const orderType = location?.state?.orderType;
  const orderDetails = location?.state?.orderDetails;
  const receivedOrderDate = location?.state?.orderDate;

  useEffect(() => {
    
    if (orderDetails?.productId) {
      productItemByIdDirectOrder(orderDetails.productId);
      setIsDisableProducts(false);
    };
    if (orderDetails?.practiceId) {
      setPracticeFromIVR(orderDetails.practiceId);
    }
    setOrder({
      orderType: orderType,
      orderDetails: orderDetails,
      orderDate: receivedOrderDate,
    });
    setOrderData(orderDetails)
    setUserRole();
    fetchPracticeListByUserRole();
    dispatch(fetchProductsByUserRoleData(true));
  }, []);

  const setOrderData = (orderDetails: any) => {
    if (orderDetails?.orderId) {
      setValue('po', orderDetails?.poNumber);
      setValue('dateOfService', orderDetails?.dateOfService);
      setValue("requestedDeliveryDate", orderDetails?.requestedDeliveryDate);
    }
  };

  useEffect(() => {
    if (isPracticeUser) {
      setIsDisableProducts(false);
    };
  }, [isPracticeUser]);

  const setUserRole = () => {
    if (userInfo?.userRole === USER_ROLES.ROLE_PRACTICEADMIN || userInfo?.userRole === USER_ROLES.ROLE_PRACTICESTAFF) {
      setIsPracticeUser(true);
    } else if (userInfo?.userRole === USER_ROLES.ROLE_ADMIN) {
      setIsRoleAdminUser(true);
    };
  }

  const setPracticeFromIVR = async (practiceId: number) => {
    const response = await dispatch(fetchPracticeByIdData(practiceId.toString()));
    if (response?.payload?.data) {
      setPracticeIVR(response.payload.data);
    };
  }

  const productItemByIdDirectOrder = async (selectedOrderId: number) => {
    const onlyActiveRecords = true;
    const response = await dispatch(fetchProductItemsByProductId({ productId: selectedOrderId, onlyActiveRecords }));
    if (response.meta.requestStatus === 'fulfilled') {
      setProductIVR(response?.payload);
    };
  };

  const productItemByIdConsignmentOrder = async (selectedProductId: any) => {
    const onlyActiveRecords = true;
    const response = await dispatch(fetchProductItemsByProductId({ productId: selectedProductId, onlyActiveRecords }));
    if (response.meta.requestStatus === 'fulfilled') {
      return response.payload;
    };
  };

  const setOrderItemsData = async (orderDetails: any) => {
    if (orderDetails?.orderId) {
      const response = await dispatch(getOrderItemsByOrderId(orderDetails.orderId));
      const orderItemList: OrderItemDTO[] = response?.payload?.data;
      let graftList: GraftQty[] = [];
      orderItemList.forEach(orderItem => {
        const prevQty = getValues(`graftQty.${orderItem.productItemId}`) || "0";
        const newQty = parseInt(prevQty) + orderItem.quantity;
        setValue(`graftQty.${orderItem.productItemId}`, newQty.toString());

        let graftItem: GraftQty | undefined = graftList.find(item => item.productItemId == orderItem.productItemId);
        if (graftItem) {
          const newList = graftList.filter(prev => prev.productItemId != orderItem.productItemId);
          newList.push({
            productItemId: orderItem.productItemId,
            quantity: newQty
          });
          graftList = newList;
        } else {
          graftList?.push({
            productItemId: orderItem.productItemId,
            quantity: newQty
          })
        }
      });

      setGraftsQtyList(graftList);
    }
  };

  useEffect(() => {
    if (productIVR) {
      setListData(() => {
        const newValues = [...productIVR] 
        .sort((a, b) => Number(a.units) - Number(b.units))
        .map(productItem => ({
          graftsNeeded: productItem.size,
          id: productItem.productItemId,
        }))
        return newValues;
      });
      setOrderItemsData(order?.orderDetails)
    } else if (selectedProduct) {
      productItemByIdConsignmentOrder(selectedProduct.value).then((data) => {
        setListData(() => {
          const newValues = [...(data || [])]
          .sort((a: { size: string; productItemId: number; units: number}, b: { size: string; productItemId: number; units: number}) => a.units - b.units)
          .map((productItem: { size: string; productItemId: number; units: number }) => ({
            graftsNeeded: productItem.size,
            id: productItem.productItemId,
          }));
          return newValues;
        });
      });
    };
  }, [productIVR, selectedProduct]);

  useEffect(() => {
    if (allCodeTableValueFetchStatus === "success" && allCodeTablesValuesMap) {
      setShippingMethods(getCodeTableValuesByTableName(allCodeTablesValuesMap, "shippingMethod"));
    }
  }, [allCodeTablesValuesMap, allCodeTableValueFetchStatus]);

  useEffect(() => {
    if (practiceByUserRole && practiceByUserRole.length > 0 && isPracticeUser) {
      dispatch(fetchPracticeProductsData(practiceByUserRole[0].practiceId))
    }
  }, [practiceByUserRole])

  const fetchPracticeListByUserRole = async () => {
    const response = await dispatch(fetchAllUserRoles());
    if (response?.meta?.requestStatus === 'fulfilled') {
      if (response?.payload) {
        const userRoles = response.payload;
        const role = userRoles?.find((role: UserRole) => role.roleName === userInfo.userRole);
        if (role) {
          dispatch((getPracticesByUserRole(false)))
        };
      };
    };
  };

  const dateOfService = watch('dateOfService');
  useEffect(() => {
    const fetchPracticeProduct = async () => {
      if (order?.orderDetails?.practiceId && order?.orderDetails?.productId) {
        const response = await dispatch(getAgreementByProductIdAndPracticeId({ practiceId: order.orderDetails.practiceId, productId: order.orderDetails.productId }));
        if (response?.meta?.requestStatus === 'fulfilled') {
          const agreementList = response.payload || [];
          const checkDate = dayjs(getValues('dateOfService')) || dayjs();
          const validAgreement = agreementList.find((agreement: PracticeProductAgreementDTO) => {
            return (
              (dayjs(agreement.effectiveDate).isBefore(checkDate) || dayjs(agreement.effectiveDate).isSame(checkDate)) &&
              (agreement.inactiveDate === null || dayjs(agreement.inactiveDate).isAfter(checkDate) || dayjs(agreement.inactiveDate).isSame(checkDate))
            );
          });
          setValidPP(validAgreement);
        }
      };
    };
    fetchPracticeProduct();
  }, [order, dateOfService]);

  const onSubmit = async () => {
    if (graftsQtyList && graftsQtyList.length > 0) {
      if (order?.orderType === ORDERTYPE.DIRECT_ORDER && !validPP) {
        let errObj: SingleAlertInfo = {
          message: `Valid Product Agreement not found.`,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errObj));
      } else {
        setShowOptionsPopup({
          message: "Do you really want to create the order?",
          show: true,
          variant: "confirm",
          buttons: [{ name: "Confirm", color: 'primary', onClick: () => createOrder() }, { name: "Cancel", color: 'secondary', onClick: cancelOptionPopup }]
        });
      }
    } else {
      let errObj: SingleAlertInfo = {
        message: "Please enter a quantity for at least one graft.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj));
    };
  };

  const shippingMethodOptions = useMemo(() => {
    return shippingMethods.map(method => ({
      label: method.displayValue,
      value: method.keyName
    }))
  }, [shippingMethods]);

  useEffect(() => {
    if (order?.orderDetails?.orderId && order?.orderDetails?.requestedShippingMethod) {
      setValue("requestedShippingMethod", order.orderDetails.requestedShippingMethod)
    }
  }, [order, shippingMethodOptions])

  const createOrder = async () => {
    const data = getValues();
    const selectedProvider = providerOptions.find(provider => provider.value === data.provider);
    let createOrderObj: AddOrderInfo | null = null;
    if (order?.orderDetails !== undefined && order?.orderType === ORDERTYPE.DIRECT_ORDER && order?.orderDetails.orderType === undefined) { // OrderDetails always comes with Direct Order (orderDetails !== undefined)
      createOrderObj = {
        practiceId: order.orderDetails.practiceId,
        ivrId: order.orderDetails.ivrId,
        productId: order.orderDetails.productId,
        practiceProductId: validPP?.practiceProductId,
        orderType: "DIRECT_ORDER",
        patientId: order.orderDetails.patientId,
        requestedDeliveryDate: data.requestedDeliveryDate,
        requestedShippingMethod: data.requestedShippingMethod,
        dateOfService: data.dateOfService,
        poNumber: data.po,
        orderInfo: {
          practice: order?.orderDetails?.practiceName,
          patient: (order.orderDetails.patientFirstName || order.orderDetails.patientMiddleName || order.orderDetails.patientLastName) ? 
          fullName(null, order.orderDetails.patientFirstName, order.orderDetails.patientMiddleName, order.orderDetails.patientLastName) : order.orderDetails.patientName,
          provider: selectedProvider?.label,
          facilityAddress: (data.facilityAddress.toString()),
          providerId: selectedProvider?.value
        },
        orderItems: graftsQtyList,
        orderId: order?.orderDetails?.orderId
      };
    } else if (order?.orderDetails === undefined && order?.orderType !== ORDERTYPE.DIRECT_ORDER && practiceByUserRole && signedInPractice) {
      createOrderObj = {
        practiceId: signedInPractice.practiceId,
        productId: selectedProduct?.value,
        practiceProductId: selectedProduct?.practiceProductId,
        orderType: order?.orderType === ORDERTYPE.CONSIGNMENT_ORDER ?  "CONSIGNMENT_ORDER" : order?.orderType === ORDERTYPE.PAST_ORDER ? "PASS_ORDER" : "DIRECT_ORDER",
        requestedDeliveryDate: data.requestedDeliveryDate,
        requestedShippingMethod: data.requestedShippingMethod,
        dateOfService: data.dateOfService,
        poNumber: data.po,
        orderInfo: {
          practice: signedInPractice.practiceName,
          provider: selectedProvider?.label,
          facilityAddress: (selectedLocation),
          patient: fullName(null, getValues('patientInfo.patientFirstName'), getValues('patientInfo.patientMiddleName'), getValues('patientInfo.patientLastName')),
          providerId: selectedProvider?.value,
          manufacturerEmailEnable : manufacturerEmailNotifications
        },
        orderItems: graftsQtyList,
        orderDate: order?.orderDate,
        patient: {
          firstName: getValues('patientInfo.patientFirstName'),
          middleName: getValues('patientInfo.patientMiddleName'),
          lastName: getValues('patientInfo.patientLastName'),
          birthDate: getValues('patientInfo.birthDate'),
          gender: getValues('patientInfo.gender')
        },
        manufacturerEmailEnable : manufacturerEmailNotifications
      };
    } else if (order?.orderDetails !== undefined && order?.orderDetails.orderType === ORDERTYPE.DIRECT_ORDER && order.orderDetails.ivrId != null) {
      createOrderObj = {
        practiceId: order.orderDetails.practiceId,
        ivrId: order.orderDetails.ivrId,
        productId: order.orderDetails.productId,
        practiceProductId: validPP?.practiceProductId,
        orderType: "DIRECT_ORDER",
        patientId: order.orderDetails.patientId,
        requestedDeliveryDate: data.requestedDeliveryDate,
        requestedShippingMethod: data.requestedShippingMethod,
        dateOfService: data.dateOfService,
        poNumber: data.po,
        orderInfo: {
          practice: order?.orderDetails?.practiceName,
          patient: (order.orderDetails.patientFirstName || order.orderDetails.patientMiddleName || order.orderDetails.patientLastName) ? 
          fullName(null, order.orderDetails.patientFirstName, order.orderDetails.patientMiddleName, order.orderDetails.patientLastName) : order.orderDetails.patientName,
          provider: selectedProvider?.label,
          facilityAddress: (data.facilityAddress.toString()),
          providerId: selectedProvider?.value
        },
        orderItems: graftsQtyList,
        orderId: order?.orderDetails?.orderId
      };
    } else if (order?.orderDetails !== undefined && order.orderDetails.ivrId == null && practiceByUserRole && signedInPractice && (order?.orderDetails.orderType === ORDERTYPE.DIRECT_ORDER || 
      order?.orderDetails.orderType === ORDERTYPE.CONSIGNMENT_ORDER)) {
      createOrderObj = {
        practiceId: order.orderDetails.practiceId,
        ivrId: order.orderDetails.ivrId,
        productId: order.orderDetails.productId,
        practiceProductId: validPP?.practiceProductId,
        orderType: order?.orderDetails.orderType === ORDERTYPE.CONSIGNMENT_ORDER ?  "CONSIGNMENT_ORDER" : "DIRECT_ORDER",
        patientId: order.orderDetails.patientId,
        requestedDeliveryDate: data.requestedDeliveryDate,
        requestedShippingMethod: data.requestedShippingMethod,
        dateOfService: data.dateOfService,
        poNumber: data.po,
        orderInfo: {
          practice: order?.orderDetails?.practiceName,
          patient: (order.orderDetails.patientFirstName || order.orderDetails.patientMiddleName || order.orderDetails.patientLastName) ? 
          fullName(null, order.orderDetails.patientFirstName, order.orderDetails.patientMiddleName, order.orderDetails.patientLastName) : 
          (order.orderDetails.patientName ? order.orderDetails.patientName : order.orderDetails.orderInfo?.patient),
          provider: selectedProvider?.label,
          facilityAddress: (data.facilityAddress.toString()),
          providerId: selectedProvider?.value
        },
        orderItems: graftsQtyList,
        orderId: order?.orderDetails?.orderId
      };
    };
    const response = await dispatch(addOrder(createOrderObj));
    if (response.meta.requestStatus === 'fulfilled') {
        // cs-347 (13/9/2024) - Display Order Number or IVR Number in Success Messages
      const orderNumber = response?.payload?.orderNumber;
      let successObj: SingleAlertInfo = {
        message: "Order " + orderNumber + " created successfully.",
        alertType: "success"
      };

      setShowOptionsPopup({ message: "", show: false });
      //
      dispatch(setSingleAlertObj(successObj));
      if (location && location.state && location.state.fromUI && location.state.fromUI === "IVRList") {
        navigate("/order_list");
      } else {
        // navigate(-1);
        navigate("/order_list");
      }
    }
  };

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const handleNavigation = () => {
    navigate(-1);
  };

  const signedInPractice: PracticeInfo | null = useMemo(() => {
    const selectedPracticeId = getValues("practiceDropdown");
    if (practiceByUserRole && (isPracticeUser)) {
      return practiceByUserRole[0];
    } else if (selectedPracticeId && practiceByUserRole && isRoleAdminUser) {
      const selectedPractice = practiceByUserRole.find(practice => practice.practiceId === selectedPracticeId);
      if (selectedPractice) {
        return selectedPractice;
      }
      return null;
    } else if (order?.orderDetails?.practiceId && practiceByUserRole) {
      const selectedPractice = practiceByUserRole.find(practice => practice.practiceId === order?.orderDetails?.practiceId);
      if (selectedPractice) {
        return selectedPractice;
      }
      return null;
    }
    return null;
  }, [practiceByUserRole, getValues('practiceDropdown'), order?.orderDetails]);

  const providerOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    const today = dayjs();

    if (order?.orderType !== ORDERTYPE.DIRECT_ORDER) {      
      signedInPractice?.practiceProviders?.map(provider => {
        
        if(provider.inactiveDate === null || (dayjs(provider.inactiveDate).isValid() && dayjs(provider.inactiveDate).isAfter(dayjs(today))) ||
        (dayjs(provider.inactiveDate).isValid() && dayjs(provider.inactiveDate).isSame(dayjs(today)))){
          const providerName = fullName(null, provider.firstName, provider.middleName, provider.lastName);
          options.push({
            label: providerName,
            value: provider.providerId
          });
        }
      })
    } else {
      if (order?.orderDetails?.providerName) {
        options.push({
          label: order.orderDetails.providerName,
          value: order.orderDetails.providerId
        });
        setValue("provider", order.orderDetails.providerId);
      } else {
        signedInPractice?.practiceProviders?.map(provider => {

          if (provider.inactiveDate === null || (dayjs(provider.inactiveDate).isValid() && dayjs(provider.inactiveDate).isAfter(dayjs(today))) ||
            (dayjs(provider.inactiveDate).isValid() && dayjs(provider.inactiveDate).isSame(dayjs(today)))) {
            
            const providerName = fullName(null, provider.firstName, provider.middleName, provider.lastName);
            options.push({
              label: providerName,
              value: provider.providerId
            });
          }
        })

        if (order?.orderDetails?.orderInfo?.providerId) {
          setValue("provider", parseInt(order?.orderDetails?.orderInfo?.providerId));
        }
      }
    };
    return options;
  }, [order, practiceByUserRole, signedInPractice]);

  const practiceOptions: Option[] = useMemo(() => {
    if (practiceByUserRole) {
      const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRole, "practiceName");
      return sortedPracticeInfo.map((practice: PracticeInfo) => {
        return {
          value: practice.practiceId,
          label: practice.practiceName
        }
      });
    }
    return [];
  }, [practiceByUserRole])

  const formatLocationName = (practiceLocation: PracticeLocationInfo) => {
    const locationParts = [practiceLocation.streetAddress, practiceLocation.unitNumber, practiceLocation.city, practiceLocation.state, practiceLocation.zipCode];
    const filteredLocationParts = locationParts.filter(part => part !== undefined && part !== null && part.trim() !== "");
    const locationName = filteredLocationParts?.join(", ")
    return locationName;
  }

  const locationOptions: Option[] = useMemo(() => {
    let options: Option[] = [];
    if (order?.orderType !== ORDERTYPE.DIRECT_ORDER) {
      signedInPractice?.practiceLocations?.map(practiceLocation => {
        const locationName = formatLocationName(practiceLocation);
        options.push({
          label: locationName,
          value: practiceLocation.locationId
        });
      })
    } else {
      if (practiceIVR) {
        practiceIVR.practiceLocations?.map(practiceLocation => {
          const locationName = formatLocationName(practiceLocation);
          options.push({
            label: locationName,
            value: practiceLocation.locationId
          });
        });
        if (order?.orderDetails?.locationId) {
          setValue("facilityAddress", order.orderDetails.locationId);
        } else if (order?.orderDetails?.orderInfo?.facilityAddress) {
          setValue("facilityAddress", parseInt(order.orderDetails.orderInfo.facilityAddress));
        }
      }
    };
    return options;
  }, [order, practiceByUserRole, locationDetails, signedInPractice, practiceIVR]);


  const productOptions = useMemo(() => {
    let options: { label: string; value: number; productId: number; macId: number | null; macName: string | null }[] = [];
    practiceProductsInfo?.map((option: any) => {
      const macName = option.macId && option.macName ? " - " + option.macName : '';
      const providerName = option.billingType === "INDIVIDUAL" && option.providerName ? ` (${option.providerName}) ` : ""
      options.push({
        label: `${option.productName}${providerName}${macName}`,
        value: option.practiceProductId,
        productId: option.productId,
        macId: option.macId ? option.macId : null,
        macName: option.macName ? option.macName : null
      });
    });
    return options;
  }, [practiceProductsInfo]);

  const displayPracticeName = () => {
    if (order?.orderType !== ORDERTYPE.DIRECT_ORDER ) {
      return signedInPractice?.practiceName;
    } else {
      return order?.orderDetails?.practiceName;
    };
  };

  const displayPatientName = () => {
    if (order?.orderType === ORDERTYPE.CONSIGNMENT_ORDER) {
      return null;
    } else if (order?.orderType === ORDERTYPE.DIRECT_ORDER) {
      if (order?.orderDetails) {
        if (order?.orderDetails?.patientName) {
          return order.orderDetails.patientName;
        } 
        if (order.orderDetails.orderInfo?.patient) {
          return order.orderDetails.orderInfo?.patient;
        }
        return fullName(null, order.orderDetails.patientFirstName, order.orderDetails.patientMiddleName, order.orderDetails.patientLastName);
      }
    };
  };

  const handleChange = (name: string, value: string) => {
    const selectProduct = practiceProductsInfo?.find(prod => prod.practiceProductId === value);
    if (selectProduct) {
      setSelectedProduct({
        label: selectProduct.productName,
        value: selectProduct.productId,
        practiceProductId: selectProduct.practiceProductId
      });
    }

    if(getValues("provider") == null || getValues("provider") === "") {
      setValue('provider', selectProduct?.practiceProviderId ? selectProduct.practiceProviderId : "");
    }
    //Resting the GraftItem List
    setGraftsQtyList([]);
    setValue("graftQty", []);
  };

  const handleDateChangeService = async (date: Date | null) => {
    setValue("dateOfService", dayjs(date).format("MM/DD/YYYY"));
  };

  const handleDateChangeOrder = (date: Date | null) => {
    setValue("requestedDeliveryDate", dayjs(date).format("MM/DD/YYYY"));
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, values?: any) => {
    if (!event || !event.target) {
      console.error("Event or target is undefined", event);
      return;
    }

    const { name, value } = event.target;
    const idString = name.split('.')[1];
    const id = parseInt(idString, 10);

    if (isNaN(id)) {
      console.error("Invalid productItemId. Cannot parse to number.", idString);
      return;
    }

    setGraftsQtyList((prevValues) => {
      let updatedValues = prevValues ? [...prevValues] : [];
      updatedValues = updatedValues.filter((prev) => prev.productItemId !== id);
      if (value !== '') {
        let graft: GraftQty = {
          productItemId: id,
          quantity: values?.floatValue || value,
        };
        updatedValues.push(graft);
      }
      return updatedValues;
    });
  };

  const handlePracticeChange = async () => {
    const selectedPracticeId = getValues("practiceDropdown");
    if (selectedPracticeId) {
      const response = await dispatch(fetchPracticeProductsData(selectedPracticeId));
      productData();
    }
  };

  const handleProviderChange = async () => {
    const selectedProviderId = getValues("provider");
    const selectedPracticeId = getValues("practiceDropdown");
    if (selectedPracticeId) {
      const response = await dispatch(fetchPracticeProductsDataUsingDateOfServiceAndProvider({
      practiceId: Number(selectedPracticeId),
      providerId: Number(selectedProviderId),
      dateOfService: ""} ));
    }
    
  };

  const handleLocationChange = (name: string, value: string) => {
    const selectedLocation = locationOptions?.find(practiceLocation => practiceLocation.value === value);
    if (selectedLocation) {
      setSelectedLocation(selectedLocation.value.toString());
    }
  }; 

  const productData = () => {
    setIsDisableProducts(false);
    setSelectedProduct(null);
    setValue("product", "");
    setValue("provider", "");
    setValue("facilityAddress", "");
    setListData([]);
  };

  const handlePatientDOB = (date: Date | null) => {
    setValue(`patientInfo.${"birthDate"}`, dayjs(date).format("MM/DD/YYYY"));
  }

  const patientGenderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  
  const emailToManufacturerOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleOrderDate = (date: Date | null) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      orderDate: date ? dayjs(date).format("MM/DD/YYYY") : "", // Convert Date to string format
    }));
  };

  const handleManufacturerEmail= (e: any) =>{
    if (e.target.value === "true") {
      setManufacturerEmailNotifications(true);
    } else {
      setManufacturerEmailNotifications(false);
    }
  }

  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      {showOptionPopup.show &&
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      }
      <Header title="Create Order" subtitle="Order Management" />
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {(order?.orderType === ORDERTYPE.PAST_ORDER) && 
          <Box mt={4} mb={4}>
                <BorderedSection title="Past Order Details">
                  <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                    <CustomDatePicker
                      control={control}
                      label="Order Date"
                      rules={order?.orderType === ORDERTYPE.PAST_ORDER ? { required: "Order Date is Required." } : {}}
                      onChange={handleOrderDate}
                      // @ts-ignore
                      value={dayjs(order.orderDate)}
                    />
                  </Grid>
                    <Grid item md={9} xs={12}></Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography mr={4}>
                          Do you want to send email notifications?
                      </Typography>
                      <CustomRadioButton
                        control={control}
                        options={emailToManufacturerOptions}
                        controllerName="manufactureEmailNotifications"
                        rules={{
                          required:
                            "Email notification option is required.",
                        }}
                        onChange={(e) => {
                          handleManufacturerEmail(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </BorderedSection>
              </Box>
              }
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} style={{ display: `${hideElement(order?.orderType !== ORDERTYPE.DIRECT_ORDER && !isPracticeUser)}` }} >
                <Typography>
                  <b>Practice:</b> <span style={{ paddingLeft: "10px" }}>{displayPracticeName()}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} mb={2}>
              <Grid item xs={12} md={4} style={{ display: `${hideElement(!isRoleAdminUser || order?.orderType === ORDERTYPE.DIRECT_ORDER)}` }} >
                <CustomDropdownField
                  name='practiceDropdown'
                  control={control}
                  onChangeSelect={handlePracticeChange}
                  options={practiceOptions}
                  label="Practice"
                  rules={order?.orderType !== ORDERTYPE.DIRECT_ORDER && isRoleAdminUser ? { required: "Practice is required." } : {}}
                />
              </Grid>            
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="provider"
                  control={control}
                  options={providerOptions}
                  onChangeSelect={handleProviderChange}
                  label="Provider"
                  disabled={order?.orderType !== ORDERTYPE.DIRECT_ORDER && isDisableProducts}
                  rules={order?.orderType !== ORDERTYPE.DIRECT_ORDER ? { required: "Provider is required." } : {}}
                />
              </Grid>
              <Grid item md={4}  xs={12}>
                <CustomDropdownField
                  name="facilityAddress"
                  control={control}
                  onChangeSelect={handleLocationChange}
                  options={locationOptions}
                  label="Facility Address"
                  rules={{ required: "Facility Address is required." }}
                  disabled={order?.orderType !== ORDERTYPE.DIRECT_ORDER && isDisableProducts}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2} style={{ display: `${hideElement(order?.orderType !== ORDERTYPE.DIRECT_ORDER)}` }}>
              <Grid item xs={12}>
                <Typography>
                  <b>Patient:</b> <span style={{ paddingLeft: "10px" }}>{displayPatientName()}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2} style={{ display: `${hideElement(order?.orderType === ORDERTYPE.DIRECT_ORDER)}` }}>
              <Grid item xs={12} md={4}>
                <CustomDropdownField
                  name={`product`}
                  control={control}
                  onChangeSelect={handleChange}
                  options={productOptions}
                  label="Product"
                  rules={(order?.orderType === ORDERTYPE.CONSIGNMENT_ORDER || order?.orderType === ORDERTYPE.DIRECT_ORDER_WITHOUT_IVR || order?.orderType === ORDERTYPE.PAST_ORDER) ? { required: "Product is required." } : {}}
                  disabled={isDisableProducts}
                />
              </Grid>
            </Grid>

            { 
            (order?.orderType === ORDERTYPE.DIRECT_ORDER_WITHOUT_IVR || order?.orderType === ORDERTYPE.PAST_ORDER) && 
              <Box mt={4} mb={4}>
                <BorderedSection title="Patient Information">
                  <Grid container spacing={2}>
                    <Grid item md={6}></Grid>
                    <Grid item md={12} xs={12}>
                      <CustomNametField
                        control={control}
                        name="patientInfo"
                        context="Patient"
                        xs={12}
                        isRequired={(order?.orderType === ORDERTYPE.DIRECT_ORDER_WITHOUT_IVR || order?.orderType === ORDERTYPE.PAST_ORDER)}
                      />
                    </Grid>

                    <Grid item md={4} xs={12}>
                      <CustomDatePicker
                        controllerName={`patientInfo.${"birthDate"}`}
                        control={control}
                        label="Patient's DOB"
                        rules={(order?.orderType === ORDERTYPE.DIRECT_ORDER_WITHOUT_IVR || order?.orderType === ORDERTYPE.PAST_ORDER) ? { required: "Patient's DOB is required." } : {}}
                        onChange={handlePatientDOB}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <CustomRadioButton
                        controllerName={`patientInfo.${"gender"}`}
                        control={control}
                        options={patientGenderOptions}
                        rules={(order?.orderType === ORDERTYPE.DIRECT_ORDER_WITHOUT_IVR || order?.orderType === ORDERTYPE.PAST_ORDER) ? { required: "Gender is required" } : {}}
                      />
                    </Grid>
                    <Grid item md={9} xs={12}></Grid>
                  </Grid>
                </BorderedSection>
              </Box>
            }

            <Grid container spacing={1} mt={1} mb={2}>
              <Grid item xs={12}>
                <Typography>
                  <b>Product:</b> <span style={{ paddingLeft: "10px" }}>
                    {order?.orderType !== ORDERTYPE.DIRECT_ORDER ? selectedProduct?.label : order?.orderDetails?.productName}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={7}>
                <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                  <ListItem>
                    <ListItemButton
                      onClick={() => { }}
                      sx={{ padding: 1, borderRadius: 2, fontSize: 14 }}
                    >
                      <ListItemText sx={{ width: "30%" }}>
                        <b>Size Needed</b>
                      </ListItemText>
                      <Typography sx={{ width: "50%" }}>
                        <b>QTY</b>
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  {listData?.map((row) => (
                    <ListItem key={row.id}>
                      <ListItemButton
                        onClick={() => { }}
                        sx={{ padding: 0, borderRadius: 2 }}
                      >
                        <ListItemText sx={{ width: "30%" }}>
                          {row.graftsNeeded}
                        </ListItemText>
                        <CustomNumberField
                          control={control}
                          controllerName={`graftQty.${row.id}`}
                          onChange={handleQuantityChange}
                          rules={ { min: { value: 1, message: "Minimum value allowed is 1" } } }
                          label="QTY"
                          sx={{ width: "50%" }}
                          allowDecimal={false}
                          allowNegative={false}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <CustomDatePicker
                  control={control}
                  onChange={handleDateChangeOrder}
                  controllerName="requestedDeliveryDate"
                  label="Requested Delivery Date"
                />
              </Grid>
              <Grid xs={12} item md={4}>
              </Grid>
              <Grid item md={4} xs={12}></Grid>
              <Grid xs={12} item md={4}>
                <CustomDropdownField
                  control={control}
                  name="requestedShippingMethod"
                  options={shippingMethodOptions}
                  label="Requested Shipping Method"
                  sortOptions={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomDatePicker
                  control={control}
                  onChange={handleDateChangeService}
                  controllerName="dateOfService"
                  label="Date Of Service"
                  rules={{ required: "Date of service is required." }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={4}>
                <CustomTextField
                  control={control}
                  controllerName="po"
                  label="PO (if applicable)"
                />
              </Grid>
              <Grid item md={8}></Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt="20px">
              <Grid sx={{ mr: 1 }}>
                <Button
                  type="submit"
                  startIcon={<ClassOutlined />}
                >
                  Submit
                </Button>
              </Grid>
              <Grid>
                <Button
                  color="error"
                  startIcon={<CancelOutlined />}
                  onClick={handleNavigation}
                >
                  Cancel
                </Button>
              </Grid>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default Order;
