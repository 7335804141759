
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OrderItemDTO, OrderItemInfo } from "../order/OrderSlice";
import { GeneralV3Response, InterceptorOption, LoadingType } from "../../../types/CommonTypes";
import { addCreditMemoData,  getCreditMemoDetailsByInvId, getInvoiceFromId, getInvoiceList, saveInvoice, updateInvoiceData, uploadInvoice } from "../../../service/InvoiceService";
import { useAppSelector } from "../redux-hooks";

export type SearchInvoiceInfo = {
  fromDate: string | undefined;
  toDate: string | undefined;
  agencyIds: number[] | [];
  ovrAgencyIds: number[] | [];
  v3PayStatus: string[];
  agyPayStatus: string[];
  ovrAgyPayStatus: string[];
  mfgInvoiceNumber?: string | null;
  orderNumber?: string | null;
  mfgIds: number[];
  commPeriod?: string,
  comm?: string,
  practiceIds: number[] | [];
  invoiceStatus: string[];
  currentPage: number;
  itemsPerPage: number;
  checkBox?: { [key: string]: boolean | undefined };
  selectAllCheckBox?: boolean;
  invoiceId?: number;
  isDownload: boolean
};

export type CommissionInfo = {
  commissionAgencyType: "AGENCY" | "OVERRIDE" | "V3",
  commissionAmount: number,
  commissionComments: string,
  commissionDate: string,
  commissionDocumentId: string,
  commissionId: number,
  fileId: number
}

export type AgencyCommission = {
  commissionInfo: CommissionInfo[],
  remainingAmount: number,
  totalAmount: number,
  commissionRate: number
}

export type PaymentInfo = {
  fileId: number,
  paymentAmount: number,
  paymentComments: string,
  paymentDate: string,
  paymentDocumentId: number,
  paymentId: number
}

export type Payment = {
  paymentInfo: PaymentInfo[],
  remainingAmount: number,
  totalAmount: string
}

export type InvoiceDetailDTO = {
  invoiceId: number,
  manufacturerId: number,
  productId: number,
  practiceId: number,
  mfgInvoiceNumber: string,
  mfgName: string,
  productName: string,
  practiceName: string,
  agencyName: string,
  agentNames: string[],
  orderItems: OrderItemDTO[],
  invoiceAmount: number;
  creditAmount: number;
  netCogsAmount: number;
  orderId: number;
  orderNumber: string;
  invoiceStatus: string;
  calculatedAmount: number | string;
  v3ProductCommissionId: number;
  agencyProductCommissionId: number;
  mfgInvoiceDate: string;
  invoiceDueDate: string;
  v3CommissionAmount: number | string;
  agencyCommissionAmount: number | string;
  ovrAgencyProductCommissionId: number;
  ovrAgencyCommissionAmount: number | string;
  invoiceDocumentId: number;
  v3AgencyId: number;
  overrideAgencyId: number;
  manufacturerName: string;
  v3InvoicePaidAmount: number | string;
  v3CommissionPaidAmount: number | string;
  agencyCommissionPaidAmount: number | string;
  ovrAgencyCommissionPaidAmount: number | string;
  v3CommNet: number | string;
  agencyId: number,
  agencyCommissions: AgencyCommission,
  v3Commissions: AgencyCommission,
  ovrAgencyCommissions: AgencyCommission,
  payment: Payment
  overrideAgencyName: string,
};

export type AgyPayout = {
  invoices: { invoiceId: number, agencyId: number }[],
  commissionAgencyType: 'AGENCY' | 'OVERRIDE',
  payment: number,
  payoutDate: string,
  payoutComment: string
};

export type RecordPayoutOverrideInfo = {
  payment: string;
  payoutDate: string;
  payoutComment: string;
};

export type invoiceInfo = {
  invoiceId: number;
  manufacturerId: number;
  mfgInvoiceNumber: string;
  invoiceStatus: string;
  invoiceAmount: number | string;
  creditAmount: number | string;
  calculatedAmount: number | string;
  v3ProductCommissionId: number;
  agencyProductCommissionId: number;
  mfgInvoiceDate: string;
  invoiceDueDate: string;
  v3CommissionAmount: number | string;
  agencyCommissionAmount: number | string;
  ovrAgencyProductCommissionId: number;
  ovrAgencyCommissionAmount: number | string;
  invoiceDocumentId: number;
  productId: number;
  practiceId: number;
  productName: string;
  v3AgencyId: number;
  overrideAgencyId: number;
  practiceName: string;
  manufacturerName: string;
  v3InvoicePaidAmount: number | string;
  v3CommissionPaidAmount: number | string;
  agencyCommissionPaidAmount: number | string;
  ovrAgencyCommissionPaidAmount: number | string;
  agencyCommissionRate: number | string;
  ovrAgencyCommissionRate: number | string;
  v3CommNet: number | string;
  orderItems: OrderItemDTO[];
  agencyId: number;
  invoiceLateDays?: any;
  orderNumber: string | any;
  overrideAgencyName: string;
  agencyName?: string;
  v3CommissionAmounts:number | string;
  agencyCommissionAmounts: number | string;
  ovrAgencyCommissionAmounts:number | string;
}
export type Attachment = {
  content: string,
  documentName: string,
  fileType: string,
  type: string
}

export type uploadInvoiceRequest = {
  content: string;
  documentName: string;
  fileType: string;
  type: string;
};

export interface SaveInvoiceInfo {
  orderId: number;
  manufacturerId: number;
  mfgInvoiceNumber: string;
  mfgInvoiceDate?: string;
  invoiceDueDate?: string;
  calculatedAmount: number;
  invoiceAmount: number;
  orderItems: { orderItemId: number }[];
  attachInvoice?: Attachment[];
  orderInvoiceStatus: "INVOICED" | "PARTIAL";
}

// task cs-647 start
export interface UpdateInvoice {
  invoiceId:number;
  mfgInvoiceNumber: number;
  mfgInvoiceDate: string;
  invoiceDueDate:string;
  invoiceAmount: number ;
  v3CommissionAmounts:number;
  v3CommissionAmount: number ;
  agencyCommissionAmounts:number;
  agencyCommissionAmount: number ;
  ovrAgencyCommissionAmounts:number;
  ovrAgencyCommissionAmount: number;
  agencyId:number;
  ovrAgencyId: number;
  invoiceStatus:string;
  productName:string;
}
// task cs-647 end

export interface PaginationInvoiceList {
  content: invoiceInfo[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export interface creditMemoInvoiceData {
  dateOfCredit: string;
  invoiceId: number;
  remainingAmount: number;
  mfgInvoiceNumber: string;
  orderNumber: string;
  productReturnDTOList: any;
  creditMemoDTOList: any;
}
type InvoiceState = {
  invoice?: UpdateInvoice | null; //add this task cs-647
  invoiceList: PaginationInvoiceList | null;
  invoiceListFetchStatus?: LoadingType;
  invoiceSaveStatus?: LoadingType;
  updateinvoicestatus?:LoadingType;
  getInvoiceByIdStatus?: LoadingType;
  uploadInvoiceRequest: PaymentRequest | null;
  invoiceUploadStatus?: LoadingType;
  recordCreditMemo?:creditMemoInvoiceData|null;
}

const initialState: InvoiceState = {

  invoice: {        //add this task cs-647
    invoiceId:0,
    mfgInvoiceNumber: 0,
    mfgInvoiceDate: "", 
    invoiceDueDate:"",     
    invoiceAmount: 0,
    v3CommissionAmounts:0,
    v3CommissionAmount: 0,
    agencyCommissionAmounts:0,
    agencyCommissionAmount: 0,
    ovrAgencyCommissionAmounts:0,
    ovrAgencyCommissionAmount: 0,
    invoiceStatus:"",
    agencyId:0,
    ovrAgencyId:0,
    productName:"",
  },
  invoiceList: null,
  uploadInvoiceRequest: null,
  invoiceListFetchStatus: 'idle',
  invoiceSaveStatus: 'idle',
  getInvoiceByIdStatus: 'idle',
  invoiceUploadStatus: "idle"
}

export const fetchInvoiceList = createAsyncThunk("fetchInvoiceList", async (data: SearchInvoiceInfo) => {
  
  const response = await getInvoiceList(data);
  const v3Response = response.data;
  return v3Response;
});

export const getInvoiceById = createAsyncThunk("getInvoiceById", async (invoiceId: number) => {
  const response = await getInvoiceFromId(invoiceId);
  const v3Response = response.data;
  return v3Response;
});

//task cs-647 start
export const updateInvoice = createAsyncThunk<any, { data: UpdateInvoice, interceptorOption: InterceptorOption }>(
  "updateInvoice",
  async (data, { rejectWithValue }) => {
    const { data: invoiceData, interceptorOption } = data;
    try {
      const response = await updateInvoiceData(invoiceData, interceptorOption);
      return response.data;
    } catch (error) {
      //@ts-ignore
      return rejectWithValue(error?.data);
    }
  }
);

//task cs-647 end



export const addInvoice = createAsyncThunk<any, { invoiceData: SaveInvoiceInfo, interceptorOption: InterceptorOption }>("addInvoice", async (data, { rejectWithValue }) => {
  const { invoiceData, interceptorOption } = data
  try {
    const response = await saveInvoice(invoiceData, interceptorOption);
    const v3Response = response.data;
    return v3Response;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const uploadInvoices = createAsyncThunk<any, { invoiceData: any, interceptorOption?: InterceptorOption }>(
  "uploadInvoice",
  async (data, { rejectWithValue }) => {
    try {
      const { invoiceData, interceptorOption } = data
      const response = await uploadInvoice(invoiceData, interceptorOption);
      return response;
    } catch (error) {
      //@ts-ignore
      return rejectWithValue(error?.data)
    }
  }
);
export const getCreditMemoDetailsById = createAsyncThunk("getCreditMemoDetailsById", async (id: number) => {
  const response = await getCreditMemoDetailsByInvId(id);
  const v3Response = response.data;
  return v3Response;
});

export const addCreditMemo = createAsyncThunk("addCreditMemo",async(data:any)=>{
  const response = await addCreditMemoData(data);
  const v3Response = response.data;
  return v3Response;

})


const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {

          updateInvoiceInfoObject: (state: InvoiceState, action) => {
               state.invoice = action.payload;
             },
             clearInvoiceObject: (state: InvoiceState) => {
               state.invoice = null;
             },

          
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoiceList.pending, (state) => {
        state.invoiceListFetchStatus = "loading";
      })
      .addCase(fetchInvoiceList.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.invoiceListFetchStatus = "success";
        state.invoiceList = action.payload.data;
      })
      .addCase(fetchInvoiceList.rejected, (state, action) => {
        state.invoiceListFetchStatus = 'error';
      })

    
     
      .addCase(addInvoice.pending, (state) => {
        state.invoiceSaveStatus = "loading";
      })
      .addCase(addInvoice.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.invoiceSaveStatus = "success";
      })
      .addCase(addInvoice.rejected, (state, action) => {
        state.invoiceSaveStatus = 'error';
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
          state.updateinvoicestatus = "success"     //add task for cs-647 
        state.invoice = action.payload.data
      })
      .addCase(getInvoiceById.pending, (state) => {
        state.getInvoiceByIdStatus = "loading";
      })
      .addCase(getInvoiceById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.getInvoiceByIdStatus = "success";
      })
      .addCase(getInvoiceById.rejected, (state, action) => {
        state.getInvoiceByIdStatus = 'error';
      })
      .addCase(uploadInvoices.pending, (state) => {
        state.invoiceUploadStatus = "loading";
      })
      .addCase(uploadInvoices.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.invoiceUploadStatus = "success";
      })
      .addCase(uploadInvoices.rejected, (state, action) => {
        state.invoiceUploadStatus = 'error';
      })
      .addCase(getCreditMemoDetailsById.pending, (state) => {
        state.invoiceUploadStatus = "loading";
      })
      .addCase(getCreditMemoDetailsById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.invoiceUploadStatus = "success";
        state.recordCreditMemo = action.payload.data;
      })
      .addCase(getCreditMemoDetailsById.rejected, (state, action) => {
        state.invoiceUploadStatus = 'error';
      })
  }
});
export const { updateInvoiceInfoObject, clearInvoiceObject } = invoiceSlice.actions;
export const useInvoiceSlice = () => useAppSelector((state) => state.invoiceSlice)

export default invoiceSlice.reducer; 