import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FETCH_PRODUCT_WITH_PRICE_URL } from "../../../service/URL";
import { http } from "../../../service/http";
import { useAppSelector } from "../redux-hooks";
import {
    agreementCountForExpiredProductPrice, deleteProductAgreement, fetchProductsByUserRole, fetchProductsIncludingInactiveByUserRole, generateAgreementsForExpiredProductPrice,
    getProductAgreementList, getProductPricingByMAC, inactivateProductAgreement, initiateAgreements, previewOnboardAgreement,
    saveProductAgreement, signManufactureProductAgreement, signPracticeProductAgreement,
    uploadSignedAgreement
} from "../../../service/ProductService";
import { DocumentInfo, PracticeInfo } from "../practiceSlice";
import { InterceptorOption, LoadingType } from "../../../types/CommonTypes";
import { ProductInfo } from "../product/productListSlice";
import { fetchPracticesByUserRole } from "../../../service/PracticeService";


export type MacInfo = {
    macId: number,
    macName: string
}

export type practiceMac = {
    macId: number,
    description: string
}


type CreateAgreement = {
    practiceId: string | null;
    effectiveDate: string;
    MAC: {};

};

export type PracticeProductAgreement = {
    practiceProductId: number,
    productId: number,
    productName: string;
    effectiveDate: string;
    inactiveDate: string;
    agreementStatus: string;
    discountType: string;
    extendedPrice: string;
    practiceName: string;
    providerName: string;
    auditTimestamp: string;
    fileType: string;
}


export type PracticeLocationInfo = {
    locationId: number;
    locationType: string;
    streetAddress: string;
    unitNumber: string;
    city: string;
    state: string;
    zipCode: string;
  }
  
  export type PracticeProviderInfo = {
    providerId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    npi: string;
    ptan: string;
    createdDate: Date;
    inactiveDate: Date;
    title: string;
  }

  export type SearchPracticeProductAgreementRequest = {
    productIds: number[] | null ;
    practiceIds: number[] | null;
    agreementStatus: string[];
    currentPage: number;
    itemsPerPage: number;
  }

  export type PracticeProductInfo = {
    productId: string;
    practiceProviderId: number | null;
    discountType?: string | number;
    extendedPrice: DoubleRange | number | null;
    productPriceId : string | null;
    macId?: string | number | null;
    productItemId?: number
   };

   export type inactivateInfo = {
    id: number;
    inactiveDate: string;
   };

    export interface Attachment {
        content: string;
        documentName: string;
        fileType: string;
        type: string
    }

    export type UploadSignedAgreementInfo = {
        practiceProductId: number
        documentName: string,
        content: string,
        type: string,
        fileType: string
    }
    
   interface PaginationAgreementList {
    content: PracticeProductAgreement[];
    totalElements: number;
    totalPages: number;
    size: number;
  }
  

type CreateAgreementState = {
    createAgreement: CreateAgreement;
    macInfo?: MacInfo[] | null;
    productAgreementsList?: PaginationAgreementList | null;
    document?: DocumentInfo | null;
    status: string;
    error: string | null;
    isValid: boolean;
    fetchProductsByRoleStatusAgreementSlice: LoadingType,
    productsByUserRoleAgreementSlice: ProductInfo[],
    getPracticesByRoleStatusAgreementSlice: LoadingType;
    practiceByUserRoleAgreementSlice?: PracticeInfo[] | null;
}

let initialState: CreateAgreementState = {
    createAgreement: {
        practiceId: '',
        effectiveDate: '',
        MAC: {}
    },
    productAgreementsList: undefined,
    status: "idle",
    error: null,
    isValid: false,
    fetchProductsByRoleStatusAgreementSlice: "idle",
    productsByUserRoleAgreementSlice: [],
    getPracticesByRoleStatusAgreementSlice: "idle",
    practiceByUserRoleAgreementSlice: [],
};

export const fetchProducts = createAsyncThunk("productList", async () => {
    const response = await http.get(FETCH_PRODUCT_WITH_PRICE_URL);
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchProductPricingByMAC = createAsyncThunk("productPricingByMAC", async (macId: number) => {
    const response = await getProductPricingByMAC(macId);
    const v3Response = response.data;
    return v3Response.data;
});

export const fetchProductAgreementList = createAsyncThunk("ProductAgreementList", async (data: SearchPracticeProductAgreementRequest) => {
    const response = await getProductAgreementList(data);
    const v3Response = response.data;
    return v3Response.data;
});

export const createProductAgreement = createAsyncThunk<
any, {agreementData: any, interceptorOption?: InterceptorOption}
>("createProductAgreement", async (data, { rejectWithValue }) => {
    try{
        const { agreementData, interceptorOption } = data;
        const response = await saveProductAgreement(agreementData, interceptorOption);
        const v3Response = response.data;
        return v3Response;
    } catch (error) {
        //@ts-ignore
        return rejectWithValue(error?.data);

    }
});

export const practiceSignProductAgreement = createAsyncThunk("practiceSignProductAgreement", async(data: any) => {
    const response = await signPracticeProductAgreement(data);
    const v3Response = response.data;
    return v3Response; 
});

export const manufactureSignProductAgreement = createAsyncThunk("manufactureSignProductAgreement", async(data: any) => {
    const response = await signManufactureProductAgreement(data);
    const v3Response = response.data;
    return v3Response; 
});

export const deleteProductAgreementById = createAsyncThunk("deleteProductAgreementById", async (practiceProductId: number) => {
    const response = await deleteProductAgreement(practiceProductId);
    const v3Response = response.data;
    return v3Response;  
});

export const inactivateProductAgreementsById = createAsyncThunk<
any, {inactiveData: inactivateInfo, interceptorOption: InterceptorOption}
> 
("inactivateProductAgreementsById", async ( data, { rejectWithValue }) => {
    const { inactiveData, interceptorOption } = data;
    try {
        const response = await inactivateProductAgreement(inactiveData, interceptorOption);
        const v3Response = response.data;
        return v3Response;  
    } catch(error){
        //@ts-ignore
        return rejectWithValue(error?.data)
    }
    
});

export const previewAgreement = createAsyncThunk('previewAgreement',async( practiceProductId: string | number ) => {
    const response = await previewOnboardAgreement(practiceProductId);
    const v3Response = response.data;
    return v3Response.data;
  });

export const initiateProductAgreements = createAsyncThunk('initiateProductAgreements', async(data: any[]) => {
    const response = await initiateAgreements(data);
    const v3Response = response.data;
    return v3Response.data;
 });

export const getAgreementCountForExpiredProductPrice = createAsyncThunk('getAgreementCountForExpiredProductPrice', async() => {
    const response = await agreementCountForExpiredProductPrice();
    const v3Response = response.data;
    return v3Response.data;
 });

 export const generateNewAgreementsForExpiredProductPrice = createAsyncThunk('generateNewAgreementsForExpiredProductPrice', async() => {
    const response = await generateAgreementsForExpiredProductPrice();
    const v3Response = response.data;
    return v3Response.data;
 });

 export const fetchProductListByUserRoleAgreementSlice = createAsyncThunk("fetchProductListByUserRoleAgreementSlice", async (includeProductPrice: boolean) => {
    const response = await fetchProductsByUserRole(includeProductPrice);
    const v3Response = response.data
    return v3Response.data;
  });

  export const fetchProductListIncludingInactiveByUserRoleAgreementSlice = createAsyncThunk("fetchProductListIncludingInactiveByUserRoleAgreementSlice", async (includeProductPrice: boolean) => {
    const response = await fetchProductsIncludingInactiveByUserRole(includeProductPrice);
    const v3Response = response.data
    return v3Response.data;
  });
  
  export const getPracticeListByUserRoleAgreementSlice = createAsyncThunk('getPracticeListByUserRoleAgreementSlice', async ( isActivePracticeOnly?: boolean ) => {
    const response = await fetchPracticesByUserRole(isActivePracticeOnly);
    const v3Response = response.data;
    return v3Response.data;
  });

  export const uploadSignedAgreementById = createAsyncThunk("uploadSignedAgreementById", async (data: UploadSignedAgreementInfo) => {
    const response = await uploadSignedAgreement(data);
    const v3Response = response.data;
    return v3Response;  
});

const createAgreementSlice = createSlice({
    name: "createAgreementSlice",
    initialState,
    reducers: {
        updateCreateAgreementObject : (state: CreateAgreementState, action) => {
            state.createAgreement = action.payload;
        },
        resetProductAgreementsList : (state) =>{
            state.productAgreementsList = null;
            state.fetchProductsByRoleStatusAgreementSlice = "idle";
            state.productsByUserRoleAgreementSlice = [];
            state.getPracticesByRoleStatusAgreementSlice = "idle";
            state.practiceByUserRoleAgreementSlice = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.status = "success";
                state.isValid = true;
            })
            .addCase(fetchProducts.rejected, (state, action) => {
                state.status = "failed";
                state.isValid = false;
                state.error = action.error.message || "Failed to fetch Products";
            })
            .addCase(fetchProductPricingByMAC.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchProductPricingByMAC.fulfilled, (state, action) => {
                state.status = "success";
                state.isValid = true;
            })
            .addCase(fetchProductPricingByMAC.rejected, (state, action) => {
                state.status = "failed";
                state.isValid = false;
                state.error = action.error.message || " Failed to fetch Products by MAC";
            })
            .addCase(fetchProductAgreementList.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(fetchProductAgreementList.fulfilled, (state, action) => {
                state.status = "success";
                state.isValid = true;
                state.productAgreementsList = action.payload;
            })
            .addCase(fetchProductAgreementList.rejected, (state, action) => {
                state.status = "failed";
                state.isValid = false;
                state.error = action.error.message || " Failed to fetch Products by MAC";
            })
            .addCase(createProductAgreement.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(createProductAgreement.fulfilled, (state, action) => {
                state.status = "success";
                state.isValid = true;
            })
            .addCase(createProductAgreement.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message || "Failed to create Product Agreement"
            })
            .addCase(practiceSignProductAgreement.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(practiceSignProductAgreement.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(practiceSignProductAgreement.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(manufactureSignProductAgreement.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(manufactureSignProductAgreement.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(manufactureSignProductAgreement.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(deleteProductAgreementById.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(deleteProductAgreementById.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(deleteProductAgreementById.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(inactivateProductAgreementsById.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(inactivateProductAgreementsById.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(inactivateProductAgreementsById.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(previewAgreement.pending, (state) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(previewAgreement.fulfilled, (state, action) => {
                state.status = "success";
                state.document = action.payload;
                state.error = null;
            })
            .addCase(previewAgreement.rejected, (state, action) => {
                state.status = "rejected"
            })
            .addCase(initiateProductAgreements.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(initiateProductAgreements.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(initiateProductAgreements.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.error.message || "Failed to initiate agreement";
            })
            .addCase(getAgreementCountForExpiredProductPrice.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(getAgreementCountForExpiredProductPrice.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(getAgreementCountForExpiredProductPrice.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.error.message || "Failed to get count of expired product price agreements";
            })
            .addCase(generateNewAgreementsForExpiredProductPrice.pending, (state, action) => {
                state.status = "loading";
                state.error = null;
            })
            .addCase(generateNewAgreementsForExpiredProductPrice.fulfilled, (state, action) => {
                state.status = "success";
                state.error = null;
            })
            .addCase(generateNewAgreementsForExpiredProductPrice.rejected, (state, action) => {
                state.status = "rejected";
                state.error = action.error.message || "";
            })
            .addCase(fetchProductListByUserRoleAgreementSlice.pending, (state) => {
                state.fetchProductsByRoleStatusAgreementSlice = "loading";
            })
            .addCase(fetchProductListByUserRoleAgreementSlice.fulfilled, (state, action) => {
                state.fetchProductsByRoleStatusAgreementSlice = "success";
                state.productsByUserRoleAgreementSlice = action.payload;
            })
            .addCase(fetchProductListByUserRoleAgreementSlice.rejected, (state, action) => {
                state.fetchProductsByRoleStatusAgreementSlice = 'error';
            })
            .addCase(fetchProductListIncludingInactiveByUserRoleAgreementSlice.pending, (state) => {
                state.fetchProductsByRoleStatusAgreementSlice = "loading";
            })
            .addCase(fetchProductListIncludingInactiveByUserRoleAgreementSlice.fulfilled, (state, action) => {
                state.fetchProductsByRoleStatusAgreementSlice = "success";
                state.productsByUserRoleAgreementSlice = action.payload;
            })
            .addCase(fetchProductListIncludingInactiveByUserRoleAgreementSlice.rejected, (state, action) => {
                state.fetchProductsByRoleStatusAgreementSlice = 'error';
            })
            .addCase(getPracticeListByUserRoleAgreementSlice.pending, (state, action) => {
                state.getPracticesByRoleStatusAgreementSlice = 'loading';
            })
            .addCase(getPracticeListByUserRoleAgreementSlice.fulfilled, (state, action) => {
                state.getPracticesByRoleStatusAgreementSlice = 'success';
                state.practiceByUserRoleAgreementSlice = action.payload;
            })
            .addCase(getPracticeListByUserRoleAgreementSlice.rejected, (state, action) => {
                state.getPracticesByRoleStatusAgreementSlice = 'error';
            })
            .addCase(uploadSignedAgreementById.pending, (state, action) => {
                state.getPracticesByRoleStatusAgreementSlice = 'loading';
            })
            .addCase(uploadSignedAgreementById.fulfilled, (state, action) => {
                state.getPracticesByRoleStatusAgreementSlice = 'success';
            })
            .addCase(uploadSignedAgreementById.rejected, (state, action) => {
                state.getPracticesByRoleStatusAgreementSlice = 'error';
            });
    }
});

export const useCreateAgreementSlice = () => useAppSelector((state) => state.createAgreementSlice);
export const { updateCreateAgreementObject, resetProductAgreementsList } = createAgreementSlice.actions;
export default createAgreementSlice.reducer;

