import {
  Box,
  Collapse,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import {
  AddCircleOutlineOutlined,
  Description,
  ListAltOutlined,
  SearchOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import Card from "../../common/Card";
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { useMemo, useState } from "react";
import { SearchDocument } from "../../../redux/slice/document/DocumentSlice";
import { useForm } from "react-hook-form";
import CustomIconButton from "../../common/CustomIconButton";
import colors from "react-multi-date-picker/plugins/colors";
import { tokens } from "../../../theme";
import DocumentUploadPopup from "./DocumentUploadPopup";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}

interface DocumentRow {
  id: number | undefined;
  documentName: string | undefined;
  dateUploaded: string | undefined;
  lastUploaded: string | undefined;
  uploadBy: string | undefined;
  action?: JSX.Element;
}

const columns: Column[] = [
  { id: "documentName", label: "Document Name", minWidth: 200 },
  { id: "dateUploaded", label: "Date Uploaded", minWidth: 100 },
  { id: "lastUploaded", label: "Last Uploaded", minWidth: 250 },
  { id: "uploadBy", label: "Upload By", minWidth: 40 },
  { id: "action", label: "Action", minWidth: 50 },
];

const DocumentList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showCard, setShowCard] = useState(false);
  const [showDocumentPopup, setShowDocumentPopup] = useState(false);
  const { control, handleSubmit, reset, setValue } = useForm<SearchDocument>();

  type Option = {
    value: string;
    label: string;
  };

  const productOptions: Option[] = [
    { label: "Product 1", value: "Product 1" },
    { label: "Product 2", value: "Product 2" },
  ];

  const manufacturerOptions: Option[] = [
    { label: "Manufacturer 1", value: "Manufacturer 1" },
    { label: "Manufacturer 2", value: "Manufacturer 2" },
  ];

  const categoryOptions: Option[] = [
    { label: "Category 1", value: "Category 1" },
    { label: "Category 2", value: "Category 2" },
  ];

  const subCategoryOptions: Option[] = [
    { label: "Sub Category 1", value: "Sub Category 1" },
    { label: "Sub Category 2", value: "Sub Category 2" },
  ];

  const documentList: DocumentRow[] = [
    {
      id: 1,
      documentName: "V1Sample Document.pdf",
      dateUploaded: "11/11/2024",
      lastUploaded: "21/11/2024",
      uploadBy: "harsha@gmail.com",
    },
    {
      id: 2,
      documentName: "V2Sample Document.pdf",
      dateUploaded: "12/12/2024",
      lastUploaded: "22/12/2024",
      uploadBy: "madushanka@gmail.com",
    },
    {
      id: 3,
      documentName: "V3Sample Document.pdf",
      dateUploaded: "13/13/2024",
      lastUploaded: "23/13/2024",
      uploadBy: "hm@gmail.com",
    },
  ];

  const  handleShowDocumentPopup = () =>{
    setShowDocumentPopup(!showDocumentPopup);
  }

  const filterDocumentList = documentList?.map((row: any) => ({
    ...row,
    documentName: (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: "400px",
        }}
      >
        <Box
          sx={{
            width: "48px",
            height: "48px",
            backgroundColor : colors.info[400],
            borderRadius : 2,
            display: {
              xs: "none",
              sm: "flex",
            },
            alignItems: "center",
            justifyContent: "center",
            marginRight: "16px",
          }}
        >
          <Description sx={{ fontSize: "32px" }} />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: colors.grey[500],
                display: {
                  xs: "none",
                  sm: "flex",
                },
              }}
            >
              Document Name
            </Typography>
            <Typography variant="body2">{row.documentName}</Typography>
          </Grid>
        </Grid>
      </Box>
    ),
    dateUploaded: (
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            color: colors.grey[500],
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          Date Uploaded
        </Typography>
        <Typography>{row.dateUploaded}</Typography>
      </Box>
    ),
    lastUploaded: (
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            color: colors.grey[500],
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          Date Uploaded
        </Typography>
        <Typography>{row.lastUploaded}</Typography>
      </Box>
    ),

    uploadBy: (
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            color: colors.grey[500],
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          Upload By
        </Typography>
        <Typography>{row.uploadBy}</Typography>
      </Box>
    ),
  }));

  const actionComponent = (row: any): JSX.Element => {
    return (
      <>
        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Sample"
          color="info"
          size="small"
          variant="outlined"
        >
          <ListAltOutlined fontSize="small" />
        </CustomIconButton>
      </>
    );
  };

  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Document List" subtitle="Document Management" />
        <Box>
          <Collapse in={showCard}>
            <Card>
              <form>
                <Grid container spacing={1.5} mt={-3} mb={-1}>
                  <Grid item md={2.5} xs={12}>
                    <CustomDropdownField
                      control={control}
                      name="product"
                      label="Product"
                      options={productOptions}
                    />
                  </Grid>
                  <Grid item md={2.5} xs={12}>
                    <CustomDropdownField
                      control={control}
                      name="manufacturer"
                      label="Manufacturer"
                      options={manufacturerOptions}
                    />
                  </Grid>
                  <Grid item md={2.5} xs={12}>
                    <CustomDropdownField
                      control={control}
                      name="category"
                      label="Category"
                      options={categoryOptions}
                    />
                  </Grid>
                  <Grid item md={2.5} xs={12}>
                    <CustomDropdownField
                      control={control}
                      name="subCategory"
                      label="Sub Category"
                      options={subCategoryOptions}
                    />
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Button
                      fullWidth
                      size="large"
                      color="success"
                      startIcon={<SearchOutlined />}
                      style={{ padding: "15px" }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>

        <Box>
          <Card sx={{ mt: 2 }}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item xs={12} md="auto">
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => setShowCard(!showCard)}
                  startIcon={<SearchOutlined />}
                  fullWidth
                >
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  fullWidth
                  color="info"
                  startIcon={<UploadFileOutlined />}
                  onClick={handleShowDocumentPopup}
                >
                  UPLOAD DOCUMENT
                </Button>
              </Grid>
            </Grid>
            <Table
              columns={columns}
              rows={filterDocumentList}
              actionComponent={actionComponent}
              showMenu={true}
              visibleHeader = {false}
            />
          </Card>
        </Box>
      </Box>

      <DocumentUploadPopup
        onClose={handleShowDocumentPopup}
        open = {showDocumentPopup}
      />
    </>
  );
};

export default DocumentList;
