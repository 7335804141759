import React, { useCallback, useEffect, useMemo } from "react";
import Header from "../../common/Header";
import { Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  fetchActiveAgencyExecutives,
  usePracticeApproveSlice,
} from "../../../redux/slice/practice/practiceApproveSlice";
import { fullName } from "../../../utils/Utility";
import { fetchPracticeByIdData, usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import {
  PracticeExecutiveInfo,
  UpdatePracticeExInfo,
  fetchPracticeExecutiveData,
  updatePracticeExecutiveById,
  usePracticeExecutiveSlice,
} from "../../../redux/slice/practice/practiceExecutiveSlice";
import Button from "../../common/Button";
import { Save } from "@mui/icons-material";
import {
  MainErrorInfo,
  SingleAlertInfo,
  fetchAgenciesList,
  setMainErrorObj,
  setSingleAlertObj,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import OptionsPopup from "../../common/OptionsPopup";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { MSG_NO_AGENCY_FOUND, MSG_NO_USER_FOUND, MSG_NOT_AN_ACTIVE_EXECUTIVE, MSG_ORDER_NOT_COMPLETED, MSG_PRACTICE_EXECUTIVE_NOT_FOUND, USER_ROLES } from "../../../constants/applicationConstants";
import { useAuthSlice } from "../../../redux/slice/authSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { clearAgencyObject, fetchAgencyById, useAgencyReducer } from "../../../redux/slice/agency/AgencySlice";
import dayjs from "dayjs";

interface PracticeExecutiveInfoProps {
  contentChangedStatus: () => void;
}

type Option = {
  value: string | number;
  label: string;
};

export type PracticeEx = {
  agencyId: number | undefined;
  practiceExecutiveId: number | undefined;
  userId: number | undefined;
} 

const PracticeExecutiveInfoViewForm: React.FC<PracticeExecutiveInfoProps> = ({contentChangedStatus}) => {
  const { control, handleSubmit, reset, setValue, getValues, watch } = useForm<{accountReps: any[], agencyId: number}>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isPracticeExInfoVisible, setIsPracticeExInfoVisible] =
    React.useState(false);
  const { agencyExecutives } = usePracticeApproveSlice();
  const { practiceEditInfo } = usePracticeEditSlice();
  const { practiceExecutiveInfo } = usePracticeExecutiveSlice();
  const { agencyInfoList } = useCommonReducer();
  const { agency } = useAgencyReducer();
  const [optionPopupShow, setOptionPopupShow] = React.useState<{
    message: string;
    show: boolean;
  }>({ message: "", show: false });

  const defaultValues = useCallback(() => {
    if (practiceExecutiveInfo && practiceExecutiveInfo.length > 0) {
      const practiceExsIds = practiceExecutiveInfo
      .map(ex => { if(ex.user.userId) return ex.user.userId })
      .filter(id => id !== undefined)
      setValue('accountReps', practiceExsIds);
    }
    if(practiceEditInfo?.agencyId) {
      dispatch(fetchAgencyById(practiceEditInfo.agencyId));
      setValue('agencyId', practiceEditInfo.agencyId);
    }
  }, [practiceExecutiveInfo, reset, practiceEditInfo]);

  const location = useLocation();
  const practiceApprovalStatus =
    location.state?.practiceEditInfo?.approvalStatus;

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    dispatch(fetchAgenciesList());
    dispatch(fetchActiveAgencyExecutives({ getAgencyAdmins: true, isInactive: true }));
    if (practiceEditInfo && practiceApprovalStatus !== "REJECTED") { 
      dispatch(fetchPracticeExecutiveData({
        practiceId : parseInt(practiceEditInfo.practiceId),
        isInactive : true
      }));
    }
  }, [dispatch, practiceEditInfo, practiceApprovalStatus]);

  const confirmOptionPopup = () => {
    handleSubmit(handleUpdateExecutive)();
    setOptionPopupShow({ message: "", show: false });
  };

  const cancelOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
  };

  const setPracticeExecutiveData = () => {
    const practiceExIds = getValues('accountReps');
    return practiceExIds?.map(ex => {
      const practiceEx = practiceExecutiveInfo?.find(practiceEx => practiceEx.user.userId === ex);
      const agencyEx = agencyExecutives?.find(agency => agency.userId === ex);
        return {
          agencyId: agencyEx?.agencyId,
          practiceExecutiveId : practiceEx?.practiceExecutiveId,
          userId: agencyEx?.userId
      }
    })
  }

  const checkIsAccountExValid = () => {
    const selectedAccountRepsIds = getValues('accountReps');
    const selectedAccountReps = selectedAccountRepsIds?.map(id => {
      const rep = agencyExecutives?.find(ex => ex.userId === id)
      return rep;
    })
    const today = dayjs();
    const isAccountExInvalid = selectedAccountReps?.some(rep => {
      if(rep?.inactiveDate) {
        const inactiveDate = dayjs(rep?.inactiveDate?.split(" ")[0]);
        return inactiveDate.isBefore(today)
      }
      return false
      
    })
    return isAccountExInvalid;
  }

  const handleUpdateExecutive = async (updateExecutiveData: any) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };

    const isAccountExInvalid = checkIsAccountExValid()
    if(!isAccountExInvalid) {
      const practiceExs = setPracticeExecutiveData();
      if(practiceEditInfo) {
        const data: UpdatePracticeExInfo = {
          practiceExecutive : practiceExs,
          agencyId: getValues('agencyId'),
          practiceId: Number(practiceEditInfo.practiceId)
    
        };
        try {
          const response = await dispatch(updatePracticeExecutiveById(data));
          if (response.payload && response.payload.message === "SUCCESS") {
            setIsPracticeExInfoVisible(false);
            let successObj: SingleAlertInfo = {
              message: "Account Representative Updated Successfully.",
              alertType: "success",
            };
  
            dispatch(fetchPracticeByIdData(practiceEditInfo?.practiceId))
            dispatch(setSingleAlertObj(successObj));
            contentChangedStatus();
          } else {
            let errorSentence = "";
            if (response.payload && response.payload.errors.length > 0) {
              response.payload.errors.forEach((error: any, index: any) => {
                if (error.field) {
                  errorSentence += `${error.field}: ${error.errorMessage}\n`;
                } else {
                  errorSentence += `${error.reason}\n`;
                }
              });
            } else if (response.payload && response.payload.message) {
              switch (response.payload.message) {
                case MSG_NOT_AN_ACTIVE_EXECUTIVE:
                  errorSentence = "This Practice Executive is not active. Please check their status and try again.";
                  break;
                case MSG_NO_USER_FOUND:
                  errorSentence = "No user was found with the provided details.";
                  break;
                case MSG_NO_AGENCY_FOUND:
                  errorSentence = "No agency was found with the provided details.";
                  break;
                case MSG_PRACTICE_EXECUTIVE_NOT_FOUND:
                  errorSentence = "The specified Practice Executive could not be found.";
                  break;
                case MSG_ORDER_NOT_COMPLETED:
                    errorSentence = MSG_ORDER_NOT_COMPLETED;
                break;
                default:
                  errorSentence = "An unknown error occurred.";
              }
            } else {
              errorSentence = "An unknown error occurred.";
            }
    
            errorObj.title = "Updating Account Representative Failed!";
            errorObj.errorMessageStream = errorSentence;
            dispatch(setMainErrorObj(errorObj));
          }
        } catch (error) {
          errorObj.title = "Error Updating Account Representative";
          errorObj.errorMessageStream = "" + error;
          dispatch(setMainErrorObj(errorObj));
        }
    }
    } else {
      let errObj: SingleAlertInfo = {
        message: `Please select valid Account Representatives.`,
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errObj));
    }
  };

  const arraysHaveSameElements = (A: any, B: any) => {
    if (A.length !== B.length) return false;
    const sortedA = [...A].sort();
    const sortedB = [...B].sort();

    for (let i = 0; i < sortedA.length; i++) {
        if (sortedA[i] !== sortedB[i]) {
            return false;
        }
    }
    return true;
}

  const handleChange = (e: any) => {
    const practiceExIds = practiceExecutiveInfo?.map(ex => ex.user.userId);
    const selectedExIds = getValues('accountReps');
      if (arraysHaveSameElements(practiceExIds, selectedExIds)) {
        setIsPracticeExInfoVisible(false);
      } else {
        setIsPracticeExInfoVisible(true);
      }
  };

  const practiceExOps = useMemo(() => {
    let options: Option[] = [];
    options.push({ value: "", label: "Select" });
    agencyExecutives?.map((executive) => {

      if (getValues('agencyId')) {
        if (executive.agencyId === getValues('agencyId')) {
          options.push({
            value: executive.userId,
            label:
              executive.agencyName +
              " - " +
              fullName(
                null,
                executive.firstName,
                executive.middleName,
                executive.lastName
              ),
          });
        }
      } else {
        options.push({
          value: executive.userId,
          label:
            executive.agencyName +
            " - " +
            fullName(
              null,
              executive.firstName,
              executive.middleName,
              executive.lastName
            ),
        });
      }
    });

    return options;
  }, [watch('agencyId'), agencyExecutives])

  const agencyList = useMemo(() => {
    let options: Option[] = [];

    const filteredAgencyList = agencyInfoList?.filter(option =>
      option.commissionType.trim().toUpperCase() !== "V3"
    );

    filteredAgencyList?.map((option) => {
      options.push({ label: option.agencyName, value: option.agencyId });
    });

    if (agency) {
      const ag = options.find(option => option.value === agency.agencyId);
      if (!ag && agency.agencyName && agency.agencyId) {
        options.push({ label: agency.agencyName, value: agency.agencyId });
      }
    }
    return options;
  }, [agencyInfoList, agency]);

  const { userInfo } = useAuthSlice();

  const handleAgencyChange = () => {
    if((practiceEditInfo?.agencyId)?.toString() !== getValues('agencyId')?.toString()) {
      setValue('accountReps', []);
      setIsPracticeExInfoVisible(true);
    } else {
      setIsPracticeExInfoVisible(false)
    }
  }

  useEffect(() => () => {
      dispatch(clearAgencyObject());
  }, []);

  return (
    <>
      <div style={{ height: "10px" }}></div>
      <Header title="Account Representative Information" subtitle="" />
      <div style={{ height: "20px" }}></div>
      <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
          <CustomDropdownField
            control={control}
            name= 'agencyId'
            options={agencyList}
            label="Agency"
            onChangeSelect={handleAgencyChange}
            disabled={userInfo?.userRole !== USER_ROLES.ROLE_ADMIN}
            rules={{ required: "Agency is required." }}
          />
        </Grid>
        <Grid item xs={12} md={8}></Grid>
        <Grid item xs={12} md={4}>
          <CustomMultipleDropDown
            control={control}
            name={`accountReps`}
            options={practiceExOps}
            label="Account Representative"
            onChangeSelect={handleChange}
            disableSearch
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <form onSubmit={handleSubmit(handleUpdateExecutive)}>
            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
              <Button
                onClick={() => {
                  setOptionPopupShow({
                    message:
                      "Do you really want to update Account Representative details? ",
                    show: true,
                  });
                }}
                disabled={!isPracticeExInfoVisible}
                startIcon={<Save />}
                fullWidth
              >
                Save
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <OptionsPopup
        open={optionPopupShow.show}
        onClose={() => setOptionPopupShow({ message: "", show: false })}
        variant="confirm"
        message={<>{optionPopupShow.message}</>}
        buttons={[
          { name: "Confirm", color: "primary", onClick: confirmOptionPopup },
          { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
        ]}
      />
    </>
  );
};

export default PracticeExecutiveInfoViewForm;
