import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppSelector } from "../redux-hooks";
import { getAuditLogList } from "../../../service/AuditLogService";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";

export type SearchAuditLog = {
  fromDate?: string | undefined;
  toDate?: string | undefined;
  currentPage: number;
  itemsPerPage: number;
  entityNumber?: string | null;
  userName?: string | null;
  entityName?: string | null;
  actionType?: [] | undefined;
};

export type auditLogInfo = {
  action:string,
  actionType:string,
  auditMessage:string,
  auditTimestamp:string,
  clientIP:string,
  userName:string,
  auditedOn:string,
  entityId:number,
  entityName:string,
  entityNumber:string,
}
export interface PaginationAuditLogList {
  content: auditLogInfo[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export type SearchAuditLogRequest = {
  entityName: any;
  entityNumber: any;
  actionType: any;
  dateRange: any;
}

type AuditListState = {
  auditLogList: PaginationAuditLogList | null;
  auditLogListFetchStatus?: LoadingType;
}
const initialState: AuditListState = {
  auditLogList: null,
  auditLogListFetchStatus: 'idle',

};

export const fetchAuditLogList = createAsyncThunk("fetchAuditLogList", async (data: SearchAuditLog) => {
  console.log(data);
  const response = await getAuditLogList(data);
  const v3Response = response.data;
  return v3Response;
});
const AuditLogListSlice = createSlice({
  name: "AuditLogList",
  initialState,
  reducers: {
    resetAuditState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchAuditLogList.pending, (state) => {
      state.auditLogListFetchStatus = "loading";
    })
    .addCase(fetchAuditLogList.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
      state.auditLogListFetchStatus = "success";
      state.auditLogList = action.payload.data;
    })
    .addCase(fetchAuditLogList.rejected, (state, action) => {
      state.auditLogListFetchStatus = 'error';
    })
  },
});

export const { resetAuditState} = AuditLogListSlice.actions;
export const useAuditLogSlice = () => useAppSelector((state) => state.auditLogSlice);
export default AuditLogListSlice.reducer;