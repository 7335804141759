import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Switch,
  FormControlLabel,
  Stack,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FolderIcon from "@mui/icons-material/Folder";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomTextField from "../../common/CustomTextField";
import Dropzone from "../../common/Dropzone";
import CustomDatePicker from "../../common/CustomDatePicker";
import { useForm } from "react-hook-form";
import { FileUploadOutlined, Cancel } from "@mui/icons-material";
import Button from "../../common/Button";
import colors from "react-multi-date-picker/plugins/colors";
import { tokens } from "../../../theme";

interface DocumentUploadPopupProps {
  open: boolean;
  onClose: () => void;
}

const productOptions = ["Restorigin", "Barrera", "CompleteFT™"];
const manufacturerOptions = ["Manufacturer 1", "Manufacturer 2"];
const categoryOptions = ["Category 1", "Category 2", "Category 3"];
const subCategoryOptions = ["SubCategory 1", "SubCategory 2"];

const DocumentUploadPopup: React.FC<DocumentUploadPopupProps> = ({
  open,
  onClose,
}) => {
  const { control } = useForm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [activeRow, setActiveRow] = useState<string | null>(null);

  const [selectedProductOptions, setSelectedProductOptions] = useState<
    string[]
  >([]);
  const [selectedManufacturerOptions, setSelectedManufacturerOptions] =
    useState<string[]>([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<
    string[]
  >([]);
  const [selectedSubCategoryOptions, setSelectedSubCategoryOptions] = useState<
    string[]
  >([]);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    row: string
  ) => {
    setMenuAnchor(event.currentTarget);
    setActiveRow(row);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setActiveRow(null);
  };

  const handleOptionSelect = (row: string, option: string) => {
    const updateSelection = (
      currentSelections: string[],
      setSelections: React.Dispatch<React.SetStateAction<string[]>>
    ) => {
      const isSelected = currentSelections.includes(option);
      const updatedSelections = isSelected
        ? currentSelections.filter((item) => item !== option)
        : [...currentSelections, option];
      setSelections(updatedSelections);
    };

    switch (row) {
      case "product":
        updateSelection(selectedProductOptions, setSelectedProductOptions);
        break;
      case "manufacturer":
        updateSelection(
          selectedManufacturerOptions,
          setSelectedManufacturerOptions
        );
        break;
      case "category":
        updateSelection(selectedCategoryOptions, setSelectedCategoryOptions);
        break;
      case "subCategory":
        updateSelection(
          selectedSubCategoryOptions,
          setSelectedSubCategoryOptions
        );
        break;
      default:
        break;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Upload Document
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper sx={{ p: 2, mb: 2, width: "100%" }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img
              src={require("../../../assets/upload_document.png")}
              alt="Excel Icon"
              style={{ width: 24, height: 24 }}
            />
            <Typography variant="h6" component="div">
              Document Management
            </Typography>
          </Stack>
          <Grid container spacing={2} sx={{ pt: "5px" }}>
            <Grid item md xs={12}>
              <Typography variant="body2" color="textSecondary" component="p">
                Upload your document here. Supported formats: PDF, DOC, DOCX,
                JPEG, PNG, XLS, XLSX, MP4, MOV. Maximum file size: 15MB.
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <form>
          <Box p={1}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <CustomDropdownField
                  name="documentType"
                  control={control}
                  options={[
                    { label: "DropdownEx 1", value: "DropdownEx 1" },
                    { label: "DropdownEx 2", value: "DropdownEx 2" },
                  ]}
                  label="Document Type"
                  rules={{ required: "Document type is required." }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <CustomDatePicker
                  name="effectiveDate"
                  controllerName="effectiveDate"
                  control={control}
                  label="Effective Date"
                  rules={{ required: "Effective Date is required." }}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropzone
                  name="attachmentProductDocument"
                  label=""
                  onFileChange={() => {}}
                  control={control}
                  maxFileCount={1}
                  rules={{ required: "Product document is required." }}
                  maxFileSize={{ size: 15, unit: "MB" }}
                />
              </Grid>
              <Grid item md={12} xs={12} textAlign="center">
                <Typography variant="h6">OR</Typography>
              </Grid>

              <Grid item xs={4}>
                <CustomTextField
                  control={control}
                  controllerName="documentName"
                  label="Document Name"
                  rules={{ required: "Document Name is required." }}
                />
              </Grid>
              <Grid item md={8}>
                <CustomTextField
                  controllerName="documentURL"
                  control={control}
                  label="Document URL"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>Tags</Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    border: 1,
                    borderRadius: "8px",
                    borderColor: colors.grey[600],
                  }}
                >
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>Product</TableCell>
                        <TableCell sx={{ width: "70%" }}>
                          <Button
                            variant="text"
                            onClick={(e) => handleMenuOpen(e, "product")}
                            fullWidth
                          >
                            {selectedProductOptions.length > 0
                              ? selectedProductOptions.join(", ")
                              : "-"}
                          </Button>
                          <Menu
                            anchorEl={menuAnchor}
                            open={
                              Boolean(menuAnchor) && activeRow === "product"
                            }
                            onClose={handleMenuClose}
                          >
                            {productOptions.map((option) => (
                              <MenuItem
                                key={option}
                                onClick={() =>
                                  handleOptionSelect("product", option)
                                }
                              >
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Menu>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>
                          Manufacturer
                        </TableCell>
                        <TableCell sx={{ width: "70%" }}>
                          <Button
                            variant="text"
                            onClick={(e) => handleMenuOpen(e, "manufacturer")}
                            fullWidth
                          >
                            {selectedManufacturerOptions.length > 0
                              ? selectedManufacturerOptions.join(", ")
                              : "-"}
                          </Button>
                          <Menu
                            anchorEl={menuAnchor}
                            open={
                              Boolean(menuAnchor) &&
                              activeRow === "manufacturer"
                            }
                            onClose={handleMenuClose}
                          >
                            {manufacturerOptions.map((option) => (
                              <MenuItem
                                key={option}
                                onClick={() =>
                                  handleOptionSelect("manufacturer", option)
                                }
                              >
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Menu>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>Category</TableCell>
                        <TableCell sx={{ width: "70%" }}>
                          <Button
                            variant="text"
                            onClick={(e) => handleMenuOpen(e, "category")}
                            fullWidth
                          >
                            {selectedCategoryOptions.length > 0
                              ? selectedCategoryOptions.join(", ")
                              : "-"}
                          </Button>
                          <Menu
                            anchorEl={menuAnchor}
                            open={
                              Boolean(menuAnchor) && activeRow === "category"
                            }
                            onClose={handleMenuClose}
                          >
                            {categoryOptions.map((option) => (
                              <MenuItem
                                key={option}
                                onClick={() =>
                                  handleOptionSelect("category", option)
                                }
                              >
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Menu>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>SubCategory</TableCell>
                        <TableCell sx={{ width: "70%" }}>
                          <Button
                            variant="text"
                            onClick={(e) => handleMenuOpen(e, "subCategory")}
                            fullWidth
                          >
                            {selectedSubCategoryOptions.length > 0
                              ? selectedSubCategoryOptions.join(", ")
                              : "-"}
                          </Button>
                          <Menu
                            anchorEl={menuAnchor}
                            open={
                              Boolean(menuAnchor) && activeRow === "subCategory"
                            }
                            onClose={handleMenuClose}
                          >
                            {subCategoryOptions.map((option) => (
                              <MenuItem
                                key={option}
                                onClick={() =>
                                  handleOptionSelect("subCategory", option)
                                }
                              >
                                <ListItemIcon>
                                  <FolderIcon />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Menu>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>Publish</TableCell>
                        <TableCell sx={{ width: "70%" }} align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography sx={{ marginRight: 1 }}>NO</Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  color="success"
                                  // onChange={(e) => {
                                  //   const newValue = e.target.checked;
                                  //   handleSwitchToggle(data, newValue);
                                  // }}
                                />
                              }
                              label=""
                              sx={{ margin: 0 }}
                            />
                            <Typography sx={{ marginLeft: 1 }}>YES</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>

          <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button variant="contained" startIcon={<FileUploadOutlined />}>
                Upload
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="error"
                startIcon={<Cancel />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentUploadPopup;
