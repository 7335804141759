// import { Box,Button,Card,Container,Grid,Typography,TextField} from "@mui/material";
import React, { useEffect } from "react";
// import {  tokens } from "../../theme";
// import { useTheme } from "@mui/material/styles";
// import { useAppDispatch } from "../../redux/slice/redux-hooks";
// import { login } from "../../redux/slice/authSlice";
// import { hideMainErrorPopup } from "../../redux/slice/commonSlice";
import { setDataToCookies } from "../../utils/StorageUtils";
import { getTokenFromCookie, updateTokenToCookie } from "../../utils/Utility";
import { useLocation, useNavigate } from "react-router-dom";

export default function ShadowboxSignIn() {
  // const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  // const dispatch = useAppDispatch();
  let location = useLocation();
  const navigate = useNavigate();

  // const newDefault = {
  //   practiceId: 1,
  //   typeOfIVR: "newApplication",
  //   patientInfo: {
  //     patientCode: "P2024341001",
  //     patientId: 503,
  //     isPatientEdited: false,
  //     patientFirstName: "Danial",
  //     patientMiddleName: "",
  //     patientLastName: "Smith",
  //     patientDOB: "12/12/2000",
  //     gender: "Male",
  //     patientStreetAddress: "Wills hales 49 East",
  //     patientUnitNumber: "",
  //     patientCity: "Naperville",
  //     patientState: "IL",
  //     patientZipcode: "65789",
  //     faceSheet: [],
  //     isFaceSheetSkipped: true,
  //   },
  //   insuranceInfo: {
  //     insuranceName: "INSLYF",
  //     policy: "123786",
  //     isPolicyUnderDifferentName: "no",
  //     insuranceCards: [],
  //     isInsuranceCardsSkipped: true,
  //     isSecondaryInsurance: false,
  //   }
  // };
  // const [editableField, setEditableField] = React.useState(
  //   JSON.stringify(newDefault, null, 2)
  // );

  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    // if(location?.search ){
    // updateTokenToCookie(params.get("token"))
    // setDataToCookies("formData", params.get("formData") || "",{ sameSite: 'None', secure: true });
    // navigate("/ivr")
    // }
    // const params = new URLSearchParams(location.search);
    // if(location.search.length > 3){
    // const token = location.search.slice(7);
    // console.log(token)
    // updateTokenToCookie(params.get("token"))
    // console.log(params.get("token"))
    // console.log(params.get("formData"))
    // console.log(getTokenFromCookie())
    // if(getTokenFromCookie().length > 2 )
    // localStorage.setItem("formData", params.get("formData") || "");
    // localStorage.setItem("token", params.get("token") ||"");
    // setDataToCookies("formData", params.get("formData") || "",{ sameSite: 'None', secure: true });
    navigate("/" ,{ state: { from: location } })
    // setTimeout(()=>{
      
    // },3000)
    }, []);

//   const handleTemp = async (userName: string, password: string) => {
//   if (userName && userName.trim() !== "" && password) {
//     userName = userName.trim();
//     const response = await dispatch(
//       login({
//         loginInfo: { userName, password },
//         interceptorOption: { skipErrorPopup: true },
//       })
//     ).unwrap();
//       // sessionStorage.setItem("formData", editableField);
//       localStorage.setItem("login", Date.now().toString());
//     dispatch(hideMainErrorPopup());
//   } else {
//     console.log("Please enter both email and password.");
//   }
// };

  return ( 
    <></>
    // <>
    //  <Grid
    //   container
    //   direction="column"
    //   alignItems="center"
    //   justifyContent="center"
    //   sx={{ height: "100vh", pt: { xs: 0, md: 0 }, pb: 0 }}
    // >
    //   <Container component="main" maxWidth="md">
    //     <Card
    //       sx={{
    //         borderRadius: 3,
    //         height: "auto", 
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         p: 0,
    //       }}
    //     >
    //       <Grid item xs={12}>
    //         <Box
    //           py={5}
    //           px={4}
    //           sx={{
    //             bgcolor: colors.grey[900],
    //             borderRadius: 3,
    //             border: 1,
    //             borderColor: colors.grey[700],
    //             textAlign: "center",
    //           }}
    //         >
    //           <Typography
    //             variant="h4"
    //             component="div"
    //             sx={{
    //               fontWeight: "bold",
    //               textAlign: "center",
    //               color: "white",
    //               mb: 3,
    //             }}
    //           >
    //             Welcome to ShadowBox 👋
    //           </Typography>
    //           <Typography
    //             variant="h5"
    //             component="div"
    //             sx={{ fontWeight: "bold", mb: 2 }}
    //           >
    //             The Strategic Sourcing Platform for Advanced Woundcare
    //           </Typography>
    //           {/* <Typography variant="h6" component="div" sx={{ mb: 4 }}>
    //             To get started, sign in to your account.
    //           </Typography> */}

    //           <TextField
    //             fullWidth
    //             multiline
    //             minRows={15}
    //             maxRows={20}
    //             variant="outlined"
    //             value={editableField}
    //             onChange={(e) => setEditableField(e.target.value)}
    //             sx={{
    //               color: "white",
    //               borderRadius: 2,
    //               borderColor: colors.grey[700],
    //               "& .MuiOutlinedInput-root": {
    //                 borderRadius: 2,
    //               },
    //               "& .MuiInputBase-input": {
    //                 color: "white",
    //               },
    //               "& .MuiOutlinedInput-notchedOutline": {
    //                 borderColor: colors.grey[700],
    //               },
    //               mb: 3,
    //             }}
    //           />

    //           <Button
    //             variant="contained"
    //             color="info"
    //             size="large"
    //             fullWidth
    //             onClick={() =>
    //               handleTemp("practice@conscisys.com", "v3BioTeamRocks!")
    //             }
    //             sx={{
    //               borderRadius: "50px",
    //               padding: "15px",
    //               marginTop: "10px",
    //               fontWeight: "bold",
    //               width: "auto",
    //             }}
    //           >
    //             Launch V3 for PA
    //           </Button>
    //         </Box>
    //       </Grid>
    //     </Card>
    //   </Container>
    //   </Grid>
    // </>
  );
}
