import { UserInfo } from './authSlice';
import { red } from "@mui/material/colors";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changePassword, getAllUserRoles, getSecurityTokensByUserIdAndTokenName, getUserList, getUserPreferenceData, getUserProfileById, inactivateActivateUserProfile, saveNotificationPreferenceData, savePreferenceData, saveUserProfile, searchNotificationPreferenceData, } from "../../service/userService";
import { useAppSelector } from "./redux-hooks";
import { GeneralV3Response, LoadingType } from '../../types/CommonTypes';
import { UserCreateObj } from '../../components/operation/user/CreateUser';
import { findAllBillers, getAllAgenciesList, getManufacturersList } from '../../service/CommonService';
import { AgencyInfo, ManufacturerInfo } from './commonSlice';
import { fetchPracticesByUserRole } from '../../service/PracticeService';
import { PracticeInfo } from './practiceSlice';
import { BillerInfo } from './practice/practiceApproveSlice';

export interface UserDTO {
  userId: number;
  userRoleIds: number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: number;
  userRole: string;
  userStatus: string;
  manufacturerID: number;
  practiceId: number;
  billerID: number;
  agencyID: number;
  baaDetailIds: number[];
}

interface PaginationUserList {
  content: UserDTO[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export type SearchUsers = {
  firstName: string | undefined;
  lastName: string | undefined;
  userRoleIdsForm: string;
  userRoleIds?: number[];
  extraRoleIds: number[];
  userName: string | undefined;
  extraEntityId: number | undefined;
  extraEntityIds?: number[];
  entityId: number | undefined;
  phone: string | undefined;
  userStatus: string[] | undefined;
  currentPage: number;
  itemsPerPage: number;
};

export type UserCreate = {
  userId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  userRoleIds: number|undefined;  
  phone: string;
  password: string;
  confirmPassword: string;
  entityId: string;
  practiceProvider?: string | number;
};

export type UserProfileInfo = {
  userId: string;
  userRole: number;
  firstName: string;
  middleName: string;
  lastName: string;
  userRoleIds: number|undefined;
  phone: string;
  entityId: number;
  userStatus: string;
  inactiveDate: string;
  email: string;
  userRoleId: number;
  canAccessProviderProducts: number[];
  streetAddress?: string;
  city?: string;
  state?: string;
  unitNumber?: string;
  zipCode?: string;
}

export type UserRole = {
  roleDisplayValue: string,
  roleName: string,
  userRoleId: string 
}

export type ResetPassword = {
  userId: number;
  email: string;
  password: string;
  confirmPassword: string;
  oldPassword: string;
}
export type UserNotificationPreference = {
  data?: any;
  userNotificationPreferenceId? :any
  userId: any;
  notificationType: any;
  notificationSubType: any;
  isEnabled? :any
}

export type UserPreference = {
  userPreferenceId? :any
  userId: any;
  preferenceName: any;
  preferenceValue: any;
}

export type SecurityTokens = {
  securityTokenId: any;
  userId: any;
  userRoleId: any;
  tokenName: any;
  tokenValue: any;
}

export type SecurityTokenRequest = {
  userId: number;
  roleId: number;
  tokenName: string;
}

type UserState = {
  searchUsers?: PaginationUserList | null;
  agencyInfoListUserSlice?: AgencyInfo[],
  fetchAgencyListStatusUserSlice: LoadingType;
  manufacturerInfoListUserSlice?: ManufacturerInfo[],
  fetchManufactureListStatusUserSlice: LoadingType;
  practiceByUserRoleUserSlice?: PracticeInfo[] | null;
  getPracticesByIdStatusUserSlice: LoadingType;
  billerListUserSlice?: BillerInfo[] | null;
  fetchActiveBillerStatusUserSlice: LoadingType;
  userCreate?: UserCreate;
  userRoles?: UserRole[];
  userObject?: UserProfileInfo|null;
  userNotificationPreferenceData?: any
  userListInfo?: PaginationUserList | undefined;
  resetPasswordObject?: ResetPassword|null;
  userId?: number|undefined; 
  email?: string; 
  password?: string; 
  confirmPassword?: string; 
  saveUserProfileDataFetchStatus?: LoadingType;
  userError?: string[];
  status: string;
  fetchAllUserRoleStatus: LoadingType;
  error: string | null;
  isValid: boolean;
  userPreferenceData: UserPreference[];
  saveUserPreferenceStatus: LoadingType;
  fetchUserPreferenceStatus: LoadingType;
  fetchSecurityTokensStatus: LoadingType;
  userSecurityTokensData: SecurityTokens[];
  canAccessProviderProducts?: number[];
};

const initialState: UserState = {
  searchUsers: undefined,
  agencyInfoListUserSlice: [],
  fetchAgencyListStatusUserSlice: "idle",
  manufacturerInfoListUserSlice: [],
  fetchManufactureListStatusUserSlice: "idle",
  practiceByUserRoleUserSlice: [],
  getPracticesByIdStatusUserSlice: "idle",
  billerListUserSlice: [],
  fetchActiveBillerStatusUserSlice: "idle",
  userCreate: {
    userRoleIds: undefined,
    userId: "",
    email: "",
    phone: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    entityId: "",
  },
  userRoles: [],
  userObject: undefined,
  userListInfo: undefined,
  resetPasswordObject: {
    userId: 0,
    email: "",
    password: "",
    confirmPassword: "",
    oldPassword: "",
  },
  userId: undefined,
  email: "",
  password: "",
  confirmPassword: "",
  saveUserProfileDataFetchStatus: "idle",
  userError: undefined,
  status: "idle",
  fetchAllUserRoleStatus: "idle",
  error: null,
  isValid: false,
  userPreferenceData: [],
  saveUserPreferenceStatus: "idle",
  fetchUserPreferenceStatus: "idle",
  fetchSecurityTokensStatus: "idle",
  userSecurityTokensData: []
};

export const fetchAllUserRoles = createAsyncThunk("fetchAllUserRoles", async () => {
  const response = await getAllUserRoles();
  const v3Response = response.data;
  return v3Response.data.userRoles;
});

export const fetchUserProfileById = createAsyncThunk("fetchUserProfileById", async (userId: number) => {
  const response = await getUserProfileById(userId);
  return response;
});

export const saveUsersProfile = createAsyncThunk("saveUsersProfile", async (data: UserCreateObj, { rejectWithValue }) => {
  try {
    const response = await saveUserProfile(data);
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const searchUserList = createAsyncThunk("search_user_list", async (data: SearchUsers) => {
  const response = await getUserList(data);
  return response;
});

export const resetPassword = createAsyncThunk("reset_account_password", async (passwordObj: ResetPassword) => {
  const response = await changePassword(passwordObj);
  return response;
});

// export const inactivateActivateUser = createAsyncThunk("inactivateActivateUser", async (userId: number, action: string) => {
//   const response = await inactivateActivateUserProfile(userId, action);
//   return response;
// });

export const inactivateActivateUser = createAsyncThunk<
    any,
    { userId: number, action: string },
    { rejectValue: string }
>("inactivateActivateUser", async (data) => {
    const { userId, action } = data;
    const response = await inactivateActivateUserProfile(userId, action);
  return response;
});

export const fetchAgenciesListUserSlice = createAsyncThunk("fetchAgenciesListUserSlice", async () => {
  const response = await getAllAgenciesList();
  const v3Response = response.data;
  return v3Response.data;
});

export const fetchManufacturersListUserSlice = createAsyncThunk("fetchManufacturersListUserSlice", async () => {
  const response = await getManufacturersList();
  const v3Response = response.data;
  return v3Response.data;
});

export const getPracticesByUserRoleUserSlice = createAsyncThunk('getPracticesByUserRoleUserSlice', async ( isActivePracticeOnly?: boolean ) => {
  const response = await fetchPracticesByUserRole(isActivePracticeOnly);
  const v3Response = response.data;
  return v3Response.data;
});

export const fetchActiveBillersUserSlice = createAsyncThunk("billerListUserSlice", async () => {
  const response = await findAllBillers(true);
  const v3Response = response.data;
  return v3Response.data;
});
export const searchUserNotificationPreference = createAsyncThunk<
    UserNotificationPreference, // The return type of the payload creator
    UserNotificationPreference,  // The first argument to the payload creator
    { rejectValue: string }      // The type of the `rejectWithValue`
>(
    "searchUserNotificationPreference",
    async (data, { rejectWithValue }) => {
        try {
            const response = await searchNotificationPreferenceData(data);
            return response.data;
        } catch (error) {
            return rejectWithValue('An error occurred');
        }
    }
);
export const saveUserNotificationPreference = createAsyncThunk<
    UserNotificationPreference, // The return type of the payload creator
    UserNotificationPreference,  // The first argument to the payload creator
    { rejectValue: string }      // The type of the `rejectWithValue`
>(
    "saveUserNotificationPreference",
    async (data, { rejectWithValue }) => {
        try {
            const response = await saveNotificationPreferenceData(data);
            return response.data;
        } catch (error) {
            return rejectWithValue('An error occurred');
        }
    }
);

export const saveUserPreference = createAsyncThunk<
  UserPreference, // The return type of the payload creator
  UserPreference,  // The first argument to the payload creator
  { rejectValue: string }      // The type of the `rejectWithValue`
>(
  "saveUserPreference",
  async (data, { rejectWithValue }) => {
    try {
      const response = await savePreferenceData(data);
      return response.data;
    } catch (error) {
      return rejectWithValue('An error occurred');
    }
  }
);

export const fetchUserPreference = createAsyncThunk('fetchUserPreference', async (userId: number) => {
  const response = await getUserPreferenceData(userId);
  const v3Response = response.data;
  return v3Response.data;
});

export const fetchSecurityTokens = createAsyncThunk('fetchSecurityTokensByUserIdAndTokenName', async (securityTokenRequest: SecurityTokenRequest) => {
  const response = await getSecurityTokensByUserIdAndTokenName(securityTokenRequest.userId, securityTokenRequest.roleId, securityTokenRequest.tokenName);
  const v3Response = response.data;
  return v3Response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfoObject: (state: UserState, action) => {
      state.userObject = action.payload;
    },
    resetFetchStatus: (state: UserState) =>{
        state.userCreate = undefined;
    },
    clearUserObject: (state: UserState) => {
      state.userObject = null;
    },
    clearResetPasswordObject: (state: UserState) => {
      state.resetPasswordObject = undefined;
    },
    resetUserState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUserNotificationPreference.pending, (state) => {
        state.status = "loading";
      })
      .addCase(searchUserNotificationPreference.fulfilled, (state, action) => {
        state.status = "success";
        state.userNotificationPreferenceData = action.payload.data;
      })
      .addCase(searchUserNotificationPreference.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(saveUserNotificationPreference.pending, (state) => {
        state.fetchAllUserRoleStatus = "loading";
      })
      .addCase(saveUserNotificationPreference.fulfilled, (state, action) => {
        state.fetchAllUserRoleStatus = "success";
        state.userNotificationPreferenceData = action.payload;
      })
      .addCase(saveUserNotificationPreference.rejected, (state, action) => {
        state.fetchAllUserRoleStatus = "error";
        // state.error = action.error.message ?? null;
      })
      .addCase(fetchAllUserRoles.pending, (state) => {
        state.fetchAllUserRoleStatus = "loading";
      })
      .addCase(fetchAllUserRoles.fulfilled, (state, action) => {
        state.fetchAllUserRoleStatus = "success";
        state.userRoles = action.payload;
      })
      .addCase(fetchAllUserRoles.rejected, (state, action) => {
        state.fetchAllUserRoleStatus = "error";
        state.error = action.error.message ?? null;
      })
      .addCase(fetchUserProfileById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfileById.fulfilled, (state, action) => {
        state.status = "success";
        state.userObject = action.payload.data.data;
      })
      .addCase(fetchUserProfileById.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message ?? null;
      })
      .addCase(saveUsersProfile.pending, (state) => {
        state.saveUserProfileDataFetchStatus = "loading";
      })
      .addCase(saveUsersProfile.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.saveUserProfileDataFetchStatus = "success";
        state.userError = undefined;
      })
      .addCase(saveUsersProfile.rejected, (state, action: any) => {
        state.saveUserProfileDataFetchStatus = 'error';
        state.userError = action.payload.errors;
      })
      .addCase(searchUserList.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(searchUserList.fulfilled, (state, action) => {
        state.status = "success";
        state.isValid = true;
        state.searchUsers = action.payload;
      })
      .addCase(searchUserList.rejected, (state, action) => {
        state.status = "failed";
        state.isValid = false;
        state.error = action.error.message || "Failed to fetch user profiles";
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "success";
        state.isValid = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.isValid = false;
        state.error = action.error.message || "Failed to reset password";
      })
      .addCase(inactivateActivateUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(inactivateActivateUser.fulfilled, (state, action) => {
        state.status = "success";
        state.isValid = true;
        state.userObject = action.payload;
      })
      .addCase(inactivateActivateUser.rejected, (state, action) => {
        state.status = "failed";
        state.isValid = false;
        state.error = action.error.message || "Failed to reset inactivate user profile";
      })
      .addCase(fetchAgenciesListUserSlice.pending, (state) => {
        state.fetchAgencyListStatusUserSlice = "loading";
        state.error = null;
      })
      .addCase(fetchAgenciesListUserSlice.fulfilled, (state, action) => {
        state.fetchAgencyListStatusUserSlice = "success";
        state.agencyInfoListUserSlice = action.payload;
      })
      .addCase(fetchAgenciesListUserSlice.rejected, (state, action) => {
        state.fetchAgencyListStatusUserSlice = "error";
        state.error = action.error.message || "An error occurred when getting agencies";
      })
      .addCase(fetchManufacturersListUserSlice.pending, (state) => {
        state.fetchManufactureListStatusUserSlice = "loading";
        state.error = null;
      })
      .addCase(fetchManufacturersListUserSlice.fulfilled, (state, action) => {
        state.fetchManufactureListStatusUserSlice = "success";
        state.manufacturerInfoListUserSlice = action.payload;
      })
      .addCase(fetchManufacturersListUserSlice.rejected, (state, action) => {
        state.fetchManufactureListStatusUserSlice = "error";
        state.error = action.error.message || "An error occurred when getting manufacturers";
      })
      .addCase(getPracticesByUserRoleUserSlice.pending, (state, action) => {
        state.getPracticesByIdStatusUserSlice = 'loading';
        state.error = null;
      })
      .addCase(getPracticesByUserRoleUserSlice.fulfilled, (state, action) => {
        state.getPracticesByIdStatusUserSlice = 'success';
        state.practiceByUserRoleUserSlice = action.payload;
      })
      .addCase(getPracticesByUserRoleUserSlice.rejected, (state, action) => {
        state.getPracticesByIdStatusUserSlice = 'error';
        state.error = action.error.message || "Failed to load practices";
      })
      .addCase(fetchActiveBillersUserSlice.pending, (state) => {
        state.fetchActiveBillerStatusUserSlice = "loading";
        state.error = null;
      })
      .addCase(fetchActiveBillersUserSlice.fulfilled, (state, action) => {
        state.fetchActiveBillerStatusUserSlice = "success";
        state.billerListUserSlice = action.payload;
        state.isValid = true;
      })
      .addCase(fetchActiveBillersUserSlice.rejected, (state, action) => {
        state.fetchActiveBillerStatusUserSlice = "error";
        state.error = action.error.message || "Failed to fetch Billers"
      })
      .addCase(saveUserPreference.pending, (state) => {
        state.saveUserPreferenceStatus = "loading";
      })
      .addCase(saveUserPreference.fulfilled, (state, action) => {
        state.saveUserPreferenceStatus = "success";
        // state.userPreferenceData = action.payload;
      })
      .addCase(saveUserPreference.rejected, (state, action) => {
        state.saveUserPreferenceStatus = "error";
        // state.error = action.error.message ?? null;
      })
      .addCase(fetchUserPreference.pending, (state) => {
        state.fetchUserPreferenceStatus = "loading";
      })
      .addCase(fetchUserPreference.fulfilled, (state, action) => {
        state.fetchUserPreferenceStatus = "success";
        state.userPreferenceData = action.payload;
      })
      .addCase(fetchUserPreference.rejected, (state, action) => {
        state.fetchUserPreferenceStatus = "error";
      })
      .addCase(fetchSecurityTokens.pending, (state) => {
        state.fetchSecurityTokensStatus = "loading";
      })
      .addCase(fetchSecurityTokens.fulfilled, (state, action) => {
        state.fetchSecurityTokensStatus = "success";
        state.userSecurityTokensData = action.payload;
      })
      .addCase(fetchSecurityTokens.rejected, (state, action) => {
        state.fetchSecurityTokensStatus = "error";
      });
  },
});
// export const {updatePracticeInfoObject} = userSlice.actions;
// export const usePracticeSlice = () => useAppSelector((state) => state.userSlice);
// export default userSlice.reducer;
export const { updateUserInfoObject, resetFetchStatus, clearUserObject, clearResetPasswordObject, resetUserState } = userSlice.actions;
export const useUserReducer = () => useAppSelector((state) => state.userSlice);
export default userSlice.reducer;
