import * as React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { ColorModeContext, tokens } from "../../theme";
import { Alert, IconButton } from "@mui/material";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import Button from "../common/Button";
import Card from "../common/Card";
import Footer from "../common/Footer";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { login } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import {
  removeItemFromLocalStorage,
  setDataToLocalStorage,
  getDataFromLocalStorage,
} from "../../utils/StorageUtils";
import Checkbox from "../common/Checkbox";
import CustomPasswordTextField from "../common/CustomPasswordTextField";
import { useForm } from "react-hook-form";
import { FAILED_CODE } from "../../service/ServiceConstants";
import { hideMainErrorPopup } from "../../redux/slice/commonSlice";
import { useCacheBuster } from "react-cache-buster";
import OptionsPopup from "../common/OptionsPopup";
import { fetchUserPreference } from "../../redux/slice/userSlice";

export default function SignIn() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = React.useContext(ColorModeContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorMessageContent, setErrorMessageContent] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { control } = useForm();
  const [showInfoPopup, setShowInfoPopup] = React.useState<{ message: string, show: boolean }>({ message: "", show: false });

  React.useEffect(() => {
    const savedUsername = getDataFromLocalStorage("savedUsername");
    // const savedPassword = getDataFromLocalStorage("savedPassword");
    const savedRememberMe = getDataFromLocalStorage("rememberMe") === "true";

    if (savedRememberMe && savedUsername) {
      setUsername(savedUsername);
      // setPassword(savedPassword);
      setRememberMe(savedRememberMe);
    }

  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email: string = data.get("email") as string;
    const password: string = data.get("password") as string;

    handleLogin(email, password);
  };

  const handleLogin = async (userName: string, password: string) => {
    if (userName && userName.trim() !== "" && password) {
      setUsername(userName.trim());
      userName = userName.trim();
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(userName)) {
        setErrorMessageContent("Invalid email address.");
        setOpen(true);
        return;
      }
      try {
        const response = await dispatch(
          login({
            loginInfo: { userName, password },
            interceptorOption: { skipErrorPopup: true },
          })
        ).unwrap();

        // Set localStorage item to inform other tabs of the login action
        localStorage.setItem("login", Date.now().toString());

        if (rememberMe) {
          setDataToLocalStorage("savedUsername", userName);
          setDataToLocalStorage("rememberMe", "true");
        } else {
          removeItemFromLocalStorage("savedUsername");
          removeItemFromLocalStorage("rememberMe");
        }

        if (response) {
          dispatch(fetchUserPreference(response.userId)).then((res) => {
            const preferences = res.payload;

            const preferredTheme = preferences.find(
              (pref: { preferenceName: string }) => pref.preferenceName === "preferredTheme"
            );
            if (preferredTheme) {
              if (theme.palette.mode !== preferredTheme.preferenceValue) {
                colorMode.toggleColorMode();
              }
            }
          })
        }

        dispatch(hideMainErrorPopup());
      } catch (e: any) {
        if (e?.code === FAILED_CODE) {
          setErrorMessageContent(e?.message);
        } else if (e?.message === "Rejected") {
          setErrorMessageContent("Unknown error. Please contact support.");
        } else {
          setErrorMessageContent("Unknown error. Please contact support.");
        }
      }
    } else {
      setErrorMessageContent("Please enter both email and password.");
    }
    setOpen(true);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            borderBottom: 1,
            zIndex: 1100,
            borderColor:
              theme.palette.mode === "dark" ? "primary.dark" : "secondary.main",
          }}
        >
          <Grid
            container
            spacing={2}
            p={2}
            display="flex"
            style={{ backgroundColor: colors.grey[800] }}
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <img
                alt="logo"
                src={require("../../assets/V3_logo-gradient.png")}
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: "end" }}>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <LightModeOutlined />
                ) : (
                  <DarkModeOutlined />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh", pt: { xs: 10, md: 0 } }}
        >
          <Container
            component="main"
            maxWidth="md"
            style={{ paddingTop: "40px", paddingBottom: "50px" }}
          >
            <Card>
              <Grid container spacing={2} display="flex">
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Avatar sx={{ m: 1 }}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Sign in
                    </Typography>

                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      {errorMessageContent == null ||
                        errorMessageContent === "" ? (
                        ""
                      ) : (
                        <Box mb={2}>
                          {open && (
                            <Alert
                              variant="filled"
                              severity="error"
                              onClose={handleClose}
                            >
                              {errorMessageContent}
                            </Alert>
                          )}
                        </Box>
                      )}
                      <TextField
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        color={
                          theme.palette.mode === "dark" ? "secondary" : "primary"
                        }
                        style={{
                          borderRadius: "0",
                        }}
                        InputProps={{ sx: { borderRadius: 2 } }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <CustomPasswordTextField
                        control={control}
                        margin="normal"
                        fullWidth
                        controllerName="password"
                        label="Password"
                        id="password"
                        autoComplete="current-password"
                        sx={{ borderRadius: 2 }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Checkbox
                          value={rememberMe}
                          color="primary"
                          label="Remember me"
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <Typography
                          variant="body1"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/forgot-password")}
                        >
                          Forgot password?
                        </Typography>
                      </Box>
                      <Grid container display="flex" justifyContent="center">
                        <Grid item md={6} xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            size="large"
                            fullWidth
                            style={{
                              borderRadius: 50,
                              padding: "15px",
                              marginTop: "10px",
                            }}
                          >
                            Sign In
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    py={3}
                    px={2}
                    sx={{
                      backgroundColor: colors.grey[900],
                      borderRadius: 3,
                      border: 1,
                      borderColor: colors.grey[700],
                      textAlign: "center"
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Welcome to V3 Biomed 👋
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontWeight: "bold", mt: 2 }}
                    >
                      The Strategic Sourcing Platform for Advanced Woundcare
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      At V3 Biomed, we provide wound care providers and
                      organizations with an intuitive platform to access top-tier
                      CTPs and skin substitutes. More importantly, we simplify and
                      streamline the administrative tasks that come with managing
                      these products.
                    </Typography>
                    <Typography variant="h6" component="div" sx={{ mt: 2 }}>
                      To get started, sign in to your account on the left. If
                      you're new to V3 Biomed, click "Practice Registration" to
                      begin your journey with us.
                    </Typography>
                    <Grid container display="flex" justifyContent="center">
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color="info"
                          size="large"
                          fullWidth
                          style={{
                            borderRadius: 50,
                            padding: "15px",
                            marginTop: "10px"
                          }}
                          onClick={() => navigate("/practice")}
                        >
                          Practice Registration
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </Grid>
        <Box
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0, borderTop: 5 }}
        >
          <Footer />
        </Box>
      </Box>
    </>
  );
}
