import React from "react";
import { Box, Grid, Typography, ToggleButton, useTheme } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { tokens } from "../../../theme";

// Define the types for props
interface Permission {
  id: number;
  label: string;
  isPrivate: boolean;
}

interface PermissionSectionProps {
  title: string;
  subtitle: string;
  permissions: Permission[];
  toggleHandler: (id: number) => void;
  colors: any;
  colorKey: string; // Dynamic color key for each section
}

// Reusable PermissionSection component
const PermissionSection: React.FC<PermissionSectionProps> = ({
  title,
  subtitle,
  permissions,
  toggleHandler,
  colors,
  colorKey,
}) => (
  <Card sx={{ p: 2, mb: 2 }}>
    <Typography variant="h5" fontWeight="bold">
      {title}
    </Typography>
    <Typography sx={{ mb: 2 }}>{subtitle}</Typography>
    <Grid container spacing={2}>
      {permissions.map((permission: Permission) => (
        <Grid item xs={12} md="auto" key={permission.id}>
          <Card
            sx={{
              border: permission.isPrivate
                ? `1px solid ${colors[colorKey][400]}`
                : "1px solid #4b4b4b",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              padding: "16px",
              boxShadow: permission.isPrivate
                ? "none"
                : "0px 0px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Typography variant="body1" fontWeight="bold" sx={{ pr: 2 }}>
                {permission.label}
              </Typography>
            </Box>
            <ToggleButton
              value="check"
              selected={permission.isPrivate}
              onChange={() => toggleHandler(permission.id)}
              sx={{
                color: permission.isPrivate ? colors[colorKey][400] : "#999",
                width: 70,
                "&.Mui-selected": {
                  color: "#ffffff",
                  backgroundColor: colors[colorKey][400],
                },
              }}
            >
              {permission.isPrivate ? (
                <CheckCircleIcon sx={{ marginRight: "5px" }} />
              ) : (
                <CancelIcon sx={{ marginRight: "5px" }} />
              )}
              {permission.isPrivate ? "On" : "Off"}
            </ToggleButton>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Card>
);

const UserPermissions: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [canAccessOrderActions, setCanAccessOrderActions] = React.useState<
    Permission[]
  >([
    { id: 1, label: "RETURN", isPrivate: false },
    { id: 2, label: "TRACKING", isPrivate: false },
    { id: 3, label: "REORDER", isPrivate: false },
    { id: 4, label: "CANCEL", isPrivate: false },
  ]);

  const [canAccessPracticeActions, setCanAccessPracticeActions] =
    React.useState<Permission[]>([
      { id: 1, label: "VIEW", isPrivate: false },
      { id: 2, label: "EDIT", isPrivate: false },
      { id: 3, label: "PREVIEW", isPrivate: false },
    ]);

  const [restrictedInvoiceColumns, setRestrictedInvoiceColumns] =
    React.useState<Permission[]>([
      { id: 1, label: "v3CommissionAmount", isPrivate: false },
      { id: 2, label: "v3CommissionPaidAmount", isPrivate: false },
      { id: 3, label: "agencyCommissionAmount", isPrivate: false },
      { id: 4, label: "v3CommNet", isPrivate: false },
      { id: 5, label: "ovrAgencyCommissionAmount", isPrivate: false },
      { id: 6, label: "ovrAgencyCommissionPaidAmount", isPrivate: false },
      { id: 7, label: "agencyCommissionPaidAmount", isPrivate: false },
    ]);

  const [canViewInvoiceSearchFields, setCanViewInvoiceSearchFields] =
    React.useState<Permission[]>([
      { id: 1, label: "ORDER_NUMBER", isPrivate: false },
      { id: 2, label: "INVOICE_NUMBER", isPrivate: false },
      { id: 3, label: "INVOICE_STATUS", isPrivate: false },
      { id: 4, label: "INVOICE_DATE_RANGE", isPrivate: false },
      { id: 5, label: "MANUFACTURE", isPrivate: false },
      { id: 6, label: "PRACTICE", isPrivate: false },
      { id: 7, label: "AGENCY", isPrivate: false },
      { id: 8, label: "COMM_PERIOD", isPrivate: false },
    ]);

  const [canManageUsers, setCanManageUsers] = React.useState<Permission[]>([
    { id: 1, label: "ROLE_PRACTICEADMIN", isPrivate: false },
    { id: 2, label: "ROLE_PRACTICESTAFF", isPrivate: false },
    { id: 3, label: "ROLE_PROVIDER", isPrivate: false },
  ]);

  const handleToggleOrderActions = (id: number) => {
    setCanAccessOrderActions((prev) =>
      prev.map((permission) =>
        permission.id === id
          ? { ...permission, isPrivate: !permission.isPrivate }
          : permission
      )
    );
  };

  const handleTogglePracticeActions = (id: number) => {
    setCanAccessPracticeActions((prev) =>
      prev.map((permission) =>
        permission.id === id
          ? { ...permission, isPrivate: !permission.isPrivate }
          : permission
      )
    );
  };

  const handleToggleRestrictedInvoiceColumns = (id: number) => {
    setRestrictedInvoiceColumns((prev) =>
      prev.map((permission) =>
        permission.id === id
          ? { ...permission, isPrivate: !permission.isPrivate }
          : permission
      )
    );
  };

  const handleToggleInvoiceSearchFields = (id: number) => {
    setCanViewInvoiceSearchFields((prev) =>
      prev.map((permission) =>
        permission.id === id
          ? { ...permission, isPrivate: !permission.isPrivate }
          : permission
      )
    );
  };

  const handleToggleManageUsers = (id: number) => {
    setCanManageUsers((prev) =>
      prev.map((permission) =>
        permission.id === id
          ? { ...permission, isPrivate: !permission.isPrivate }
          : permission
      )
    );
  };

  const sections = [
    {
      title: "Can Access Order Actions",
      subtitle: "Manage and Perform Actions on Your Orders with Ease",
      permissions: canAccessOrderActions,
      toggleHandler: handleToggleOrderActions,
      colorKey: "success",
    },
    {
      title: "Can Access Practice Actions",
      subtitle: "Permission to view and perform practice-related actions",
      permissions: canAccessPracticeActions,
      toggleHandler: handleTogglePracticeActions,
      colorKey: "info",
    },
    {
      title: "Restricted Invoice Columns",
      subtitle: "Limited access to specific invoice data columns",
      permissions: restrictedInvoiceColumns,
      toggleHandler: handleToggleRestrictedInvoiceColumns,
      colorKey: "warning",
    },
    {
      title: "Can View Invoice Search Fields",
      subtitle: "Permission to access and search invoice fields",
      permissions: canViewInvoiceSearchFields,
      toggleHandler: handleToggleInvoiceSearchFields,
      colorKey: "secondary",
    },
    {
      title: "Can Manage Users",
      subtitle: "Permission to manage user roles and access",
      permissions: canManageUsers,
      toggleHandler: handleToggleManageUsers,
      colorKey: "error",
    },
  ];

  return (
    <Box
      sx={{
        margin: {
          xs: "20px 10px",
          sm: "20px",
        },
      }}
    >
      <Header title="User Permission Management" subtitle="User Permission" />
      {sections.map((section, index) => (
        <PermissionSection
          key={section.title}
          title={section.title}
          subtitle={section.subtitle}
          permissions={section.permissions}
          toggleHandler={section.toggleHandler}
          colors={colors}
          colorKey={section.colorKey}
        />
      ))}
    </Box>
  );
};

export default UserPermissions;
