import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  Divider
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import {
  Cancel,
  SaveOutlined,
  DoNotDisturbOnOutlined,
  HelpOutline,
} from "@mui/icons-material";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useEffect, useMemo, useState } from "react";
import { convertBase64 } from "../../../utils/Utility";
import { BENEFIT_SUMMARY_STATUS } from "../../../constants/applicationConstants";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { UpdateIVRObj, fetchIVRById, updateElementInCreateIVRSlice, updateIVRRespond, useCreateIVRSlice } from "../../../redux/slice/ivr/createIVRSlice";

interface ExtendedIVRObj extends UpdateIVRObj {
  action: string
}

const BenefitSummary = () => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setError,
    setValue,
    clearErrors,
    register,
    formState: { errors },
  } = useForm<ExtendedIVRObj>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { IVRObject} = useCreateIVRSlice();

  const [showOptionPopup, setShowOptionsPopup] = useState<OptionPopupObjectProps>({message:'', show:false});
  const { IVRListUpdateStatus } = useCreateIVRSlice();
      const orderDetails = location?.state?.orderDetails;

  
  const insuranceInfo = useMemo(()=>{
    const obj = IVRObject?.patient?.patientInfo?.find((data)=>
      data.infoType === "PRIMARY_INSURANCE"
    )
    return obj
  },[IVRObject])

  useEffect(() => {
    setValue("ivrId", orderDetails?.ivrId);
    setValue("note.noteType", "BENEFIT_SUMMARY");
    if (orderDetails?.ivrId) {}
      dispatch(fetchIVRById((orderDetails.ivrId).toString()));
  }, []);
  
  const handleClickDeny = (noteContent: string) => {
    if(!noteContent || noteContent?.length === 0) {
      setValue('note.attachment', '')
    };
    setValue("verificationStatus", BENEFIT_SUMMARY_STATUS.DENIED);
  }; 
  
  const handleClickNeedMoreInfo = (noteContent: string) => {
    if(!noteContent || noteContent?.length === 0) {
      setValue('note.attachment', '')
    };
    setValue("verificationStatus", BENEFIT_SUMMARY_STATUS.NEED_MORE_INFO);
  };

  const handleClickVerify = () => {
    clearErrors('note.content');
    setValue("verificationStatus", BENEFIT_SUMMARY_STATUS.VERIFIED);
  };

  const setBenefitSummaryError = () => {
    setError('note.attachment', {
      type: 'required',
      message: "Benefit Summary (Attachment) is required."
    });
    let errorObj: SingleAlertInfo = {
      message: "Please attach the Benefit Summary.",
      alertType: "error"
    };
    dispatch(setSingleAlertObj(errorObj));
  }
 
  const onSubmit = () => {
    const action = getValues('action');
    const noteContent = getValues('note.attachment');

    if(action === BENEFIT_SUMMARY_STATUS.VERIFIED) {
      if(!noteContent || noteContent?.length === 0) {
        setBenefitSummaryError();
        return;
      };
      handleClickVerify();
    } else if (action === BENEFIT_SUMMARY_STATUS.NEED_MORE_INFO) {
      handleClickNeedMoreInfo(noteContent);
    } else if (action === BENEFIT_SUMMARY_STATUS.DENIED) {
      handleClickDeny(noteContent);
    };
    IVRAction();
  };

  const IVRAction = () => {
    setShowOptionsPopup({message: "Do you really want to update the IVR? ", 
    show: true, 
    variant: "confirm", 
    buttons: [{name: "Confirm", color: 'primary', onClick:() => updateIVR() },{name: "Cancel", color: 'secondary', onClick:cancelOptionPopup }]});
  }

  const updateIVR = async () => {
    const {action, ...updateIVRData} = getValues();
    const response = await dispatch(updateIVRRespond(updateIVRData));
    cancelOptionPopup();
  };

  useEffect(() => {
    if(IVRListUpdateStatus === 'success') {
      let successObj: SingleAlertInfo = {
        message: `IVR ${orderDetails?.ivrNumber} has been updated successfully.`,
        alertType: "success"
      };
      dispatch(setSingleAlertObj(successObj));
      dispatch(updateElementInCreateIVRSlice(
       { elementName : "IVRListUpdateStatus",
        value: ""}
      ))
      navigate("/ivr_list", {state: {withSearchHistory: true}});
    }
  }, [IVRListUpdateStatus])

  const cancelOptionPopup = () => {
    setShowOptionsPopup({ message: "", show: false });
  };

  const handleDropzoneChange = async (uploadedFiles: ExtendedFile[], fieldName: any) => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      const base64File = String(await convertBase64(uploadedFiles[0]));
      const fileName = uploadedFiles[0].name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1).toLowerCase();
      const base64Substring = base64File.substring(
        fileExtension === "pdf" ? "data:application/pdf;base64,".length  : "data:image/png;base64,".length, 
        base64File.length);
      setValue("note.attachment", base64Substring);
      setValue("note.documentName", fileName);
      setValue("note.fileType", fileExtension);
    }
  };

  const handleDeleteFile = () => {
    setValue("note.attachment", "");
    setValue("note.documentName", "");
    setValue("note.fileType", "");
  }

  return (
    <Box
      sx={{
        margin: {
          xs: "20px 10px",
          sm: "20px",
        },
      }}
    >
      {showOptionPopup.show && (
        <OptionsPopup
          open={showOptionPopup.show}
          onClose={() => setShowOptionsPopup({ message: "", show: false })}
          variant={showOptionPopup.variant}
          message={<>{showOptionPopup.message}</>}
          buttons={showOptionPopup.buttons}
          title={showOptionPopup.title}
          obj={showOptionPopup.obj}
        />
      )}
      <Header title="Benefit Summary" subtitle="Benefit Management" />
      <Box>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={3}>
                <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
                  <b>Patient :</b>
                  <span>{orderDetails ? orderDetails.patient : ""}</span>
                </Typography>
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
                  <b>DOB :</b>
                  <span>{orderDetails ? orderDetails.patientDOB : ""}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} md={3}>
                <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
                  <b>Insurance Info :</b>
                  <span>{insuranceInfo?.patientinfoData?.insuranceName}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
                  <b>Policy Number :</b>
                  <span>{insuranceInfo?.patientinfoData?.policy}</span>
                </Typography>
              </Grid>
            </Grid>

              <Grid item md={12} xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                />
              </Grid>
              <Grid container spacing={2} mb={2} mt={1}>
                <Grid item md={4} xs={12}>
                  <Dropzone
                    name="note.attachment"
                    onFileChange={() => {}}
                    onFileChangeTest={handleDropzoneChange}
                    onDeleteFile={handleDeleteFile}
                    control={control}
                    maxFileCount={1}
                    label="Benefit Summary (Attachment)"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mb={2}>
                <Grid item md={12} xs={12}>
                  <CustomTextField
                    controllerName="note.content"
                    control={control}
                    label="Notes"
                    multiline
                    rows={4}
                    rules={{ required: "Notes are required." }}
                    maxRows={4}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md="auto">
                  <Button
                    color="warning"
                    type="submit"
                    startIcon={<DoNotDisturbOnOutlined />}
                    fullWidth
                    sx={{ mr: 1 }}
                    onClick={() => {
                      {
                        setValue("action", BENEFIT_SUMMARY_STATUS.DENIED);
                      }
                    }}
                  >
                    Deny
                  </Button>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Button
                    color="info"
                    type="submit"
                    startIcon={<HelpOutline />}
                    fullWidth
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setValue("action", BENEFIT_SUMMARY_STATUS.NEED_MORE_INFO);
                    }}
                  >
                    Need More Info
                  </Button>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Button
                    type="submit"
                    startIcon={<SaveOutlined />}
                    fullWidth
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setValue("action", BENEFIT_SUMMARY_STATUS.VERIFIED);
                    }}
                  >
                    Verify
                  </Button>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Button
                    color="error"
                    startIcon={<Cancel />}
                    fullWidth
                    onClick={() =>
                      navigate("/ivr_list", {
                        state: { withSearchHistory: true },
                      })
                    }
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <input type="hidden" {...register("action")} />
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default BenefitSummary;
