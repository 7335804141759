import { AddPaymentInfo, commissionPaymentRequest, PaymentRequest, RecordPayoutInfo } from "../redux/slice/payment/paymentSlice";
import { InterceptorOption } from "../types/CommonTypes";
import { ADD_PAYMENTS_URL, SAVE_COMMISSION_PAYOUTS_URL, UPLOAD_COMMISSION_PAYMENT_URL, UPLOAD_PAYMENT_URL } from "./URL";
import { http } from "./http";


export const uploadPaymentFileData = async (paymentData: PaymentRequest, interceptorOption: InterceptorOption) => {
  try {
    const response = await http.post(UPLOAD_PAYMENT_URL, paymentData, undefined, interceptorOption);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadCommissionPaymentFileData = async (commissionData: commissionPaymentRequest, interceptorOption: InterceptorOption) => {
  try {
    const response = await http.post(UPLOAD_COMMISSION_PAYMENT_URL, commissionData, undefined, interceptorOption);
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveAgencyCommissionPayoutData = async (data: RecordPayoutInfo) => {
  try {
    const response = await http.post(SAVE_COMMISSION_PAYOUTS_URL, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const saveInvoicePaymentData = async(data:AddPaymentInfo)=>{
  try{
    const response =await http.post(ADD_PAYMENTS_URL,data);
    return response;
  }catch(error){
    throw error;
  }
}
