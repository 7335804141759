import React, { useEffect, useMemo, useState } from 'react';
import { Box, Collapse, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import Card from "../../common/Card";
import Table from "../../common/CustomTable";
import Header from "../../common/Header";
import Button from "../../common/Button";
import DifferenceIcon from '@mui/icons-material/Difference';
import {
  AddCircleOutlineOutlined, AppRegistrationOutlined, DeleteOutlineOutlined, ListAltOutlined, ModeOutlined,
  TrackChanges,
  Troubleshoot
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProductInfo } from '../../../redux/slice/product/productListSlice';
import { useAppDispatch } from '../../../redux/slice/redux-hooks';
import { PracticeInfo } from '../../../redux/slice/practiceSlice';
import { selectIsTokenAvailable, selectIsValueFoundInToken, useAuthSlice } from '../../../redux/slice/authSlice';
import { AUDIT_ENTITYS, CAN_ACCESS_APPLICATION, IVR_STATUS, IVR_STATUS_LIST, ORDERTYPE } from '../../../constants/applicationConstants';
import { formatDate, fullName, hideElement, sortNames } from '../../../utils/Utility';
import {
  fetchManufacturerDataById,
  fetchManufacturerList, useManufacturerListSlice
} from '../../../redux/slice/manufacturer/ManufacturerSlice';
// import IVRPracticeSelectionPopup from './IVRPracticeSelectionPopup';
import CustomIconButton from '../../common/CustomIconButton';
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from '../../../redux/slice/codetableSlice';
import { setSingleAlertObj, showHideLoadingDialog, SingleAlertInfo } from '../../../redux/slice/commonSlice';
import { RootState } from '../../../redux/store';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
}



interface Row {
  contactPhone: string;
  manufacturerName: string;
  // notificationEmail: string;
  inactiveDate: string;
  action: JSX.Element;
  manufacturerId: number
}




// Define columns and rows data
const columns: Column[] = [
  { id: "manufacturerName", label: "Manufacturer ", minWidth: 100 },
  { id: "contactPhone", label: "Contact Phone", minWidth: 100 },
  { id: "inactiveDate", label: "Inactive Date", minWidth: 100 },
  // { id: 'notificationEmail', label: 'Notification Email', minWidth: 100 },
  { id: "action", label: "Action", minWidth: 50, },
];


const ManufacturerList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const canAuditManufacture = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessAuditAction", AUDIT_ENTITYS.USERAUDIT)(state));

  const { ManufacturerListInfo } = useManufacturerListSlice();


  const handleActionEdit = (row: any) => {
    const { manufacturerName, notificationEmail, customConfig, contactPhone, manufacturerId } = row;
    dispatch(fetchManufacturerDataById(parseInt(manufacturerId)));
    navigate("/manufacturer_create", {
      state: { mfrData: { manufacturerName, notificationEmail, customConfig, contactPhone, manufacturerId } },
    });
  }

  const actionComponent = (row: Row[]): JSX.Element => {
    return (
      <>

        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Edit"
          color="info"
          size="small"
          variant="outlined"
          onClick={() => handleActionEdit(row)}
        >
          <ModeOutlined fontSize="small" />
        </CustomIconButton>

        <CustomIconButton
          tooltipPlacement="top"
          tooltipMessage="Manufacture Audit"
          color="warning"
          size="small"
          variant="outlined"
          style={{ display: `${hideElement(!canAuditManufacture)}` }}
          onClick={() => handleClickManufactureAudit(row)}
        >
          <TrackChanges fontSize="small" />
        </CustomIconButton>
      </>
    );
  };
  const formattedManufacturerInfo: Row[] = useMemo(() => {
    let manufacturers: Row[] = [];
    if (ManufacturerListInfo) {
      manufacturers = ManufacturerListInfo.map((manufacturer: any) => ({
        manufacturerName: manufacturer.manufacturerName,
        inactiveDate: manufacturer.inactiveDate,
        contactPhone: manufacturer.contactPhone,
        manufacturerId: manufacturer.manufacturerId,
        // notificationEmail: manufacturer.notificationEmail != null ? JSON.stringify(manufacturer.notificationEmail).replaceAll(',', '\n') : '',
        action: actionComponent(manufacturer),
      }));
    };
    return manufacturers;
  }, [ManufacturerListInfo]);


  //Mount
  useEffect(() => {
    fetchInitialLists();
  }, []);

  const fetchInitialLists = async () => {
    dispatch(showHideLoadingDialog(true));
    await dispatch(fetchManufacturerList());
    dispatch(showHideLoadingDialog(false));
  }

  //Unmount
  useEffect(() => () => {
  }, []);


  const handleClickManufactureAudit = (data: any) => {
    
    if (data && data.manufacturerName) {
      const MFRAuditData = {
        entityName: "MANUFACTURER",
        entityNumber: data?.manufacturerName
      }

      const url = `/auditlog?entityName=${MFRAuditData.entityName}&entityNumber=${MFRAuditData.entityNumber}`;
      window.open(url, '_blank');
      // navigate("/auditlog", '_blank',{ state: { entityName: IVRAuditData.entityName, entityNumber: IVRAuditData.entityNumber } });
    }
  }

  return (
    <>
      <Box my="8px" ml="12px" mr="10px">
        <Header title="Manufacturer List" subtitle="List Of Manufacturers" />

        <Box pt={2}>
          <Card>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1} mt={-2} >
              <Grid item xs={12} md>
                {/* {isMobile ? <LegacyToolBar items={legacyItems} /> : ""} */}
              </Grid>
              {/* <Grid item xs={12} md="auto">
                <Button variant="outlined" color="info" onClick={() => setShowCard(!showCard)} startIcon={<SearchOutlined />} fullWidth>
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid> */}
              <Grid item xs={12} md="auto">
                <Button fullWidth color="info" startIcon={<AddCircleOutlineOutlined />} onClick={() => navigate("/manufacturer_create")} >
                  Create Manufacturer
                </Button>
              </Grid>
            </Grid>
            <Table columns={columns} rows={formattedManufacturerInfo} actionComponent={(row: Row[]) => actionComponent(row)} emptyText="No Manufacturer(s) Found" showMenu={true} disablePagination={true}  />
          </Card>
        </Box>
      </Box>

    </>
  )
}

export default ManufacturerList;