import { Box, Grid, Collapse, Popover, useTheme, useMediaQuery, Link, Menu, MenuItem, IconButton, ListItemIcon, Typography } from "@mui/material";
import Header from "../../common/Header";
import CustomTextField from "../../common/CustomTextField";
import {
  AssignmentReturnedOutlined,
  AttachMoney,
  CloudDownload,
  CreditCard,
  DownloadOutlined,
  EditNote,
  ListAltOutlined,
  ModeOutlined,
  Payment,
  SearchOutlined,
  TableViewOutlined,
  ViewWeekOutlined,
} from "@mui/icons-material";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import LegacyToolBar from "../../common/LegacyToolBar";
import {
  SearchInvoiceInfo,
  fetchInvoiceList,
  invoiceInfo,
  useInvoiceSlice,
} from "../../../redux/slice/invoice/InvoiceSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AgencyInfo,
  fetchAgenciesList,
  fetchManufacturersList,
  setSingleAlertObj,
  SingleAlertInfo,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  getPracticesByUserRole,
  usePracticeSlice,
} from "../../../redux/slice/practiceSlice";
import {
  selectIsTokenAvailable,
  selectIsValueFoundInToken,
  selectTokenValues,
  useAuthSlice,
} from "../../../redux/slice/authSlice";
import { CAN_ACCESS_APPLICATION, USER_ROLES, INVOICE_STATUS, INVOICE_SEARCH_FIELDS, EXPORTABLE_ITEMS, INVOICE_ACTION } from "../../../constants/applicationConstants";
import { UserRole, fetchAllUserRoles, fetchUserPreference, saveUserPreference, useUserReducer } from "../../../redux/slice/userSlice";
import ColumnToggle from "../../common/ColumnToggle";
import dayjs from "dayjs";
import CustomCheckbox from "../../common/CustomCheckBox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import UploadPaymentsPopup from "./UploadPaymentsPopup";
import DateRangePicker from "../../common/CustomDateRangePicker";
import { hideElement, priceFormatter, sortNames } from "../../../utils/Utility";
import CustomIconButton from "../../common/CustomIconButton";
import CustomDateRange from "../../common/CustomDateRange";
import { getSearchCriteriaFromSessionStorage, setSearchCriteriaToSessionStorage } from "../../../utils/StorageUtils";
import { DateObject } from "react-multi-date-picker";
import { tokens } from "../../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExportToExcel from "../../common/ExportToExcel";
import CustomDateRangePickerAll from "../../common/CustomDateRangePickerAll";
import CustomMonthYearPicker from "../../common/CustomMonthYearPicker";
import { format } from "date-fns";

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  padding?: string;
}

interface invoiceTableRow extends invoiceInfo {
  checkBox: JSX.Element | any;
}

const InvoiceList = () => {
  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<SearchInvoiceInfo>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const legacyItems = [{ icon: <ModeOutlined color="info" />, text: "Edit" }];
  const { manufacturerInfoList, agencyInfoList } = useCommonReducer();
  const { practiceByUserRole } = usePracticeSlice();
  const { userInfo } = useAuthSlice();
  const { invoiceList } = useInvoiceSlice();
  const [showCard, setShowCard] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [showUploadPaymentsPopup, setShowUploadsPaymentsPopup] =
    useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState<number | null>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  const [isDateRangeChanged, setIsDateRangeChanged] = useState(false);
  const [isClearDateRange, setIsClearDateRange] = useState(false);

  const ITEMS_PER_PAGE = 10;

  const canAccessUploadPayments = useSelector((state: RootState) => selectIsValueFoundInToken("canUploadPayments", INVOICE_STATUS.INVOICE)(state));
  const canAccessUploadCommisions = useSelector((state: RootState) => selectIsValueFoundInToken("canUploadPayments", INVOICE_STATUS.COMMISSION)(state));
  const canAccessCreateAGYPayout = useSelector((state: RootState) => selectIsValueFoundInToken("canCreatePayOut", INVOICE_STATUS.AGENCY)(state));
  const canAccessCreateOVRAGYPayout = useSelector((state: RootState) => selectIsValueFoundInToken("canCreatePayOut", INVOICE_STATUS.OVERRIDE)(state));
  const canAccessImportInvoices = useSelector((state: RootState) => selectIsTokenAvailable("canImportInvoices")(state));

  const restrictedInvoiceColumns = useSelector((state: RootState) => selectTokenValues("restrictedInvoiceColumns")(state));

  const canViewInvoiceNumber = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.INVOICE_NUMBER)(state));
  const canViewOrderNumber = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.ORDER_NUMBER)(state));
  const canViewInvoiceStatus = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.INVOICE_STATUS)(state));
  const canViewInvoiceDateRange = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.INVOICE_DATE_RANGE)(state));
  const canViewManufacture = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.MANUFACTURE)(state));
  const canViewPractice = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.PRACTICE)(state));
  const canViewAgency = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.AGENCY)(state));
  const canViewV3PayStatus = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.V3_PAY_STATUS)(state));
  const canViewAgyPayStatus = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.AGY_PAY_STATUS)(state));
  const canViewOvrAgyPayStatus = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.OVR_AGY_PAY_STATUS)(state));
  const canViewCommPeriod = useSelector((state: RootState) => selectIsValueFoundInToken("canViewInvoiceSearchFields", INVOICE_SEARCH_FIELDS.COMMISSION_PERIOD)(state));
  const canAccessRecordCreditMemoAction = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessInvoiceActions", INVOICE_ACTION.CREDIT_MEMO)(state));
  const canAccessAddPaymentAction = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessInvoiceActions", INVOICE_ACTION.ADD_PAYMENT)(state));
  const canAccessEditInvoiceAction = useSelector((state: RootState) => selectIsValueFoundInToken("canAccessInvoiceActions", INVOICE_ACTION.EDIT_INVOICE)(state));
  useEffect(() => {
    dispatch(fetchManufacturersList());
    fetchPracticeListByUserRole();
    dispatch(fetchAgenciesList());

    dispatch(fetchUserPreference(userInfo.userId)).then((res) => {
      const preferences = res.payload;

      const preferredColumns = preferences.find(
        (pref: { preferenceName: string }) => pref.preferenceName === "preferredInvoiceListColumns"
      );
      if (preferredColumns) {
        const hiddenColumnsArray = preferredColumns.preferenceValue.split(';');
        setHiddenColumns(hiddenColumnsArray);
      } else {
        if (userInfo.userRole === "ROLE_ADMIN") {
          const initialHiddenColumnsArray = [
            "agencyName",
            "agencyCommissionAmount",
            "agencyCommissionPaidAmount",
            "overrideAgencyName",
            "ovrAgencyCommissionAmount",
            "ovrAgencyCommissionPaidAmount",
          ];
          setHiddenColumns(initialHiddenColumnsArray);
        }
      }

    })
  }, []);

  useEffect(() => {
    const hiddenColumnsString = hiddenColumns.join(';');
    const data = {
      userId: userInfo?.userId,
      preferenceName: "preferredInvoiceListColumns",
      preferenceValue: hiddenColumnsString
    }
    dispatch(saveUserPreference(data))
  }, [hiddenColumns])

  useEffect(() => {
    // const invoiceNumber = location.state?.invoiceNumber;
    // if(invoiceNumber) {
    //  setShowCard(true)
    //  setValue("mfgInvoiceNumber", invoiceNumber);
    //  searchInvoiceList(1, {...getValues(), mfgInvoiceNumber:invoiceNumber})
    // }

    // CS-397 Start : Changing the logic for OrderNumber
    const orderNumber = location.state?.orderNumber;

    if (orderNumber) {
      setShowCard(true)
      setValue("orderNumber", orderNumber);

      const fromDateRange = location.state?.fromDate;
      const toDateRange = location.state?.toDate;

      searchInvoiceList(1, { ...getValues(), orderNumber: orderNumber, fromDate: fromDateRange || "", toDate: toDateRange || "" });
      if (fromDateRange === "" && toDateRange === "") {
        setIsClearDateRange(true)
      }

    }
    // CS-397 End
    else {
      let criteria = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT + "SearchCriteria" + userInfo.userId);
      if ((location.state && location.state.invoiceListSearchCriteria) || (location.state && location.state.withSearchHistory && criteria)) {
        if (location.state && location.state.withSearchHistory) {
          navigate("/invoice_list", { state: { invoiceListSearchCriteria: criteria } });
          return;
        }
        let searchCriteria: SearchInvoiceInfo = {
          fromDate: "",
          toDate: "",
          mfgInvoiceNumber: "",
          commPeriod: "",
          orderNumber: "",
          mfgIds: [],
          agencyIds: [],
          ovrAgencyIds: [],
          practiceIds: [],
          invoiceStatus: [],
          currentPage: 1,
          itemsPerPage: ITEMS_PER_PAGE,
          agyPayStatus: [],
          v3PayStatus: [],
          ovrAgyPayStatus: [],
          isDownload: false
        };
        if (location.state && location.state.invoiceListSearchCriteria) {
          searchCriteria = location.state.invoiceListSearchCriteria;
        } else {
          searchCriteria = criteria ? criteria : searchCriteria;
        }
        setValuesToCriteriaFields(searchCriteria);

        searchInvoiceList(searchCriteria.currentPage, searchCriteria);
      } else {
        const fromDateRange = getValues("fromDate");
        const toDateRange = getValues("toDate");

        if (fromDateRange && toDateRange) {
          searchInvoiceList(1, {
            ...getValues(), fromDate: fromDateRange || "",
            toDate: toDateRange || ""
          })
          setIsDateRangeChanged(false);
        } else {
          searchInvoiceList(1);
        }
      }
    }
  }, [location.state, practiceByUserRole, manufacturerInfoList]);


  const setValuesToCriteriaFields = (searchCriteria: SearchInvoiceInfo) => {
    setValue("mfgInvoiceNumber", searchCriteria.mfgInvoiceNumber);
    setValue("orderNumber", searchCriteria.orderNumber);
    setValue("mfgIds", searchCriteria.mfgIds);
    setValue("practiceIds", searchCriteria.practiceIds);
    setValue("invoiceStatus", searchCriteria.invoiceStatus);
    setValue("currentPage", searchCriteria.currentPage);
    setValue("itemsPerPage", searchCriteria.itemsPerPage);
    setValue("agyPayStatus", searchCriteria.agyPayStatus);
    setValue("agencyIds", searchCriteria.agencyIds);
    setValue("v3PayStatus", searchCriteria.v3PayStatus);
    setValue("ovrAgyPayStatus", searchCriteria.ovrAgyPayStatus);
    setValue("commPeriod", searchCriteria.commPeriod);
    setValue("comm", searchCriteria.comm);
  }

  const invoiceStatusOptions = [
    { label: "PAID", value: "PAID" },
    { label: "UNPAID", value: "UNPAID" }
  ];

  const invoicesStatusOptions = [
    { label: "PARTIAL", value: "PARTIAL" },
    { label: "PAID", value: "PAID" },
    { label: "UNPAID", value: "UNPAID" }
  ];

  const manufacturesList = useMemo(() => {
    let options: { label: string; value: any }[] = [];
    manufacturerInfoList.map((item) => {
      options.push({
        label: item.manufacturerName,
        value: item.manufacturerId,
      });
    });
    return options;
  }, [manufacturerInfoList]);

  const agenciesList = useMemo(() => {
    let options: { label: string; value: any }[] = [];
    if (agencyInfoList) {
      const sortedAgencies: AgencyInfo[] = sortNames(agencyInfoList, 'agencyName');
      sortedAgencies.map((item) => {
        options.push({
          label: item.agencyName,
          value: item.agencyId,
        });
      });
    }
    return options;
  }, [agencyInfoList]);
    const overAgencyListoptions = useMemo(() => {
      let options: { label: string; value: any }[] = [];
      agencyInfoList
        ?.filter(option => option.commissionType === 'Override')
        .map((option) => {
          options.push({
            label: option.agencyName,
            value: option.agencyId,
          });
        });
      return options;
    }, [agencyInfoList]);

  const practiceList = useMemo(() => {
    // CS - 442 START//
    // if (userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN) {
    //   let options: { label: string; value: any }[] = [];
    //   if (practiceByUserRole) {
    //     setValue("practiceIds", [Number(practiceByUserRole[0]?.practiceId)]);
    //     practiceByUserRole.map((item) => {
    //       options.push({ label: item.practiceName, value: item.practiceId });
    //     });
    //   }
    //   return options;
    // } else {
    let options: { label: string; value: any }[] = [
      // { label: "ALL", value: "" },
    ];
    //CS - 442 END 
    if (practiceByUserRole) {
      if (practiceByUserRole?.length > 1) {
        practiceByUserRole.map((item) => {
          options.push({ label: item.practiceName, value: item.practiceId });
        });
      } else {
        setValue("practiceIds", [Number(practiceByUserRole[0]?.practiceId)]);
        practiceByUserRole.map((item) => {
          options.push({ label: item.practiceName, value: item.practiceId });
        });
      }
    }
    return options;
    // }
  }, [practiceByUserRole]);

  const fetchPracticeListByUserRole = async () => {
    const response = await dispatch(fetchAllUserRoles());
    if (response?.meta?.requestStatus === "fulfilled") {
      if (response?.payload) {
        const useRoles = response.payload;
        const role = useRoles?.find(
          (role: UserRole) => role.roleName === userInfo.userRole
        );
        if (role) {
          dispatch(getPracticesByUserRole(false));
        }
      }
    }
  };

  

  const onSubmit = (data: SearchInvoiceInfo) => {
    // const commPeriod: string | undefined = getValues("commPeriod");
    // if (commPeriod && commPeriod !== undefined) {
    //   const [startDateStr, endDateStr] = commPeriod.split(" - ");
    //   if (!startDateStr || !endDateStr) {
    //     let errorObj: SingleAlertInfo = {
    //       message: "Invalid Comm Period date range. Please select start date and an end date.",
    //       alertType: "error",
    //     };
    //     dispatch(setSingleAlertObj(errorObj));
    //     return;
    //   }
    // }
    if (!isDateRangeChanged) {
      setIsClearDateRange(true);
    }
    const { checkBox, selectAllCheckBox, ...submitObj } = data;
    submitObj.currentPage = 1;
    submitObj.fromDate = isDateRangeChanged ? getValues("fromDate") : "";
    submitObj.toDate = isDateRangeChanged ? getValues("toDate") : "";

    navigate("/invoice_list", { state: { invoiceListSearchCriteria: submitObj } });
  };

  const handleColumnToggleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColumnToggleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const pagination = (pageNumber: number) => {
    let obj = getValues();
    obj.currentPage = pageNumber + 1;
    navigate("/invoice_list", { state: { invoiceListSearchCriteria: obj } });
    setValue('selectAllCheckBox', false);
    formattedInvoiceRows?.map(row => {
      //@ts-ignore
      setValue(`checkBox.invoice_${row.invoiceId}`, false);
    });
    setSelectedAgencyId(null);
  };

  const checkboxComponent = (row: invoiceInfo): JSX.Element => {


    

    return (
      <>
        <CustomCheckbox
          control={control}
          color="primary"
          controllerName={`checkBox.invoice_${row.invoiceId}`}
          onChange={(e) => handleCheckbox(e, row.agencyId)}
          isDisabled={selectedAgencyId ? selectedAgencyId !== row.agencyId : false}
          sx={{ p: 0, m: 0 }}
        />
      </>
    );
  };

  const handleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    agencyId: number
  ) => {
    const { checked } = e.target;
    if (!checked) {
      setValue("selectAllCheckBox", false);
      setSelectedAgencyId(null);
    } else {
      setSelectedAgencyId(agencyId);
    }
  };
  // CS-416 Invoice List UI Header modifications
  const columnsInvoice: Column[] = [
    { id: "checkBox", label: <CustomCheckbox control={control} controllerName="selectAllCheckBox" isDisabled={!selectedAgencyId} onChange={(e) => handleSelectAllCheckBox(e)} sx={{ p: 0, m: 0 }} />, minWidth: 1, align: "center" },
    { id: "mfgInvoiceNumber", label: "INV #", minWidth: 10, padding: "5px", align: "center" },
    { id: "mfgInvoiceDate", label: "INV Date", minWidth: 10, padding: "5px", align: "center" },
    { id: "invoiceDueDate", label: "INV Due Date", minWidth: 10, padding: "5px", align: "center" },
    { id: "invoiceLateDays", label: "INV Aging", minWidth: 10, padding: "5px", align: "center" },
    { id: "orderNumber", label: "V3 Order #", minWidth: 10, padding: "5px", align: "center" },
    { id: "manufacturerName", label: "MFR", minWidth: 10, padding: "5px", align: "center" },
    { id: "practiceName", label: "Practice", minWidth: 10, padding: "5px", align: "center" },
    // { id: "agencyName", label: "Agency ", minWidth: 10, padding: "5px", align: "center" },
    { id: "invoiceAmount", label: "INV Amt", minWidth: 10, padding: "5px", align: "center" },
    { id: "creditAmount", label: "Credit Amt", minWidth: 10, padding: "5px", align: "center" },
    {
      id: "v3InvoicePaidAmount",
      label: "INV Paid Amt",
      minWidth: 10,
      padding: "5px", align: "center"
    },
    { id: "invoiceStatus", label: "Status", minWidth: 10, padding: "5px", align: "center" },
    {
      id: "v3CommissionAmount",
      label: "V3 Comm",
      minWidth: 10,
      padding: "5px", align: "center"
    },
    { id: "v3CommissionPaidAmount", label: "V3 Pay Status", minWidth: 10, padding: "5px", align: "center" },
    // {
    //   id: "agencyCommissionAmount",
    //   label: "AGY Comm",
    //   minWidth: 10,
    //   padding: "5px", align: "center"
    // },
    // { id: "agencyCommissionPaidAmount", label: "AGY Pay Status", minWidth: 10, padding: "5px", align: "center" },
    // { id: "overrideAgencyName", label: "Override Agency", minWidth: 10, padding: "5px", align: "center" },
    // {
    //   id: "ovrAgencyCommissionAmount",
    //   label: "OVR Comm",
    //   minWidth: 10,
    //   padding: "5px", align: "center"
    // },
    // {
    //   id: "ovrAgencyCommissionPaidAmount",
    //   label: "OVR Pay Status",
    //   minWidth: 10,
    //   padding: "5px", align: "center"
    // },
    {
      id: "action",
      label: "Action",
      padding: "5px",
      minWidth: 10,
    },
  ];
  const actionComponent = (row: invoiceTableRow): JSX.Element => {

    const invoiceState = {
      invoiceId: row.invoiceId,
      mfgInvoiceNumber: row.mfgInvoiceNumber,
      invAmt: row.invoiceAmount,
      invDate: row?.mfgInvoiceDate,
      invDueDate: row?.invoiceDueDate,
      v3CommissionAmount: row.v3CommissionAmount,
      agencyCommissionAmount: row.agencyCommissionAmount,
      overrideAgencyAmount: row.ovrAgencyCommissionAmount,
      mfr: row.manufacturerName,
      practice: row.practiceName,
      agencyId: row.agencyId,
      ovrAgencyId:row.overrideAgencyId,
      invoiceStatus: row.invoiceStatus,
      creditAmount: row.creditAmount,
      v3InvoicePaidAmount: row.v3InvoicePaidAmount,
      practiceId: row.practiceId,
      v3CommissionAmounts: row.v3CommissionAmounts,
      agencyCommissionAmounts: row.agencyCommissionAmounts,
      ovrAgencyCommissionAmounts:row.ovrAgencyCommissionAmounts,
      productName: row.productName,
    };

    return (
      <>
        {/* task cs-647 start here */}

        <CustomIconButton
          color="info"
          tooltipMessage="Edit"
          tooltipPlacement="top"
          variant="outlined"
          size="small"
          style={{ display: `${hideElement(!canAccessEditInvoiceAction || row.invoiceStatus === 'PAID' )}`}}
          onClick={() => navigate("/edit_invoice", { state: invoiceState })}
          
        >
          <ModeOutlined fontSize="small" />
        </CustomIconButton>

        {/* task cs-647 End here */}

        <CustomIconButton
          color="info"
          tooltipMessage="Details"
          tooltipPlacement="top"
          variant="outlined"
          size="small"

          onClick={() =>
            navigate("/billing_detail", { state: { invoiceId: row.invoiceId } })
          }
        >
          <ListAltOutlined fontSize="small" />
        </CustomIconButton>

        <CustomIconButton
          color="warning"
          tooltipMessage="Record Credit Memo"
          tooltipPlacement="top"
          variant="outlined"
          size="small"
          style={{ display: `${hideElement(!canAccessRecordCreditMemoAction || row.invoiceStatus === 'PAID')}` }}
          onClick={() =>
            navigate("/record_credit_memo", { state: { invoiceId: row.invoiceId, invNumber: row.mfgInvoiceNumber, invAmt: row.invoiceAmount, invDate: row.mfgInvoiceDate, practice: row.practiceName, agency: row.agencyName, mfr: row.manufacturerName } })
          }
        >
          <CreditCard fontSize="small" />
        </CustomIconButton>
        <CustomIconButton
          color="secondary"
          tooltipMessage="Record Payment"
          tooltipPlacement="top"
          variant="outlined"
          size="small"
          style={{ display: `${hideElement(!canAccessAddPaymentAction || row.invoiceStatus === 'PAID')}` }}
          onClick={() =>
            navigate("/record_payment", { state: { invoiceId: row.invoiceId, invNumber: row.mfgInvoiceNumber, invAmt: row.invoiceAmount, invDate: row.mfgInvoiceDate, practice: row.practiceName, agency: row.agencyName, mfr: row.manufacturerName ,invoiceAmount:row.invoiceAmount ,creditAmount:row.creditAmount} })
          }
        >
          <AttachMoney fontSize="small" />
        </CustomIconButton>
      </>
    );
  };
  const displayCheckboxConditional = (row: any) => {
    if (row?.invoiceStatus === "UNPAID") {
      return "";
    } else if (row?.invoiceStatus === "PAID" && (row?.agencyCommissionAmount === row?.agencyCommissionPaidAmount) && (row?.ovrAgencyCommissionAmount === row?.ovrAgencyCommissionPaidAmount)) {
      return "";
    } else {
      return checkboxComponent(row);
    }
  }
  const DynamicAgingColumn = (data: any) => {
    if (data?.invoiceCountDays === 60 && data?.invoiceStatus !== "PAID") {
      // If invoiceLateDays is greater than 60 (green)
      if (data?.invoiceLateDays > 60) {
        return (
          <span style={{ color: "#5BE49B", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
      // Range: 31-59 (green)
      if (data?.invoiceLateDays >= 31 && data?.invoiceLateDays <= 60) {
        return (
          <span style={{ color: "#5BE49B", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
      // Range: 1-30 (yellow)
      if (data?.invoiceLateDays >= 1 && data?.invoiceLateDays <= 30) {
        return (
          <span style={{ color: "yellow", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
      // If invoiceLateDays <= 0 (red)
      if (data?.invoiceLateDays <= 0) {
        return (
          <span style={{ color: "#F44336", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
    } else if (data?.invoiceCountDays === 45 && data?.invoiceStatus !== "PAID") {
      if (data?.invoiceLateDays > 45) {
        return (
          <span style={{ color: "#5BE49B", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
      // Range: 16-45 (green)
      if (data?.invoiceLateDays >= 16 && data?.invoiceLateDays <= 45) {
        return (
          <span style={{ color: "#5BE49B", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
      // Range: 1-15 (yellow)
      if (data?.invoiceLateDays >= 1 && data?.invoiceLateDays <= 15) {
        return (
          <span style={{ color: "yellow", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
      // If invoiceLateDays <= 0 (red)
      if (data?.invoiceLateDays <= 0) {
        return (
          <span style={{ color: "#F44336", fontWeight: "bold" }}>
            {data?.invoiceLateDays}
          </span>
        );
      }
    }
    else {
      return (
        <span style={{ color: "white", fontWeight: "bold" }}>-</span>
      )
    }

  }
  const formattedInvoiceRows: invoiceTableRow[] | [] = useMemo(() => {
    let formatRows: invoiceTableRow[] = [];
    invoiceList?.content?.map((row) => {
      let formatRow: invoiceTableRow = {
        ...row,
        invoiceLateDays: DynamicAgingColumn(row),
        orderNumber: row.orderNumber !== null ? (
          <Link
            component="button"
            underline="hover"
            variant="body2"
            onClick={() => handleOrderListNavigate(row)}
            color={colors.info[300]}
          >
            {row.orderNumber}
          </Link>
        ) : (
          row.orderNumber || ''
        ),
        invoiceAmount: priceFormatter(Number(row.invoiceAmount)),
        creditAmount: priceFormatter(Number(row.creditAmount ? row.creditAmount : 0)),
        v3InvoicePaidAmount: priceFormatter(Number(row.v3InvoicePaidAmount)),
        v3CommissionAmount: priceFormatter(Number(row.v3CommissionAmount)),
        v3CommissionPaidAmount: priceFormatter(Number(row.v3CommissionPaidAmount)),
        agencyCommissionAmount: priceFormatter(
          Number(row.agencyCommissionAmount)
        ),
        agencyCommissionPaidAmount: priceFormatter(Number(row.agencyCommissionPaidAmount)),
        ovrAgencyCommissionAmount: priceFormatter(
          Number(row.ovrAgencyCommissionAmount)
        ),
        ovrAgencyCommissionPaidAmount: priceFormatter(Number(row.ovrAgencyCommissionPaidAmount)),
        checkBox: displayCheckboxConditional(row),
        // checkBox: row?.invoiceStatus !== "UNPAID" ? checkboxComponent(row) : "",
      };
      formatRows.push(formatRow);
    });
    return formatRows;
  }, [invoiceList, getValues()]);

  const formattedInvoiceColumns: Column[] | [] = useMemo(() => {
    let formatColumns: Column[] = [];

    // If user is an admin, return all columns
    if (userInfo?.userRole === USER_ROLES.ROLE_ADMIN) {
      return columnsInvoice;
    }

    if (Array.isArray(columnsInvoice)) {
      columnsInvoice.forEach((invoiceColumn) => {
        if (!restrictedInvoiceColumns.includes(invoiceColumn.id)) {
          formatColumns.push(invoiceColumn);
        }
      });
    }

    return formatColumns;
  }, [restrictedInvoiceColumns, columnsInvoice]);

  const handleSelectAllCheckBox = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    formattedInvoiceRows?.map(row => {
      if (selectedAgencyId === row.agencyId) {
        //@ts-ignore
        setValue(`checkBox.invoice_${row.invoiceId}`, checked ? true : false);
      }
    });

    if (!checked) {
      setSelectedAgencyId(null);
    }

  }, [formattedInvoiceRows, getValues('selectAllCheckBox')])

  const practiceIdsList = useMemo(() => {
    return (
      practiceByUserRole?.map((practice) => parseInt(practice.practiceId)) || []
    );
  }, [practiceByUserRole]);

  const mfgIdsList = useMemo(() => {
    return manufacturerInfoList?.map((mfg) => mfg.manufacturerId) || [];
  }, [manufacturerInfoList]);

  const agencyIdsList = useMemo(() => {
    return agencyInfoList?.map((agy) => parseInt(agy.agencyId)) || [];
  }, [agencyInfoList]);

  const ovrAgencyIdsList = useMemo(() => {
    return agencyInfoList
    ?.filter(option => option.commissionType === 'Override')
    ?.map((agy) => parseInt(agy.agencyId)) || [];
  }, [agencyInfoList]);

  const searchInvoiceList = useCallback(
    (currentPage: number, obj?: SearchInvoiceInfo) => {
      let data: SearchInvoiceInfo = {
        fromDate: obj?.fromDate,
        toDate: obj?.toDate,
        commPeriod: obj?.commPeriod,
        mfgIds: obj?.mfgIds
          ? obj.mfgIds
          : mfgIdsList?.length === 1
            ? mfgIdsList
            : [],
        agencyIds: obj?.agencyIds
          ? obj.agencyIds
          : agencyIdsList?.length === 1
            ? agencyIdsList
            : [],
        ovrAgencyIds: obj?.ovrAgencyIds
          ? obj.ovrAgencyIds
          : ovrAgencyIdsList?.length === 1
            ? ovrAgencyIdsList
            : [],
        practiceIds: obj?.practiceIds
          ? obj.practiceIds
          : practiceIdsList?.length === 1
            ? practiceIdsList
            : [],
        mfgInvoiceNumber: obj?.mfgInvoiceNumber ? obj.mfgInvoiceNumber : "",
        orderNumber: obj?.orderNumber ? obj.orderNumber : "",
        invoiceStatus: obj?.invoiceStatus ? obj.invoiceStatus : [],
        currentPage: currentPage,
        itemsPerPage: ITEMS_PER_PAGE,
        v3PayStatus: obj?.v3PayStatus
          ? obj.v3PayStatus : [],
        agyPayStatus: obj?.agyPayStatus
          ? obj.agyPayStatus : [],
        ovrAgyPayStatus: obj?.ovrAgyPayStatus
          ? obj.ovrAgyPayStatus : [],
        isDownload: false
      };
      setValue("currentPage", currentPage);
      setSearchCriteria(data);
      dispatch(fetchInvoiceList(data));
    },
    [practiceByUserRole, manufacturerInfoList]
  );

  const setSearchCriteria = useCallback((data: SearchInvoiceInfo) => {
    setSearchCriteriaToSessionStorage(CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT + "SearchCriteria" + userInfo.userId, data);
  }, []);

  const handleDateRangeChange = (fromDate: DateObject | null, toDate: DateObject | null) => {
    const fromDateString = fromDate ? fromDate.format("MM/DD/YYYY") : null;
    const toDateString = toDate ? toDate.format("MM/DD/YYYY") : null;

    setValue("fromDate", fromDateString ? fromDateString : "");
    setValue("toDate", toDateString ? toDateString : "");


    setIsDateRangeChanged(true);
  };

  const handleDateChange = (date: Date | null, endDate?: Date | null) => {
    if (date && endDate) {
      const formattedStart = format(date, "MMM yy");
      const formattedEnd = format(endDate, "MMM yy");

      setValue('commPeriod', `${formattedStart} - ${formattedEnd}`);
    } else if (date && !endDate) {
      const formattedDate = format(date, "MMM yy");
      setValue('commPeriod', formattedDate);
    }
  };

  const handleClearDateChange = () => {
    setValue('commPeriod', "");
  }

  const [uploadPopupInfo, setUploadPopupInfo] = useState({
    title: "",
    fileName: "",
    filePath: "",
  });

  const showPaymentPopup = (popupName: string) => {
    if (popupName === "Import Invoices") {
      setUploadPopupInfo({
        title: popupName,
        fileName: "V3Bio_Template_Import_Invoice_.xlsx",
        filePath: "/V3Bio_Template_Import_Invoice_.xlsx",
      });
    } else if (popupName === "Import Invoice Payments") {
      setUploadPopupInfo({
        title: popupName,
        fileName: "V3Bio_Template_Invoice_Payments.xlsx",
        filePath: "/V3Bio_Template_Invoice_Payments.xlsx",
      });
    } else {
      setUploadPopupInfo({
        title: popupName,
        fileName: "V3Bio_Template_Invoice_commission_Payments.xlsx",
        filePath: "/V3Bio_Template_Invoice_commission_Payments.xlsx",
      });
    }
    setShowUploadsPaymentsPopup(true);
  };

  // Function to export data to Excel
  const handleExport = () => {

    const searchCriteriaFromStorage = getSearchCriteriaFromSessionStorage(CAN_ACCESS_APPLICATION.INVOICE_MANAGEMENT + "SearchCriteria" + userInfo.userId);

    if (searchCriteriaFromStorage) {

      let initData: SearchInvoiceInfo = {
        fromDate: searchCriteriaFromStorage.fromDate || "",
        toDate: searchCriteriaFromStorage.toDate || "",
        mfgIds: searchCriteriaFromStorage.mfgIds || [],
        agencyIds: searchCriteriaFromStorage.agencyIds || [],
        ovrAgencyIds: searchCriteriaFromStorage.ovrAgencyIds || [],
        practiceIds: searchCriteriaFromStorage.practiceIds || [],
        mfgInvoiceNumber: searchCriteriaFromStorage.mfgInvoiceNumber || "",
        orderNumber: searchCriteriaFromStorage.orderNumber || "",
        invoiceStatus: searchCriteriaFromStorage.invoiceStatus || [],
        currentPage: getValues("currentPage"),
        itemsPerPage: ITEMS_PER_PAGE,
        v3PayStatus: searchCriteriaFromStorage.v3PayStatus || [],
        agyPayStatus: searchCriteriaFromStorage.agyPayStatus || [],
        ovrAgyPayStatus: searchCriteriaFromStorage.ovrAgyPayStatus || [],
        isDownload: true
      };

      ExportToExcel(initData, formattedInvoiceColumns, dispatch, EXPORTABLE_ITEMS.INVOICE, hiddenColumns);

    }
  }
  const selectedInvList = () => {
    const checkedList: any = getValues('checkBox');
    const checkedIdList: number[] = Object.entries(
      checkedList
    )
      .filter(([invoiceIdString, isChecked]) => isChecked)
      .map(([invoiceIdString]) => parseInt(invoiceIdString.split("_")[1]));

    const selectedRows: invoiceInfo[] = []
    checkedIdList.forEach((invId: number) => {
      const invoice = invoiceList?.content?.find(inv => inv.invoiceId === invId);
      if (invoice) {
        selectedRows.push(invoice)
      }
    });
    return selectedRows;
  }

  const navigateRecordPayout = () => {
    const selectedRows: invoiceInfo[] = selectedInvList();
    if (selectedRows?.length > 0) {
      navigate("/recordPayout", { state: { selectedInvoices: selectedRows } });
    } else {
      let errObj: SingleAlertInfo = {
        message: "Please select at least one invoice.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj));
    }
  };

  const navigateAgyRecordPayout = () => {
    const selectedRows: invoiceInfo[] = selectedInvList();
    const isOvrAgenciesFound = selectedRows.every(row => row.overrideAgencyId);
    if (!selectedRows || selectedRows?.length == 0) {
      let errObj: SingleAlertInfo = {
        message: "Please select at least one invoice.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj));
    } else if (!isOvrAgenciesFound) {
      let errObj: SingleAlertInfo = {
        message: "Please select invoice/s with override agencies.",
        alertType: "error"
      };
      dispatch(setSingleAlertObj(errObj));
    } else {
      navigate("/overrideRecordPayout", { state: { selectedInvoices: selectedRows } });
    };
  };

  const handleOrderListNavigate = (row: any) => {
    if (row?.orderNumber !== null) {
      navigate("/order_list", { state: { orderNumber: row.orderNumber } });
    };
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  return (
    <>
      <Box sx={{
        margin: {
          xs: "20px 10px",
          sm: "20px",
        },
      }}>
        <Header title="Invoice List" subtitle="Invoice Management" />
        {showUploadPaymentsPopup && (
          <UploadPaymentsPopup
            open={showUploadPaymentsPopup}
            title={uploadPopupInfo.title}
            downloadFileName={uploadPopupInfo.fileName}
            downloadFilePath={uploadPopupInfo.filePath}
            onClose={() => {
              setShowUploadsPaymentsPopup(false);
              pagination(0);
            }}
          />
        )}
        <Box>
          <Collapse in={showCard}>
            <Card sx={{ mb: 2 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  {
                    canViewOrderNumber &&
                    <Grid item md={2} xs={12}>
                      <CustomTextField
                        controllerName="orderNumber"
                        control={control}
                        name="orderNumber"
                        label="Order Number"
                      />
                    </Grid>
                  }
                  {
                    canViewInvoiceNumber &&
                    <Grid item md={2} xs={12}>
                      <CustomTextField
                        controllerName="mfgInvoiceNumber"
                        control={control}
                        name="mfgInvoiceNumber"
                        label="Invoice Number"
                      />
                    </Grid>
                  }
                  {
                    canViewInvoiceStatus &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="invoiceStatus"
                        label="Invoice Status"
                        options={invoicesStatusOptions}
                      />
                    </Grid>
                  }
                  {
                    canViewCommPeriod &&
                    <Grid item md={2} xs={12}>
                      <CustomMonthYearPicker
                        label="Pay Period"
                        control={control}
                        controllerName="comm"
                        name="comm"
                        onDateChange={handleDateChange}
                        // isRange={true}
                        onClear={handleClearDateChange}
                      />
                    </Grid>
                  }
                  {
                    canViewInvoiceDateRange &&
                    <Grid item md={3} xs={12}>
                      <CustomDateRange
                        label="Invoice Date Range"
                        control={control}
                        controllerName="dateRange"
                        onChange={handleDateRangeChange}
                        dateRangeMonths={6}
                        onClear={isClearDateRange}
                      />
                    </Grid>
                  }
                  {
                    canViewManufacture &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="mfgIds"
                        label="Manufacture"
                        options={manufacturesList}
                      />
                    </Grid>
                  }
                  {
                    canViewPractice &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="practiceIds"
                        label="Practice"
                        options={practiceList}
                      // CS - 442 Changes START//
                      // disabled={
                      //   userInfo.userRole === USER_ROLES.ROLE_PRACTICEADMIN
                      // }                       
                      // CS - 442 Changes END//

                      />
                    </Grid>
                  }
                  {
                    canViewAgency &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="agencyIds"
                        label="Agency"
                        options={agenciesList}
                      />
                    </Grid>
                  }
                  {
                    canViewAgency &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="ovrAgencyIds"
                        label="Override Agency"
                        options={overAgencyListoptions}
                      />
                    </Grid>
                  }
                  {
                    canViewV3PayStatus &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="v3PayStatus"
                        label="V3 Pay Status"
                        options={invoiceStatusOptions}
                      />
                    </Grid>
                  }
                  {
                    canViewAgyPayStatus &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="agyPayStatus"
                        label="AGY Pay Status"
                        options={invoiceStatusOptions}
                      />
                    </Grid>
                  }
                  {
                    canViewOvrAgyPayStatus &&
                    <Grid item md={3} xs={12}>
                      <CustomMultipleDropDown
                        control={control}
                        name="ovrAgyPayStatus"
                        label="OVR AGY Pay Status"
                        options={invoiceStatusOptions}
                      />
                    </Grid>
                  }
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      size="large"
                      color="success"
                      startIcon={<SearchOutlined />}
                      style={{
                        padding: "15px",
                      }}
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Card>
          </Collapse>
        </Box>
        <Box>
          <Card>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} md="auto">
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => setShowCard(!showCard)}
                  startIcon={<SearchOutlined />}
                  fullWidth
                >
                  {showCard ? "Hide Filters" : "Show Filters"}
                </Button>
              </Grid>
              {canAccessImportInvoices ?
                <Grid item xs={12} md="auto">
                  <Button
                    color="info"
                    startIcon={<AssignmentReturnedOutlined />}
                    onClick={() => showPaymentPopup("Import Invoices")}
                    fullWidth
                  >
                    Import Invoices
                  </Button>
                </Grid>
                : ""}
              {canAccessUploadPayments ?
                <Grid item xs={12} md="auto">
                  <Button
                    color="warning"
                    // startIcon={<CloudUpload />}
                    startIcon={<AssignmentReturnedOutlined />}
                    onClick={() => showPaymentPopup("Import Invoice Payments")}
                    fullWidth
                  >
                    Import INV Pmts
                  </Button>
                </Grid>
                : ""}
              {canAccessUploadCommisions ?
                <Grid item xs={12} md="auto">
                  <Button
                    color="info"
                    // startIcon={<CloudUpload />}
                    startIcon={<AssignmentReturnedOutlined />}
                    onClick={() => showPaymentPopup("Import Manufacturer Payments")}
                    fullWidth
                  >
                    Import MFR Pmts
                  </Button>
                </Grid>
                : ""}
              {canAccessCreateAGYPayout ?
                <Grid item xs={12} md="auto">
                  <Button
                    color="error"
                    startIcon={<EditNote />}
                    onClick={() => navigateRecordPayout()}
                    fullWidth
                  >
                    CREATE AGY PMTS
                  </Button>
                </Grid>
                : ""}
              {canAccessCreateOVRAGYPayout ?
                <Grid item xs={12} md="auto">
                  <Button
                    color="secondary"
                    startIcon={<EditNote />}
                    onClick={() => navigateAgyRecordPayout()}
                    fullWidth
                  >
                    CREATE OVR PMTS
                  </Button>
                </Grid>
                : ""}
              <Grid item xs={12} md="auto">
                <CustomIconButton
                  onClick={handleColumnToggleClick}
                  color="warning"
                  variant="contained"
                  tooltipMessage="Toggle Columns"
                  size="small"
                >
                  <TableViewOutlined />
                </CustomIconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleColumnToggleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ColumnToggle
                    columns={formattedInvoiceColumns}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                  />
                </Popover>
                {isMobile ?
                  (<CustomIconButton
                    color="success"
                    size="small"
                    variant="outlined"
                    tooltipMessage="Download List"
                    tooltipPlacement="top"
                    onClick={(event) => handleMenuClick(event)}
                  >
                    <DownloadOutlined fontSize="small" />
                  </CustomIconButton>)
                  :
                  (<IconButton onClick={(event) => handleMenuClick(event)}>
                    <MoreVertIcon />
                  </IconButton>)
                }
                <Box>
                  <Menu
                    anchorEl={anchorElMenu}
                    open={Boolean(anchorElMenu)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleExport()}>
                      <ListItemIcon>
                        <CloudDownload />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        Download Search List
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            </Grid>
            <Table
              columns={formattedInvoiceColumns}
              rows={formattedInvoiceRows || []}
              emptyText="No Invoice(s) Found"
              totalPages={invoiceList?.totalPages}
              pageNumber={pagination}
              hiddenColumns={hiddenColumns}
              actionComponent={(row: invoiceTableRow) => actionComponent(row)}
              totalElementText={"Records: " + (invoiceList?.totalElements ? invoiceList?.totalElements : "0")}
              currentPageNumber={getValues("currentPage") ? getValues("currentPage") - 1 : 1}
              showMenu={true}
              sortableColumns={["mfgInvoiceNumber","mfgInvoiceDate","invoiceDueDate"]}
            />
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default InvoiceList;
