import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "./../redux-hooks";
import { GeneralV3Response, LoadingType } from "../../../types/CommonTypes";
import { fetchPracticeById, editPractice, fetchPracticesByIds } from "../../../service/PracticeService";
import { PracticeInfo } from "./practiceRegistrationSlice";
import { PracticeLocationInfo } from "./practiceLocationSlice";
import { PracticeProviderInfo } from "./practiceProvidersSlice";

export type PracticeEditInfo = {
  practiceId: string;
  practiceName: string;
  contactFirstName: string;
  contactLastName: string;
  contactMiddleName: string;
  contactEMail: string;
  contactPhone: string;
  contactFax: string;
  apFirstName: string;
  apMiddleName: string;
  apLastName: string;
  apPhone: string;
  apEmail: string;
  ptan: string;
  npi: string;
  taxId: string;
  notificationEmails: string;
};

type PracticeEditState = {
  practiceEditInfo?: PracticeInfo | null;
  multiplePracticesInfo?: PracticeInfo[];
  editPracticeDataFetchStatus?: LoadingType
  multiplePracticesInfoFetchStatus?: LoadingType
};

const initialState: PracticeEditState = {
  practiceEditInfo: undefined,
  multiplePracticesInfo: [],
  editPracticeDataFetchStatus: "idle",
  multiplePracticesInfoFetchStatus: "idle"
};

export const editPracticeData = createAsyncThunk("editPracticeData", async (data: PracticeEditInfo) => {
  const response = await editPractice(data);
  return response.data;
});

export const fetchPracticeByIdData = createAsyncThunk("fetch_practice", async( practiceId: string ) => {
    const response = await fetchPracticeById(practiceId);
    return response.data;
  })

  export const fetchPracticesByIdsData = createAsyncThunk("fetch_practices_by_ids", async( practiceIds: number[] ) => {
    const response = await fetchPracticesByIds(practiceIds);
    return response.data;
  });
  
const practiceEditSlice = createSlice({
  name: "practiceEdit",
  initialState,
  reducers: {
    updatePracticeEditInfoObject: (state: PracticeEditState, action) => {
      state.practiceEditInfo = action.payload;
    },
    resetPracticeEditInfo: (state) =>{
      state.practiceEditInfo = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPracticeData.pending, (state) => {
        state.editPracticeDataFetchStatus = "loading";
      })
      .addCase(editPracticeData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.editPracticeDataFetchStatus = "success";
        // state.practiceEditInfo = action.payload.data;
      })
      .addCase(editPracticeData.rejected, (state, action) => {
        state.editPracticeDataFetchStatus = 'error';
      })
      .addCase(fetchPracticeByIdData.pending, (state) => {
        state.editPracticeDataFetchStatus = "loading";
      })
      .addCase(fetchPracticeByIdData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.editPracticeDataFetchStatus = "success";
        state.practiceEditInfo = action.payload.data;
      })
      .addCase(fetchPracticeByIdData.rejected, (state, action) => {
        state.editPracticeDataFetchStatus = 'error';
      })
      .addCase(fetchPracticesByIdsData.pending, (state) => {
        state.multiplePracticesInfoFetchStatus = "loading";
      })
      .addCase(fetchPracticesByIdsData.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.multiplePracticesInfoFetchStatus = "success";
        state.multiplePracticesInfo = action.payload.data;
      })
      .addCase(fetchPracticesByIdsData.rejected, (state, action) => {
        state.multiplePracticesInfoFetchStatus = 'error';
      });
  },
});

export const { updatePracticeEditInfoObject, resetPracticeEditInfo } = practiceEditSlice.actions;
export const usePracticeEditSlice = () => useAppSelector((state) => state.practiceEditSlice);
export default practiceEditSlice.reducer;