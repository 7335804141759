import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

const TechSupportButton: React.FC = () => {
  const [hovered, setHovered] = useState(false);

  const handleButtonClick = () => {
    window.open(
      "https://v3biomedical.clickup.com/forms/9014004456/f/8cmdjq8-46514/FOI039EIJVCMV8FLMR",
      "_blank"
    );
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 10,
        right: 10,
        zIndex: 1300,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={handleButtonClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: hovered ? "#5748e1" : "rgba(87, 72, 225, 0.7)",
          borderRadius: "50px",
          height: "45px",
          width: hovered ? "140px" : "45px",
          padding: "0 8px",
          transition: "all 0.3s ease-in-out",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%", 
          }}
        >
          <Box
            component="img"
            src={require("../../assets/chat.png")}
            sx={{
              width: "20px",
              height: "20px",
              transition: "all 0.3s ease-in-out",
            }}
          />
          {hovered && (
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                whiteSpace: "nowrap",
                marginLeft: "8px", 
                opacity: 1,
                transition: "opacity 0.3s ease-in-out 0.1s",
                color : "white"
              }}
            >
              Tech Support
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TechSupportButton;
