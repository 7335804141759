import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { http } from "../../service/http";
import { CREATE_PRACTICE_URL, FETCH_PRACTICES_URL, SAVE_PRACTICE_URL, FETCH_WOUNDCAREINFO_URL, FETCH_PRACTICE_URL, EDIT_PRACTICE_URL, APPROVE_PRACTICE_REQUEST_URL, FETCH_MACS_URL, FETCH_ACTIVE_AGENCY_EXECUTIVES_URL, FETCH_PRODUCT_WITH_PRICE_URL, SEARCH_PRACTICES_URL, GET_DOCUMENT_URL, FETCH_PRACTICE_BY_USER_ROLE, GET_PRACTICE_PRODUCT_BY_PRODUCTID, GET_TEMPLATE_BY_ID_URL, GET_PRACTICE_PRODUCT_AGREEMENT_BY_PRACTICE_PRODUCT_URL  } from "../../service/URL"
import { useAppSelector } from "./redux-hooks";
import { string } from "yup";
import { LoadingType } from "../../types/CommonTypes";
import { fetchAgreementByProductIdAndPracticeId, fetchPracticesByUserRole, fetchUpdatedPracticeRegistrationByPracticeId } from "../../service/PracticeService";

export type PracticeInfo = {
  practiceId: string;
  practiceName: string;
  contactFirstName: string;
  approvalStatus:string;
  contactLastName: string;
  contactMiddleName: string;
  contactEMail: string;
  contactPhone: string;
  contactFax: string;
  apFirstName: string;
  apMiddleName: string;
  apLastName: string;
  apPhone: string;
  apEmail: string;
  ptan: string;
  npi: string;
  taxId: string;
  billingType: string;
  notificationEmails: string;
  agencyId: string;
  agencyName: string;
  accountPayableNotApplicable: boolean;
  invitationCode: string;
  practiceRequestInfo : {
    billerFirstName: string;
    billerMiddleName: string;
    billerLastName: string;
    billerContactPhone: string;
    billerContactEmail: string;
    billerStreetAddress: string;
    billerUnitNumber: string;
    billerCity: string;
    billerState: string;
    billerZipCode: string;
    billerNotApplicable: boolean;
    currentlyUsedProducts:string;
    anyQuestions:string;
    woundCareFocus: '',
    averageWoundsTreatedPerMonth: string;
    placesOfService: [string];
    insurancePayer1: string;
    insurancePayer2: string;
    insurancePayer3: string;
  }
  practiceLocations: PracticeLocationInfo[];
  practiceProviders: PracticeProviderInfo[];
  executivesNames: String[];
  baaDetailId: number

};

export type PracticeCreateInfo = {
  contactFirstName : string,
  contactMiddleName: string,
  contactLastName: string,
  contactEMail: string,
}

 type PracticeRegistrationInfo = {
  woundCareFocus: string,
  placeOfService: string,
  productOfInterest: string
 }

 export type MacInfo = {
  macId: number,
  macName: string
 }

 export type AgencyExecutives = {
    userId: number;
    agencyId: number;
    agencyName: string
    firstaName: string;
    lastName: string;
    middleName: string;
 }

 export type PracticeLocationInfo = {
  locationId: number;
  locationType: string;
  streetAddress: string;
  unitNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export type PracticeProviderInfo = {
  providerId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  npi: string;
  ptan: string;
  createdDate: Date;
  inactiveDate: Date;
  title:string;
}

 export type ApprovePracticeRequestInfo = {
  practiceId: string;
  practiceExecutive: AgencyExecutives;
  practiceMAC: MacInfo[];
  notificationEmails: string;
  approvalComments: string;
 };

export type SearchPracticeRequest = {
  practiceId: number | null,
  contactFirstName: string,
  contactLastName: string,
  contactEmail: string,
  contactPhone: string,
  approvalStatus: string,
  agencyId: string,
  userId: string,
  currentPage: number;
  itemsPerPage: number;
};

export type DocumentInfo = {
  documentName : string;
  contentPath : string;
  type : any;
  fileType : string; 
  content : string;
}

export type PracticeExecutive = {
  userId: number;
  agencyId: number;
}

interface PaginationPracticeList {
  content: PracticeInfo[];
  totalElements: number;
  totalPages: number;
  size: number;
}

type PracticeState = {
    practiceInfo?: PaginationPracticeList | null;
    practiceByUserRole?: PracticeInfo[] | null;
    practiceCreateInfo?: PracticeCreateInfo | null;
    PracticeSaveInfo?: PracticeInfo;
    practiceRegistrationInfo?: PracticeRegistrationInfo[] | null;
    woundCareFocus?: string[];
    placeOfService?: string[];
    productOfInterest?: string[];
    macInfo?: MacInfo[] | null;
    agencyExecutives?: AgencyExecutives[] | null;
    document?: DocumentInfo | null;
    documentByte?: string | null;
    status: string;
    getPracticesByIdStatus: LoadingType;
    error: string | null;
    isValid: true | false;
  };

const initialState: PracticeState = {
    practiceInfo: undefined,
    macInfo: undefined,
    status: "idle",
    getPracticesByIdStatus: "idle",
    error: null,
    isValid: false,
  };

export const createPractice = createAsyncThunk("practice", async(data:PracticeCreateInfo) => {
 const response = await http.post(CREATE_PRACTICE_URL, data);
  return response.data;;
});

export const approvePracticeRequest = createAsyncThunk("ApprovePracticeRequest", async(data: any) => {
  const response = await http.post(APPROVE_PRACTICE_REQUEST_URL, data);
  return response.data;
});  

export const searchPracticeList = createAsyncThunk("search_practice_list", async(data:any) => {
  const response = await http.post(SEARCH_PRACTICES_URL, data);
  const v3Response = response.data;
  const resData = v3Response.data;
  return resData;
})

export const fetchPracticeById = createAsyncThunk("fetch_practice", async( practiceId: string ) => {
  const response = await http.get(`${FETCH_PRACTICE_URL}?practiceId=${practiceId}`);
  const resData = response.data;
  return resData;
})

export const savePractice = createAsyncThunk("savePractice", async(data:PracticeInfo & { invitationCode: string }) => {
  const { invitationCode, ...requestData } = data;
  const response = await http.put(`${SAVE_PRACTICE_URL}/${invitationCode}`, requestData);
        return response.data;
 });

 export const editPractice = createAsyncThunk("editPractice", async(data:PracticeInfo & { id: string }) => {
  const { id, ...requestData } = data;
  const response = await http.put(`${EDIT_PRACTICE_URL}/${id}`, requestData);
        return response.data;;
 });

export const fetchWoundCareInfo = createAsyncThunk("woundCareFocus", async() => {
  const response = await http.get(FETCH_WOUNDCAREINFO_URL);
  const v3Response = response.data;
  return v3Response.data;
})

export const fetchMac = createAsyncThunk("MAC", async() => {
  const response = await http.get(FETCH_MACS_URL);
  const v3Response = response.data;
  return v3Response.data;
});

export const fetchActiveAgencyExecutives = createAsyncThunk("activeAgencyExecutives", async() => {
  const response = await http.get(FETCH_ACTIVE_AGENCY_EXECUTIVES_URL);
  const v3Response = response.data;
  return v3Response.data;
})

export const fetchDocumentById = createAsyncThunk('pdfDocument',async( documentId: string ) => {
    const response = await http.get(`${GET_DOCUMENT_URL}/${documentId}`);
    const v3Response = response.data;
    return v3Response.data;
  }
);

export const fetchTemplateById = createAsyncThunk('pdfTemplate', async (templateId: string) => {
  const response = await http.get(`${GET_TEMPLATE_BY_ID_URL}/${templateId}`);
  const v3Response = response.data;
  return v3Response.data;
}
);

export const getPracticesByUserRole = createAsyncThunk('getPracticesByUserRole', async ( isActivePracticeOnly?: boolean ) => {
  const response = await fetchPracticesByUserRole(isActivePracticeOnly);
  const v3Response = response.data;
  return v3Response.data;
});

export const getPracticeProductById = createAsyncThunk("getPracticeProductById", async(practiceProductId: string | null) => {
  const response = await http.get(`${GET_PRACTICE_PRODUCT_BY_PRODUCTID}?productId=${practiceProductId}`);
  const v3Response = response.data;
  return v3Response.data;
});

export const getPracticeProductByPracticeProductId = createAsyncThunk("getPracticeProductByPracticeProductId", async({ practiceProductId, productItemId }: { practiceProductId: string | null; productItemId: string | null }) => {
  const response = await http.get(`${GET_PRACTICE_PRODUCT_AGREEMENT_BY_PRACTICE_PRODUCT_URL}?practiceProductId=${practiceProductId}&productItemId=${productItemId}`);
  const v3Response = response.data;
  return v3Response.data;
});

export const getUpdatedPracticeRegistrationByPracticeId = createAsyncThunk("getUpdatedPracticeRegistrationByPracticeId", async(data: any) => {
  const response = await fetchUpdatedPracticeRegistrationByPracticeId(data.practiceId, data.updatePracticeAgreement);
  const resData = response.data;
  return resData;
});

export const getAgreementByProductIdAndPracticeId = createAsyncThunk("getAgreementByProductIdAndPracticeId", async(data:any) => {
  const response = await fetchAgreementByProductIdAndPracticeId(data.practiceId, data.productId);
  const v3Response = response.data;
  return v3Response.data;
});


const practiceSlice = createSlice({
    name: "practice",
    initialState,
    reducers: {
      updatePracticeInfoObject: (state: PracticeState, action) => {
        state.PracticeSaveInfo = action.payload;
      },
      resetPracticeByUserRole : (state) =>{
        state.practiceByUserRole = undefined;
        state.getPracticesByIdStatus = "idle";
      },
      resetPracticeInfo : (state) =>{
        state.practiceInfo = null;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(createPractice.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(createPractice.fulfilled, (state, action: PayloadAction<string>) => {
          state.status = "success";
          state.isValid = true;
          state.status = action.payload;
        })
        .addCase(createPractice.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Practice creation failed";
        })
        .addCase(approvePracticeRequest.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(approvePracticeRequest.fulfilled, (state, action) => {
          state.status ="success"
          state.isValid = true;
          state.status = action.payload;
        })
        .addCase(approvePracticeRequest.rejected, (state,action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Approve failed";
        })
        .addCase(searchPracticeList.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(searchPracticeList.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
          state.practiceInfo = action.payload;
        })
        .addCase(searchPracticeList.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Failed to fetch practices";
        })
        .addCase(fetchPracticeById.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(fetchPracticeById.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
          state.PracticeSaveInfo = action.payload.data;
        })
        .addCase(fetchPracticeById.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Failed to fetch practices";
        })
        .addCase(savePractice.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(savePractice.fulfilled, (state, action: PayloadAction<string>) => {
          state.status = "success";
          state.isValid = true;
          state.status = action.payload;
        })
        .addCase(savePractice.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Practice save failed";
        })
        .addCase(editPractice.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(editPractice.fulfilled, (state, action: PayloadAction<string>) => {
          state.status = "success";
          state.isValid = true;
          state.status = action.payload;
        })
        .addCase(editPractice.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Practice save failed";
        })
        .addCase(fetchWoundCareInfo.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(fetchWoundCareInfo.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
          state.woundCareFocus = action.payload.data.woundCareFocus;
          state.placeOfService = action.payload.data.placeOfService;
          state.productOfInterest = action.payload.data.productOfInterest;
        })
        .addCase(fetchWoundCareInfo.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Failed to fetch wound care focus";
        })
        .addCase(fetchMac.pending, (state) =>{
          state.status = "loading";
          state.error = null;
        })
        .addCase(fetchMac.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
          state.macInfo = action.payload;
        })
        .addCase(fetchMac.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Failed to fetch MAC";
        })
        .addCase(fetchActiveAgencyExecutives.pending, (state) =>{
          state.status = "loading";
          state.error = null;
        })
        .addCase(fetchActiveAgencyExecutives.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
          state.agencyExecutives = action.payload;
        })
        .addCase(fetchActiveAgencyExecutives.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Failed to fetch Active Account Representatives";
        })
        .addCase(fetchDocumentById.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(fetchDocumentById.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.document = action.payload;
        })
        .addCase(fetchDocumentById.rejected, (state, action) => {
          state.status = 'failed';
          state.isValid = false;
          state.error = action.error.message || "Failed to Preview Document";
        })
        .addCase(fetchTemplateById.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(fetchTemplateById.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.document = action.payload;
        })
        .addCase(fetchTemplateById.rejected, (state, action) => {
          state.status = 'failed';
          state.isValid = false;
          state.error = action.error.message || "Failed to Preview Document";
        })
        .addCase(getPracticesByUserRole.pending, (state, action) => {
          state.getPracticesByIdStatus = 'loading';
          state.error = null;
        })
        .addCase(getPracticesByUserRole.fulfilled, (state, action) => {
          state.getPracticesByIdStatus = 'success';
          state.practiceByUserRole = action.payload;
        })
        .addCase(getPracticesByUserRole.rejected, (state, action) => {
          state.getPracticesByIdStatus = 'error';
          state.isValid = false;
          state.error = action.error.message || "Failed to load practices";
        })
        .addCase(getPracticeProductById.pending, (state, action) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getPracticeProductById.fulfilled, (state, action) => {
          state.status = 'fulfilled';
        })
        .addCase(getPracticeProductById.rejected, (state, action) => {
          state.status = 'failed';
          state.isValid = false;
          state.error = action.error.message || "Failed to get practice product";
        })
        .addCase(getPracticeProductByPracticeProductId.pending, (state, action) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(getPracticeProductByPracticeProductId.fulfilled, (state, action) => {
          state.status = 'fulfilled';
        })
        .addCase(getPracticeProductByPracticeProductId.rejected, (state, action) => {
          state.status = 'failed';
          state.isValid = false;
          state.error = action.error.message || "Failed to get practice product";
        })
        .addCase(getUpdatedPracticeRegistrationByPracticeId.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(getUpdatedPracticeRegistrationByPracticeId.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
          state.document = action.payload.data;
        })
        .addCase(getUpdatedPracticeRegistrationByPracticeId.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
          state.error = action.error.message || "Failed to fetch updated practice registration";
        })
        .addCase(getAgreementByProductIdAndPracticeId.pending, (state) => {
          state.status = "loading";
          state.error = null;
        })
        .addCase(getAgreementByProductIdAndPracticeId.fulfilled, (state, action) => {
          state.status = "success";
          state.isValid = true;
        })
        .addCase(getAgreementByProductIdAndPracticeId.rejected, (state, action) => {
          state.status = "failed";
          state.isValid = false;
        });
    },
  });

  export const {resetPracticeByUserRole, updatePracticeInfoObject, resetPracticeInfo } = practiceSlice.actions;
  export const usePracticeSlice = () => useAppSelector((state) => state.practiceSlice);
export default practiceSlice.reducer;