import { InactivateProductDocumentInfo, ProductDocument, ProductInfo, ProductItemInfo, ProductItemPriceInfo, ProductItemPriceRequest, ProductItemsPriceInfo, UpdateProductDocumentStatusRequest } from "../redux/slice/product/createProductSlice";
import { ProductPriceByProductRequest, searchParamsInfo, searchParamsProdcutsList } from "../redux/slice/product/productListSlice";
import { PracticeProductInfo, SearchPracticeProductAgreementRequest, UploadSignedAgreementInfo, inactivateInfo } from "../redux/slice/productAgreement/createAgreementSlice";
import { InterceptorOption } from "../types/CommonTypes";
import {
    CREATE_PRODUCT_AGREEMENT, CREATE_PRODUCT_URL, DELETE_PRODUCT_AGREEMENT_BY_ID_URL, DELETE_PRODUCT_URL,
    FETCH_PRODUCTLIST_URL, FETCH_PRODUCTS_AGREEMENTS, FETCH_PRODUCTS_BY_USER_ROLE_URL, FETCH_PRODUCTS_PRICE_BY_MAC,
    FETCH_PRODUCT_DETAILS_BY_ID, FETCH_PRODUCT_WITH_PRICE_URL, GET_PRODUCT_ITEM_HISTORY_URL, GET_PRODUCT_LIST_WITH_LOGO_URL,
    GET_PRODUCT_PRICE_ITEM_HISTORY_URL, INACTIVATE_PRODUCT_AGREEMENT_BY_ID_URL, INACTIVE_PRODUCT_ITEM_URL, INACTIVE_PRODUCT_PRICE_ITEM_URL,
    MANUFACTURE_SIGN_PRODUCT_AGREEMENT, PRACTICE_SIGN_PRODUCT_AGREEMENT, SAVE_PRODUCT_ITEM_URL, SAVE_PRODUCT_LOGO_URL,
    SAVE_PRODUCT_PRICE_ITEM_URL, UPDATE_PRODUCT, DELETE_PRODUCT_PRICE_ITEM_URL,
    PREVIEW_PRACTICE_PRODUCT_AGREEMENT,
    INITIATE_PRODUCT_AGREEMENT_URL,
    GENERATE_NEW_AGREEMENTS_FOR_EXPIRED_PRODUCT_PRICE,
    GET_AGREEMENT_COUNT_FOR_EXPIRED_PRODUCT_PRICE,
    FETCH_ALL_PRODUCTS_URL,
    GET_PRODUCT_ITEM_BY_PRODUCT_ID,
    GET_PRODUCT_DOCUMENTS_URL,
    ACTIVE_PRODUCT_ITEM_URL,
    UPLOAD_SIGNED_AGREEMENT_BY_ID,
    FETCH_PRODUCTS_INCLUDING_INACTIVE_BY_USER_ROLE_URL,
    UPLOAD_PRODUCT_DOCUMENT,
    SEARCH_PRODUCTLIST_URL,
    INACTIVE_PRODUCT_DOCUMENT,
    FETCH_PRODUCTS_INCLUDING_INACTIVE_FOR_PRODUCT_SEARCH_URL,
    GET_INACTIVATED_PRODUCT_DOCUMENT,
    ACTIVATE_PRODUCT_DOCUMENT,
    UPDATE_PRODUCT_DOCUMENT_STATUS,
    SAVE_PRODUCT_PRICE_ITEMS_URL,
    GET_PRODUCT_PRICING_BY_PRODUCT_ID_URL
} from "./URL";
import { http } from "./http";

export const getProductList = async (searchParams : searchParamsInfo) => {
    let queryParam = "";
    if (searchParams.productName) queryParam += `?productName=${searchParams.productName}&`;
    if (searchParams.inactiveDate) queryParam += `?inactiveDate=${searchParams.inactiveDate}&`;
    const response = await http.get(FETCH_PRODUCTLIST_URL);
    console.log("1")
    return response;
};

export const getProductListWithLogos = async () => {
   const response = await http.get(GET_PRODUCT_LIST_WITH_LOGO_URL);
    return response;
};

export const createProducts = async (product: ProductInfo) => {
    const response = await http.post(CREATE_PRODUCT_URL, product);
    return response;
};

export const saveProductItems = async (productItem: ProductItemInfo) => {
    try{
        const response = await http.post(SAVE_PRODUCT_ITEM_URL, productItem);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const saveProductPriceItems = async (productPriceItem: ProductItemsPriceInfo) => {
    try{
        const response = await http.post(SAVE_PRODUCT_PRICE_ITEM_URL, productPriceItem);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const inactivateProductPrices = async (id: number, date: string) => {
    try {
        const response = await http.post(`${INACTIVE_PRODUCT_PRICE_ITEM_URL}/${id}`, null, {
            params: {
                inactiveDate: date
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteProductPrice = async (productPriceId: number) => {
    try {
        const response = await http.post(`${DELETE_PRODUCT_PRICE_ITEM_URL}/${productPriceId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const inactivateProductItems = async (id: number, date: string) => {
    try {
        const response = await http.post(`${INACTIVE_PRODUCT_ITEM_URL}/${id}`, null, {
            params: {
                inactiveDate: date
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const activateProductItems = async (id: number) => {
    try {
        const response = await http.post(`${ACTIVE_PRODUCT_ITEM_URL}/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProductItem = async (productId: number) => {
    const response = await http.get(FETCH_PRODUCT_DETAILS_BY_ID,
        {
            params: {
                productId: productId
            }
        }
    );
    return response;
};

export const deleteProductItem = async (productId: number) => {
    let queryParam = "";
    if (productId) queryParam += `?productId=${productId}&inactiveDate=${new Date()}`;
    const response = await http.get(DELETE_PRODUCT_URL);
    return response;
};

export const fetchProductsByUserRole = async (includeProductPrice: boolean) => {
    try{
        const response = await http.get(`${FETCH_PRODUCTS_BY_USER_ROLE_URL}?includeProductPrice=${includeProductPrice}`);
        return response;
    } catch (error) {
        throw error;
        }
};

export const fetchProductsIncludingInactiveByUserRole = async (includeProductPrice: boolean) => {
    try{
        const response = await http.get(`${FETCH_PRODUCTS_INCLUDING_INACTIVE_BY_USER_ROLE_URL}?includeProductPrice=${includeProductPrice}`);
        return response;
    } catch (error) {
        throw error;
        }
};

export const getProductsPricingHistory = async (id: number) => {
    try {
        const response = await http.get(`${GET_PRODUCT_PRICE_ITEM_HISTORY_URL}/?productId=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const getProductItemsHistory = async (id: number) => {
    try {
        const response = await http.get(`${GET_PRODUCT_ITEM_HISTORY_URL}/?productId=${id}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const saveProductAgreement = async (agreementData: PracticeProductInfo, interceptorOption?: InterceptorOption) => {
    try {
        const response = await http.post(CREATE_PRODUCT_AGREEMENT, agreementData, undefined, interceptorOption);
        return response;
    }catch(error) {
        throw error;
    }
};

export const getProductPricingByMAC = async (macId: number) => {
    try {
        const response = await http.get(`${FETCH_PRODUCTS_PRICE_BY_MAC}?macId=${macId}`);
        return response;
    }catch(error) {
        throw error;
    }  
};

export const getProductPricing = async () => {
    try {
        const response = await http.get(FETCH_PRODUCT_WITH_PRICE_URL);
        return response;
    }catch(error) {
        throw error;
    }
};

export const getProductAgreementList = async(data: SearchPracticeProductAgreementRequest) => {
    try{
        const response = await http.post(FETCH_PRODUCTS_AGREEMENTS, data);
        return response;
    }catch(error) {
        throw error;
    }
};

export const saveProductsLogo = async(data: Object) => {
    try{
        const response = await http.post(SAVE_PRODUCT_LOGO_URL, data);
        return response;
    }catch(error) {
        throw error;
    }
};

export const updateProducts = async(product: Object) => {
    try{
        const response = await http.post(UPDATE_PRODUCT, product);
        return response;
    }catch(error) {
        throw error;
    }
};

export const signPracticeProductAgreement = async (data: Object) => {
    try {
        const response = await http.post(PRACTICE_SIGN_PRODUCT_AGREEMENT, data);
        return response;
    }catch(error){
        throw error;
    }
};

export const signManufactureProductAgreement = async (data: Object) => {
    try {
        const response = await http.post(MANUFACTURE_SIGN_PRODUCT_AGREEMENT, data);
        return response;
    }catch(error){
        throw error;
    }
};

export const deleteProductAgreement = async (id: number) => {
    try {
        console.log(id)
        const response = await http.post(`${DELETE_PRODUCT_AGREEMENT_BY_ID_URL}?practiceProductId=${id}`);
        return response;
    } catch(error){
        throw error;
    }
};

export const inactivateProductAgreement = async (inactiveData: inactivateInfo, interceptorOption: InterceptorOption) => {
    try {
        const response = await http.post(INACTIVATE_PRODUCT_AGREEMENT_BY_ID_URL, inactiveData, undefined, interceptorOption);
        return response;
    } catch(error){
        throw error;
    }
};

export const previewOnboardAgreement = async (practiceProductId: string | number) => {
    try{
        const response = await http.get(`${PREVIEW_PRACTICE_PRODUCT_AGREEMENT}/${practiceProductId}`);
        return response;
    } catch(error){
        throw error;
    }
};

export const initiateAgreements = async (data: number[]) => {
    try { 
        const response = await http.post(INITIATE_PRODUCT_AGREEMENT_URL, data);
        return response;
    } catch (error){
        throw error;
    }
};

export const agreementCountForExpiredProductPrice = async () => {
    try { 
        const response = await http.get(GET_AGREEMENT_COUNT_FOR_EXPIRED_PRODUCT_PRICE);
        return response;
    } catch (error){
        throw error;
    }
};

export const fetchAllProducts = async (includeProductPrice: boolean) => {
    try {
        const response = await http.get(`${FETCH_ALL_PRODUCTS_URL}?includeProductPrice=${includeProductPrice}`);
        return response;
    } catch (error){
        throw error;
    }
};

export const generateAgreementsForExpiredProductPrice = async () => {
    try {
        const response = await http.get(`${GENERATE_NEW_AGREEMENTS_FOR_EXPIRED_PRODUCT_PRICE}`);
        return response;
    } catch (error){
        throw error;
    }
};

export const getProductItemsByProductId = async (productId: number, onlyActiveRecords: boolean) =>{
    const response = await http.get(`${GET_PRODUCT_ITEM_BY_PRODUCT_ID}?productId=${productId}&onlyActiveRecords=${onlyActiveRecords}`);
    return response;
};


export const getProductDocumentsByProductId = async (productId: number) => {
    try {
        const response = await http.get(`${GET_PRODUCT_DOCUMENTS_URL}?productId=${productId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const uploadSignedAgreement = async (data: UploadSignedAgreementInfo) => {
    try { 
        const response = await http.post(UPLOAD_SIGNED_AGREEMENT_BY_ID, data);
        return response;
    } catch (error){
        throw error;
    }
};

export const saveProductDocument = async(data: ProductDocument) => {
    try{
        console.log(data)
        const response = await http.post(UPLOAD_PRODUCT_DOCUMENT, data);
        return response;
    }catch(error) {
        throw error;
    }
};

export const fetchProductList = async (searchParams : searchParamsProdcutsList) => {
    const response = await http.post(SEARCH_PRODUCTLIST_URL,searchParams);
    return response;
};

export const inactivateProductDocument = async(data: InactivateProductDocumentInfo) => {
    try{
        const response = await http.post(INACTIVE_PRODUCT_DOCUMENT, data);
        return response;
    }catch(error) {
        throw error;
    }
};

export const getInactivatedProductDocuments = async(productId: number) => {
    try{
        const response = await http.get(`${GET_INACTIVATED_PRODUCT_DOCUMENT}?productId=${productId}`);
        return response;
    }catch(error) {
        throw error;
    }
};

export const productDocumentActivation = async(activateRequest: UpdateProductDocumentStatusRequest) => {
    try{
        const response = await http.post(ACTIVATE_PRODUCT_DOCUMENT, activateRequest);
        return response;
    }catch(error) {
        throw error;
    }
};

export const fetchProductsIncludingInactiveForProductSearch = async (includeProductPrice: boolean) => {
    try {
        const response = await http.get(`${FETCH_PRODUCTS_INCLUDING_INACTIVE_FOR_PRODUCT_SEARCH_URL}?includeProductPrice=${includeProductPrice}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const editProductDocumentStatus = async(updateRequest: UpdateProductDocumentStatusRequest) => {
    try{
        const response = await http.post(UPDATE_PRODUCT_DOCUMENT_STATUS, updateRequest);
        return response;
    }catch(error) {
        throw error;
    }
};

export const saveProductsItemsPrices = async (productPriceItem: ProductItemsPriceInfo) => {
    try{
        const response = await http.post(SAVE_PRODUCT_PRICE_ITEMS_URL, productPriceItem);
        return response;
    }
    catch(error){
        throw error;
    }
};

export const productPriceByProductId = async(productPriceByProductRequest: ProductPriceByProductRequest) => {
    try{
        const response = await http.get(`${GET_PRODUCT_PRICING_BY_PRODUCT_ID_URL}?productId=${productPriceByProductRequest.productId}&effectiveDate=${productPriceByProductRequest.effectiveDate}`);
        return response;
    }catch(error) {
        throw error;
    }
};