import { Box, Grid, List, ListItem, ListItemButton, ListItemText, Typography, useTheme, Divider, ListItemIcon, } from "@mui/material";
import Button from "../../common/Button";
import CustomTextField from "../../common/CustomTextField";
import Card from "../../common/Card";
import { useFieldArray, useForm } from "react-hook-form";
import { CancelOutlined, ClassOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import { tokens } from "../../../theme";
import CustomDatePicker from "../../common/CustomDatePicker";
import { SingleAlertInfo, setSingleAlertObj } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { useEffect, useMemo, useState } from "react";
import { OrderFulfillmentInfo, OrderInfo, OrderItemDTO, getOrderItemsByOrderId, initialValuesOfOrderFulfillment, updateOrder, useOrderSlice } from "../../../redux/slice/order/OrderSlice";
import { CodeTable, fetchAllCodeTablesValues, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import dayjs from "dayjs";
import { PRODUCT_STATUS } from "../../../constants/applicationConstants";

var statuses: CodeTable[] = [];
var orderDetail: OrderInfo = {
  orderId: 0,
  practiceId: 0,
  productId: 0,
  ivrId: 0,
  patientId: 0,
  practiceName: "",
  hasMiddleName: false,
  patientName: "",
  productName: "",
  manufacturerName: "",
  providerName: "",
  orderNumber: "",
  orderDate: "",
  orderType: "",
  orderStatus: "",
  mfgConfirmationNumber: "",
  shippedDate: "",
  deliveredDate: "",
  shippingCarrier: "",
  trackingNumber: "",
  poNumber: "",
  orderTotalUnit: 0,
  orderTotalPrice: 0,
  orderDocumentId: 0,
  orderItems: [] as OrderItemDTO[]
};

const OrderFulfillment = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();

  const [updateStatusLabel, setUpdateStatusLabel] = useState("");

  const { control, handleSubmit, reset, watch, getValues, setError,
    setValue, formState: { errors } } = useForm<OrderFulfillmentInfo>({ defaultValues: initialValuesOfOrderFulfillment });

  const { fields, append, remove } = useFieldArray({ name: "orderItems", control });

  const { allCodeTablesValuesMap, allCodeTableValueFetchStatus } = useCodetablesSlice();
  const { orderItemsListInfo } = useOrderSlice();

  //mount
  useEffect(() => {
    orderDetail = location?.state?.selectedOrderDetail;
    reset(orderDetail);
    setValue("updateStatusDate", dayjs().format('MM/DD/YYYY'));
    dispatch(getOrderItemsByOrderId(orderDetail.orderId));
  }, []);

  // unmount
  useEffect(() => () => { }, []);

  useEffect(() => {
    if (orderItemsListInfo) {
      const sortedOrderItems = [...orderItemsListInfo].sort((a, b) => a.units - b.units);
      setValue("orderItems", sortedOrderItems);
    }
  }, [orderItemsListInfo]);

  const carrierOptions = useMemo(() => {
    let carriers: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      carriers = getCodeTableValuesByTableName(allCodeTablesValuesMap, "Carrier");
    }
    if (!carriers || carriers.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options: { label: string, value: any }[] = [];
    carriers?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

  const updateStatuses = useMemo(() => {
    if (allCodeTablesValuesMap) {
      statuses = getCodeTableValuesByTableName(allCodeTablesValuesMap, "UpdateOrderStatus");
    }
    if (!statuses || statuses.length === 0) {
      dispatch(fetchAllCodeTablesValues());
    }
    let options: { label: string, value: any }[] = [];
    statuses?.map((option) => {
      options.push({ label: option.displayValue, value: option.keyName });
      // if (getValues("orderStatus") === option.keyName) {
      //   setUpdateStatusLabel(option.displayValue);
      // }
    });
    return options;
  }, [allCodeTablesValuesMap]);

  const onSubmit = async (data: OrderFulfillmentInfo) => {
    const response = await dispatch(updateOrder(data));
    if (response.payload && response.payload.code === "V3OK" && response.payload.message === "SUCCESS"
        && (!response.payload.errors || response.payload.errors.length === 0)) {
      let successObj: SingleAlertInfo = {
        message: `Order ${orderDetail?.orderNumber} is updated successfully.`,
        alertType: "success",
      };
      dispatch(setSingleAlertObj(successObj));
      navigate("/order_list");
    }
  }

  const handleStatusSelectChange = (name: string, value: string) => {
    let ct: CodeTable | undefined = statuses.find((option) => {
      if (option.keyName === value) {
        return option.displayValue;
      }
    });
    setUpdateStatusLabel(ct ? ct.displayValue : "");
    if (ct?.keyName === PRODUCT_STATUS.SHIPPED) {
      setValue("shippedDate", dayjs(getValues("updateStatusDate")).format('MM/DD/YYYY'));
      setValue("deliveredDate", orderDetail.deliveredDate);
      setValue("orderStatus", ct?.keyName);
    } else if (ct?.keyName === PRODUCT_STATUS.DELIVERED) {
      setValue("deliveredDate", dayjs(getValues("updateStatusDate")).format('MM/DD/YYYY'));
      setValue("shippedDate", orderDetail.shippedDate);
      setValue("orderStatus", ct?.keyName);
    } else if (ct?.keyName === PRODUCT_STATUS.PROCESSING) {
      setValue("orderStatus", ct?.keyName);
      setValue("shippedDate", orderDetail.shippedDate);
      setValue("deliveredDate", orderDetail.deliveredDate);
    } else {
      setValue("deliveredDate", orderDetail.deliveredDate);
      setValue("shippedDate", orderDetail.shippedDate);
      setValue("orderStatus", orderDetail.orderStatus);
    }
  }

  const handleUpdateStatusDateChange = (date: Date | null) => {
    let statusDate: string = dayjs(date).format("MM/DD/YYYY");
    if (getValues("orderStatus") === PRODUCT_STATUS.SHIPPED) {
      setValue("shippedDate", statusDate);
      setValue("deliveredDate", orderDetail.deliveredDate);
    } else if (getValues("orderStatus") === PRODUCT_STATUS.DELIVERED) {
      setValue("deliveredDate", statusDate);
      setValue("shippedDate", orderDetail.shippedDate);
    } else {
      setValue("deliveredDate", orderDetail.deliveredDate);
      setValue("shippedDate", orderDetail.shippedDate);
    }
  }

  return (
    <Box my="8px" ml="12px" mr="8px">
      <Box mb="10px">
        <Typography variant="h3" fontWeight="bold" sx={{ m: "0 0 5px 0" }} >
          {"Update Order"}
        </Typography>
      </Box>
      <Box>
        <Card sx={{ pt: "1px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mb={2} mt={2}>
              <Grid item md={6}>
                <Typography component="label" fontWeight="bold" sx={{ width: 70, display: "inline-block" }}>
                  Patient:
                </Typography>
                <Typography component="label">{getValues("patientName") || orderDetail?.orderInfo?.patient}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item md={"auto"}>
                <Typography component="label" fontWeight="bold" sx={{ width: 70, display: "inline-block" }}>
                  Order #:
                </Typography>
                <Typography component="label">{getValues("orderNumber")}</Typography>
              </Grid>
              <Grid item md={"auto"}>
                <Typography component="label" fontWeight="bold" sx={{ width: 70, display: "inline-block" }}>
                  PO #:
                </Typography>
                <Typography component="label">{getValues("poNumber")}</Typography>
              </Grid>
            </Grid>

          <Grid container spacing={2} mb={2}>
              <Grid item md={6}>
                <Typography component="label" fontWeight="bold" sx={{ width: 70, display: "inline-block" }}>
                  Product:
                </Typography>
                <Typography component="label">{getValues("productName")} {"("+ getValues("manufacturerName") + ")"}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={1} mt={1}>
              <Grid item xs={12} md={6} mb={2}>
                <List sx={{ bgcolor: colors.grey[900], borderRadius: 3 }}>
                  <ListItem>
                    <ListItemButton onClick={() => { }} sx={{ padding: 1, borderRadius: 2, fontSize: 14 }} >
                      <Typography fontWeight="bold" color={theme.palette.mode === "dark" ? colors.info[300] : colors.grey[500]} sx={{ width: "25%" }}>
                        Ordered Size
                      </Typography>
                      <Typography fontWeight="bold" color={theme.palette.mode === "dark" ? colors.info[300] : colors.grey[500]} sx={{ width: "75%" }}>
                        Serial Number
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                  {fields?.map((field, index: number) => (
                    <ListItem key={field.id}>
                      <ListItemButton sx={{ padding: 0, borderRadius: 2 }}>
                        <ListItemText sx={{ width: "25%", paddingLeft: 1 }}>
                          {field.size}
                        </ListItemText>
                        <CustomTextField controllerName={`orderItems.${index}.serialNumber`} control={control} sx={{ width: "75%" }}
                          // rules={{
                          //   required: "Field is required.",
                          //   validate: {
                          //     customValidation: (value: string) => {
                          //       if (!value || value.trim() === "") {
                          //         return "Field is required.";
                          //       }
                          //     }
                          //   }
                          // }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2} mt={0}>
              <Grid item md={4} xs={12}>
                <CustomDropdownField name={"shippingCarrier"} control={control} options={carrierOptions} label="Carrier"
                  // rules={{
                  //   required: "Carrier is required.",
                  //   validate: {
                  //     customValidation: (value: string) => {
                  //       if (!value) {
                  //         return "Carrier is required.";
                  //       }
                  //     }
                  //   }
                  // }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
              <Grid item md={4}>
                <CustomTextField controllerName="trackingNumber" label="Shipping Tracking #" control={control}
                  // rules={{
                  //   required: "Shipping Tracking # is required.",
                  //   validate: {
                  //     customValidation: (value: string) => {
                  //       if (!value || value.trim() === "") {
                  //         return "Shipping Tracking # is required.";
                  //       }
                  //     }
                  //   }
                  // }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item md={4} xs={12}>
                <CustomDropdownField name={"orderStatus"} control={control} options={updateStatuses} label="Update Status to"
                  onChangeSelect={handleStatusSelectChange}
                  rules={{
                    required: "Status is required.",
                    validate: {
                      customValidation: (value: string) => {
                        if (!value) {
                          return "Status is required.";
                        }
                      }
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Box >
                  <CustomDatePicker control={control} controllerName="updateStatusDate" label={updateStatusLabel ? updateStatusLabel + " Date" : "Date"}
                    onChange={handleUpdateStatusDateChange}
                    rules={{
                      required: "Date is required.",
                      validate: {
                        isValidDate: (
                          value: | string | number | Date | dayjs.Dayjs | null | undefined
                        ) => {
                          if (!dayjs(value, "MM/DD/YYYY", true).isValid()) {
                            return "Invalid date format."
                          }
                        }
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" startIcon={<ClassOutlined />} sx={{ mr: 1 }} >Submit</Button>
              <Button sx={{ mr: 1 }} color="error" startIcon={<CancelOutlined />} onClick={() => navigate(-1)} >Cancel</Button>
            </Box>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default OrderFulfillment;