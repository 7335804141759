import { Box, Divider, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";
import Header from "../../common/Header";
import Card from "../../common/Card";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { CancelOutlined, SaveOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import userSlice, {
  fetchAllUserRoles,
  saveUsersProfile,
  updateUserInfoObject,
  useUserReducer,
  clearUserObject,
  UserRole,
  UserNotificationPreference,
  searchUserNotificationPreference,
  saveUserNotificationPreference,
} from "../../../redux/slice/userSlice";
import CustomDropdownField from "../../common/CustomDropdownFiled";
import CustomPasswordTextField from "../../common/CustomPasswordTextField";
import CustomNametField from "../../common/CustomNametField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  AgencyInfo,
  MainErrorInfo,
  SingleAlertInfo,
  fetchAgenciesList,
  fetchManufacturersList,
  setMainErrorObj,
  setSingleAlertObj,
  showHideLoadingDialog,
  useCommonReducer,
} from "../../../redux/slice/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { PracticeInfo, getPracticesByUserRole, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import CustomPatternTextField from "../../common/CustomPatternTextField";
import { sortNames } from "../../../utils/Utility";
import { selectTokenValues, useAuthSlice } from "../../../redux/slice/authSlice";
import { USER_ROLES, accountExecutiveRoleId, agencyAdminRoleId, billerRoleId, manufacturerAdminRoleId, manufacturerStaffRoleId, manufacturerIvrRoleId, manufacturerServiceId, practiceAdminRoleId, practiceStaffRoleId, NOTIFICATION_TYPE_LIST, NOTIFICATION_TYPE, providerRoleId } from "../../../constants/applicationConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchActiveBillers, usePracticeApproveSlice } from "../../../redux/slice/practice/practiceApproveSlice";
import { CodeTable, getCodeTableValuesByTableName, useCodetablesSlice } from "../../../redux/slice/codetableSlice";
import CustomMultipleDropDown from "../../common/CustomMultipleDropDown";
import { fetchActivePracticeProviders, fetchAllProviderUsedPracticeIDs, fetchPracticeProviderByUser, fetchUsedPracticeProviderIDs, usePracticeProvidersSlice } from "../../../redux/slice/practice/practiceProvidersSlice";
import { fetchPracticeProductsDataUsingDateOfServiceAndProvider, fetchPracticeProductsDataWithoutDetails, usePracticeProductsSlice } from "../../../redux/slice/practice/practiceProductSlice";
import { fetchPracticeByIdData, fetchPracticesByIdsData, usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import CustomCheckbox from "../../common/CustomCheckBox";
import OptionsPopup, { OptionPopupObjectProps } from "../../common/OptionsPopup";
import { StringLiteral } from "typescript";
import { usePracticeLocationsSlice } from "../../../redux/slice/practice/practiceLocationSlice";

type Option = {
  value: string | number;
  label: string;
};

type PracticeProviderOption = {
  label: string;
  value: string | number;
  firstName: string;
  middleName?: string;
  lastName: string;
};

type providerAddressOptions = {
  label: string;
  value: string | number;
  streetAddress: string;
  unitNumber: string;
  city: string;
  state: string;
  zipCode: string;
};

export type UserCreateObj = {
  userId: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  userRoleId: number | undefined;
  phone: string;
  password: string;
  confirmPassword: string;
  entityId: number;
  entityIds: number[] | undefined;
  practiceProvider?: string | number;
  eligibleProducts?: number[];
  locationId?: number;
};

const CreateUser = () => {
  const location = useLocation();
  const userData = location.state?.user;
  const isEditing = Boolean(userData);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<UserCreateObj>();
  const { userRoles, userObject, userNotificationPreferenceData ,fetchAllUserRoleStatus} = useUserReducer();
  const { manufacturerInfoList, agencyInfoList } =
    useCommonReducer();
  const { practiceByUserRole } = usePracticeSlice();
  const { practiceEditInfo, multiplePracticesInfo } = usePracticeEditSlice();
  const { practiceProvidersInfo, practiceProviderInfo, allProviderUsedPracticeIds, usedPracticeProviderIds } = usePracticeProvidersSlice();
  const { practiceProductsInfo } = usePracticeProductsSlice();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedUserType = watch("userRoleId");
  const { userInfo } = useAuthSlice();
  const { allCodeTablesValuesMap } = useCodetablesSlice();
  const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({});
  const [eligibleProducts, setEligibleProducts] = useState<number[]>([]);
  const [providerAssigned, setProviderAssigned] = useState(false);
  const [optionPopupShow, setOptionPopupShow] = React.useState<OptionPopupObjectProps>({ message: "", show: false });

  const { billerList } = usePracticeApproveSlice();
  const canManageProviderProductsSection = useSelector((state: RootState) => selectTokenValues("canManageProviderProductsSection")(state));
  //cs-442

  const notificationOptions = useMemo(() => {
    let notificationTypes: CodeTable[] = [];
    if (allCodeTablesValuesMap) {
      notificationTypes = getCodeTableValuesByTableName(allCodeTablesValuesMap, NOTIFICATION_TYPE_LIST);
    }
    let options: { label: string, value: any }[] = [];
    notificationTypes?.map((option) => {
      options.push({ label: option.displayValue, value: option.displayValue });
    });
    return options;
  }, [allCodeTablesValuesMap]);

   //Calling these again when notfication preference is set true
   useEffect(() => {
    if (fetchAllUserRoleStatus === "success") {
      const data: UserNotificationPreference = {
        userId: userData?.userId,
        notificationType: NOTIFICATION_TYPE,
        notificationSubType: null
      }
      dispatch(searchUserNotificationPreference(data))
    }

  }, [fetchAllUserRoleStatus])


  useEffect(() => {
    if(userObject?.canAccessProviderProducts){
      setEligibleProducts(userObject?.canAccessProviderProducts);
    }
  }, [userObject])


  //handling the toggle switch
  useEffect(() => {
    // Initialize state based on userNotificationPreferenceData
    if (Array.isArray(userNotificationPreferenceData)) {
      const initialStates: { [key: string]: boolean } = {};
      userNotificationPreferenceData.forEach((pref) => {
        initialStates[pref.notificationSubType] = pref.isEnabled;
      });
      setSwitchStates(initialStates);
    }
  }, [userNotificationPreferenceData]);
  //
  useEffect(() => {
    const data: UserNotificationPreference = {
      userId: userData?.userId,
      notificationType: NOTIFICATION_TYPE,
      notificationSubType: null
    }
    dispatch(searchUserNotificationPreference(data))
  }, [])
  const handleSwitchToggle = async (data: { value: string }, newValue: boolean) => {

    const preference = userNotificationPreferenceData?.find((pref: { notificationSubType: string; }) =>
      pref.notificationSubType === data.value
  );

    const preferenceSelection = {
      userNotificationPreferenceId: preference ? preference.userNotificationPreferenceId : null,
      userId: userData?.userId,
      notificationType: NOTIFICATION_TYPE,
      notificationSubType: data.value,
      isEnabled: newValue,
    };

    // Call the API to update/save the preference
    await dispatch(saveUserNotificationPreference(preferenceSelection));

    // Update the local state
    setSwitchStates((prevStates) => ({
      ...prevStates,
      [data.value]: newValue,
    }));
  };
  //

  const handleCheckingForProducts = async (data: { value: string }, newValue: boolean) => {

    if (newValue) {
      setEligibleProducts((prevEligible) => [...prevEligible, parseInt(data.value)]);
    } else {
      setEligibleProducts((prevEligible) =>
        prevEligible.filter((productId) => productId !== parseInt(data.value))
      );
    }
  };

  const canManageUserProfile = useSelector((state: RootState) => selectTokenValues("canManageUsers")(state));

  useEffect(()=>{
    if(isEditing && userObject){
      dispatch(fetchPracticeProviderByUser(userObject.userId));
    }
  },[userObject]);

  useEffect(()=>{
    if(practiceProviderInfo?.practiceId){  
      dispatch(fetchPracticeByIdData(String(practiceProviderInfo?.practiceId))) 
    }
  },[practiceProviderInfo]);

  useEffect(()=>{
    if(isEditing){
    if (practiceEditInfo && practiceProviderInfo?.practiceId) {
      if (practiceEditInfo.billingType === "GROUP") {
        dispatch(fetchPracticeProductsDataWithoutDetails(String(practiceProviderInfo.practiceId)));
      } else {
        dispatch(
          fetchPracticeProductsDataUsingDateOfServiceAndProvider({
            practiceId: Number(practiceProviderInfo.practiceId),
            providerId: Number(practiceProviderInfo.providerId),
            dateOfService: "", 
          })
        );
      }
    }
  }
  },[practiceEditInfo, practiceProviderInfo]);


  useEffect(()=>{
    if(selectedUserType === providerRoleId) {
      dispatch(fetchAllProviderUsedPracticeIDs());
      dispatch(fetchUsedPracticeProviderIDs());
    }
  },[selectedUserType]);

  const defaultValues = useCallback(() => {
    if (userObject) {
      reset(userObject);
      if (isEditing && selectedUserType === providerRoleId && practiceProviderInfo) {
        const providers = Array.isArray(practiceProviderInfo) 
          ? practiceProviderInfo 
          : [practiceProviderInfo];
        
        const practiceIds = providers.map(provider => Number(provider.practiceId));
        setValue("entityIds", practiceIds);
        setValue("practiceProvider", providers[0].providerId);
        setValue("locationId", providers[0].locationId);
      }
    } else {
      reset({
        userRoleId: undefined,
        entityId: 0,
        entityIds: [],
        email: '',
        locationId: undefined,
        password: '',
        confirmPassword: '',
        phone: '',
      });
    }
  }, [userObject, reset, practiceProviderInfo]);  

  const onSubmit = async (data: UserCreateObj) => {
    if (isEditing) {
      dispatch(updateUserInfoObject({ ...data, id: userData.userId}));
      if(selectedUserType === providerRoleId && data.practiceProvider) {
        data = { ...data, entityIds: [data.practiceProvider ? Number(data.practiceProvider) : 0]}
      }
      await handleSaveUserProfile({ ...data, eligibleProducts: eligibleProducts});
      setEligibleProducts([]);
      // navigate(-1);
    } else {
      if (selectedUserType === providerRoleId) {

        const selectedPractice = multiplePracticesInfo?.find(practice =>
          practice.practiceProviders.some(provider => 
            provider.providerId === Number(selectedProviderId)
          )
        );

        if(selectedPractice?.billingType === "GROUP" && practiceProductsInfo && practiceProductsInfo?.length > 0 && eligibleProducts.length===0){
          const alertObj: SingleAlertInfo = {
            message:  "Choose at least one product to proceed",
            alertType: "error",
          };

          dispatch(setSingleAlertObj(alertObj));

        }else{
        await handleSaveUserProfile({
          ...data,
          password: data.password.replace(/\s+/g, ""),
          confirmPassword: data.confirmPassword.replace(/\s+/g, ""),
          entityIds: data.practiceProvider ? [Number(data.practiceProvider)] : [],
          eligibleProducts: eligibleProducts,
        });
      }
      } else {
        await handleSaveUserProfile({
          ...data,
          password: data.password.replace(/\s+/g, ""),
          confirmPassword: data.confirmPassword.replace(/\s+/g, ""),
          eligibleProducts: []
        });
      }
    }
  };

  const onClose = () => {
    navigate(-1);
  }

  const confirmOptionPopup = () => {
    setOptionPopupShow({ message: "", show: false });
    setValue("practiceProvider", "");
    setProviderAssigned(false);
  };

  const userRoleList = useMemo(() => {
    let allUserRoles: Option[] = [];
    let availableUserRoles: UserRole[] | undefined = undefined;

    if (canManageUserProfile && canManageUserProfile.length > 0) {
      availableUserRoles = userRoles?.filter((role: UserRole) => canManageUserProfile.includes(role.roleName));
    }
    availableUserRoles?.forEach((option: UserRole) => {
      allUserRoles.push({ label: option.roleDisplayValue, value: option.userRoleId });
    });
    return allUserRoles;
  }, [canManageUserProfile, userRoles]);

  const manufacturersList = useMemo(() => {
    let options: Option[] = [];
    manufacturerInfoList?.map((option) => {
      options.push({
        label: option.manufacturerName,
        value: option.manufacturerId,
      });
    });
    return options;
  }, [manufacturerInfoList]);

  const agencyList = useMemo(() => {
    let options: Option[] = [];

    if (agencyInfoList && Array.isArray(agencyInfoList)) {
      const filteredAgencyList = agencyInfoList.filter((agency: AgencyInfo) =>
        agency.commissionType.trim().toUpperCase() !== "V3"
      );

      const sortedAgencyList = sortNames(filteredAgencyList, 'agencyName');

      sortedAgencyList.forEach((agency: AgencyInfo) => {
        options.push({ label: agency.agencyName, value: agency.agencyId });
      });
    }
    return options;
  }, [agencyInfoList]);

  const billerUsersList = useMemo(() => {
    let options: Option[] = [];
    billerList?.map((option) => {
      options.push({ label: option.billerName, value: option.billerId });
    });
    return options;
  }, [billerList]);

  const practiceList = useMemo(() => {
    let options: Option[] = [];
    if (practiceByUserRole) {
      if (practiceByUserRole && practiceByUserRole.length === 1) {
        setValue('entityId', parseInt(practiceByUserRole[0]?.practiceId));
        practiceByUserRole?.map(practice => {
          options.push({ value: practice.practiceId, label: practice.practiceName });
        });
      } else {
        const sortedPracticeInfo: PracticeInfo[] = sortNames(practiceByUserRole, 'practiceName');
        sortedPracticeInfo?.map(practice => {
          options.push({ value: practice.practiceId, label: practice.practiceName });
        });
      }
    }

    return options;
  }, [practiceByUserRole]);

  const practiceProvidersOptions = useMemo(() => {
    let options: PracticeProviderOption[] = [];
    practiceProvidersInfo?.map((option: any) => {
      options.push({
        label: `${option.firstName ? option.firstName : ""} ${
          option.middleName ? option.middleName + " " : ""
        }${option.lastName ? option.lastName : ""}`,
        value: option.providerId,
        firstName: option.firstName,
        middleName: option.middleName,
        lastName: option.lastName,
      });
    });

    return options;
  }, [practiceProvidersInfo]);

  // const providerAddressOptions = useMemo(() => {
  //   const practiceLocationsInfo = practiceEditInfo?.practiceLocations || [];
  //   const CurrentDate = new Date();

  //   let options: providerAddressOptions[] = [];
  //   console.log("practiceLocationsInfo", practiceLocationsInfo);
  //   practiceLocationsInfo?.filter((option: any) => (new Date(option?.inactiveDate) > CurrentDate || !option?.inactiveDate)).map((option: any) => {
  //     options.push({
  //       label: `${option.streetAddress ? option.streetAddress + ", " : ""} ${
  //         option.unitNumber ? option.unitNumber + ", " : ""
  //       }${option.city ? option.city + ", " : ""}${option.state ? option.state + ", " : ""}${option.zipCode ? option.zipCode : ""}`,
  //       value: option.locationId,
  //       streetAddress: option.streetAddress,
  //       unitNumber: option.unitNumber,
  //       city: option.city,
  //       state: option.state,
  //       zipCode: option.zipCode
  //     });
  //   });

  //   return options;
  // }, [practiceEditInfo]);

  const providerAddressOptions = useMemo(() => {
    const CurrentDate = new Date();
    const processLocations = (locations: any[]) => {
      return locations
        .filter((option) => !option?.inactiveDate || new Date(option.inactiveDate) > CurrentDate)
        .map((option) => ({
          label: `${option.streetAddress ? option.streetAddress + ", " : ""}${
            option.unitNumber ? option.unitNumber + ", " : ""
          }${option.city ? option.city + ", " : ""}${option.state ? option.state + ", " : ""}${
            option.zipCode ? option.zipCode : ""
          }`,
          value: option.locationId,
          streetAddress: option.streetAddress,
          unitNumber: option.unitNumber,
          city: option.city,
          state: option.state,
          zipCode: option.zipCode,
        }));
    };
  
    let options: providerAddressOptions[] = [];

    const practices = multiplePracticesInfo || [];
    practices.forEach((practice) => {
      const practiceLocationsInfo = practice.practiceLocations || [];
      options = options.concat(processLocations(practiceLocationsInfo));
    });
  
    return options;
  }, [practiceEditInfo, multiplePracticesInfo]);
  
  
  const handleSaveUserProfile = async (data: UserCreateObj) => {
    let errorObj: MainErrorInfo = {
      title: "",
    };

    try {
      const response = await dispatch(saveUsersProfile(data));
      if (response.payload && response.payload.message === 'SUCCESS') {
        navigate(-1);

        let alertMessage = isEditing ?
          "User Profile Updated Successfully!" :
          "User Profile Created Successfully!";

        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };

        dispatch(setSingleAlertObj(alertObj));
      } else {
        let errorSentence = '';
        if (response.payload.errors.length <= 0) {
          errorSentence = response.payload.message || "An unknown error occurred.";
        } else {
          response.payload.errors.forEach((error: any, index: any) => {
            if (error.field) {
              errorSentence += `${error.field}: ${error.errorMessage}\n`;
              if (error.field === 'email') {
                setError('email', {
                  type: 'manual',
                  message: error.errorMessage,
                });
              }
            } else {
              errorSentence += `${error.reason}\n`;
            }
          });
        }

        errorObj.title = "User Profile Creation Failed!";
        errorObj.errorMessageStream = errorSentence;
        dispatch(setMainErrorObj(errorObj));
      }
    } catch (error) {
      console.log("Error creating user profile:", error);
      errorObj.title = "Error creating user profile";
      errorObj.errorMessageStream = "" + error;
      dispatch(setMainErrorObj(errorObj));
    }
  };

  const getAllList = async () => {
    try {
      dispatch(showHideLoadingDialog(true));
      const [
        resManufacturerCall,
        resAgenciesCall,
        resPracticesCall,
        resUserCall,
        resBillersCall
      ] = await Promise.all([
        dispatch(fetchManufacturersList()),
        dispatch(fetchAgenciesList()),
        dispatch(getPracticesByUserRole(true)),
        dispatch(fetchAllUserRoles()),
        dispatch(fetchActiveBillers())
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      dispatch(showHideLoadingDialog(false));
    }
  };

  useEffect(() => {
    const practiceEntityIds = getValues('entityIds') || [];
    if (practiceEntityIds !== null && practiceEntityIds !== undefined && selectedUserType === providerRoleId) {
      dispatch(fetchActivePracticeProviders({ practiceIds: practiceEntityIds, onlyActiveRecords: true }));
    }
  }, [watch('entityIds')]);
  
  useEffect(() => {
    getAllList();
  }, [dispatch]);

  useEffect(() => {
    defaultValues();
  }, [defaultValues]);

  useEffect(() => {
    return () => {
      dispatch(clearUserObject());
    };
  }, [dispatch]);

  const selectedProviderId = watch("practiceProvider");

  useEffect(() => {
    if (typeof selectedProviderId === "string" || typeof selectedProviderId === "number") {
      const selectedProvider = practiceProvidersOptions.find(
        (option) => option.value === selectedProviderId
      );

      if(!isEditing && selectedProvider && usedPracticeProviderIds) {
        if(usedPracticeProviderIds.includes(Number(selectedProviderId))){
          setProviderAssigned(true);
          setOptionPopupShow({
            message: `The selected provider already assigned to a user profile `,
            show: true
          });
        } else {
          setProviderAssigned(false);
        } 
      } 
  
      if (selectedProvider) {
        setValue("firstName", selectedProvider?.firstName || "");
        setValue("middleName", selectedProvider.middleName || "");
        setValue("lastName", selectedProvider.lastName || "");
      }  else if (userObject) {
        setValue("firstName", userObject?.firstName || "");
        setValue("middleName", userObject.middleName || "");
        setValue("lastName", userObject.lastName || "");
      }
      else if (selectedProvider ==="" || selectedProvider === undefined || selectedProvider === null) {
        setValue("firstName", "");
        setValue("middleName", "");
        setValue("lastName", "");
      }
    }
  }, [selectedProviderId, practiceProvidersOptions, setValue, usedPracticeProviderIds]);


  useEffect(() => {
    const practiceEntityIds = getValues('entityIds') || [];
    if (!isEditing && selectedProviderId) {

      const selectedPractice = multiplePracticesInfo?.find(practice =>
        practice.practiceProviders.some(provider => 
          provider.providerId === Number(selectedProviderId)
        )
      );

      if (selectedPractice) {
      const { practiceId, billingType } = selectedPractice;

      if(billingType==="INDIVIDUAL"){
      dispatch(fetchPracticeProductsDataUsingDateOfServiceAndProvider({practiceId: Number(practiceId), dateOfService:"", providerId: Number(selectedProviderId)}));
      }else{
        dispatch(fetchPracticeProductsDataWithoutDetails(practiceId));
      }
    }
    }
  }, [selectedProviderId]);
  
  useEffect(() => {
    const practiceEntityIds = getValues('entityIds') || [];
    
    if (!isEditing && practiceEntityIds.length > 0) {
      // dispatch(fetchPracticeByIdData(String(practiceEntityIds[0])));
      dispatch(fetchPracticesByIdsData(practiceEntityIds));
    } else if(isEditing && practiceEntityIds.length > 0) {
      dispatch(fetchPracticesByIdsData(practiceEntityIds));
    }
  }, [getValues('entityIds')]);


  return (
    <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
      <Header
        title="User Profile Management"
        subtitle={isEditing ? "User Profile Update" : "User Profile Create"}
      />
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <CustomDropdownField
                name="userRoleId"
                control={control}
                options={userRoleList}
                label="User Type"
                rules={{
                  required: "User Type is required.",
                  validate: {
                    notZero: (value: number) =>
                      value !== 0 || "User type is required.",
                  },
                }}
                onChangeSelect={() => { setValue('entityId', 0); setValue('entityIds', []); }}
                disabled= {isEditing && selectedUserType === providerRoleId}
              />
            </Grid>

            {selectedUserType === agencyAdminRoleId ||
              selectedUserType === accountExecutiveRoleId ? (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="entityId"
                  control={control}
                  options={agencyList}
                  label="Agency"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Agency is required.",
                    },
                  }}
                />
              </Grid>
            ) : selectedUserType === manufacturerAdminRoleId ||
              selectedUserType === manufacturerStaffRoleId ||
              selectedUserType === manufacturerIvrRoleId ||
              selectedUserType == manufacturerServiceId ? (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="entityId"
                  control={control}
                  options={manufacturersList}
                  label="Manufacturer"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Manufacturer is required.",
                    },
                  }}
                />
              </Grid>
            ) : selectedUserType === practiceAdminRoleId ||
              selectedUserType === practiceStaffRoleId ||
              selectedUserType === providerRoleId ? (
              <Grid item md={4} xs={12}>
                {/* <CustomDropdownField */}
                <CustomMultipleDropDown
                  name="entityIds"
                  control={control}
                  options={practiceList}
                  label="Practice"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Practice is required.",
                    },
                  }}
                  disabled= {isEditing && selectedUserType === providerRoleId}
                />
              </Grid>
            ) : selectedUserType === billerRoleId ? (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="entityId"
                  control={control}
                  options={billerUsersList}
                  label="Biller"
                  rules={{
                    validate: {
                      notZero: (value: number) =>
                        value !== 0 || "Biller is required.",
                    },
                  }}
                />
              </Grid>
            ) : (
              <Grid item md={4} xs={12}></Grid>
            )}
            {selectedUserType === providerRoleId && (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="practiceProvider"
                  control={control}
                  options={practiceProvidersOptions}
                  label="Practice Provider"
                  disabled= {isEditing && selectedUserType === providerRoleId}
                />
              </Grid>)}
              {selectedUserType === providerRoleId && (
              <Grid item md={4} xs={12}>
                <CustomDropdownField
                  name="locationId"
                  control={control}
                  options={providerAddressOptions}
                  label="Provider Address"
                />
              </Grid>)}
            <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>

            <OptionsPopup
              open={optionPopupShow.show}
              onClose={() => setOptionPopupShow({ message: "", show: false })}
              variant="confirm"
              message={<>{optionPopupShow.message}</>}
              title={optionPopupShow.title}
              buttons={[
                { name: "OK", color: "primary", onClick: confirmOptionPopup },
                // { name: "Cancel", color: "secondary", onClick: cancelOptionPopup },
              ]}
            />

            {/* <Grid item md={4} xs={12}>
              <CustomTextField
                control={control}
                controllerName="userName"
                label="Username"
                rules={{ required: "Username is required." }}
              />
            </Grid> */}

          {!providerAssigned && <><Grid item md={4} xs={12}>
              <CustomTextField
                control={control}
                controllerName="email"
                label="Email"
                rules={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                    message: "Invalid email.",
                  },
                }} />
            </Grid><Grid item md={8} xs={12}></Grid></> }

            {!providerAssigned && !isEditing && (
              <>
                <Grid item md={4} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="password"
                    label="Password"
                    autoComplete="new-password"
                    rules={
                      isEditing ? {} : {
                        required: "Password is required.",
                        minLength: {
                          value: 12,
                          message: "Password should be at least 12 characters.",
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)/,
                          message: "Password must contain at least one uppercase letter, one number, and one special character with no spaces"
                        },
                      }
                    }
                    isDisabled={isEditing}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <CustomPasswordTextField
                    control={control}
                    controllerName="confirmPassword"
                    label="Confirm Password"
                    autoComplete="new-password"
                    rules={
                      isEditing
                        ? {}
                        : {
                          required: "Confirmation of password is required.",
                          minLength: {
                            value: 12,
                            message: "Password must be at least 12 characters.",
                          },
                          pattern: {
                            value: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
                            message: "Password must contain at least one uppercase letter, one number, and one special character"
                          },
                          validate: (value: string) =>
                            value === watch("password") ||
                            "Passwords do not match",
                        }
                    }
                    isDisabled={isEditing}
                  />
                </Grid>
              </>
            )}

{!providerAssigned && <><Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <CustomNametField
                control={control}
                name=""
                isRequired={true}
                context=""
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <CustomPatternTextField
                control={control}
                name="phone"
                label="Phone"
                rules={{ required: "Phone is required." }}
              />
            </Grid></>}
            {/* CS-442 */}
            {userData && userData?.userId && (<>
              <Grid item md={12} xs={12}>
                <Divider
                  orientation="horizontal"
                  variant="middle"
                  flexItem
                  sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Header
                  title="Notification Preference(s)"
                  subtitle={""}
                />
                  <Grid container spacing={2} mt={2} wrap="wrap">
                    {notificationOptions.map((data: any, index: number) => {

                      return (
                        <>
                        <Grid item xs="auto" key={index}>
                          <Typography>
                            {data?.label}
                          </Typography>
                        </Grid>
                        <Grid item xs="auto" mt={-1}  key={index}>
                          <FormControlLabel
                            control={<Switch color="success"
                              checked={switchStates[data.value] || false}
                              onChange={(e) => {
                                const newValue = e.target.checked;
                                handleSwitchToggle(data, newValue);
                              }} />}
                            label=""
                            sx={{ marginLeft: "auto" }}
                          />
                          </Grid>
                          </>
                      );
                    })}
                  </Grid>
                {/* // */}
              </Grid>
                
            </>)}
            {(isEditing && selectedUserType === providerRoleId) || (selectedUserType === providerRoleId && selectedProviderId) ? (
              <>
              <Grid item md={12} xs={12}>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{ padding: 0, margin: 0, mt: 1, mb: 1 }}
              />
            </Grid>
              <Grid item md={12} xs={12}>
              {practiceProductsInfo && practiceProductsInfo?.length > 0 && (
                <>
              <Header title="Products" subtitle="" />
                <Grid container spacing={2} mt={2} wrap="wrap">
                  {practiceProductsInfo?.map((product: any, index: number) => {
                    return  (
                      <>
                        <Grid item xs="auto" key={`product-name-${index}`}>
                          <Typography>{product?.productName}</Typography>
                        </Grid>
                        <Grid item xs="auto" mt={-1} key={`product-switch-${index}`}>
                        <CustomCheckbox
                          control={control}
                          checked={
                            (() => {
                              const selectedPractice = isEditing && multiplePracticesInfo?.find(practice =>
                                  practice.practiceProviders.some(provider =>
                                    provider.providerId === Number(selectedProviderId)
                                  )
                                );
                              return selectedPractice && selectedPractice.billingType === "GROUP"
                                ? eligibleProducts.includes(product.productId)
                                : true;
                            })()
                          }
                          isDisabled={
                            (() => {
                              const selectedPractice = isEditing && multiplePracticesInfo?.find(practice =>
                                  practice.practiceProviders.some(provider =>
                                    provider.providerId === Number(selectedProviderId)
                                  )
                                );

                              return canManageProviderProductsSection && selectedPractice && selectedPractice.billingType === "GROUP" ? false : true;
                            })()
                          }
                          onChange={(e) =>
                            handleCheckingForProducts({ value: product.productId.toString() }, e.target.checked)
                          }
                        />
                        </Grid>
                      </>
                    ) 
                  })}
                </Grid>
                </>
                )}
                {/* // */}
              </Grid>
              </>
              ):null}
            {/* // */}
            <Grid
              mt={2}
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              { !providerAssigned && <Grid item xs={12} md="auto">
                <Button
                  type="submit"
                  color="info"
                  startIcon={<SaveOutlined />}
                  fullWidth
                >
                  {isEditing ? "Update User" : "Create User"}
                </Button>
              </Grid>}
              <Grid item xs={12} md="auto">
                <Button
                  color="error"
                  startIcon={<CancelOutlined />}
                  onClick={() => onClose()}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Box>
  );
};

export default CreateUser;