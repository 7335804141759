import { Box, Grid, Stack, useTheme, Typography  } from "@mui/material";
import Header from "../../common/Header";
import FormStepper from "../../common/FormStepper";
import Card from "../../common/Card";
import React, { useEffect, useMemo, useState } from "react";
import PracticeEditViewForm from "./PracticeEditViewForm";
import Table from "../../common/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../common/Button";
import {
  AddCircleOutlineOutlined,
  CancelOutlined,
  DoDisturbAltOutlined,
  FileOpenOutlined,
} from "@mui/icons-material";
import { fetchPracticeByIdData, resetPracticeEditInfo, usePracticeEditSlice } from "../../../redux/slice/practice/practiceEditSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  usePracticeLocationsSlice,
  fetchPracticeLocationsData,
  PracticeLocationInfo,
  savePracticeLocations,
  inactivePracticeLocation,
  resetPracticeLocationsInfo,
} from "../../../redux/slice/practice/practiceLocationSlice";
import {
  usePracticeProvidersSlice,
  fetchPracticeProvidersData,
  PracticeProviderInfo,
  savePracticeProviders,
  inactivePracticeProvider,
  resetPracticeProvidersInfo,
  fetchUsedPracticeProviderIDs,
} from "../../../redux/slice/practice/practiceProvidersSlice";
import {
  usePracticeProductsSlice,
  fetchPracticeProductsData,
} from "../../../redux/slice/practice/practiceProductSlice";
import CustomAlert from "../../common/CustomAlert";
import PracticeLocationPopup, { initialState } from "./PracticeLocationPopup";
import ProviderPopup, { providerInitialState } from "./ProviderPopup";
import moment from "moment";
import {
  SingleAlertInfo,
  setSingleAlertObj,
} from "../../../redux/slice/commonSlice";
import BillerInfoEditViewForm from "./BillerInfoEditViewForm";
import PracticeExecutiveInfoViewForm from "./PracticeExecutiveInfoViewForm";
import OptionsPopup from "../../common/OptionsPopup";
import { Controller, useForm } from "react-hook-form";
import LegacyToolBar from "../../common/LegacyToolBar";
import { USER_ROLES } from "../../../constants/applicationConstants";
import { useAuthSlice } from "../../../redux/slice/authSlice";
import CustomIconButton from "../../common/CustomIconButton";
import { tokens } from "../../../theme";
import IconDetailToolBar from "../../common/IconDetailToolBar";
import AuthorizedSignerPopup, { AuthorizedSignerInfo } from "./AuthorizedSignerPopup";
import ErrorDialog from "../../common/ErrorDialog";
import { fetchDocumentById, usePracticeSlice } from "../../../redux/slice/practiceSlice";
import PDFViewer from "../../common/pdf/PDFViewer";

const PracticeEditView = () => {
  interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "right" | "center" | "left";
    format?: (value: number) => string;
  }

  const columns: Column[] = [
    {
      id: "combinedAddress",
      label: "Practice Location Address",
      minWidth: 100,
    },
    { id: "modifiedInactiveDate", label: "Inactive Date", minWidth: 100 },
    { id: "billingAddress", label: "Billing Address", minWidth: 100 },
    {
      id: "action",
      label: "Action",
      minWidth: 170,
      align: "center",
    },
  ];

  const columns2: Column[] = [
    { id: "fullName", label: "Provider Name", minWidth: 110 },
    { id: "npi", label: "NPI", minWidth: 30 },
    { id: "ptan", label: "PTAN", minWidth: 30 },
    { id: "taxId", label: "TAX ID", minWidth: 30 },
    { id: "modifiedInactiveDate", label: "Inactive Date", minWidth: 30 },
    {
      id: "action",
      label: "Action",
      minWidth: 170,
      align: "center",
    },
  ];

  const columns3: Column[] = [
    { id: "productName", label: "Product Name", minWidth: 100 },
    { id: "agreementStatus", label: "Agreement Status", minWidth: 100 },
    {
      id: "action",
      label: "Action",
      minWidth: 170,
      align: "center",
    },
  ];

  const columns4: Column[] = [
    { id: "fullName", label: "Full Name", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
  ];

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { practiceEditInfo } = usePracticeEditSlice();
  const { practiceProvidersInfo, usedPracticeProviderIds } = usePracticeProvidersSlice();
  const { practiceProductsInfo } = usePracticeProductsSlice();
  const { practiceLocationsInfo } = usePracticeLocationsSlice();
  const [isAlertShow, setIsAlertShow] = React.useState(false);
  const [showLocationPopup, setShowLocationPopup] = React.useState(false);
  const [showProviderPopup, setShowProviderPopup] = React.useState(false);
  const [provider, setProvider] = React.useState<PracticeProviderInfo[]>([]);
  const [locationItemObject, setLocationItemObject] =
    React.useState<PracticeLocationInfo>(initialState);
  const [providerItemObject, setProviderItemObject] =
    React.useState<PracticeProviderInfo>(providerInitialState);
  const [inactiveLocation, setInactiveLocation] = React.useState<{
    message: string;
    show: boolean;
    row: any;
  }>({ message: "", show: false, row: "" });
  const [inactiveProvider, setInactiveProvider] = React.useState<{
    message: string;
    show: boolean;
    row: any;
  }>({ message: "", show: false, row: "" });
  const { getValues } = useForm();
  const { userInfo } = useAuthSlice();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showAuthorizedSignerPopup, setShowAuthorizedSignerPopup] = React.useState(false);
  const location = useLocation();
  const [totalLocationAddressRows, setTotalLocationAddressRows] = React.useState(0);
  const [billingRows, setBillingRows] = React.useState(0);
  const [inactiveBillingRows, setInactiveBillingRows] = React.useState(0);
  const [locationError, setLocationError] = React.useState('');
  const [totalProvidersRows, setTotalProvidersRows] = React.useState(0);
  const [inactiveProvidersRows, setInactiveProvidersRows] = React.useState(0);
  const [providerError, setProviderError] = React.useState('');
  const [lastActiveProvider, setLastActiveProvider] = React.useState(false);
  const [practiceUpdated, setPracticeUpdated] = React.useState(false);
  const shouldShowConfirmButton = lastActiveProvider;
  const [optionPopupShow, setOptionPopupShow] = React.useState<{
    message: string;
    show: boolean;
  }>({ message: "", show: false });
  const [showPDF, setShowPDF] = useState(false);
  const { document } = usePracticeSlice(); 

  React.useEffect(() => {
    const practiceId = location?.state?.practiceId;
    if (practiceId){
      dispatch(fetchPracticeByIdData(practiceId)).then((res)=>{
        if (res.payload && res.payload.code === "V3OK" && res.payload.message === "SUCCESS"){
          dispatch(fetchPracticeLocationsData(practiceId));
          dispatch(fetchPracticeProvidersData(practiceId));
          dispatch(fetchPracticeProductsData(practiceId));
        }
      })
    }
  }, []);

  useEffect(()=>()=>{
    dispatch(resetPracticeEditInfo());
    dispatch(resetPracticeLocationsInfo());
    dispatch(resetPracticeProvidersInfo());
    dispatch(fetchUsedPracticeProviderIDs());
  },[])

  // const handleEditActionLocation = (row: any) => {
  //   let isBillingAddress = "";
  //   if (row.locationType === "BILLING") {
  //     isBillingAddress = "yes";
  //   } else {
  //     isBillingAddress = "no";
  //   }
  //   const combinedObject = {
  //     ...row,
  //     isBillingAddress: isBillingAddress,
  //   };
  //   setLocationItemObject(combinedObject);
  //   setShowLocationPopup(true);
  // };

  const handleInactivateBillingAddress = (row: any) => {
    const lastActiveBillingAddress = row.locationType === "BILLING";
    if (lastActiveBillingAddress) {
      setInactiveLocation({
        message:
        "At least 1 billing address is required, please add a new billing address.",
        show: true,
        row: row,
      });

      let ErrObj: SingleAlertInfo = {
        message: "At least 1 billing address is required, please add a new billing address.",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(ErrObj));

    } else {
      setInactiveLocation({
        message:
          "Do you really want to inactive this practice location? ",
        show: true,
        row: row,
      });

    };
  }

  const confirmInactiveLocation = () => {
    if (inactiveLocation.row && inactiveLocation.row.locationType === "BILLING") {
      setShowLocationPopup(true);
    } 
      handleDeleteActionLocation(inactiveLocation.row);
      setInactiveLocation({ message: "", show: false, row: null });
  };

  const cancelInactiveLocation = () => {
    setInactiveLocation({ message: "", show: false, row: "" });
  };

  const handleDeleteActionLocation = async (row: any) => {
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format("MM/DD/YYYY");
    const data = {
      locationId: row.locationId,
      inactiveDate: formattedDate,
    };
    const response = await dispatch(inactivePracticeLocation(data));
    if (practiceEditInfo) {
      if (
        response.payload !== undefined &&
        response.payload.message === "SUCCESS"
      ) {
        let successObj: SingleAlertInfo = {
          message: "Practice Location Successfully Inactivated",
          alertType: "success",
        };
        setPracticeUpdated(true);
        dispatch(setSingleAlertObj(successObj));
        dispatch(fetchPracticeLocationsData(practiceEditInfo.practiceId));
      } else if (response.payload !== undefined && response.payload.message) {
        let errorObj: SingleAlertInfo = {
          message: response.payload.message,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        let errorObj: SingleAlertInfo = {
          message: "Practice Location Inactive Failed.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    }
  };

  // const handleEditActionProvider = (row: any) => {
  //   setProviderItemObject(row);
  //   setShowProviderPopup(true);
  // };

  const handleInactivateProvider = (row: any) => {
    if (lastActiveProvider) {
      setInactiveProvider({
        message:
          "At least 1 practice provider is required.",
        show: true,
        row: row,
      });
    } else{
      setInactiveProvider({
        message:
          "Do you really want to inactive this practice provider? ",
        show: true,
        row: row,
      });
    }
  }

  const confirmInactiveProvider = () => {
    handleDeleteActionProvider(inactiveProvider.row);
    setInactiveProvider({ message: "", show: false, row: "" });
  };

  const cancelInactiveProvider = () => {
    setInactiveProvider({ message: "", show: false, row: "" });
  };

  const handleDeleteActionProvider = async (row: any) => {
    const currentDate = new Date();
    const formattedDate = moment(currentDate).format("MM/DD/YYYY");
    const data = {
      providerId: row.providerId,
      inactiveDate: formattedDate,
    };
    const response = await dispatch(inactivePracticeProvider(data));
    if (practiceEditInfo) {
      if (
        response.payload !== undefined &&
        response.payload.message === "SUCCESS"
      ) {
        let successObj: SingleAlertInfo = {
          message: "Practice Provider Successfully Inactivated",
          alertType: "success",
        };
        setPracticeUpdated(true);
        dispatch(setSingleAlertObj(successObj));
        dispatch(fetchPracticeProvidersData(practiceEditInfo.practiceId));
      } else if (response.payload !== undefined && response.payload.message) {
        let errorObj: SingleAlertInfo = {
          message: response.payload.message,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        let errorObj: SingleAlertInfo = {
          message: "Practice Provider Inactive Failed.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    }
  };

  const legacyItems = [
    // { icon: <ModeOutlined color="info" />, text: "Edit" },
    { icon: <DoDisturbAltOutlined color="warning" />, text: "Inactivate" },
  ];

  const actionComponentLocationPopup = (row: any): JSX.Element => {
    return (
      <>
        {row.inactiveDate === null || row.inactiveDate === "" ? (
          <>
            {/* <CustomIconButton
              color="info"
              size="small"
              variant="outlined"
              tooltipMessage="Edit"
              tooltipPlacement="top"
              onClick={() => handleEditActionLocation(row)}
            >
              <ModeOutlined fontSize="small" />
            </CustomIconButton> */}
            <CustomIconButton
              color="warning"
              size="small"
              variant="outlined"
              tooltipMessage="Inactivate"
              tooltipPlacement="top"
              onClick={() => {handleInactivateBillingAddress(row)}}
            >
              <DoDisturbAltOutlined fontSize="small" />
            </CustomIconButton>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const actionComponentProviderPopup = (row: any): JSX.Element => {
    return (
      <>
        {row.inactiveDate === null || row.inactiveDate === "" ? (
          <>
            {/* <CustomIconButton
              color="info"
              size="small"
              variant="outlined"
              tooltipMessage="Edit"
              tooltipPlacement="top"
              onClick={() => handleEditActionProvider(row)}
            >
              <ModeOutlined fontSize="small" />
            </CustomIconButton> */}
            <CustomIconButton
              color="warning"
              size="small"
              variant="outlined"
              tooltipMessage="Inactivate"
              tooltipPlacement="top"
              onClick={() => {handleInactivateProvider(row)}} 
            >
              <DoDisturbAltOutlined fontSize="small" />
            </CustomIconButton>
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const clickShowLocationPopup = () => {
    setLocationItemObject(initialState);
    setShowLocationPopup(true);
  };

  const clickClosePracticesPopup = () => {
    setShowLocationPopup(false);
  };

  const clickShowProviderPopup = () => {
    setProviderItemObject(providerInitialState);
    setShowProviderPopup(true);
  };

  const clickCloseProviderPopup = () => {
    setShowProviderPopup(false);
  };

  const clickShowAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(true);
  };

  const clickCloseAuthorizedSignerPopup = () => {
    setShowAuthorizedSignerPopup(false);

  };

  const addPractice = async (practiceLocation: PracticeLocationInfo) => {

    // if (practiceLocation.isBillingAddress === "yes") {
    //   const hasBillingAddress = modifiedPracticeLocationInfo.some(
    //     (location) => location.locationType === "BILLING"
    //   );

    //   if (hasBillingAddress && practiceLocation.locationId === undefined) {
    //     let errorObj: SingleAlertInfo = {
    //       message:
    //         "There's already a practice location marked as the billing address. Please update the existing location instead.",
    //       alertType: "error",
    //     };
    //     dispatch(setSingleAlertObj(errorObj));
    //     return;
    //   }
    // }

    if (practiceEditInfo) {
      const modifiedPracticeLocation = {
        ...practiceLocation,
        practiceId: practiceEditInfo.practiceId,
      };
      const response = await dispatch(
        savePracticeLocations(modifiedPracticeLocation)
      );
      if (
        response.payload !== undefined &&
        response.payload.message === "SUCCESS"
      ) {
        let successObj: SingleAlertInfo = {
          message:
            practiceLocation.locationId === undefined
              ? "Practice Location Successfully Added."
              : "Practice Location Successfully Updated",
          alertType: "success",
        };
        setPracticeUpdated(true);
        dispatch(setSingleAlertObj(successObj));
        dispatch(fetchPracticeLocationsData(practiceEditInfo.practiceId));
      } else if (response.payload !== undefined && response.payload.message) {
        let errorObj: SingleAlertInfo = {
          message: response.payload.message,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        let errorObj: SingleAlertInfo = {
          message: "Practice Location Adding Failed.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    }
  };

  const addProvider = async (provider: PracticeProviderInfo) => {
    if (practiceEditInfo) {
      const modifiedPracticeProvider = {
        ...provider,
        practiceId: practiceEditInfo.practiceId,
      };
      const response = await dispatch(
        savePracticeProviders(modifiedPracticeProvider)
      );
      if (
        response.payload !== undefined &&
        response.payload.message === "SUCCESS"
      ) {
        let successObj: SingleAlertInfo = {
          message:
            provider.providerId === undefined
              ? "Practice Provider Successfully Added."
              : "Practice Provider Successfully Updated.",
          alertType: "success",
        };
        setPracticeUpdated(true);
        dispatch(setSingleAlertObj(successObj));
        dispatch(fetchPracticeProvidersData(practiceEditInfo.practiceId));
      } else if (response.payload !== undefined && response.payload.message) {
        let errorObj: SingleAlertInfo = {
          message: response.payload.message,
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      } else {
        let errorObj: SingleAlertInfo = {
          message: "Practice Provider Adding Failed.",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(errorObj));
      }
    }
  };

  const addSigner = (signer: AuthorizedSignerInfo) => {
  };

  const handleSignerChange = (name: string, value: string) => {
  };

  const handleProviderChange = (name: string, value: string) => {
    setProvider((prevPro) => ({
      ...prevPro,
      [name]: value,
    }));
  };

  //modify practice locations object array to display the combined address
  const modifiedPracticeLocationInfo = useMemo(() => {
    if (practiceLocationsInfo) {
      return practiceLocationsInfo.map((info) => ({
        ...info,
        combinedAddress: `${info.streetAddress}${info.unitNumber ? ', ' + info.unitNumber : ''}${info.city ? ', ' + info.city : ''}${info.state ? ', ' + info.state : ''}${info.zipCode ? ', ' + info.zipCode : ''}`,
        modifiedInactiveDate:
          info.inactiveDate !== null
            ? moment(info.inactiveDate).format("MM/DD/YYYY")
            : "",
        billingAddress: info.locationType === "BILLING" ? "Yes" : "No"
      }));
    }
    return [];
  }, [practiceLocationsInfo]);

  //modify the practice providers object array to display full name
  const modifiedPracticeProvidersInfo = useMemo(() => {
    if (practiceProvidersInfo) {
      return practiceProvidersInfo.map((info) => ({
        ...info,
        fullName: `${info.title ? info.title + " " : " "} ${info.firstName} ${
          info.middleName ? info.middleName + " " : ""
        }${info.lastName}`,
        modifiedInactiveDate:
          info.inactiveDate !== null
            ? moment(info.inactiveDate).format("MM/DD/YYYY")
            : "",
      }));
    }
    return [];
  }, [practiceProvidersInfo]);

  const authorizedSignersInfo = useMemo(() => {
    if (practiceEditInfo) {
      return practiceEditInfo.practiceRequestInfo?.authorizedSigners?.map((info) => ({
        ...info,
        fullName: info.fullName,
        email: info.email
      }));
    }
    return [];
  }, [practiceEditInfo]);

  useEffect(() => {
    const totalLocations = modifiedPracticeLocationInfo.length;
    const billing = modifiedPracticeLocationInfo.filter(row => row.locationType === 'BILLING').length;
    const inactiveBilling = modifiedPracticeLocationInfo.filter(row => row.locationType === 'BILLING' && row.inactiveDate).length;

    setTotalLocationAddressRows(totalLocations);
    setBillingRows(billing);
    setInactiveBillingRows(inactiveBilling);

    if (billing > 0 && billing === inactiveBilling) {
      setLocationError('All the billing addresses are inactivated, please add a new billing address.');
    } else {
      setLocationError('');
    }
  }, [modifiedPracticeLocationInfo]);

  useEffect(() => {
    const totalProviders = modifiedPracticeProvidersInfo.length;
    const inactiveProvider = modifiedPracticeProvidersInfo.filter(row => row.inactiveDate).length;

    setTotalProvidersRows(totalProviders);
    setInactiveProvidersRows(inactiveProvider);

    if (inactiveProvider > 0 && totalProviders === inactiveProvider) {
      setProviderError('All the practice providers are inactivated, please add a new practice provider.');
    } else {
      setProviderError('');
    }
  }, [modifiedPracticeProvidersInfo]);

  useEffect(() => {
    const provider = modifiedPracticeProvidersInfo.filter(row => row.inactiveDate === null).length;
    setLastActiveProvider(!!modifiedPracticeProvidersInfo.length && provider === 1);
  }, [modifiedPracticeProvidersInfo]);

  const downloadPracticeRegistration = async () => {
    const documentId = location?.state?.documentId
    if (documentId && documentId > 0) {
      const response = await dispatch(fetchDocumentById(documentId));
      const documentData = response.payload;
      if (documentData && documentData.content) {
        setShowPDF(true);
      }
      else {
        let errorObj: SingleAlertInfo = {
          message: `Associate agreement document not exists for this practice Id ${practiceEditInfo?.practiceId} please contact administrator`,
          alertType: "info"
        }
        dispatch(setSingleAlertObj(errorObj));
      }

    } else {
      let errorObj: SingleAlertInfo = {
        message: "Not found associate agreement document",
        alertType: "error",
      };
      dispatch(setSingleAlertObj(errorObj));
    }
  };

  const contentChangedStatus = () => {
    setPracticeUpdated(true);
  }
  const handleClose = () => {
    setShowPDF(false);
  }

  return (
    <>
      <PracticeLocationPopup
        onClose={clickClosePracticesPopup}
        open={showLocationPopup}
        addPractice={addPractice}
        practiceLocations={practiceLocationsInfo}
        billingAddressPanel={false}
        practiceLocationObject={locationItemObject}
      />
      <ProviderPopup
        onClose={clickCloseProviderPopup}
        open={showProviderPopup}
        addProvider={addProvider}
        onChange={handleProviderChange}
        practiceProviderObject={providerItemObject}
      />
      <AuthorizedSignerPopup
        onClose={clickCloseAuthorizedSignerPopup}
        open={showAuthorizedSignerPopup}
        addAuthorizedSigner={addSigner}
        onChange={handleSignerChange}
      />
      <Box sx={{
          margin: {
            xs: "20px 10px", 
            sm: "20px",
          },
        }}>
        <CustomAlert
          open={isAlertShow}
          onClose={() => {
            setIsAlertShow(false);
          }}
          severity="info"
          message="Feature currently under development. Stay tuned for updates!"
        />
        <Header title="Practice View" subtitle="Practice Edit and View" />
        <Box mb="20px">
          <Card>
            <PracticeEditViewForm activeStep={activeStep} contentChangedStatus={contentChangedStatus} />
          </Card>
        </Box>
        <Box mb="20px">
          <Card>
            <BillerInfoEditViewForm contentChangedStatus={contentChangedStatus} />
          </Card>
        </Box>
        <Box mb="20px">
          <Card>
            <PracticeExecutiveInfoViewForm contentChangedStatus={contentChangedStatus} />
          </Card>
        </Box>
        <Box mb="20px">
          <Grid container spacing={1}>
            <Grid item md={5} xs={12}>
              <Card>
                <Grid
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 1, flexWrap: "wrap", mb: 1 }}
                  spacing={1}
                >
                </Grid>
                <Grid container direction="row" alignItems="center" sx={{ mt: -2.5 }}>
                  <div style={{ height: "10px" }}></div>
                  <Header title="Authorized Signer(s)" subtitle="" />
                  <div style={{ height: "20px" }}></div>
                </Grid>
                <Table
                  columns={columns4}
                  rows={authorizedSignersInfo?authorizedSignersInfo:[]}
                  disablePagination={true}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mb="20px">
          <Grid container spacing={1}>
            <Grid item md={5} xs={12}>
              <Card>
                <Grid
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 1, flexWrap: "wrap", mb : 1 }}
                  spacing={1}
                >
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: -2.5 }}>
                  <Grid sx={{ display: "flex", borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }}>
                    {
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center">
                        <Box sx={{ display: "inline-block", mr: 2, mt: 0.5 }}><IconDetailToolBar items={legacyItems} /></Box>
                      </Grid>
                    }
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" >
                      {
                        <Button
                          fullWidth
                          startIcon={<AddCircleOutlineOutlined />}
                          onClick={clickShowLocationPopup}
                          color={billingRows > 0 && billingRows === inactiveBillingRows ? "error": "success"}
                        >
                          Add More
                        </Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              {locationError && (
                <Typography color="error" sx={{ mt: 1, mb: 1, textAlign: "center" }}>
                  {locationError}
                </Typography>
              )}
                <Table
                  columns={columns}
                  rows={modifiedPracticeLocationInfo}
                  actionComponent={(row: any) =>
                    actionComponentLocationPopup(row)
                  }
                  disablePagination={true}
                />
              </Card>
            </Grid>
            <Grid item md={7} xs={12}>
              <Card>
                <Grid
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 1, flexWrap: "wrap", mb: 1 }}
                  spacing={1}
                >
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: -2.5 }}>
                  <Grid sx={{ display: "flex", borderRadius: 1, boxShadow: 1, mb: 1, p: 1, backgroundColor: colors.grey[900] }}>
                    {
                      <Grid item md={"auto"} textAlign={"end"} alignItems="center">
                        <Box sx={{ display: "inline-block", mr: 2, mt: 0.5 }}><IconDetailToolBar items={legacyItems} /></Box>
                      </Grid>
                    }
                    <Grid item md={"auto"} textAlign={"end"} alignItems="center" >
                      {
                        <Button
                          startIcon={<AddCircleOutlineOutlined />}
                          onClick={clickShowProviderPopup}
                          fullWidth
                          color={inactiveBillingRows > 0 && totalProvidersRows === inactiveProvidersRows ? "error": "success"}
                        >
                          Add More
                        </Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                {providerError && (
                <Typography color="error" sx={{ mt: 1, mb: 1, textAlign: "center" }}>
                  {providerError}
                </Typography>
              )}
                <Table
                  columns={columns2}
                  rows={
                    modifiedPracticeProvidersInfo
                      ? modifiedPracticeProvidersInfo
                      : []
                  }
                  actionComponent={(row: any) =>
                    actionComponentProviderPopup(row)
                  }
                  disablePagination={true}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box>
        <Grid item md={12} xs={12}>
          <React.Fragment>
            <Stack direction="row" justifyContent="flex-end" >
              <Button
                sx={{ mb: 2, mr: 1 }}
                color="info"
                  startIcon={<FileOpenOutlined />}
                  onClick={() => {
                    downloadPracticeRegistration();
                  }}
                >
                  PREVIEW ARCHIVE AGREEMENT
                </Button>
              <Button
                sx={{ mb: 2 }}
                color="error"
                startIcon={<CancelOutlined />}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Stack>
          </React.Fragment>
        </Grid>
        </Box>
      </Box>
      <OptionsPopup
        open={inactiveLocation.show}
        onClose={() =>
          setInactiveLocation({ message: "", show: false, row: "" })
        }
        variant="confirm"
        message={<>{inactiveLocation.message}</>}
        buttons={[
          {
            name: "Confirm",
            color: "primary",
            onClick: confirmInactiveLocation,
          },
          {
            name: "Cancel",
            color: "secondary",
            onClick: cancelInactiveLocation,
          },
        ]}
      />
      <OptionsPopup
        open={!lastActiveProvider && inactiveProvider.show}
        onClose={() =>
          setInactiveProvider({ message: "", show: false, row: "" })
        }
        variant="confirm"
        message={<>{inactiveProvider.message} <br></br> {usedPracticeProviderIds && usedPracticeProviderIds.includes(inactiveProvider.row.providerId) && "Please note: Deactivating this practice provider will also deactivate the associated user profile."}</>}
        buttons={[
          {
            name: "Confirm",
            color: "primary",
            onClick: confirmInactiveProvider,
          },
          {
            name: "Cancel",
            color: "secondary",
            onClick: cancelInactiveProvider,
          },
        ]}
      />
      <ErrorDialog
        open={lastActiveProvider && inactiveProvider.show}
        onClose={() =>
          setInactiveProvider({ message: "", show: false, row: "" })
        }
        content = {inactiveProvider.message}
      />
      <PDFViewer
        isOpen={showPDF}
        onClose={handleClose}
        fileName={document?.documentName + ""}
        title={document?.documentName + ""}
        base64String={document?.content || ""}
      />
    </>
  );
};

export default PracticeEditView;
