import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../common/Button";
import { PracticeLocationInfo, PracticeProviderInfo } from "../../../redux/slice/productAgreement/createAgreementSlice";
import { useCommonReducer } from "../../../redux/slice/commonSlice";
import { Divider, FormControl, useTheme } from '@mui/material';
import Table from "../../common/CustomTable";
import { tokens } from "../../../theme";
import { useDispatch } from "react-redux";
import { fetchPracticeByIdData } from "../../../redux/slice/practice/practiceEditSlice";
import CustomRadioButton from "../../common/CustomRadioButton";
import { Cancel } from "@mui/icons-material";

interface PracticeDetailsPopup {
    open: boolean;
    onClose: () => void;
    practiceId: string | null
};

interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: "left" | "center" | "right";
    format?: (value: number) => string;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const locationColumns: Column[] = [
    { id: "id", label: "Practice Location Address", minWidth: 100 },
];

const providerColumns: Column[] = [
    { id: "name", label: "Provider Name", minWidth: 100 },
    { id: "npi", label: "Provider NPI", minWidth: 100 },
    { id: "ptan", label: "Provider PTAN", minWidth: 100 },
];


const PracticeDetailsPopup: React.FC<PracticeDetailsPopup> = ({
    open,
    onClose,
    practiceId
}) => {

    const { control, handleSubmit, reset, watch, getValues, setValue } = useForm();
    const [locationRows, setLocationRows] = React.useState<{ id: string }[]>([]);
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);
    const [providerRows, setProviderRows] = React.useState<{ name: string, npi: string, ptan: string }[]>([]);
    const { practiceInfo } = useCommonReducer();


    useEffect(() => {

        //@ts-ignore
        practiceId && dispatch(fetchPracticeByIdData(practiceId)
        ).then((res: any) => {
            let practice = res.payload?.data;
            setLocations(practice);
            setProviders(practice);
            setValue("billingType",practice?.billingType);
        });
    }, [practiceId]);

    const setLocations = (practiceRow: any) => {
        const locationsArr: { id: string; }[] = [];
        practiceRow && practiceRow.practiceLocations?.forEach((location: PracticeLocationInfo) => {
            const locationParts = [location.streetAddress, location.unitNumber, location.city, location.state, location.zipCode];
            const filteredLocationParts = locationParts.filter(part => part !== undefined && part !== null);
            locationsArr.push({
                id: filteredLocationParts?.join(", ")
            });
        });
        setLocationRows(locationsArr);
    };

    const setProviders = (practiceRow: any) => {
        const providersArr: { name: string, npi: string, ptan: string }[] = [];
        practiceRow && practiceRow.practiceProviders?.forEach((provider: PracticeProviderInfo) => {
            providersArr.push({
                name: (provider.title ? provider.title + " " : "") + provider.firstName + " " + provider.middleName + " " + provider.lastName,
                npi: provider.npi,
                ptan: provider.ptan
            });
        });
        setProviderRows(providersArr);
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <form>
                    <DialogTitle>Practice Details</DialogTitle>
                    <DialogContent>
                        <Box p={1}>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <p>
                                        How the practice bills?
                                    </p>
                                    <FormControl>
                                        <CustomRadioButton control={control} controllerName="billingType" options={[{ value: "INDIVIDUAL", label: "Individual NPI" }, { value: "GROUP", label: "Group NPI" }]} isDisabled={true}></CustomRadioButton>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                        <Table
                                            columns={providerColumns}
                                            rows={providerRows}
                                            disablePagination={true}
                                            showMenu={true} 
                                            borderBox = {true}/>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Table
                                            columns={locationColumns}
                                            rows={locationRows}
                                            disablePagination={true} 
                                            showMenu={true}
                                            borderBox = {true}/>
                                            
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color="error" onClick={onClose}  startIcon={<Cancel />}>
                            close
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    )
}

export default PracticeDetailsPopup;