import { ManufacturerCreateObj } from "../components/operation/manufacturerNotification/CreateManufacturer";
import { EDIT_MANUFACTURER_URL, GET_MANUFACTURER_DATA_BY_ID, SAVE_MANUFACTURER_URL } from "./URL";
import { http } from "./http";

export const saveManufacturer = async (saveManufacturer: ManufacturerCreateObj) => {
    try{
        const response = await http.post(SAVE_MANUFACTURER_URL, saveManufacturer, undefined, {skipErrorPopup: true});
        return response;
    }
    catch(error){
        throw error;
    }
};
export const editManufacturer = async (EditManufacturerData: ManufacturerCreateObj) => {
    try{
        const response = await http.post(EDIT_MANUFACTURER_URL, EditManufacturerData, undefined, {skipErrorPopup: true});
        return response;
    }
    catch(error){
        throw error;
    }
};

export const getDetailsOfManufacturerById = async (mfrId:number)=>{
    const response = await http.get(`${GET_MANUFACTURER_DATA_BY_ID}?manufacturerId=${mfrId}`);
    return response;
}