import React, { useState } from "react";
import {
  useTheme,
  Slide,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Box,
  Divider,
} from "@mui/material";
import { CommentOutlined } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import Button from "./Button";

interface CustomCommentPopupProps {
  title?: string;
  commentBody?: string;
  open: boolean;
  onClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomCommentPopup: React.FC<CustomCommentPopupProps> = ({
  title,
  commentBody,
  open,
  onClose,
}) => {
  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
      >
        <DialogTitle> {title}</DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" textAlign="center">
            {commentBody}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button onClick={handleClose}>Cancel</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomCommentPopup;
