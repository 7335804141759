import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from "../redux-hooks";
import {getManufacturerList} from "../../../service/ManufacturerNotificationService";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GeneralV3Response, LoadingType } from '../../../types/CommonTypes';
import { updateElementValue } from '../../../utils/updateElementValue';
import { ManufacturerCreateObj } from '../../../components/operation/manufacturerNotification/CreateManufacturer';
import { editManufacturer, getDetailsOfManufacturerById, saveManufacturer } from "../../../service/ManufacturerService";

export type ManufactureInfo={
  contactPhone:string;
  customConfig:{
    invoice_due_date:string;
    invoice_net_days:string;
  };
  manufacturerId:number;
  manufacturerName:string
}

export interface ManufacturerData {
  manufacturerName:string;
  contactPhone:string;
  notificationEmail:string;
  customConfig:string;
  manufacturerId:string;
}
type ManufacturerListState = {
    ManufacturerListInfo?: ManufactureInfo[] | null;
    ManufacturerListFetchStatus?: LoadingType;
    status?: LoadingType;
    ManufacturerData?:ManufacturerCreateObj
}


const initialState: ManufacturerListState = {
    ManufacturerListInfo: undefined,
    ManufacturerData: undefined
};

export const fetchManufacturerList = createAsyncThunk("fetchManufacturerList", async () => {
    const response = await getManufacturerList();
    const v3Response = response.data;
    console.log("V3 Response", v3Response);
    return v3Response;
});

export const saveManufacturers = createAsyncThunk("saveManufacturer", async (data: ManufacturerCreateObj, { rejectWithValue }) => {
  try {
    const response = await saveManufacturer(data); 
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});
export const editManufacturerData = createAsyncThunk("editManufacturerData", async (data: ManufacturerCreateObj, { rejectWithValue }) => {
  try {
    const response = await editManufacturer(data); 
    return response.data;
  } catch (error) {
    //@ts-ignore
    return rejectWithValue(error?.data);
  }
});

export const fetchManufacturerDataById = createAsyncThunk('fetchManufacturerDataById',async(mfrId:number)=>{
  const response = getDetailsOfManufacturerById(mfrId)
  return response;

});

// Create the slice
const manufacturerSlice = createSlice({
  name: 'manufacturer',
  initialState,
  reducers: {
    updateElementInManufacturerSlice : updateElementValue,
    resetManufacturerData: (state: ManufacturerListState) => {
      return {
        ...state,
        ...initialState,
      };
    },
    resetManufacturerList: (state: ManufacturerListState) => {
      state.ManufacturerListInfo = undefined;
      state.ManufacturerListFetchStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchManufacturerList.pending, (state) => {
        state.ManufacturerListFetchStatus = "loading";
      })
      .addCase(fetchManufacturerList.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.ManufacturerListFetchStatus = "success";
        state.ManufacturerListInfo = action.payload.data;
      })
      .addCase(fetchManufacturerList.rejected, (state, action) => {
        state.ManufacturerListFetchStatus = 'error';
      })
      .addCase(fetchManufacturerDataById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchManufacturerDataById.fulfilled, (state, action: PayloadAction<GeneralV3Response>) => {
        state.status= "success";
        state.ManufacturerData = action.payload.data.data;
      })
      .addCase(fetchManufacturerDataById.rejected, (state, action) => {
        state.status = 'error';
      })
  }
});

// Export actions for use in components
export const {
    resetManufacturerData,
    resetManufacturerList,
} = manufacturerSlice.actions;

// Export the reducer for the store configuration
export const useManufacturerListSlice = () => useAppSelector((state) => state.manufacturerSlice);
export default manufacturerSlice.reducer;
