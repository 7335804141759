import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Typography,
  useTheme,
  Box,
  Pagination,
  useMediaQuery,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Stack,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { tokens } from "../../theme";
import { IVR_STATUS } from "../../constants/applicationConstants";
import styled from "styled-components";
import Card from "./Card";
import { ArrowUpwardOutlined, ImportExportOutlined, RadioButtonChecked } from "@mui/icons-material";
import Button from "./Button";

interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: "right" | "center" | "left";
  format?: (value: number) => string;
  padding?: string;
}

interface Row {
  [key: string]: any;
  uniqueId?: any;
}

interface Props {
  columns: Column[];
  rows: Row[];
  hiddenColumns?: string[];
  onRowClick?: (row: Row) => void;
  actionComponent?: (row: any, rowIndex: number) => JSX.Element;
  disablePagination?: boolean;
  borderBox?: boolean;
  emptyText?: string;
  pageNumber?: (newPage: number) => void;
  totalPages?: number;
  totalElementText?: string;
  resetPage?: boolean;
  currentPageNumber?: number;
  uniqueValue?: string;
  cardContentComponent?: (row: Row) => JSX.Element;
  disabled?: boolean;
  availabilitySwitchComponent?: (row: any, rowIndex: number) => JSX.Element;
  showMenu?: boolean;
  visibleHeader?: boolean;
  sortableColumns?: string[];
  singleActionComponent?: JSX.Element;
}

const CustomTable: React.FC<Props> = ({
  columns,
  rows,
  hiddenColumns = [],
  onRowClick,
  actionComponent,
  disablePagination,
  borderBox,
  emptyText,
  pageNumber,
  totalPages,
  totalElementText,
  resetPage,
  currentPageNumber,
  uniqueValue,
  cardContentComponent,
  disabled,
  availabilitySwitchComponent,
  showMenu,
  visibleHeader = true,
  sortableColumns,
  singleActionComponent
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState<Row | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuRow, setMenuRow] = useState<Row | null>(null);
  const [menuActionPropRow, setMenuActionPropRow] = useState<any | null>(null);
  const [isActionProps, setIsActionProps] = useState(false);
  const [activeMenuRow, setActiveMenuRow] = useState<Row | null>(null);

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedRows, setSortedRows] = useState<Row[]>(rows);
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [mobileSortAnchor, setMobileSortAnchor] = useState<null | HTMLElement>(null);
  const [mobileSortColumn, setMobileSortColumn] = useState<string | null>(null);

  useEffect(() => {
    setPage(0);
  }, [resetPage]);

  useEffect(() => {
    if (currentPageNumber !== null && currentPageNumber !== undefined) {
      setPage(currentPageNumber);
    }
  }, [currentPageNumber]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    pageNumber && pageNumber(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row: Row) => {
    setSelectedRow(row);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, row: Row) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(row);
    setActiveMenuRow(row);
    setIsActionProps(true);
  };

  const handleWithoutPropMenuClick = (event: React.MouseEvent<HTMLElement>, rowColumn: any, row: Row) => {
    setAnchorEl(event.currentTarget);
    setMenuActionPropRow(rowColumn);
    setActiveMenuRow(row);
    setIsActionProps(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRow(null);
    setActiveMenuRow(null);
  };

  const filteredColumns = columns.filter(
    (column) => !hiddenColumns.includes(column.id)
  );

  useEffect(() => {
    setSortedRows([...rows]);
  }, [rows]);

  const handleSort = (columnId: string) => {
    if (!sortableColumns?.includes(columnId)) return;
  
    const isAsc = sortColumn === columnId && sortOrder === "asc";
    const newOrder = isAsc ? "desc" : "asc";
  
    const sorted = [...rows].sort((a, b) => {
      const valA = a[columnId] || '';
      const valB = b[columnId] || '';
      if (valA < valB) return newOrder === "asc" ? -1 : 1;
      if (valA > valB) return newOrder === "asc" ? 1 : -1;
      return 0;
    });
  
    setSortColumn(columnId);
    setSortOrder(newOrder);
    setSortedRows(sorted);
  };
  
  const renderSortIndicator = (columnId: string) => {
    const isActive = sortColumn === columnId;
  
    const iconStyle = {
      fontSize: "16px",
      opacity: isActive || hoveredColumn === columnId ? 1 : 0.3,
      transition: "opacity 0.3s, transform 0.3s ease-in-out", 
      transform: isActive && sortOrder === "asc" ? "rotate(0deg)" : "rotate(180deg)", 
    };
  
    return (
      <IconButton
        sx={{
          color: colors.success[200],
          transition: "all 0.3s ease-in-out",
          "&:hover": { opacity: 1 },
        }}
      >
        <ArrowUpwardOutlined style={iconStyle} />
      </IconButton>
    );
  };

  const handleMobileSortMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileSortAnchor(event.currentTarget);
  };

  const handleMobileSortMenuClose = () => {
    setMobileSortAnchor(null);
  };

  const handleMobileSort = (columnId: string) => {
    setMobileSortColumn(columnId);
    handleSort(columnId);
    setMobileSortAnchor(null);
  };

  
  const renderMobileSortMenu = () => (
    <Menu
      anchorEl={mobileSortAnchor}
      open={Boolean(mobileSortAnchor)}
      onClose={handleMobileSortMenuClose}
      sx={{ zIndex: 1300 }}
    >
      {sortableColumns?.map((columnId) => {
        const column = columns.find((col) => col.id === columnId);
        return (
          <MenuItem
            key={columnId}
            onClick={() => handleMobileSort(columnId)}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Typography>{column?.label}</Typography>
            {mobileSortColumn === columnId && (
              <Box>
                {sortOrder === "asc" ? (
                  <ArrowUpwardOutlined fontSize="small" color="success"/>
                ) : (
                  <ArrowUpwardOutlined fontSize="small"  color="success" style={{ transform: 'rotate(180deg)' }} />
                )}
              </Box>
            )}
          </MenuItem>

        );
      })}
    </Menu>
  );

  const renderSingleActionComponent = () => {
    if (!singleActionComponent) return null;
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        {singleActionComponent}
      </Box>
    );
  };

  const renderTable = () => (
    <Box sx={{ width: "100%", overflowX: isMobile ? "auto" : "hidden", mt: 1 }}>
      {isMobile && sortableColumns && sortableColumns.length > 0 && (
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="text"
          startIcon={<ImportExportOutlined />}
          onClick={handleMobileSortMenuOpen}
            >
          Sort
        </Button>
      </Box>
      )}
      {sortableColumns && sortableColumns.length > 0 && renderMobileSortMenu()}
      
      {(!isMobile || !showMenu) && (
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ background: disabled ? theme.palette.mode === "dark" ? "#383838" : "#FFFFFF" : colors.grey[800] }}
          >
            {visibleHeader && (
            <TableHead>
              <TableRow>
                {filteredColumns.map(
                  (column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth, background: disabled ? theme.palette.mode === "dark" ? "#383838" : "#FFFFFF" : colors.grey[800],
                         height : 40, paddingTop : 0, paddingBottom : 0,
                        ...(column.padding && { padding: column.padding })
                      }}
                      onClick={() =>
                        sortableColumns?.includes(column.id) && handleSort(column.id)
                      }
                      sx={{
                        cursor: sortableColumns?.includes(column.id) ? "pointer" : "default",
                      }}
                      onMouseEnter={() => setHoveredColumn(column.id)}
                      onMouseLeave={() => setHoveredColumn(null)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: column.align === "right" ? "flex-end" : "flex-start",
                          gap: "8px",
                        }}
                      >
                        <Typography
                          fontWeight="bold"
                          color={
                            disabled
                              ? "#898989"
                              : theme.palette.mode === "dark"
                              ? colors.info[300]
                              : colors.grey[500]
                          }
                          sx={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }} 
                        >
                          {column.label}
                        </Typography>
                        {sortableColumns?.includes(column.id) && (
                          <Box>
                            {renderSortIndicator(column.id)}
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            )}
            <TableBody>
              {rows.length > 0 ? (
                sortedRows.map((row, rowIndex) => renderTableRow(row, rowIndex))
              ) : (
                <TableRow>
                  <TableCell colSpan={filteredColumns.length + 1} align="center">
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {emptyText || "No Data Found"}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isMobile && renderMobileCards()}
      {/* {isMobile && (
        <Box>
          {rows.length > 0 ? (
            rows.map((row, rowIndex) => renderCardView(row, rowIndex))
          ) : (
            <Box sx={{ textAlign: "center", padding: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {emptyText || "No Data Found"}
              </Typography>
            </Box>
          )}
        </Box>
      )} */}
      {renderSingleActionComponent()}
      {!disablePagination && renderPagination()}
    </Box>
  );

  const renderTableRow = (row: Row, rowIndex: number) => {
    const isDrafted = row.status === IVR_STATUS.DRAFTED;
    const isNeedMoreInfo = row.status === IVR_STATUS.NEEDS_MORE_INFO;
    const isSelected = activeMenuRow === row;

    const isActiveMenuRow = activeMenuRow && uniqueValue && activeMenuRow[uniqueValue] === row[uniqueValue];

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.uniqueId || rowIndex}
        onClick={() => {
          if (onRowClick) {
            handleRowClick(row);
            onRowClick(row);
          }
        }}
        sx={{
          cursor: onRowClick ? "pointer" : "",
          backgroundColor: isSelected || isActiveMenuRow ? colors.grey[700] : "inherit",
          "&:hover": {
            backgroundColor: colors.grey[700],
          },
        }}
      >
        {filteredColumns.map(
          (column) => (
            <TableCell key={column.id} align={column.align}>
              {column.id === "status" || column.id === "orderStatus" ? (
                <span
                  style={{
                    color: isDrafted ? "#f69697"
                      : isNeedMoreInfo
                        ? "orange"
                        : row[column.id] === "CANCELED"
                          ? "red"
                          : "inherit",
                  }}>
                  {row[column.id]}
                </span>
              ) :
                column.id === "practice" && row[column.id].length > 20 ? (
                  <span title={row[column.id]}>{`${row[column.id].substring(
                    0,
                    20
                  )}...`}</span>
                ) : column.format && typeof row[column.id] === "number" ? (
                  column.format(row[column.id])
                ) : column.id === "action" && row[column.id] ? (
                  showMenu ? (
                    <IconButton onClick={(event) => handleWithoutPropMenuClick(event, row[column.id], row)}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    row[column.id]
                  )
                ) : column.id === "action" && actionComponent ? (
                  showMenu ? (
                    <IconButton onClick={(event) => handleMenuClick(event, row)}>
                      <MoreVertIcon />
                    </IconButton>
                  ) : (
                    actionComponent && actionComponent(row, rowIndex)
                  )
                ) : column.id === "isSizeActive" && availabilitySwitchComponent ? (
                  availabilitySwitchComponent(row, rowIndex)
                ) : column.id === "notificationEmail" ? (
                  <pre>{row[column.id]}</pre>
                ) : (
                  row[column.id]
                )}
            </TableCell>
          )
        )}
      </TableRow>
    );
  };

  const IconBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 32,
    height: 32,
    borderRadius: "6px",
    border: `1px dotted ${colors.grey[700]}`,
    color: colors.grey[600],
    fontWeight: "bold",
    fontSize: "14px",
  });

  const DraftedChip = styled(Chip)({
    fontWeight: "bold",
    borderRadius: "20px",
    fontSize: "12px",
    height: 30,
    border: "1px solid",
    backgroundColor: colors.grey[900],
  });

  const FieldContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    paddingBottom: 8,
    borderBottom: `1px dotted ${colors.grey[500]}`,
    "&:last-child": {
      borderBottom: "none",
    },
  });

  const renderCardView = (row: Row, rowIndex: number) => {
    const isDrafted = row.status === IVR_STATUS.DRAFTED;
    const isNeedMoreInfo = row.status === IVR_STATUS.NEEDS_MORE_INFO;

    const isSelected = activeMenuRow === row;
    const isActiveMenuRow = activeMenuRow && uniqueValue && activeMenuRow[uniqueValue] === row[uniqueValue];

    return (
      <Box key={rowIndex} sx={{ mb: 2 }}>
        <Box
          onClick={() => {
            if (onRowClick) {
              handleRowClick(row);
              onRowClick(row);
            }
          }}
          sx={{
            cursor: onRowClick ? "pointer" : "default",
            backgroundColor: colors.grey[900],
            borderRadius: 3,
            border: 1,
            borderColor: colors.primary[500],
          }}
        >
          {filteredColumns.map((column, index) => (
            <Box sx={{ position: "relative" }}>
              {column.id === "action" && row[column.id] ? (
                <IconButton
                  sx={{ position: "absolute", top: 8, right: 8 }}
                  onClick={(event) =>
                    handleWithoutPropMenuClick(event, row[column.id], row)
                  }
                >
                  <MoreVertIcon />
                </IconButton>
              ) : column.id === "action" && actionComponent ? (
                <IconButton
                  sx={{ position: "absolute", top: 8, right: 8 }}
                  onClick={(event) => handleMenuClick(event, row)}
                >
                  <MoreVertIcon />
                </IconButton>
              ) : null}
            </Box>
          ))}
          <Card sx={{ backgroundColor: isSelected || isActiveMenuRow ? colors.grey[700] : "inherit", }}>

            {(filteredColumns[0].id !== "action" &&
              !filteredColumns[0].id.includes("checkbox")) && (
                <Box
                  display="flex"
                  alignItems="center"
                  pb={1}
                  borderBottom={`1px dotted ${theme.palette.grey[500]}`}
                >
                  <IconBox>
                    <RadioButtonChecked />
                  </IconBox>
                  <Box ml={2}>
                    <Typography
                      sx={{ color: colors.grey[500], fontSize: "0.7em" }}
                    >
                      {filteredColumns[0].id !== "action" &&
                        filteredColumns[0].label}
                    </Typography>
                    {filteredColumns[0].id !== "action" &&
                      row[filteredColumns[0].id]}
                  </Box>
                </Box>
              )}
            <Stack spacing={1.5} mt={1}>
              {filteredColumns.slice(1).map(
                (column, index) =>
                  column.id !== "action" && (
                    <FieldContainer key={index}>
                      <IconBox>
                        <RadioButtonChecked />
                      </IconBox>
                      <Box ml={2}>
                        <Typography
                          sx={{ color: colors.grey[500], fontSize: "0.7em" }}
                        >
                          {column.label}
                        </Typography>

                        {column.id.toLowerCase().includes("status") ? (
                          <DraftedChip
                            label={row[column.id]}
                            variant="outlined"
                            sx={{
                              color: isDrafted
                                ? "#f69697"
                                : isNeedMoreInfo
                                  ? "orange"
                                  : row[column.id] === "CANCELED"
                                    ? colors.error[300]
                                    : colors.success[400],
                            }}
                          />
                        ) : column.id === "isSizeActive" && availabilitySwitchComponent ? (
                          availabilitySwitchComponent(row, rowIndex)
                        ) : (
                          row[column.id]
                        )}
                      </Box>
                    </FieldContainer>
                  )
              )}
            </Stack>
          </Card>
        </Box>
      </Box>
    );
  }

  const renderMobileCards = () => (
    <Box>
      {sortedRows.length > 0 ? (
        sortedRows.map((row, rowIndex) => renderCardView(row, rowIndex))
      ) : (
        <Box sx={{ textAlign: "center", padding: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {emptyText || "No Data Found"}
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderPagination = () => (
    <Box
      sx={{
        padding: 1,
        bottom: 0,
        background: colors.grey[900],
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md>
          <Box display="flex" justifyContent="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={totalPages || Math.ceil(rows.length / rowsPerPage)}
              page={page + 1}
              onChange={(event, value) => handleChangePage(event, value - 1)}
              color={theme.palette.mode === "dark" ? "secondary" : "primary"}
              showFirstButton
              showLastButton
            />
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <label style={{ textWrap: "nowrap", fontSize: "13px", alignSelf: "center" }}>{totalElementText ? totalElementText : ""}</label>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderMenuItems = () => {
    if (!menuRow || !actionComponent) return null;

    const actions = actionComponent(menuRow, rows.indexOf(menuRow));
    const toggleSwitch = availabilitySwitchComponent ? availabilitySwitchComponent(menuRow, 0) : null;

    const renderComponentItems = (component: JSX.Element) =>
      React.Children.map(component, (action, index) =>
        React.Children.map(action.props?.children, (child, childIndex) => {
          if (!child?.props || child.props?.style?.display === 'none') return null;

          const { onClick, tooltipMessage } = child.props;

          return (
            <MenuItem
              key={index}
              sx={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                onClick?.();
                handleMenuClose();
              }}
            >
              {child}
              {tooltipMessage && (
                <Typography variant="body2" sx={{ ml: 1 }}>
                  {tooltipMessage}
                </Typography>
              )}
            </MenuItem>
          );
        })
      );

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {actions && renderComponentItems(actions)}
        {toggleSwitch && renderComponentItems(toggleSwitch)}
      </Box>
    );
  };

  const renderWithoutPropsMenuItems = () => {
    const actionComponent_ = menuActionPropRow || null;
    return (
      <>
        {React.Children.map(actionComponent_, (action, index) =>
          React.Children.map(action.props?.children, (child, childIndex) => {
            if (!child?.props || child.props?.style?.display === "none")
              return null;

            const { onClick, tooltipMessage } = child.props;

            return (
              <MenuItem
                key={index}
                sx={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  onClick?.();
                  handleMenuClose();
                }}
              >
                {child}
                {tooltipMessage && (
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {tooltipMessage}
                  </Typography>
                )}
              </MenuItem>
            );
          })
        )}
      </>
    );
  };

  return (
    <Box sx={borderBox ? tableBorderStyle : {}}>
      {renderTable()}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {isActionProps ? renderMenuItems() : renderWithoutPropsMenuItems()}
      </Menu>
    </Box>
  );
};

const tableBorderStyle = {
  backgroundColor: (theme: any) =>
    theme.palette.mode === "dark"
      ? tokens(theme.palette.mode).primary[500]
      : tokens(theme.palette.mode).grey[700],
  borderRadius: 2,
  p: 1,
};

export default CustomTable;
