import React, { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../common/Button";
import Dropzone, { ExtendedFile } from "../../common/Dropzone";
import { useForm } from "react-hook-form";
import { Cancel, FileUploadOutlined } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import { convertBase64 } from "../../../utils/Utility";
import { setSingleAlertObj, SingleAlertInfo } from "../../../redux/slice/commonSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import { fetchIVRNotesById, IVRNotes, uploadIVRNotes } from "../../../redux/slice/ivr/ivrDocumentSlice";

interface IVRNotePopupProps {
  open: boolean;
  onClose: () => void;
  ivrId: number;
}

const IVRNotePopup: React.FC<IVRNotePopupProps> = ({
  open,
  onClose,
  ivrId,
}) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, setValue, getValues } = useForm<IVRNotes>();

  useEffect(() => {
    setValue('xrefId', ivrId.toString());
    setValue('xrefTable', "IVR");
    setValue('noteType', "NOTE");
  }, []);

  const onSubmit = async (data: IVRNotes) => {
    try {
      const response = await dispatch(uploadIVRNotes(data));
      if (response.payload && response.payload.message === 'Note is added successfully') {
        let alertMessage = "Note saved Successfully!";
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        dispatch(fetchIVRNotesById(ivrId));
        onClose()
      }
    } catch (error) {
      console.log("Error saving note:", error);
    }
  };

  const removeDataUriPrefix = (dataUri: string): string => {
    const base64String = dataUri.split(',')[1];
    return base64String || '';
  };

  const handleNotDocumentFileChange = async (uploadedFiles: ExtendedFile[]) => {
    let base64File = "";
    const fileData = uploadedFiles[uploadedFiles.length - 1];
    base64File = removeDataUriPrefix(String(await convertBase64(fileData)));

    setValue('attachment', base64File);
    setValue('documentName', fileData.name);
    setValue('fileType', fileData.type);
    setValue('noteType', 'NOTE');
    setValue('xrefTable', 'IVR');
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        IVR Note
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#FFFFFF",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTextField
                  controllerName="content"
                  control={control}
                  label="Notes"
                  multiline
                  rows={4}
                  maxRows={4}
                  rules={{ required: "Notes are required." }}
                />
              </Grid>
              <Grid item xs={12}>
                <Dropzone
                  name="attachment"
                  onFileChange={handleNotDocumentFileChange}
                  control={control}
                  label="Attachment"
                />
              </Grid>

            </Grid>
          </Box>
          <Grid container justifyContent="flex-end" spacing={1} p={2}>
            <Grid item xs={12} sm="auto">
              <Button type="submit" startIcon={<FileUploadOutlined />} fullWidth>
                Save
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                color="error"
                startIcon={<Cancel />}
                onClick={onClose}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default IVRNotePopup;
