import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Alert,
  Grid,
  Box,
  useTheme,
  Slide,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../common/Button";
import CustomTextField from "../common/CustomTextField";
import OTPVerification from "../2FA/OTPVerification";
import {
  Generate2faRequest,
  TwoFactorSaveRequest,
  fetchAllUserTwoFactorList,
  generate2faOTP,
  reset2FAFetchStatus,
  save2faCommunicationData,
  useTwoFactorReducer,
  validate2faOTP,
} from "../../redux/slice/2FA/twoFactorSlice";
import { useAuthSlice } from "../../redux/slice/authSlice";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import {
  SingleAlertInfo,
  hideSingleAlert,
  setSingleAlertObj,
} from "../../redux/slice/commonSlice";
import { TWO_FACTOR_VERIFICATION_CODE_LENGTH } from "../../constants/applicationConstants";
import CustomPatternTextField from "../common/CustomPatternTextField";
import { tokens } from "../../theme";
import PhoneInputField from "../common/PhoneInputField";
import { TransitionProps } from "@mui/material/transitions";
import CustomCheckbox from "../common/CustomCheckBox";

export type verificationForm = {
  account: string | undefined;
};

interface VerificationDialogProps {
  open: boolean;
  onClose: () => void;
  type: "email" | "phone";
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const VerificationDialog: React.FC<VerificationDialogProps> = ({
  open,
  onClose,
  type,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { control, handleSubmit, reset } = useForm<verificationForm>();
  const [value, setValue] = useState("");
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(false);
  const [isAcknowledgementChecked, setAcknowledgementChecked] = useState(false);

  const { userInfo } = useAuthSlice();
  const { generate2faStatus, validate2faStatus, save2faOptionStatus } =
    useTwoFactorReducer();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      if (generate2faStatus === "success") {
        const alertObj: SingleAlertInfo = {
          message: "successfully sent the OTP code.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
      } else if (generate2faStatus === "error") {
        const alertObj: SingleAlertInfo = {
          message: "error while sending OTP code",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      setTimeout(() => {
        dispatch(hideSingleAlert());
      }, 5000);
    }
  }, [generate2faStatus]);

  useEffect(() => {
    if (open) {
      if (validate2faStatus === "success") {
        const alertObj: SingleAlertInfo = {
          message: "successfully verified your account.",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        let obj: Generate2faRequest = {
          methodValue: value,
          methodType: type === "email" ? "EMAIL" : "MOBILE",
          isPrimary: false,
        };
        dispatch(save2faCommunicationData(obj));
      } else if (validate2faStatus === "error") {
        const alertObj: SingleAlertInfo = {
          message: "error while validate the OTP",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      setTimeout(() => {
        dispatch(hideSingleAlert());
      }, 5000);
    }
  }, [validate2faStatus]);

  useEffect(() => {
    if (open) {
      if (save2faOptionStatus === "success") {
        const alertObj: SingleAlertInfo = {
          message: "successfully added two factor option",
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        closePopup();
        dispatch(fetchAllUserTwoFactorList(true));
      } else if (save2faOptionStatus === "error") {
        const alertObj: SingleAlertInfo = {
          message: "error while saving two factor option",
          alertType: "error",
        };
        dispatch(setSingleAlertObj(alertObj));
      }
      setTimeout(() => {
        dispatch(hideSingleAlert());
      }, 5000);
      setValue("");
      setDisable(false);
    }
  }, [save2faOptionStatus]);

  useEffect(() => {
    console.log(disable);
  }, [disable]);

  const closePopup = () => {
    onClose();
    reset();
    setValue("");
    setOtp("");
    setDisable(false);
    setAcknowledgementChecked(false);
    dispatch(reset2FAFetchStatus());
  };

  const handleSend = () => {
    let obj: TwoFactorSaveRequest = {
      communicationValue: value,
      communicationType: type === "email" ? "EMAIL" : "MOBILE",
      userId: 0,
    };
    setDisable(true);
    dispatch(generate2faOTP({ request: obj }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleVerify = () => {
    dispatch(validate2faOTP({ otp: otp }));
  };

  const onSubmit = (data: any) => {
    handleSend();
  };

  const handleCheckboxChange = (event: { target: { checked: any; }; }) => {
    const checked = event.target.checked;
    setAcknowledgementChecked(checked);
  };

  const handleDialogContent = () => {
    const twoFAtype = type === "email" ? "email address" : "mobile number";

    return (
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item>
          <CustomCheckbox
            control={control}
            controllerName="acknowledgement"
            label=""
            onChange={handleCheckboxChange}
            checked={isAcknowledgementChecked}
            sx={{p:0, m:0}}
          />
        </Grid>
        <Grid item xs>
          <Typography variant="body2" sx={{ marginBottom: 2 }}>
            By providing your {twoFAtype}, you authorize us to use it for 2FA.
            To stop receiving 2FA on this {twoFAtype}, remove it from your account profile.
            {type === "email" ? "" : " Standard messaging rates may apply."}{" "}

            {type === "phone" && (
              <Typography component="span" fontWeight="bold">
                User also agrees to the{" "}
                <a
                  href="https://v3biomed.com/terms-2fa.html"
                  target="_blank"
                  style={{ textDecoration: "underline", color: colors.info[300] }}
                >
                  2FA SMS T&C
                </a>
              </Typography>
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (!open) {
      reset();
      setValue("");
      setOtp("");
      setDisable(false);
      setAcknowledgementChecked(false);
      dispatch(reset2FAFetchStatus());
    }
  }, [open, reset, dispatch]);
  
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closePopup}
      aria-describedby="alert-dialog-slide-description"
      onClick={(event) => {
        event.stopPropagation();
      }}
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "20px",
          padding: "10px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.3)",
          backgroundColor: theme.palette.mode === "dark" ? "black" : "white",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant="h3" fontWeight="bold">
            Two-Step Authentication
          </Typography>
          {/* <Typography>
            {type === "email" ? "Email Verification" : "Phone Verification"}
          </Typography> */}
        </DialogTitle>
        <DialogContent>
          {handleDialogContent()}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            {type === "phone" && (
              <Grid item xs={12} md>
                <PhoneInputField
                  control={control}
                  name="Phone"
                  label="Phone"
                  value={value}
                  isDisabled={!isAcknowledgementChecked}
                  onChange={handleChange}
                />
              </Grid>
            )}
            {type === "email" && (
              <Grid item xs={12} md>
                <CustomTextField
                  control={control}
                  controllerName={"Email"}
                  onChange={handleChange}
                  value={value}
                  label={"Email"}
                  variant="outlined"
                  fullWidth
                  disabled={!isAcknowledgementChecked}
                  rules={
                    type === "email"
                      ? {
                          required: "Email is required.",
                          pattern: {
                            value:
                              /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|n\/a|N\/A)$/i,
                            message: "Invalid email.",
                          },
                        }
                      : {}
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md="auto">
              <Button
                variant="contained"
                type="submit"
                disabled={value.trim().length === 0 || disable}
                color="info"
                fullWidth
                style={{
                  padding: "15px",
                }}
              >
                Send Code
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent="center" alignItems="center" mt={4}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6">
                Please enter the 6 digit code that you received on your {type === "email" ? "email " : "phone "}
                below
              </Typography>
            </Grid>
            <Grid item md={8} xs={12} mt={4}>
              <OTPVerification
                control={control}
                sendOtp={handleSend}
                submitCode={(value) => setOtp(value)}
                disable={!disable}
              />
            </Grid>
          </Grid>
         
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              mt={3}
            >
              <Grid item xs={12} md="auto">
                <Button
                  variant="contained"
                  onClick={handleVerify}
                  disabled={
                    otp.trim().length !== TWO_FACTOR_VERIFICATION_CODE_LENGTH
                  }
                  fullWidth
                >
                  Verify
                </Button>
              </Grid>
              <Grid item xs={12} md="auto">
                <Button
                  onClick={closePopup}
                  color="error"
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default VerificationDialog;
