import React, { useEffect, useState } from "react";
import { Box, ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import { tokens } from "../../theme";
import { useAppDispatch } from "../../redux/slice/redux-hooks";
import { useAuthSlice } from "../../redux/slice/authSlice";
import { saveUserPreference } from "../../redux/slice/userSlice";

interface ToggleViewProps {
  isCalendarView?: boolean;
  onViewChange?: (isCalendarView: boolean) => void;
}

const ToggleView: React.FC<ToggleViewProps> = ({ isCalendarView = true, onViewChange }) => {
  const [view, setView] = useState<string>(isCalendarView ? "calendar" : "list");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useAppDispatch();
  const { userInfo } = useAuthSlice();
  
  useEffect(() => {
    setView(isCalendarView ? "calendar" : "list");
  }, [isCalendarView]);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null) {
      setView(newView);
      if (onViewChange) {
        onViewChange(newView === "calendar");
      }
    }

    const data = {
      userId: userInfo?.userId,
      preferenceName: "preferredViewMode",
      preferenceValue: newView === "calendar" ? "GRID" : "LIST"
    }
    dispatch(saveUserPreference(data))
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        sx={{
          overflow: "hidden",
        }}
      >
        <Tooltip title="Switch to List View">
        <ToggleButton
          value="list"
          sx={{
            flex: 1,
            padding: "8px 10px",
            color: view === "list" ? colors.grey[800] : colors.grey[600],
            backgroundColor: view === "list" ? colors.grey[500] : colors.grey[800],
          }}
        >
          <ViewListIcon />
        </ToggleButton>
        </Tooltip>
        <Tooltip title="Switch to Grid View">
        <ToggleButton
          value="calendar"
          sx={{
            flex: 1,
            padding: "5px 10px",
            color: view === "calendar" ? colors.grey[800] : colors.grey[600],
            backgroundColor: view === "calendar" ? colors.grey[500] : colors.grey[800],
          }}
        >
          <CalendarViewMonthIcon />
        </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToggleView;
