import * as React from "react";
import {
  TextField,
  useTheme,
  Autocomplete,
  TextFieldProps,
  Chip,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";

export type InputFieldProps = {
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium";
  label?: any | "";
  variant?: "filled" | "outlined" | "standard" | undefined;
  name?: string;
  control?: Control<any, any>;
  rules?: Object;
  isDisabled?: boolean;
  returnAsArray?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEventHandler<HTMLInputElement>) => void;
  controllerName?: string;
  isEmpty?: boolean;
} & TextFieldProps;

const CustomEmailChipField: React.FC<InputFieldProps> = ({
  size,
  color,
  label,
  variant,
  name,
  control,
  rules,
  isDisabled,
  onChange,
  onBlur,
  controllerName,
  isEmpty,
  returnAsArray,  // Include the returnAsArray prop
  ...props
}) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = React.useState("");

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <Controller
      name={controllerName || ""}
      control={control}
      rules={rules ? rules : {}}
      render={({ field: { onChange: onControllerChange, value, ...field }, fieldState }) => {
        // Convert value to array if returnAsArray is true
        const currentValue = returnAsArray ? (value || []) : (value ? value.split(',') : []);

        return (
          <Autocomplete
            {...field}
            value={currentValue}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            clearIcon={false}
            options={[]}
            freeSolo
            multiple
            onKeyDown={(event) => {
              if (event.key === 'Tab' || event.key === ' ') {
                event.key = 'Enter';
              }
            }}
            onChange={(event: any, newValue: any) => {
              // Filter valid emails
              const validEmails = newValue.filter((email: string) =>
                isValidEmail(email)
              );
              if (returnAsArray) {
                // If returnAsArray is true, pass the value as an array
                onControllerChange(validEmails);
              } else {
                // Otherwise, join the valid emails into a comma-separated string
                const emailsAsString = validEmails.join(',');
                onControllerChange(emailsAsString);
              }
              if (onChange) {
                onChange(event);
              }
              setInputValue("");
            }}
            onBlur={(event: any) => {
              const inputValue = event.target.value;
              if (inputValue && isValidEmail(inputValue)) {
                const existingEmails = (currentValue || []).filter((email: string) => email.trim() !== "");
                const newEmails = [...existingEmails, inputValue];
                if (returnAsArray) {
                  onControllerChange(newEmails);  // Use array format
                } else {
                  onControllerChange(newEmails.join(','));  // Use comma-separated string format
                }
                if (onBlur) {
                  onBlur(event);
                }
                setInputValue("");
              } else {
                if (onBlur) {
                  onBlur(event);
                }
              }
            }}
            size={size || "medium"}
            color={color || theme.palette.mode === "dark" ? "secondary" : "primary"}
            renderTags={(value, getTagProps) =>
              value
                .filter((option: string) => isValidEmail(option))
                .map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
            }
            renderInput={(params) => <TextField label={label} error={!!fieldState.error} helperText={fieldState.error ? fieldState.error.message : null} color={color || theme.palette.mode === "dark" ? "secondary" : "primary"} variant={variant || "outlined"} {...params} />}
          />
        );
      }}
    />
  );
};

export default CustomEmailChipField;
