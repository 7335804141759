import moment from "moment";
import { CodeTable } from "../redux/slice/codetableSlice";
import dayjs, { Dayjs } from "dayjs";
import { getDataFromCookies, setDataToCookies } from "./StorageUtils";
import { TokenString, authenticationString } from "../types/CommonTypes";

// calculate the discount prices
export const calculateValue = (discount: string, price: number) => {
  let discountValue = parseFloat(discount.replace('%', '')) / 100;
  return (1 - discountValue) * price;
}

// format the price 
export const priceFormatter = (price: number) => {
  if (price && price > 0) {
    price = Math.round(price * 100) / 100;
    let formattedPrice = price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    if (Math.round(price) < 10) {
      formattedPrice = '0' + formattedPrice;
    }
    return `$${formattedPrice}`;
  }
  return `$0.00`;
}

// task cs-647  use for priceFormatter value convert Number(0-9)
export const parseAmount = (value: string | number): number => {
  if (!value) return 0;

  if (typeof value === 'string') {
    const sanitizedValue = value.replace(/[^0-9.-]+/g, '');
    return parseFloat(sanitizedValue) || 0;
  }

  return parseFloat(value.toString()) || 0;
};
// task cs-647  end

//generate full name
export const fullName = (title: string | null | undefined, firstName: string | null | undefined, middleName: string | null | undefined, lastName: string | null | undefined): string => {
  const parts = [title, firstName, middleName, lastName].filter(part => part !== null && part !== undefined && part.trim() !== "");
  return parts.join(" ");
};

//format Data
export const formatDate = (date: Date | string | null, formatPattern: string = 'MM/DD/YYYY') => {
  if (!date) {
    return '';
  }
  return moment(date).format(formatPattern);
};

export const convertPriceToString = (price: any) => {
  return price ? price.toString().replace('$', '').replaceAll(',', '') : "";
}

export const isDateGivenRange = (to: Dayjs, from: Dayjs, date: Dayjs) => {
  return (date.isSame(from) || date.isAfter(from)) && (date.isSame(to) || date.isBefore(to));
}
// Sort by alphabetical order
export const sortNames = (info: any, sortingField: string) => {
  return info.slice().sort((a: any, b: any) => {
    const nameA = a[sortingField].trim().toLocaleLowerCase();
    const nameB = b[sortingField].trim().toLocaleLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

// hide an element
export const hideElement = (condition: boolean) => {
  if (condition) {
    return 'none';
  } else {
    return "";
  }
};

// Convert file data to base64 string
export const convertBase64 = (file: any) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  });
};

// Format address
export const formatAddress = (
  streetAddress?: string | null,
  unitNumber?: string | null,
  city?: string | null,
  state?: string | null,
  zipCode?: string | null
) => {
  const addressParts = [streetAddress, unitNumber, city, state, zipCode];
  const filteredLAddressParts = addressParts.filter(part => part !== undefined && part !== null);
  return filteredLAddressParts?.join(", ");
};

// Hide Days and Months of Birthday
export const hideDayMonthOfBOD = (bod: string) => {
  if (!bod) return '';
  const bodParts = bod?.split("/");
  if (bodParts.length === 3 && bodParts[2].length === 4) {
    const year = bodParts[2];
    return `XX/XX/${year}`;
  };
  return '';
};


export const retrieveDataFromTokenCookie = (data: string) => {
  let obj: TokenString = {
    token: "",
    count: 0,
  };
  try {
    obj = JSON.parse(data)
  } catch {
  }
  return obj;
}

export const updateTokenToCookie = (value: any) => {
  const token = getDataFromCookies("token");
  let data: TokenString = retrieveDataFromTokenCookie(token);
  data.token = value;
  setDataToCookies("token", JSON.stringify(data));
}

export const getTokenFromCookie = () => {
  const token = getDataFromCookies("token");
  let data: TokenString = retrieveDataFromTokenCookie(token);
  return data.token;
}

export const updateWindowCountToCookie = (value: any) => {
  const token = getDataFromCookies("token");
  let data: TokenString = retrieveDataFromTokenCookie(token);
  data.count = value;
  setDataToCookies("token", JSON.stringify(data));
}

export const getWindowCountFromCookie = () => {
  const token = getDataFromCookies("token");
  let data: TokenString = retrieveDataFromTokenCookie(token);
  return data.count;
}

