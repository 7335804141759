import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Divider,
  Avatar,
  Stack,
  IconButton,
  InputBase,
} from "@mui/material";
import Header from "../../common/Header";
import Button from "../../common/Button";
import Card from "../../common/Card";
import {
  AttachFile,
  CancelOutlined,
  Download,
  DownloadOutlined,
  Send,
  SimCardDownloadOutlined,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { tokens } from "../../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchIVRNotesById,
  fetchIVRDocuments,
  useIVRDocumentSlice,
  IVRNotes,
  uploadIVRNotes,
} from "../../../redux/slice/ivr/ivrDocumentSlice";
import { useAppDispatch } from "../../../redux/slice/redux-hooks";
import {
  setSingleAlertObj,
  SingleAlertInfo,
} from "../../../redux/slice/commonSlice";
import { fetchDocumentById } from "../../../redux/slice/practiceSlice";
import CustomItemList from "../../common/CustomItemList";
import CustomIconButton from "../../common/CustomIconButton";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import IVRNotePopup from "./IVRNotePopup";
import { useSelector } from "react-redux";
import { selectIsValueFoundInToken } from "../../../redux/slice/authSlice";
import { IVR_STATUS } from "../../../constants/applicationConstants";
import { RootState } from "../../../redux/store";
import { convertBase64, hideElement } from "../../../utils/Utility";
import CustomTextField from "../../common/CustomTextField";

const IVRDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { IVRNotesList, ivrDocumentInfoList } = useIVRDocumentSlice();
  const userSideMap: Record<string, boolean> = {};
  let lastAuditUserName: string | null = null;

  const { handleSubmit, setValue, getValues, control } = useForm<IVRNotes>();

  const downloadBase64File = (
    base64String: string,
    fileName: string,
    mimeType: string
  ) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = blobUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor); // Required for Firefox
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(blobUrl);
  };
  const [ivrId, setIvrId] = useState(0);
  const [ivrNumber, setIvrNumber] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientDOB, setPatientDOB] = useState("");
  const [showIVRNotePopup, setIVRNotePopup] = useState<{
    show: boolean;
    ivrId: number;
  }>({ show: false, ivrId: 0 });
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const canNoteIVR = useSelector((state: RootState) =>
    selectIsValueFoundInToken("canAccessIVRActions", IVR_STATUS.NOTE)(state)
  );

  useEffect(() => {
    const ivrId = location?.state?.ivrInfo?.ivrId;
    setIvrId(ivrId);
    setValue("xrefId", ivrId.toString());
    setIvrNumber(location?.state?.ivrInfo?.ivrNumber);
    setPatientName(location?.state?.ivrInfo?.patient);
    setPatientDOB(location?.state?.ivrInfo?.patientDOB);
    dispatch(fetchIVRNotesById(ivrId));
    dispatch(
      fetchIVRDocuments({
        ivrId: location?.state?.ivrInfo?.ivrId,
        patientId: location?.state?.ivrInfo?.patientId,
      })
    );
  }, [location, dispatch]);

  const handleActionPreview = async (row: any, type: string) => {
    const documentId = type === "note" ? row.attachementId : row.documentId;
    if (documentId) {
      const actionResult = await dispatch(
        fetchDocumentById(documentId.toString())
      );
      const documentData = actionResult.payload;
      if (documentData && documentData.content) {
        downloadBase64File(
          documentData.content,
          documentData.documentName,
          documentData.fileType
        );
      } else {
        dispatch(
          setSingleAlertObj({
            message: "Requested Document has not found under server location!",
            alertType: "info",
          })
        );
      }
    }
  };

  const isFileAttached = (row: any) => (row.attachementId ? "" : "none");

  const documentColumns = [
    { id: "documentType", label: "Doc Type" },
    { id: "documentName", label: "Doc Name" },
    { id: "auditTimestamp", label: "Time" },
    {
      id: "action",
      label: "Download",
      align: "center" as "center" | "left" | "right",
    },
  ];

  const documentRows = ivrDocumentInfoList?.map((row: any) => ({
    ...row,
    action: (
      <CustomIconButton
        color="info"
        size="small"
        variant="outlined"
        onClick={() => handleActionPreview(row, "document")}
        tooltipMessage="Download"
        tooltipPlacement="bottom"
      >
        <Download />
      </CustomIconButton>
    ),
  }));

  const downloadAll = async () => {
    if (!ivrDocumentInfoList) return;
    const zip = new JSZip();
    const documentsFolder = zip.folder("documents");
    if (!documentsFolder) {
      console.error("Failed to create documents folder in the zip archive.");
      return;
    }
    for (const row of ivrDocumentInfoList) {
      if (row.documentId) {
        await downloadAndAddToZip(row.documentId.toString(), documentsFolder);
      }
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "all_documents.zip");
    });
  };

  const downloadAndAddToZip = async (documentId: string, zipFolder: JSZip) => {
    const actionResult = await dispatch(
      fetchDocumentById(documentId.toString())
    );
    const documentData = actionResult.payload;
    if (documentData && documentData.content) {
      const byteCharacters = atob(documentData.content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      zipFolder.file(documentData.documentName, byteArray, { binary: true });
    } else {
      console.log(`Document with ID ${documentId} not found or is empty.`);
    }
  };

  useEffect(() => {
    resetData();
  }, []);

  const resetData = () => {
    setValue("attachment", "");
    setValue("xrefTable", "IVR");
    setValue("noteType", "NOTE");
    setValue("content", "");
    handleRemoveFile();
  };

  const onSubmit = async (data: IVRNotes) => {
    setValue("xrefId", ivrId.toString());
    try {
      const response = await dispatch(uploadIVRNotes(data));
      if (
        response.payload &&
        response.payload.message === "Note is added successfully"
      ) {
        let alertMessage = "Note saved Successfully!";
        const alertObj: SingleAlertInfo = {
          message: alertMessage,
          alertType: "success",
        };
        dispatch(setSingleAlertObj(alertObj));
        dispatch(fetchIVRNotesById(ivrId));
        resetData();
      }
    } catch (error) {
      console.log("Error saving note:", error);
    }
  };

  const removeDataUriPrefix = (dataUri: string): string => {
    const base64String = dataUri.split(",")[1];
    return base64String || "";
  };

  const handleNotDocumentFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const files = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        const base64File = await convertBase64(file);

        setValue("attachment", removeDataUriPrefix(String(base64File)));
        setValue("documentName", file.name);
        setValue("fileType", file.type);
        setValue("noteType", "NOTE");
        setValue("xrefTable", "IVR");
        setSelectedFileName(file.name);
      } else {
        console.error("No files selected or invalid input.");
      }
    } catch (error) {
      console.error("Error processing the file:", error);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFileName(null);
    setValue("attachment", "");
  };

  return (
    <Box m="15px">
      {showIVRNotePopup?.show && (
        <IVRNotePopup
          open={showIVRNotePopup.show}
          ivrId={showIVRNotePopup.ivrId}
          onClose={() => setIVRNotePopup({ show: false, ivrId: 0 })}
        />
      )}
      <Header title="IVR Details" subtitle="IVR Management" />

      <Box
        sx={{
          border: 1,
          p: 2,
          borderRadius: 3,
          borderColor: colors.grey[700],
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={7} xs={12} sx={{ order: { xs: 2, md: 1 } }}>
            <Card sx={{ padding: 0 }}>
              {documentRows && documentRows.length > 0 &&(
              <Box display="flex" justifyContent="flex-end" sx={{p:2}}>
                <Button
                  startIcon={<DownloadOutlined />}
                  color="info"
                  onClick={downloadAll}
                  sx={{ mb: 1 }}
                >
                  Download All
                </Button>
              </Box>
              )}
              {documentRows && documentRows.length > 0 ? (
                documentRows.map((row, index) => (
                  <Box key={index}>
                    <Box sx={{ padding: "2px 25px" }}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{
                          paddingY: 2,
                          overflowX: "auto",
                        }}
                      >
                        <Grid item md={3} xs={12}>
                          <Typography color={colors.grey[500]}>
                            Doc Type
                          </Typography>
                          <Typography>{row.documentType}</Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Typography color={colors.grey[500]}>
                            Doc Name
                          </Typography>
                          <Typography>{row.documentName}</Typography>
                        </Grid>
                        <Grid item md={3} xs={9}>
                          <Typography color={colors.grey[500]}>Time</Typography>
                          <Typography>{row.auditTimestamp}</Typography>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <Typography color={colors.grey[500]} sx={{display : {
                            sm: "block",
                            xs : "none"
                          }}}>
                            Download
                          </Typography>
                          {row.action}
                        </Grid>
                      </Grid>
                    </Box>
                    {index < documentRows.length - 1 && (
                      <Box
                        sx={{
                          borderBottom: "1px dashed",
                          borderColor: colors.grey[700],
                        }}
                      />
                    )}
                  </Box>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  minHeight="150px" 
                  >
                  <Typography variant="h5" fontWeight="bold">No documents available.</Typography>
                </Box>
              )}
            </Card>
          </Grid>
          <Grid item md={5} xs={12} sx={{ order: { xs: 1, md: 2 }, mt: 2 }}>
            <Card sx={{ padding: 0 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box p={2}>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ color: colors.success[300], marginBottom: 2 }}
                    >
                      Info
                    </Typography>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>IVR #</Typography>
                        <Typography>{ivrNumber}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>
                          Patient
                        </Typography>
                        <Typography>{patientName}</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between">
                        <Typography color={colors.grey[500]}>DOB</Typography>
                        <Typography>{patientDOB}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Card>

            <Card sx={{ mt: 2}}>
              <Typography variant="h6" fontWeight="bold">
                Notes
              </Typography>
              <Box
                sx={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  border: 1,
                  borderColor: colors.grey[700],
                }}
              >
                {IVRNotesList?.map((note: any, index: number) => {
                  if (!(note.auditUserName in userSideMap)) {
                    userSideMap[note.auditUserName] =
                      lastAuditUserName === null ||
                      !userSideMap[lastAuditUserName];
                  }
                  const isLeft = userSideMap[note.auditUserName];
                  lastAuditUserName = note.auditUserName;

                  return (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent={isLeft ? "flex-start" : "flex-end"}
                      p={2}
                    >
                      {isLeft && (
                        <Avatar
                          sx={{
                            marginRight: 1,
                            fontSize: 20,
                            fontWeight: "bold",
                            backgroundColor: colors.info[300],
                          }}
                        >
                          {note.auditUserName.charAt(0)}
                        </Avatar>
                      )}
                      <Box
                        sx={{
                          padding: 1,
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "#1c252e"
                              : "e9ebec",
                          minWidth: "30%",
                          maxWidth: "100%",
                          borderRadius: isLeft
                            ? "1px 10px 10px 10px"
                            : "10px 1px 10px 10px",
                          border: 1,
                          borderColor: colors.grey[700],
                        }}
                      >
                        <Typography variant="subtitle1">
                          {note.auditUserName}
                        </Typography>
                        <Typography variant="body1" mt={1} fontWeight="bold">
                          {note.content}
                        </Typography>

                        {note.attachementId && (
                          <Stack
                            alignItems="center"
                            direction="row"
                            gap={1}
                            onClick={() => handleActionPreview(note, "note")}
                            mt={1}
                            color={colors.info[300]}
                            sx={{ cursor: "pointer" }}
                          >
                            <Typography variant="h5">
                              {note.documentName}
                            </Typography>
                            <SimCardDownloadOutlined sx={{ fontSize: 30 }} />
                          </Stack>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                          <Typography variant="h6" color="textSecondary">
                            {note.auditTimestamp}
                          </Typography>
                        </Box>
                      </Box>

                      {!isLeft && (
                        <Avatar
                          sx={{
                            marginLeft: 1,
                            fontSize: 20,
                            fontWeight: "bold",
                          }}
                        >
                          {note.auditUserName.charAt(0)}
                        </Avatar>
                      )}
                    </Box>
                  );
                })}
              </Box>
              <Divider />
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  pt: 2,
                }}
              >
                <Box style={{ display: `${hideElement(!canNoteIVR)}` }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    <IconButton
                      color="default"
                      component="label"
                      sx={{
                        p: 2,
                        mr: 1,
                        borderRadius: 2,
                        backgroundColor: colors.grey[700],
                      }}
                    >
                      <AttachFile />
                      <input
                        type="file"
                        hidden
                        onChange={handleNotDocumentFileChange}
                      />
                    </IconButton>
                    <CustomTextField
                      controllerName="content"
                      control={control}
                      label="Notes"
                      multiline
                      rules={{ required: "Notes are required." }}
                    />
                    <IconButton
                      sx={{
                        p: 2,
                        ml: 1,
                        borderRadius: 2,
                        backgroundColor: colors.warning[300],
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      <Send sx={{ color: colors.grey[900] }} />
                    </IconButton>
                  </Box>
                  {selectedFileName && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: colors.info[500],
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontStyle: "italic",
                          mr: 1,
                          color: colors.grey[100],
                        }}
                      >
                        {selectedFileName}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={handleRemoveFile}
                        sx={{
                          color: colors.error[300],
                        }}
                      >
                        <CancelOutlined />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" justifyContent="end" mt="20px"  pb={5}>
        <Button
          sx={{ mr: 1 }}
          color="error"
          startIcon={<CancelOutlined />}
          onClick={() =>
            navigate("/ivr_list", { state: { withSearchHistory: true } })
          }
          // onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default IVRDetails;
